import React, { useContext } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { ZCDCheckbox } from '@zencity/common-ui';
import { WidgetGeneratorContext } from '../contexts/WidgetGeneratorContext';
import { GenericQuestionSelection } from './utilsFunctionsAdditionalFields/GenericQuestionSelection/GenericQuestionSelection';
import styles from './WidgetAdditionalFields.module.scss';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';

export const ScoreTextWithSentimentBarAdditionalFields = (): React.ReactElement => {
  const { selectedSurveyGroup } = useContext(WidgetGeneratorContext);
  const { handleBenchmarkCycle, isBenchmarked, showAllSatisfactionScores, handleShowAllSatisfactionScores } =
    useContext(WidgetsConfigsContext);

  return (
    <div className={styles.fieldContainer}>
      <GenericQuestionSelection selectedSurveyGroup={selectedSurveyGroup} />
      <Spacer orientation="vertical" size="xsmall" />
      <ZCDCheckbox
        label="Show all scores"
        checked={showAllSatisfactionScores}
        onChange={handleShowAllSatisfactionScores}
      />
      <Spacer orientation="vertical" size="xsmall" />
      <ZCDCheckbox
        label="Add benchmark data to previous cycle"
        checked={isBenchmarked}
        onChange={handleBenchmarkCycle}
      />
    </div>
  );
};
