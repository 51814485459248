import { IDocumentParagraphElement } from '@zencity/reporting-types';
import { lineBreakElement } from './lineBreakElement';

export const generateLineBreaks = (margin: number): IDocumentParagraphElement[] => {
  const marginFromTop = [];
  for (let textRow = 0; textRow < margin; textRow += 1) {
    marginFromTop.push(lineBreakElement);
  }
  return marginFromTop;
};
