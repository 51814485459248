import { ThunkAction, AnyAction } from '@reduxjs/toolkit';
import { reduceTranslationSections } from 'components/SurveyTranslationsList/SurveyTranslationsList.helpers';
import { setTranslationSections } from 'slices/surveyManage';
import { RootState } from 'store';

/**
 * Update the translation items after any translation request has been fulfilled.
 */
export const updateTranslationSections =
  (surveyId: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const {
      questions: questionsSlice,
      genericQuestions: genericQuestionsSlice,
      surveys: surveysSlice,
      surveyManage: surveyManageSlice,
      endScreens: endScreensSlice,
    } = getState();
    const reducedTranslationSections = reduceTranslationSections(
      questionsSlice.questions,
      surveysSlice.surveys[surveyId],
      genericQuestionsSlice.genericQuestionsById,
      surveyManageSlice.resolvedIndexes,
      endScreensSlice.endScreens,
    );
    dispatch(setTranslationSections(reducedTranslationSections));
  };
