/* eslint-disable max-statements */
import { ZCDSelect } from '@zencity/common-ui';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchClients } from 'services/client';
import { logger } from 'utils/logger';
import { statusIsError, statusIsIdle, statusIsLoading } from 'utils/misc';
import { FieldTitle } from '../FieldTitle/FieldTitle';

interface Props {
  stateValue: number;
  onChangeCallback: (v: number) => void;
}

export const ClientSelection = (props: Props): ReactElement => {
  const { stateValue, onChangeCallback } = props;
  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();
  const { clientsSortedByName, fetchStatus, errorMessage } = useAppSelector((state) => state.clients);
  const { t: translate } = useTranslation();

  const options = useMemo(
    () =>
      Object.values(clientsSortedByName).map((client) => ({
        value: client.id,
        key: `client-${client.id}`,
        label: client.state ? `${client.name}, ${client.state}` : client.name,
      })),
    [clientsSortedByName],
  );
  const selectedValue = options.find((option) => option.value === stateValue);
  const handleClientSelection = (newValue: unknown) => {
    const selected = newValue as { value: number };
    onChangeCallback(selected.value);
  };
  /**
   * Fetch the clients from the server if still idled.
   */
  useEffect(() => {
    if (statusIsIdle(fetchStatus)) {
      dispatch(fetchClients());
    }
    if (errorMessage) {
      toastError();
      logger.error(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, errorMessage, fetchStatus]);

  if (errorMessage && statusIsError(fetchStatus)) {
    return <> </>;
  }

  return (
    <>
      <FieldTitle title={`${translate('widgetGenerator.selectClient')}`} />
      <ZCDSelect
        placeholder="Select client"
        options={options}
        onChange={handleClientSelection}
        value={selectedValue}
        isLoading={statusIsLoading(fetchStatus)}
      />
    </>
  );
};
