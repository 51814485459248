import { ZCDSelect } from '@zencity/common-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from 'screens/WidgetGenerator/components/FieldTitle/FieldTitle';
import { AspectValue, DemographicGeographicType } from 'screens/WidgetGenerator/utils/misc';
import { WidgetsConfigsContext } from 'screens/WidgetGenerator/contexts/WidgetsConfigsContext';
import { logger } from 'utils/logger';
import { WidgetGeneratorContext } from 'screens/WidgetGenerator/contexts/WidgetGeneratorContext';
import { CROSS_QUESTION_TYPES } from 'screens/WidgetGenerator/widgets/CrossTabs/utils';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { SurveyGroup } from '@zencity/survey-types';
import { getGenericQuestionsData } from 'screens/WidgetGenerator/widgetAdditionalFields/utilsFunctionsAdditionalFields/GenericQuestionSelection/utils';

interface GenericQuestionSelectionProps {
  selectedSurveyGroup?: SurveyGroup;
  aspect?: AspectValue;
}

export const CrossGenericQuestionMultiSelection = (props: GenericQuestionSelectionProps): React.ReactElement => {
  const { selectedSurveyGroup, aspect } = props;
  const [genericQuestionOptions, setGenericQuestionOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    crossTabQuestionSelectedOptions,
    setCrossTabQuestionSelectedOptions,
    selectedDemographicGeographic,
    setSelectedDemographicGeographic,
  } = useContext(WidgetsConfigsContext);
  const { selectedDateRange } = useContext(WidgetGeneratorContext);
  const shouldDisableSelection = !selectedSurveyGroup || !genericQuestionOptions.length;
  const { t: translate } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const fetchedGenericQuestionOptions = await getGenericQuestionsData({
          aspect,
          selectedDateRange,
          selectedSurveyGroup,
          questionTypesToInclude: CROSS_QUESTION_TYPES,
        });
        const extendedCrossGenericQuestions = [...fetchedGenericQuestionOptions] as SelectOption[];
        Object.entries(DemographicGeographicType).forEach(([key, value]) => {
          extendedCrossGenericQuestions.push({ value: key, label: value });
        });

        setGenericQuestionOptions(extendedCrossGenericQuestions);
      } catch (error) {
        logger.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [aspect, selectedDateRange, selectedSurveyGroup]);

  const handleSelectGenericQuestions = (newValue: unknown) => {
    const selectedGenericQuestionOptions = newValue as SelectOption[];
    const allSelectedGenericQuestions = selectedGenericQuestionOptions.filter(
      (value) => !Object.keys(DemographicGeographicType).includes(value.value as string),
    );
    const selectedDemographicAndGeographics = selectedGenericQuestionOptions.filter((value) =>
      Object.keys(DemographicGeographicType).includes(value.value as string),
    );
    setCrossTabQuestionSelectedOptions(
      allSelectedGenericQuestions.map((item) => ({
        value: item.value,
        label: item.label,
      })),
    );
    setSelectedDemographicGeographic(selectedDemographicAndGeographics.map((item) => item.value as string));
  };

  const selectedValues = crossTabQuestionSelectedOptions?.map(({ value }) =>
    genericQuestionOptions.find((genericQuesitonOption) => genericQuesitonOption.value === value),
  );

  if (selectedDemographicGeographic?.length) {
    selectedDemographicGeographic.forEach((demographicGeographic) => {
      const selectedDemographic =
        DemographicGeographicType[demographicGeographic as keyof typeof DemographicGeographicType];
      selectedValues?.push({ value: demographicGeographic, label: selectedDemographic });
    });
  }

  return (
    <>
      <FieldTitle title={translate('widgetGenerator.filters.crossTabs.selectCrossQuestions')} />
      <ZCDSelect
        isMulti
        placeholder={translate('widgetGenerator.filters.crossTabs.selectCrossQuestions')}
        options={genericQuestionOptions}
        onChange={handleSelectGenericQuestions}
        value={selectedValues}
        isDisabled={shouldDisableSelection}
        isLoading={isLoading}
      />
    </>
  );
};
