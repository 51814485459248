import React from 'react';
import { GenericReactTable } from 'screens/WidgetGenerator/components/GenericReactTable/GenericReactTable';
import { TableWrapperWidgetData } from 'screens/WidgetGenerator/utils/misc';
import styles from '../BenchmarkWidgets/benchmarkColors.module.scss';

interface TableWrapperProps {
  widgetData: TableWrapperWidgetData[];
}

const getTableHeaders = (itemsToMap: TableWrapperWidgetData[]): string[] => {
  if (itemsToMap.length > 0) {
    // assumes that each object in the list contains similar properties as the first one
    return Object.keys(itemsToMap[0]);
  }
  return [];
};

export function TableWrapper(props: TableWrapperProps): React.ReactElement {
  const { widgetData } = props;

  const headers = getTableHeaders(widgetData);

  const columns = headers.map((header) => ({
    Header: header,
    accessor: header,
    width: 'auto',
  }));

  return (
    <div className="tableWrapper">
      <GenericReactTable
        columns={columns}
        tableData={widgetData}
        getRowProps={(rowInTable) => ({
          style: {
            color: rowInTable.index === 0 ? styles.client : '',
            backgroundColor: styles.defaultTableColor,
          },
        })}
      />
    </div>
  );
}
