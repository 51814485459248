import { zcdConfirm } from '@zencity/common-ui';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ZCDDialogConfirmParams {
  title: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export const zcdDialogConfirm = async ({
  title,
  cancelButtonText = 'Cancel',
  message,
  confirmButtonText = 'Confirm',
}: ZCDDialogConfirmParams): Promise<boolean> => {
  const userResponse = await zcdConfirm({
    title,
    cancelButtonText,
    message,
    confirmButtonText,
  });
  return userResponse;
};
