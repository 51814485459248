import { DraggableType } from 'components/BuildForm/BuildForm.helpers';
import { QuestionWrapper } from 'components/QuestionWrapper/QuestionWrapper';
import { QuestionTypeProps } from 'components/QuestionWrapper/QuestionWrapper.declarations';
import { determineQuestionTypeComponent } from 'components/QuestionWrapper/QuestionWrapper.helpers';
import React, { ReactElement } from 'react';
import { ReactSortable, Sortable, SortableEvent } from 'react-sortablejs';
import { REACT_SORTABLE_OPTIONS } from 'constants/misc';
import { QuestionType } from 'types/genericQuestion';
import { QuestionItem } from 'types/questions';
import styles from './GroupQuestion.module.scss';

interface Props extends QuestionTypeProps {
  handleOnEndDrop: () => void;
  setQuestionItems: React.Dispatch<React.SetStateAction<QuestionItem[]>>;
  allowEditMode: boolean;
}

/**
 * The Group Question component on the "Build" screen.
 */
export const GroupQuestion = (props: Props): ReactElement => {
  const { questionItem, index, handleOnEndDrop, setQuestionItems, allowEditMode } = props;

  /**
   * Determines whether elements can be added from other lists,
   * or an array of group names from which elements can be taken.
   *
   * @returns Boolean indicating a specific element can be returned, or
   * an array of string types to accept.
   */
  // eslint-disable-next-line id-length,@typescript-eslint/no-unused-vars,prettier/prettier
  const handleOnPut = (to: Sortable, from: Sortable, draggedElement: HTMLElement, event: SortableEvent): Sortable.PutResult => {
    const { questionType } = draggedElement.dataset;
    if (questionType && questionType !== QuestionType.GROUP) {
      return true;
    }
    return [DraggableType.NORMAL_QUESTION];
  };

  /**
   * Updates the whole QuestionItems list when child question items are
   * dragged and dropped.
   */
  const handleOnSetChildrenList = (updatedChildrenList: QuestionItem[]): void => {
    setQuestionItems((sourceQuestionItems) => {
      const updatedQuestionItems = [...sourceQuestionItems];
      updatedQuestionItems[index].children = updatedChildrenList;
      return updatedQuestionItems;
    });
  };

  const { children } = questionItem;
  return (
    <QuestionWrapper questionItem={questionItem} index={index} withLargeText allowEditMode={allowEditMode}>
      {children && (
        <ReactSortable
          disabled={!allowEditMode}
          className={styles.groupQuestionChildrenList}
          list={children}
          setList={handleOnSetChildrenList}
          group={{
            name: DraggableType.NORMAL_QUESTION,
            put: handleOnPut,
          }}
          onEnd={handleOnEndDrop}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...REACT_SORTABLE_OPTIONS}
        >
          {children.map((childQuestion, childIndex) =>
            determineQuestionTypeComponent(childQuestion, childIndex, allowEditMode, index),
          )}
        </ReactSortable>
      )}
    </QuestionWrapper>
  );
};
