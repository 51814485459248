import i18next from 'i18next';
import { QuestionnaireWidgetData } from 'screens/WidgetGenerator/utils/misc';
import { Aspect } from 'types/aspect';
import { QuestionType } from 'types/genericQuestion';
import { Question } from 'types/questions';

export const filterQuestionnaireData = (questions: Question[]): Question[] => {
  const questionTypesToExclude: QuestionType[] = [QuestionType.GROUP, QuestionType.STATEMENT];
  return questions.filter((question) => !questionTypesToExclude.includes(question.question_type as QuestionType));
};
export const formatQuestionnaireData = (questions: Question[]): QuestionnaireWidgetData[] => {
  // Group questions by aspect to return an array of QuestionnaireWidgetData
  const questionnaireData: QuestionnaireWidgetData[] = [];
  const aspects: Aspect[] = [];
  questions.forEach((question) => {
    if (!aspects.find((aspect) => aspect.id === question.aspect.id)) {
      aspects.push(question.aspect);
    }
  });
  aspects.forEach((aspect) => {
    const aspectQuestions = questions.filter((question) => question.aspect.id === aspect.id);
    questionnaireData.push({
      aspect,
      questions: aspectQuestions,
    });
  });
  return questionnaireData;
};

// Convert a string to title case.
export const formatToTitleCase = (text: string) =>
  text
    .split('_')
    .filter((char) => char.length > 0)
    .map((char) => char.charAt(0).toUpperCase() + char.slice(1))
    .join(' ');

const QUESTION_TYPE_NAME_DICTIONARY = {
  [QuestionType.CHOICES]: i18next.t('aspects.choices'),
  [QuestionType.DROPDOWN]: i18next.t('aspects.dropdown'),
  [QuestionType.EMAIL]: i18next.t('aspects.email'),
  [QuestionType.GROUP]: i18next.t('aspects.group'),
  [QuestionType.NUMBER]: i18next.t('aspects.number'),
  [QuestionType.OPEN_ENDED]: i18next.t('aspects.openEnded'),
  [QuestionType.SCALE]: i18next.t('aspects.scale'),
  [QuestionType.STATEMENT]: i18next.t('aspects.statement'),
  [QuestionType.YES_NO]: i18next.t('aspects.yesNo'),
  [QuestionType.ZIP_CODE]: i18next.t('aspects.zipCode'),
};

interface GenerateDisplayTextPayload {
  questionType: string;
  isDisplayOther: boolean;
  choices: QuestionnaireWidgetData['questions'][0]['choices'];
  steps?: string;
  startAtOne?: boolean;
}

export const generateMainDisplayText = ({
  questionType,
  isDisplayOther,
  choices,
  startAtOne,
  steps,
}: GenerateDisplayTextPayload) => {
  const questionTypesWithChoices = [QuestionType.CHOICES, QuestionType.DROPDOWN, QuestionType.YES_NO];
  if (questionTypesWithChoices.includes(questionType as QuestionType) && choices?.length) {
    const choicesText = choices.map((choice) => choice.label).join(' / ');
    return isDisplayOther ? `${choicesText} / ${i18next.t('common.other')}` : choicesText;
  }

  if (questionType === QuestionType.SCALE) {
    if (!steps) {
      return i18next.t('aspects.scale');
    }
    return `${startAtOne ? 1 : 0} - ${steps} ${i18next.t('aspects.scale')}`;
  }

  return QUESTION_TYPE_NAME_DICTIONARY?.[questionType as QuestionType] ?? formatToTitleCase(questionType);
};
