import { BenchmarkCycleExecution } from 'types/benchmarkCycleExecution';
import { generateVaultApiUrl, createAxiosInstance } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/api/v2/benchmark_cycle_executions/');
const axiosInstance = createAxiosInstance(apiUrl);

export const fetchBenchmarkCycleExecutions = async (): Promise<BenchmarkCycleExecution[]> => {
  const response = await axiosInstance.get<BenchmarkCycleExecution[]>('');
  return response.data;
};
