import { ZCDIcon, ZCDIconColor } from '@zencity/common-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TypeformSurveyLanguageItem.module.scss';

interface ShareSurveyButtonProps {
  onClick: () => void;
}
export const ShareSurveyButton = ({ onClick }: ShareSurveyButtonProps): JSX.Element => {
  const { t: translate } = useTranslation();
  return (
    <button type="button" className={styles.shareSurveyButton} onClick={onClick}>
      {translate('surveyManage.typeformSurvey.shareSurvey')}
      <ZCDIcon name="arrow-right" color={ZCDIconColor.BLUE} />
    </button>
  );
};
