/* eslint-disable max-lines */
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { fetchEndScreensBySurveyId } from 'services/endScreen';
import { fetchGenericQuestionsByIds } from 'services/genericQuestion';
import { fetchQuestionsBySurveyId } from 'services/questions';
import { fetchSurveyById } from 'services/survey';
import { updateGenericQuestions } from 'slices/genericQuestion';
import { initialState as globalConfigInitialState } from 'slices/globalConfig';
import { setCurrentSurveyId } from 'slices/questions';
import { setSurveyInStore } from 'slices/survey';
import type { AppDispatch, RootState } from 'store';
import { Question } from 'types/questions';
import { findMissingGenericQuestions } from 'utils/question';
import { currentSurveyEndScreensAreNotFetched, currentSurveyQuestionsAreNotFetched } from 'utils/survey';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAppDispatch = (): any => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * React Error Boundaries do not account for errors in an asynchronous function/call.
 * This hook can be used in a component and will trigger an Error Boundary.
 *
 * For reference: https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
 */
export const useAsyncError = (): ((error: Error) => void) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setError] = React.useState();
  return React.useCallback(
    (error) => {
      setError(() => {
        throw error;
      });
    },
    [setError],
  );
};

/**
 * Fetches the relevant survey and adds it to the `surveys` slice state.
 */
export const useCurrentSurvey = (surveyId: number): Error | undefined => {
  const dispatch = useAppDispatch();
  const { surveys } = useAppSelector((state: RootState) => state.surveys);
  const [fetchError, setFetchError] = useState<Error>();

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const survey = await fetchSurveyById(surveyId);
        dispatch(setSurveyInStore(survey));
      } catch (error) {
        const asError = error as Error;
        setFetchError(asError);
      }
    };

    if (!Object.keys(surveys).includes(surveyId.toString())) {
      fetchSurvey();
    }
  }, [surveyId, surveys, dispatch]);

  return fetchError;
};

/**
 * Fetch the survey's questions if the fetched questions
 * do not align with the survey ID or no questions have been fetched yet.
 */
export const useCurrentSurveyQuestions = (surveyId: number): string | undefined => {
  const dispatch = useAppDispatch();
  const { questions, currentSurveyId, errorMessage, fetchStatus } = useAppSelector(
    (state: RootState) => state.questions,
  );

  useEffect(() => {
    // Fetch the survey's questions if the fetched questions do not align with the survey ID,
    // or no questions have been fetched yet.
    if (currentSurveyQuestionsAreNotFetched(currentSurveyId, surveyId, fetchStatus)) {
      dispatch(fetchQuestionsBySurveyId({ survey: surveyId }));
      dispatch(setCurrentSurveyId(surveyId));
    }
  }, [questions, dispatch, currentSurveyId, fetchStatus, surveyId]);

  return errorMessage;
};

/**
 * Fetch the survey's end screens if the fetched end screens
 * do not align with the survey ID or no end screens have been fetched yet.
 */
export const useCurrentSurveyEndScreens = (surveyId: number): string | undefined => {
  const dispatch = useAppDispatch();
  const { currentSurveyId } = useAppSelector((state: RootState) => state.questions);
  const { endScreens, errorMessage, fetchStatus } = useAppSelector((state: RootState) => state.endScreens);

  useEffect(() => {
    // Fetch the survey's end screens if the fetched end screens do not align with the survey ID,
    // or no end screens have been fetched yet.
    if (currentSurveyEndScreensAreNotFetched(currentSurveyId, surveyId, fetchStatus)) {
      dispatch(fetchEndScreensBySurveyId({ survey: surveyId }));
    }
  }, [endScreens, dispatch, currentSurveyId, fetchStatus, surveyId]);

  return errorMessage;
};

/**
 * A hook to determine whether to use the selected language or not.
 */
export const useSelectedLanguage = (): string | undefined => {
  const { selectedLanguage } = useAppSelector((state: RootState) => state.surveyManage);

  // Use the selected language only when it's not the default language ('en').
  // This is because all the content is originally in English, and the app is
  // built in such way that it uses it by default anyway.
  return selectedLanguage !== globalConfigInitialState.defaultLanguage ? selectedLanguage : undefined;
};

/**
 * Fetches any missing question's generic questions and updates the
 * `genericQuestions` slice state accordingly.
 * @param questions - The current survey's questions.
 * @returns Boolean indicating the fetch status of the generic questions.
 */
export const useCurrentGenericQuestions = (questions: Question[]): [boolean, Error | undefined] => {
  const [loadingGenericQuestions, setLoadingGenericQuestions] = useState(false);
  const dispatch = useAppDispatch();
  const { genericQuestionsById } = useAppSelector((state: RootState) => state.genericQuestions);
  const [fetchError, setFetchError] = useState<Error>();

  useEffect(() => {
    const fetchGenericQuestions = async (genericQuestionIds: number[]) => {
      setLoadingGenericQuestions(true);
      try {
        const response = await fetchGenericQuestionsByIds(genericQuestionIds);
        dispatch(updateGenericQuestions(response.results));
      } catch (error) {
        const asError = error as Error;
        setFetchError(asError);
      }
      setLoadingGenericQuestions(false);
    };

    const missingGenericQuestionIds = findMissingGenericQuestions(questions, genericQuestionsById);

    if (missingGenericQuestionIds.length) {
      fetchGenericQuestions(missingGenericQuestionIds);
    }
  }, [dispatch, questions, genericQuestionsById]);

  return [loadingGenericQuestions, fetchError];
};

/**
 * Determine whether the initial render of a component already happened.
 */
export function useFirstMountState(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

/**
 * A helper hook to handle an outside click of an element.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useOutsideClickListener(wrapperRef: MutableRefObject<any>, callbackToInvoke: () => void): void {
  useEffect(() => {
    function handleOnOutsideClick(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        callbackToInvoke();
      }
    }

    // Bind the event.
    document.addEventListener('mousedown', handleOnOutsideClick);
    return () => {
      // Unbind the event on cleanup.
      document.removeEventListener('mousedown', handleOnOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);
}

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

/**
 * A helper hook that copy text to the clipboard.
 */
export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}

// TODO: This is a temporary solution until we add React Query to the project.
// This will be handled in task: https://zencity.atlassian.net/browse/SRV-938.
// Once added, this hook will be removed.
export function useAsyncEffect<T>(
  asyncFunction: () => Promise<T>,
  dependencies: any[],
): {
  fetchedData?: T;
  isLoading: boolean;
  error?: Error;
} {
  const [fetchedData, setFetchedData] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        if (isMounted) {
          setIsLoading(true);
        }
        const result = await asyncFunction();
        if (isMounted) {
          setFetchedData(result);
        }
        // eslint-disable-next-line id-length
      } catch (err) {
        if (isMounted) {
          setError(err as Error);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return { fetchedData, isLoading, error };
}
