import { Dropdown } from 'components/Dropdown/Dropdown';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchAspects } from 'services/aspect';
import { logger } from 'utils/logger';
import { statusIsIdle } from 'utils/misc';

interface Props {
  stateValue: string;
  onChangeCallback: (v: string) => void;
  showEmptyOption?: boolean;
  dropdownClassName?: string;
}

/**
 * A Dropdown component for the Aspects.
 */
export const AspectsDropdown = (props: Props): ReactElement => {
  const { stateValue, onChangeCallback, showEmptyOption = true, dropdownClassName } = props;
  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const { aspectsSortedByLabel, fetchStatus, errorMessage } = useAppSelector((state) => state.aspects);

  const options = useMemo(
    () =>
      Object.values(aspectsSortedByLabel).map((aspect) => ({
        value: aspect.id,
        key: `aspect-${aspect.id}`,
        label: aspect.label,
      })),
    [aspectsSortedByLabel],
  );

  /**
   * Fetch the Aspects from the server if still idled.
   */
  useEffect(() => {
    if (statusIsIdle(fetchStatus)) {
      dispatch(fetchAspects({}));
    }
    if (errorMessage) {
      toastError();
      logger.error(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus, dispatch, errorMessage]);

  return (
    <Dropdown
      selectedValue={stateValue}
      onChangeCallback={onChangeCallback}
      emptyOptionLabel={showEmptyOption ? translate('common.aspect') : undefined}
      options={options}
      dropdownClassName={dropdownClassName}
    />
  );
};
