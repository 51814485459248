import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { SurveyGroup } from '@zencity/survey-types';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from 'screens/WidgetGenerator/components/FieldTitle/FieldTitle';
import { DateFormat, formatISODate } from 'utils/dates';

interface SurveyGroupSelectionProps {
  surveyGroups: SurveyGroup[];
  handleSelectSurveyGroup: (newValue: unknown) => void;
  selectedSurveyGroup?: SurveyGroup;
}

export const SurveyGroupSelection = (props: SurveyGroupSelectionProps): ReactElement => {
  const { handleSelectSurveyGroup, selectedSurveyGroup, surveyGroups } = props;
  const [surveyGroupOptions, setSurveyGroupOptions] = useState<SelectOption[]>([]);
  const selectedValue = surveyGroupOptions.find(({ value }) => value === selectedSurveyGroup?.id);
  const { t: translate } = useTranslation();

  useEffect(() => {
    const mappedResults = surveyGroups.map<SelectOption>((surveyGroup) => ({
      value: surveyGroup.id,
      label: `${surveyGroup.title_for_display} - ${formatISODate(
        surveyGroup.created_at,
        DateFormat.SLASHES_WITHOUT_TIME,
      )}`,
    }));
    setSurveyGroupOptions(mappedResults);
  }, [surveyGroups]);

  return (
    <>
      <FieldTitle title={translate('widgetGenerator.selectSurveyGroup')} />
      <ZCDSelect
        placeholder={translate('widgetGenerator.selectSurveyGroup')}
        options={surveyGroupOptions}
        onChange={handleSelectSurveyGroup}
        value={selectedValue}
        isDisabled={!surveyGroups.length}
      />
    </>
  );
};
