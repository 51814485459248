import React from 'react';

import { ZCDSelect } from '@zencity/common-ui';
import { Keys } from '@zencity/survey-types';
import { User } from 'types/user';
import AnalysisAppEntry from './AnalysisAppEntry';
import SurveyClientConfigurationEntry from './SurveyClientConfigurationEntry';
import SurveyManagerAppEntry from './SurveyManagerAppEntry';
import VolumeTrackerAppEntry from './VolumeTrackerAppEntry';
import WidgetGeneratorAppEntry from './WidgetGeneratorAppEntry';

import './index.scss';

const devMockUser: User = {
  id: process.env.X_ZC_USER_ID || 'mocked-user-invalid-id',
  firstName: 'Jane',
  lastName: 'Doe',
};

const clientId = process.env.X_ZC_CLIENT_ID || 'mocked-client-invalid-id';

interface EntryOption {
  label: keyof typeof EntryApps;
  value: keyof typeof EntryApps;
}

const EntryApps = {
  SurveyManagerAppEntry,
  SurveyClientConfigurationEntry,
  AnalysisAppEntry,
  VolumeTrackerAppEntry,
  WidgetGeneratorAppEntry,
};
export default function ScreenSelection() {
  const [selectedComponent, setSelectedComponent] = React.useState<keyof typeof EntryApps>('SurveyManagerAppEntry');
  const entries = Object.keys(EntryApps) as Keys<typeof EntryApps>[];
  const SelectedComponent = EntryApps[selectedComponent];

  const handleSelectChange = (option: null | EntryOption) => {
    if (option) {
      setSelectedComponent(option.value);
    }
  };

  return (
    <>
      <ZCDSelect<EntryOption>
        options={entries.map((entryPointName) => ({
          label: entryPointName,
          value: entryPointName,
        }))}
        onChange={handleSelectChange}
      />
      {devMockUser && <SelectedComponent clientId={clientId} user={devMockUser} />}
    </>
  );
}
