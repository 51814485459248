import { SentimentScore } from '@zencity/survey-types';
import React from 'react';
import { SentimentBar } from 'screens/WidgetGenerator/components/SentimentBar/SentimentBar';

interface TableSentimentProps {
  score: SentimentScore;
  prevPositiveValue?: number;
  isBenchmarked?: boolean;
}
export const TableSentiment = ({ score, prevPositiveValue, isBenchmarked }: TableSentimentProps): JSX.Element => (
  <SentimentBar
    positive={score?.positive}
    neutral={score?.neutral}
    negative={score?.negative}
    positiveText
    prevPositiveValue={prevPositiveValue}
    isBenchmarked={isBenchmarked}
  />
);
