import { BuildForm, BuildFormScreen } from 'components/BuildForm/BuildForm';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { statusIsSuccessful } from 'utils/misc';
import styles from './SurveyPreview.module.scss';

/**
 * Shows a preview of a survey using the same form as the one seen on the Build page.
 */
export const SurveyPreview = (): ReactElement => {
  const { t: translate } = useTranslation();

  const { selectedLanguage } = useAppSelector((state: RootState) => state.surveyManage);
  const { fetchStatus: questionsFetchStatus } = useAppSelector((state: RootState) => state.questions);

  if (!statusIsSuccessful(questionsFetchStatus)) {
    // TODO Change according to design.
    return <></>;
  }
  return (
    <div className={styles.surveyPreview}>
      <h2>{`${translate('surveyManage.surveyPreview.previewHeader')} (${selectedLanguage})`}</h2>
      <BuildForm screen={BuildFormScreen.MANAGE} />
    </div>
  );
};
