/* eslint-disable max-statements */
import { getCyclesStartingFromSelectedCycle } from 'screens/WidgetGenerator/utils/cycles';
import {
  AnswersSummaryData,
  fetchAnswersChoicesQuestionSummary,
} from 'services/communitySurveyReport/answersChoicesQuestionSummary';
import { LeanClient } from 'types/client';
import { logger } from 'utils/logger';
import { ChoicesQuestionChartData, SelectedDateRange, SurveyCycle, ChoiceData } from '../utils/misc';

interface FetchAnswersChoicesQuestionSummaryParams {
  client: LeanClient;
  cycles: SurveyCycle[];
  selectedDateRange: SelectedDateRange;
  questionId?: number;
  surveyGroupId?: number;
}
const FALLBACK_RESPONSE = {
  total_responses: 0,
  total_valid_responses: 0,
  total_weights: 0,
  choices: [],
};

const mapAnswersChoicesData = ({
  total_responses,
  total_valid_responses,
  total_weights,
  choices,
}: AnswersSummaryData) => {
  const mappedChoices: ChoiceData[] = choices.map(({ id, label, responses, valid_responses, weights_sum }) => ({
    responseOptions: [
      {
        count: responses,
        validCount: valid_responses,
        weightsSum: weights_sum,
        segmentLabel: '', // segmentLabel is empty but displayed to let users know they can edit the segmentation manually
      },
    ],
    id,
    responseContent: label,
  }));
  return {
    total_responses,
    total_valid_responses: total_valid_responses ?? 0,
    total_weights: total_weights ?? 0,
    choices: mappedChoices,
  };
};

const combineAnswersChoicesData = (
  mostRecentCycleMappedData: ChoicesQuestionChartData,
  previousCycleMappedData: ChoicesQuestionChartData,
) => {
  const combinedChoices: ChoiceData[] = mostRecentCycleMappedData.choices.map((choiceOption) => {
    const previousCycleChoiceOption = previousCycleMappedData.choices.find(
      (previousCycleChoice) => previousCycleChoice.id === choiceOption.id,
    );
    if (!previousCycleChoiceOption) {
      return choiceOption;
    }
    const combinedResponseOptions = choiceOption.responseOptions.map((responseOption, index) => ({
      count: responseOption.count,
      validCount: responseOption.validCount,
      weightsSum: responseOption.weightsSum,
      previousCycle: {
        count: previousCycleChoiceOption.responseOptions[index].count,
        validCount: previousCycleChoiceOption.responseOptions[index].validCount,
        weightsSum: previousCycleChoiceOption.responseOptions[index].weightsSum,
      },
      segmentLabel: responseOption.segmentLabel,
    }));

    return {
      responseOptions: combinedResponseOptions,
      id: choiceOption.id,
      responseContent: choiceOption.responseContent,
    };
  });

  return {
    total_responses: mostRecentCycleMappedData.total_responses,
    total_valid_responses: mostRecentCycleMappedData.total_valid_responses ?? 0,
    total_weights: mostRecentCycleMappedData.total_weights ?? 0,
    choices: combinedChoices,
    previousCycle: {
      total_responses: previousCycleMappedData.total_responses,
      total_valid_responses: previousCycleMappedData.total_valid_responses ?? 0,
      total_weights: previousCycleMappedData.total_weights ?? 0,
    },
  };
};

export const getAnswersChoicesQuestionSummary = async ({
  client,
  cycles,
  selectedDateRange,
  questionId,
  surveyGroupId,
}: FetchAnswersChoicesQuestionSummaryParams): Promise<ChoicesQuestionChartData> => {
  const filteredCycles = getCyclesStartingFromSelectedCycle(selectedDateRange, cycles);
  if (!filteredCycles.length) {
    return FALLBACK_RESPONSE;
  }
  const mostRecentCycleIndex = 0;
  const previousCycleIndex = 1;
  const { startDate, endDate } = filteredCycles[mostRecentCycleIndex];
  try {
    const mostRecentCycleAnswersSummary = await fetchAnswersChoicesQuestionSummary(
      {
        generic_question_id: questionId,
        start_date: startDate,
        end_date: endDate,
        survey_group_id: surveyGroupId,
        include_weights: true,
      },
      client,
    );
    const mostRecentCycleMappedData = mapAnswersChoicesData(mostRecentCycleAnswersSummary);

    if (!filteredCycles[previousCycleIndex]) {
      return mostRecentCycleMappedData;
    }
    const { startDate: previousCycleStartDate, endDate: previousCycleEndDate } = filteredCycles[previousCycleIndex];
    const previousCycleAnswersSummary = await fetchAnswersChoicesQuestionSummary(
      {
        generic_question_id: questionId,
        start_date: previousCycleStartDate,
        end_date: previousCycleEndDate,
        survey_group_id: surveyGroupId,
        include_weights: true,
      },
      client,
    );
    const previousCycleMappedData = mapAnswersChoicesData(previousCycleAnswersSummary);
    const combinedChoices = combineAnswersChoicesData(mostRecentCycleMappedData, previousCycleMappedData);
    return combinedChoices;
  } catch (error) {
    logger.error(error);
    return FALLBACK_RESPONSE;
  }
};
