import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentSurvey } from 'screens/SurveyEditStartScreen/SurveyEditStartScreen.helpers';
import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { Cadence } from '@zencity/survey-types';
import styles from '../../SurveyEditStartScreen.module.scss';

interface Props {
  relevantCadences: Cadence[];
  allowedToSelectCadence: boolean;
  currentSurvey: CurrentSurvey;
  setCurrentSurvey: React.Dispatch<React.SetStateAction<CurrentSurvey>>;
}

export const SurveyCadenceSelect = (props: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const { allowedToSelectCadence, relevantCadences, currentSurvey, setCurrentSurvey } = props;

  const cadenceSelectOptions: SelectOption[] = useMemo(
    () =>
      relevantCadences.map((cadence) => ({
        label: translate(`surveyCadences.${cadence}`),
        value: cadence,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [relevantCadences],
  );

  const [selectedCadence, setSelectedCadence] = useState<SelectOption>();

  const onSurveyCadenceChange = (newCadence: SelectOption) => {
    setSelectedCadence(newCadence);
    setCurrentSurvey((prevCurrentSurvey) => ({
      ...prevCurrentSurvey,
      surveyCadence: newCadence.value as Cadence,
    }));
  };

  useEffect(() => {
    if (!allowedToSelectCadence) {
      setSelectedCadence(undefined);
    } else if (currentSurvey.surveyCadence) {
      const currentSurveyCadenceOption = cadenceSelectOptions.find(
        (selectOption) => selectOption.value === currentSurvey.surveyCadence,
      );
      setSelectedCadence(currentSurveyCadenceOption);
    }
  }, [currentSurvey.surveyCadence, cadenceSelectOptions, allowedToSelectCadence]);

  return (
    <div className={styles.cadenceWrapper}>
      <ZCDSelect
        options={cadenceSelectOptions}
        isDisabled={!allowedToSelectCadence}
        value={selectedCadence || ''}
        onChange={(newValue) => onSurveyCadenceChange(newValue as SelectOption)}
      />
    </div>
  );
};
