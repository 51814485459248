import { SentimentScore } from '@zencity/survey-types';

export interface CommunitySurveyOverallScore {
  scores: SentimentScore;
  total_submissions: number;
  start_date: string;
  end_date: string;
}

export interface CommunitySurveyAspectsScore {
  [aspectValue: string]: CommunitySurveyOverallScore[];
}

export enum DemographicType {
  ETHNICITY = 'ethnicity',
  GENDER = 'gender',
  AGE_GROUP = 'age_group',
  EDUCATION = 'education',
  INCOME = 'income',
  ORIGIN = 'origin',
}

export interface CommunitySurveyDemographicsScore {
  demographic_value: string;
  demographic_label: string;
  demographic_type: DemographicType;
  scores: CommunitySurveyOverallScore[];
}

export interface CommunitySurveyGenericQuestionsScore {
  generic_question_id: number;
  text: string;
  aspect_value: string;
  scores: CommunitySurveyOverallScore[];
}

export interface CommunitySurveyAreasScore {
  [polygon_id: string]: {
    polygon_id: string;
    polygon_name: string;
    scores: CommunitySurveyOverallScore[];
  };
}
