import { ZCDButton, ZCDDialog, ZCDSelect, ZCDSpinner } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { QuestionTextArea } from 'components/QuestionTextArea/QuestionTextArea';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import i18next from 'i18next';
import React, { ReactElement, useContext, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNewEndScreen } from 'services/endScreen';
import { RootState } from 'store';
import { ScreenType } from 'types/endScreen';
import { logger } from 'utils/logger';
import styles from './NewEndScreenDialog.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | ((state: boolean) => void);
}

interface DialogState {
  title: string;
  description: string;
  screenType: SelectOption;
}

export enum DialogActionsType {
  UPDATE_TITLE = 'updateTitle',
  UPDATE_DESC = 'updateDesc',
  UPDATE_SCREEN_TYPE = 'updateScreenType',
  CLOSE = 'close',
}

type DialogActions =
  | { type: DialogActionsType.UPDATE_TITLE; payload: { title: string } }
  | { type: DialogActionsType.UPDATE_DESC; payload: { description: string } }
  | { type: DialogActionsType.UPDATE_SCREEN_TYPE; payload: { screenType: SelectOption } }
  | { type: DialogActionsType.CLOSE };

const initialState: DialogState = {
  title: i18next.t('addEndScreenDialog.addTitle'),
  description: i18next.t('addEndScreenDialog.addDescription'),
  screenType: { value: ScreenType.END_SCREEN, label: i18next.t(`screenType.end_screen`) },
};

const reducer = (state: DialogState, action: DialogActions) => {
  switch (action.type) {
    case DialogActionsType.UPDATE_TITLE: {
      const { title } = action.payload;
      return { ...state, title };
    }
    case DialogActionsType.UPDATE_DESC: {
      const { description } = action.payload;
      return { ...state, description };
    }
    case DialogActionsType.UPDATE_SCREEN_TYPE: {
      const { screenType } = action.payload;
      return { ...state, screenType };
    }
    case DialogActionsType.CLOSE:
      return { ...initialState };
    default:
      throw new Error();
  }
};

const options = Object.values(ScreenType).map((type) => ({
  value: type,
  key: `screen-type-${type}`,
  label: i18next.t(`screenType.${type}`),
}));

/**
 * A dialog to add the End Screen component.
 *
 * @param props
 *  - isOpen: Indicate whether the dialog should be open or not.
 *  - setIsOpen: A handler for toggling the `isOpen` variable.
 */
// eslint-disable-next-line max-statements,complexity,max-lines-per-function
export const NewEndScreenDialog: React.FC<Props> = function QuestionBankDialog(props: Props): ReactElement {
  const { isOpen = false, setIsOpen } = props;
  const { t: translate } = useTranslation();
  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();
  const { currentSurveyId } = useAppSelector((state: RootState) => state.questions);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, dispatchReducer] = useReducer(reducer, initialState);

  const handleScreenTypeChange = (selectedType: SelectOption): void => {
    dispatchReducer({ type: DialogActionsType.UPDATE_SCREEN_TYPE, payload: { screenType: selectedType } });
  };

  const formIsValid = !!state.title && !!state.screenType;

  const submitNewEndScreen = async () => {
    setIsSubmitting(true);
    try {
      await dispatch(
        addNewEndScreen({
          surveyId: currentSurveyId as number,
          title: state.title,
          description: state.description,
          screenType: state.screenType?.value as ScreenType,
        }),
      );
      setIsSubmitting(false);
      setIsOpen(false);
      dispatchReducer({ type: DialogActionsType.CLOSE });
    } catch (error) {
      toastError();
      logger.error(error);
    }
  };

  const handleClose = (): void => {
    setIsOpen(false);
    dispatchReducer({ type: DialogActionsType.CLOSE });
  };

  // eslint-disable-next-line react/no-multi-comp
  const Footer = (): ReactElement => (
    <div className={styles.footer}>
      <ZCDButton variant="link" onClick={handleClose} text={translate('common.cancel')} />
      {isSubmitting ? (
        <ZCDSpinner size={16} color={styles.whiteColor} />
      ) : (
        <ZCDButton
          onClick={submitNewEndScreen}
          disabled={!formIsValid}
          text={translate('addQuestionDialog.addButton')}
        />
      )}
    </div>
  );

  return (
    <ZCDDialog
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      header={<h1>{translate('addEndScreenDialog.newEndScreen')}</h1>}
      onRequestClose={handleClose}
      footer={<Footer />}
    >
      <form>
        <div className={styles.newScreenForm}>
          <div className={styles.input}>
            <div>
              <QuestionTextArea
                placeholder={translate('addEndScreenDialog.addTitle')}
                value={state.title}
                onChange={(event) =>
                  dispatchReducer({ type: DialogActionsType.UPDATE_TITLE, payload: { title: event.target.value } })
                }
              />
            </div>
            <textarea
              className={styles.screenDescription}
              placeholder={translate('addEndScreenDialog.addDescription')}
              value={state.description}
              onChange={(event) =>
                dispatchReducer({ type: DialogActionsType.UPDATE_DESC, payload: { description: event.target.value } })
              }
            />
          </div>
          <div className={styles.screenType}>
            <ZCDSelect
              options={options}
              value={state.screenType}
              onChange={(newValue) => handleScreenTypeChange(newValue as SelectOption)}
            />
          </div>
        </div>
      </form>
    </ZCDDialog>
  );
};
