import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { handleOnGenericQuestionSelect } from 'slices/questionsBank';
import { RootState } from 'store';
import { GenericQuestion, QuestionType } from 'types/genericQuestion';

interface Props {
  genericQuestion: GenericQuestion;
  addQuestionInsideGroup: boolean;
}

/**
 * This input radio is used in the context of selecting a generic question
 * from the `GenericQuestionsListTable` when adding a question to a survey.
 */
export const GenericQuestionRadioSelect = (props: Props): ReactElement => {
  const { genericQuestion, addQuestionInsideGroup } = props;
  const dispatch = useAppDispatch();
  const { selectedGenericQuestionId } = useAppSelector((state: RootState) => state.questionsBank);
  const disableGenericQuestion = genericQuestion.question_type === QuestionType.GROUP && addQuestionInsideGroup;

  const handleOnChange = () => dispatch(handleOnGenericQuestionSelect(genericQuestion.id));

  return (
    <input
      type="radio"
      disabled={disableGenericQuestion}
      checked={selectedGenericQuestionId === genericQuestion.id}
      onChange={handleOnChange}
    />
  );
};
