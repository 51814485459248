import { ZCDRadioButton, ZCDPopup, ZCDIcon, ZCDIconColor, ZCDCard } from '@zencity/common-ui';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentSurvey } from 'screens/SurveyEditStartScreen/SurveyEditStartScreen.helpers';
import { SurveyType } from 'types/survey';
import styles from '../../SurveyEditStartScreen.module.scss';

interface Props {
  inEditMode: boolean;
  existsSurveyRequest: boolean;
  currentSurvey: CurrentSurvey;
  setCurrentSurvey: React.Dispatch<React.SetStateAction<CurrentSurvey>>;
}

export const SurveyTypeSelect = (props: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const { inEditMode, existsSurveyRequest, currentSurvey, setCurrentSurvey } = props;
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const infoMessagePerSurveyType: { [surveyType: string]: string } = {
    [SurveyType.COMMUNITY_ASKS]: 'Community Asks can only be created from a Survey Request.',
  };
  const knownSurveyTypes = [
    SurveyType.COMMUNITY_SURVEY,
    SurveyType.BLOCKWISE,
    SurveyType.PULSE,
    SurveyType.COMMUNITY_ASKS,
    SurveyType.CUSTOMER_EXPERIENCE,
    SurveyType.NETWORK_SPEED,
  ];

  const toggleInfoMessagePopup = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  const onSurveyTypeChange = (newSurveyType: SurveyType) => {
    setCurrentSurvey((prevCurrentSurvey) => ({
      ...prevCurrentSurvey,
      surveyType: newSurveyType,
    }));
  };

  return (
    <div className={styles.radioButtonsWrapper}>
      {knownSurveyTypes.map((surveyType) => {
        const isCommunityAsksDisabled = surveyType === SurveyType.COMMUNITY_ASKS && !existsSurveyRequest;
        const isSurveyTypeInputDisabled = inEditMode || existsSurveyRequest || isCommunityAsksDisabled;
        const isChecked = currentSurvey.surveyType === surveyType;
        return (
          <div className={styles.surveyTypeContainer} key={`survey-type-${surveyType}`}>
            <ZCDRadioButton
              label={translate(`surveyType.${surveyType}`)}
              onSelect={(value) => onSurveyTypeChange(value as SurveyType)}
              disabled={isSurveyTypeInputDisabled}
              value={surveyType}
              checked={isChecked}
            />
            {surveyType in infoMessagePerSurveyType && (
              <ZCDPopup
                triggerElement={
                  <div onMouseEnter={toggleInfoMessagePopup} onMouseLeave={toggleInfoMessagePopup}>
                    <ZCDIcon name="info" color={ZCDIconColor.BLUE} />
                  </div>
                }
                isOpen={isInfoOpen}
                setIsOpen={setIsInfoOpen}
                placement="top-start"
              >
                <ZCDCard elevated>{infoMessagePerSurveyType[surveyType]}</ZCDCard>
              </ZCDPopup>
            )}
          </div>
        );
      })}
    </div>
  );
};
