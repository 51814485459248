/* eslint-disable max-lines-per-function */
import i18next from 'i18next';
import { getCyclesForScoring } from 'screens/WidgetGenerator/utils/cycles';
import {
  fetchCommunitySurveyAspectsScore,
  fetchCommunitySurveyGenericQuestionsScore,
} from 'services/communitySurveyScores';
import { AspectValues } from 'types/aspect';
import { LeanClient } from 'types/client';
import { logger } from 'utils/logger';
import { CommunitySurveyAspectsScore, CommunitySurveyOverallScore } from 'types/communitySurveyScores';
import {
  OverallSentimentAspect,
  AspectsData,
  OverallSentimentBarTableWidgetData,
  SelectedDateRange,
  SurveyCycle,
  OverallSatisfactionScoreQuestion,
} from 'screens/WidgetGenerator/utils/misc';

const FALLBACK_SCORE = { positive: 0, negative: 0, neutral: 0 };
const CHARACTERISTIC_ASPECT_VALUE = 'characteristic';
const FALLBACK_RESPONSE = {
  qualityOfLifeLastCycleScores: [
    {
      questionId: 0,
      score: FALLBACK_SCORE,
      previousCycle: {
        score: FALLBACK_SCORE,
      },
      genericQuestionId: 0,
      text: '',
      aspectValue: '',
    },
  ],
  characteristicsLastCycleScores: [
    {
      score: FALLBACK_SCORE,
      previousCycle: {
        score: FALLBACK_SCORE,
      },
      text: '',
    },
  ],
};

interface FetchScoresForCyclePayload {
  cycleIndex: number;
  relevantCycles: SurveyCycle[];
  client: LeanClient;
}

const fetchScoresForCycle = ({ cycleIndex, relevantCycles, client }: FetchScoresForCyclePayload) => {
  const { startDate, endDate } = relevantCycles[cycleIndex];

  if (!startDate || !endDate) {
    logger.warn('No start or end date for cycle in fetchScoresForCycle');
    return Promise.resolve([[], []]);
  }

  return Promise.all([
    fetchCommunitySurveyGenericQuestionsScore(client.id, {
      startDate,
      endDate,
    }),
    fetchCommunitySurveyAspectsScore(client.id),
  ]);
};

/**
 * Finds the scores for the selected date range and the comparison score, one before
 * it (if relevant).
 */
export const findCharacteristicsAspectScores = (
  communitySurveyAspectsScores: CommunitySurveyAspectsScore,
  selectedDateRange: SelectedDateRange,
): CommunitySurveyOverallScore[] => {
  const aspectsScores = communitySurveyAspectsScores as CommunitySurveyAspectsScore;
  const characteristicsAspectScores = aspectsScores[CHARACTERISTIC_ASPECT_VALUE] ?? [];
  if (!characteristicsAspectScores.length) return [];

  // Return the selected date ranges scores and the one before it.
  const selectedDateRangeIndex = characteristicsAspectScores.findIndex(
    (characteristicsAspectScore) =>
      characteristicsAspectScore.start_date === selectedDateRange.startDate &&
      characteristicsAspectScore.end_date === selectedDateRange.endDate,
  );
  if (selectedDateRangeIndex === -1) return [];
  const relevantCharacteristicsAspectScores = [characteristicsAspectScores[selectedDateRangeIndex]];

  const isNotOldestDateRange = selectedDateRangeIndex < characteristicsAspectScores.length - 1;
  if (isNotOldestDateRange) {
    relevantCharacteristicsAspectScores.push(characteristicsAspectScores[selectedDateRangeIndex + 1]);
  }
  return relevantCharacteristicsAspectScores;
};

export const mapCharacteristicsAspectScores = (
  characteristicsAspectScores: CommunitySurveyOverallScore[],
): OverallSentimentAspect[] => {
  const mappedCharacteristicsScores: AspectsData[] = characteristicsAspectScores.map((characteristicsAspectScore) => {
    const { scores, start_date, end_date } = characteristicsAspectScore;
    return {
      score: scores,
      dateRange: {
        startDate: start_date,
        endDate: end_date,
      },
    };
  });
  return mappedCharacteristicsScores.map((characteristicsScore) => ({
    score: characteristicsScore.score,
    text: i18next.t('widgetGenerator.widgets.sentimentBarOverallTable.characteristicsPlaceholder'),
  }));
};

interface SentimentBarOverallPayload {
  cycles: SurveyCycle[];
  client: LeanClient;
  selectedDateRange: SelectedDateRange;
}

// eslint-disable-next-line max-statements
export const fetchSentimentBarOverallData = async ({
  cycles,
  client,
  selectedDateRange,
}: SentimentBarOverallPayload): Promise<OverallSentimentBarTableWidgetData> => {
  const lastCycleIndex = 0;
  const previousCycleIndex = 1;
  const relevantCycles = getCyclesForScoring(selectedDateRange, cycles);

  try {
    const [qualityOfLifeLastCycleScores, communitySurveyAspectsScores] = await fetchScoresForCycle({
      cycleIndex: lastCycleIndex,
      relevantCycles,
      client,
    });
    if (!qualityOfLifeLastCycleScores.length || !communitySurveyAspectsScores) return FALLBACK_RESPONSE;

    const qualityOfLifeLastCycleScoresFiltered = qualityOfLifeLastCycleScores.filter(
      (genericQuestionScore) => genericQuestionScore.aspect_value === AspectValues.QUALITY_OF_LIFE,
    );
    const qualityOfLifeLastCycleScoresMapped =
      qualityOfLifeLastCycleScoresFiltered.map<OverallSatisfactionScoreQuestion>((genericQuestionScore) => ({
        // Since the scores request is filtered by the date range of the latest cycle,
        // it is expected that there is only one score in the scores array.
        genericQuestionId: genericQuestionScore.generic_question_id,
        score: genericQuestionScore.scores[0].scores,
        text: genericQuestionScore.text,
        aspectValue: genericQuestionScore.aspect_value,
      }));

    const relevantCharacteristicsAspectScores = findCharacteristicsAspectScores(
      communitySurveyAspectsScores as CommunitySurveyAspectsScore,
      selectedDateRange,
    );
    const remappedCharacteristicsScores: OverallSentimentAspect[] = mapCharacteristicsAspectScores(
      relevantCharacteristicsAspectScores,
    );

    if (relevantCycles.length < 2) {
      return {
        qualityOfLifeLastCycleScores: qualityOfLifeLastCycleScoresMapped,
        characteristicsLastCycleScores: remappedCharacteristicsScores,
      };
    }

    const qualityOfLifePreviousCycle = await fetchCommunitySurveyGenericQuestionsScore(client.id, {
      startDate: relevantCycles[previousCycleIndex].startDate,
      endDate: relevantCycles[previousCycleIndex].endDate,
    });
    remappedCharacteristicsScores[0].previousCycle = {
      score: remappedCharacteristicsScores[1]?.score ?? FALLBACK_SCORE,
    };

    return {
      qualityOfLifeLastCycleScores: qualityOfLifeLastCycleScoresMapped.map<OverallSatisfactionScoreQuestion>(
        (qualityOfLifeGenQScore) => {
          const prevCycle = qualityOfLifePreviousCycle.find(
            (prevCycleGenericQuestionScore) =>
              prevCycleGenericQuestionScore.generic_question_id === qualityOfLifeGenQScore.genericQuestionId,
          );
          // Since the scores are filtered by the date range of the comparison cycle, it is expected
          // that there will only be one score in the array.
          const prevCycleScore = prevCycle?.scores?.[0]?.scores ?? FALLBACK_SCORE;

          return {
            score: qualityOfLifeGenQScore.score,
            previousCycle: {
              score: prevCycleScore,
            },
            genericQuestionId: qualityOfLifeGenQScore.genericQuestionId,
            text: qualityOfLifeGenQScore.text,
            aspectValue: qualityOfLifeGenQScore.aspectValue,
          };
        },
      ),
      characteristicsLastCycleScores: [remappedCharacteristicsScores[0]],
    };
  } catch (error) {
    logger.error(error);
    return FALLBACK_RESPONSE;
  }
};
