import { SelectedDateRange } from 'screens/Analysis/misc';
import { BenchmarkOverallScore } from 'screens/WidgetGenerator/utils/misc';
import { BenchmarkType } from 'types/benchmarkType';
import { CommunitySurveyOverallScore } from 'types/communitySurveyScores';
import { CLIENT_BENCHMARK_TYPE } from 'screens/WidgetGenerator/widgets/BenchmarkWidgets/constants';
import styles from '../benchmarkColors.module.scss';
import { BenchmarkTextSatisfaction, BenchmarkOverallScoreText } from './types';

export const filterBenchmarkOverallScoresBySelectedDate = (
  selectedDateRange: SelectedDateRange,
  overallScores: BenchmarkOverallScore[],
): BenchmarkOverallScore[] => {
  if (!overallScores.length) {
    return overallScores;
  }
  const selectedDateRangeOverallScores = overallScores.filter(
    (overallScore) =>
      overallScore.start_date === selectedDateRange.startDate && overallScore.end_date === selectedDateRange.endDate,
  );
  return selectedDateRangeOverallScores;
};

export const getMostRecentCommunitySurveyOverallScore = (
  overallScores: CommunitySurveyOverallScore[],
): CommunitySurveyOverallScore | undefined => {
  if (!overallScores.length) {
    return undefined;
  }
  // Sort from newest to oldest. It is already expected to be returned
  // from newest to oldest, this is simply a precaution.
  const sortedOverallScores = overallScores.sort((firstScore, secondScore) => {
    const firstDate = new Date(firstScore.start_date);
    const secondDate = new Date(secondScore.start_date);
    return secondDate.getTime() - firstDate.getTime();
  });
  return sortedOverallScores[0];
};

export const findBenchmarkScoreByType = (
  benchmarkScores: BenchmarkOverallScore[],
  benchmarkType: BenchmarkType,
): BenchmarkOverallScore | undefined =>
  benchmarkScores.find((benchmarkScore) => benchmarkScore.benchmark_type === benchmarkType);

export const colorsByBenchmarkTextSatisfaction: Record<string, string> = {
  [BenchmarkTextSatisfaction.HIGHER]: styles[CLIENT_BENCHMARK_TYPE],
  [BenchmarkTextSatisfaction.LOWER]: styles[BenchmarkType.NATIONAL],
  [BenchmarkTextSatisfaction.SIMILAR]: styles[BenchmarkType.NATIONAL],
};

// eslint-disable-next-line max-statements
const generateBenchmarkText = (
  clientPositiveScore: number,
  nationalPositiveScore: number,
  cohortPositiveScore: number,
  selectedClientName: string,
): BenchmarkOverallScoreText => {
  const benchmarkOverallScoreText: BenchmarkOverallScoreText = {
    prefix: `The overall resident satisfaction in ${selectedClientName} is`,
    satisfactionCohort: BenchmarkTextSatisfaction.NONE,
    suffixCohort: '',
  };
  if (clientPositiveScore - cohortPositiveScore > 1) {
    benchmarkOverallScoreText.percentage = clientPositiveScore - cohortPositiveScore;
    benchmarkOverallScoreText.satisfactionCohort = BenchmarkTextSatisfaction.HIGHER;
    benchmarkOverallScoreText.suffixCohort = 'than its cohort.';
  } else if (clientPositiveScore - cohortPositiveScore <= 1 && clientPositiveScore - cohortPositiveScore >= -2) {
    benchmarkOverallScoreText.satisfactionCohort = BenchmarkTextSatisfaction.SIMILAR;
    if (clientPositiveScore > nationalPositiveScore) {
      benchmarkOverallScoreText.suffixCohort = 'to its cohort and';
      benchmarkOverallScoreText.satisfactionNational = BenchmarkTextSatisfaction.HIGHER;
      benchmarkOverallScoreText.suffixNational = 'than the national average.';
    } else {
      benchmarkOverallScoreText.suffixCohort = 'to its cohort.';
    }
  } else if (clientPositiveScore - cohortPositiveScore < -2) {
    benchmarkOverallScoreText.satisfactionCohort = BenchmarkTextSatisfaction.LOWER;
    if (clientPositiveScore > nationalPositiveScore) {
      benchmarkOverallScoreText.suffixCohort = 'than its cohort and';
      benchmarkOverallScoreText.satisfactionNational = BenchmarkTextSatisfaction.HIGHER;
      benchmarkOverallScoreText.suffixNational = 'than the national average.';
    } else {
      benchmarkOverallScoreText.suffixCohort = 'than its cohort.';
    }
  }
  return benchmarkOverallScoreText;
};

export const generateOverallScoreText = (
  selectedClientName: string,
  mostRecentClientOverallScore?: CommunitySurveyOverallScore,
  nationalBenchmarkScore?: BenchmarkOverallScore,
  cohortBenchmarkScore?: BenchmarkOverallScore,
): BenchmarkOverallScoreText => {
  // Get the most recent overall score for the selected date range.
  // Get the overall benchmark scores for the selected date range.

  // Compare the two scores and return the appropriate text.
  if (!mostRecentClientOverallScore || !nationalBenchmarkScore || !cohortBenchmarkScore) {
    throw new Error('Missing overall, national, or cohort score.');
  }
  const { positive: clientPositiveScore } = mostRecentClientOverallScore.scores;
  const { positive: nationalPositiveScore } = nationalBenchmarkScore.scores;
  const { positive: cohortPositiveScore } = cohortBenchmarkScore.scores;
  return generateBenchmarkText(clientPositiveScore, nationalPositiveScore, cohortPositiveScore, selectedClientName);
};
