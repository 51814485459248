import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { Icon } from '@zencity/common-ui';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './BreadcrumbLink.module.scss';

interface Props {
  text: string;
  route: string;
  active: boolean;
  disabled?: boolean;
  completed?: boolean;
}

/**
 * A breadcrumb link component for the "Survey edit" pages.
 * @param text - The inner text of the breadcrumb.
 * @param route - The route to navigate to when clicked/active.
 * @param active - Active boolean for styling purposes.
 * @param disabled - Disabled boolean so the breadcrumb cannot be clicked.
 * @param completed - Boolean for styling purposes to add a checkmark in the text.
 */
export const BreadcrumbLink = (props: Props): ReactElement => {
  const { text, route, active, disabled = false, completed = false } = props;
  const { pathname } = useLocation();

  return (
    <NavLink
      key={route}
      to={route}
      className={classnames(styles.breadcrumbLink, {
        [styles.active]: active,
        [styles.disabled]: disabled,
      })}
      state={{ previousPath: pathname }}
    >
      {completed && <Icon width={15} height={11} name="checkmark.svg" className={styles.icon} />}
      {text}
    </NavLink>
  );
};
