/* eslint-disable max-lines-per-function */
/* eslint-disable id-denylist */
import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { DocumentFragment } from '../documentFragment';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import { lineBreakElement } from '../utils/lineBreakElement';
import {
  reportTemplateTable,
  reportTemplateTableCell,
  TABLE_CELL_DEFAULT_PADDING,
  TABLE_DEFAULT_BORDER_COLOR,
} from '../utils/tableTemplate';
import { generateLineBreaks } from '../utils/utilsFunctions';

export const benchmarkExplanation = (clientName: string, headerElement: ILandscapeHeaderElement): DocumentFragment => {
  const benchmarkDescription1 =
    'Residents from more than 1,000 communities across the United States have participated in Zencity’s Community Surveys. We use the combined results from these surveys to produce our benchmark estimates. By averaging across respondents from dozens or hundreds of different communities, it’s possible to arrive at a picture of what the results for a “typical” community in that cohort look like. This offers a way to compare your results- particularly strengths and areas for improvement- within a greater context. Each community running a Community Survey with Zencity receives a customized community benchmark cohort that reflects its unique characteristics.';
  const benchmarkDescription2 =
    'First, the cities, towns, and counties in the United States are allocated into cohorts using variables such as population size, geography, density, and demographics to group similar communities together. Then, each cohort’s benchmarks are calculated using the same scoring methodology outlined in the Survey Methodology section. Finally, since recruitment methods can differ slightly according to the needs of each community, cohort benchmarks are adjusted accordingly to match the exact distribution of recruitment methods.';
  const leftColumnAboutBenchmarkTable = reportTemplateTableCell({
    data: [
      {
        name: 'heading3',
        children: [
          {
            data: `How we compare ${clientName}`,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
      {
        name: 'heading3',
        children: [
          {
            data: 'to other similar communities',
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
      lineBreakElement,
      lineBreakElement,
      {
        name: 'paragraph',
        children: [
          {
            data: benchmarkDescription1,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
            },
          },
        ],
      },
    ],
    tableCellPadding: TABLE_CELL_DEFAULT_PADDING,
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '50%',
  });
  const rightColumnAboutBenchmarkTable = reportTemplateTableCell({
    data: [
      ...generateLineBreaks(5),
      {
        name: 'paragraph',
        children: [
          {
            data: benchmarkDescription2,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
            },
          },
        ],
      },
    ],
    tableCellPadding: TABLE_CELL_DEFAULT_PADDING,
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '50%',
  });
  const aboutBenchmarkTable = reportTemplateTable({
    tableColumns: [leftColumnAboutBenchmarkTable, rightColumnAboutBenchmarkTable],
    tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  });

  return [
    headerElement,
    ...generateLineBreaks(2),
    aboutBenchmarkTable,
    {
      name: 'pageBreak',
    },
  ];
};
