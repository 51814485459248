import React, { useContext } from 'react';
import { AspectValue } from '../utils/misc';
import { CrossGenericQuestionMultiSelection } from './utilsFunctionsAdditionalFields/CrossGenericQuestionMultiSelection';
import { GenericQuestionSelection } from './utilsFunctionsAdditionalFields/GenericQuestionSelection/GenericQuestionSelection';
import styles from './WidgetAdditionalFields.module.scss';
import { WidgetGeneratorContext } from '../contexts/WidgetGeneratorContext';

export const CrossTabsWidgetAdditionalFields = (): React.ReactElement => {
  const { selectedSurveyGroup } = useContext(WidgetGeneratorContext);

  return (
    <div className={styles.fieldContainer}>
      <GenericQuestionSelection selectedSurveyGroup={selectedSurveyGroup} />
      <CrossGenericQuestionMultiSelection selectedSurveyGroup={selectedSurveyGroup} aspect={AspectValue.DEMOGRAPHICS} />
    </div>
  );
};
