/* eslint-disable camelcase */
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';
import { RakeWeighterExecution } from 'utils/rakeWeighterExecutions';

const apiUrl = generateVaultApiUrl('/api/v2/rake_weighter_executions/');
const axiosInstance = createAxiosInstance(apiUrl);

/**
 * Get the date ranges for a client's community survey cadence.
 * @param surveyGroupId - The survey group ID for the client's Community Surveys.
 */
export async function fetchRakeWeighterExecutions(params: { survey_group: number }): Promise<RakeWeighterExecution[]> {
  const result = await axiosInstance.get<RakeWeighterExecution[]>('', {
    params: { ...params, bypass_client_filter: true },
  });

  return result.data;
}
