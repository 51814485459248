import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@zencity/common-ui';
import styles from './SearchBoxInput.module.scss';

interface Props {
  stateValue?: string;
  onChangeCallback: (v: string) => void;
}

/**
 * A styled input component with a magnifying class icon, for search purposes.
 */
export const SearchBoxInput = (props: Props): ReactElement => {
  const { stateValue, onChangeCallback } = props;
  const { t: translate } = useTranslation();

  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCallback(event.currentTarget.value);
  };

  return (
    <div className={styles.searchBoxWrapper}>
      <input
        type="text"
        className={styles.searchBox}
        placeholder={translate('common.search')}
        value={stateValue || ''}
        onChange={onSearchInputChange}
      />
      <Icon name="magnifying-glass.svg" height={12} width={12} className={styles.magnifyingGlassIcon} />
    </div>
  );
};
