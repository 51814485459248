import { OverallSatisfactionScoreQuestion } from 'screens/WidgetGenerator/utils/misc';

export const getBarChartFilteredData = (
  widgetData: OverallSatisfactionScoreQuestion[],
): OverallSatisfactionScoreQuestion[] =>
  widgetData
    .filter((item) => item.aspectValue === 'characteristic')
    .sort((firstItem, secondItem) => secondItem.score.positive - firstItem.score.positive);

export const LOW_SATISFACTION_THRESHOLD = 27;
export const HIGH_SATISFACTION_THRESHOLD = 85;
export const LOW_SATISFACTION_THRESHOLD_SHORT_BAR = 40;
export const HIGH_SATISFACTION_THRESHOLD_SHORT_BAR = 80;
export const LOW_SATISFACTION_THRESHOLD_SINGLE = 14;
