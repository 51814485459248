import classnames from 'classnames';
import React, { ReactElement } from 'react';
import styles from './TypeformSurveyLanguageItem.module.scss';

interface PublishStatusIconProps {
  isPublished: boolean;
  isPublishable: boolean;
  isClosed: boolean;
}
export const PublishStatusIcon = (props: PublishStatusIconProps): ReactElement => {
  const { isPublished, isPublishable, isClosed } = props;
  return (
    <div className={styles.publishStatusIconContainer}>
      <div
        className={classnames(styles.publishStatusIcon, {
          [styles.active]: isPublished,
          [styles.unpublishable]: !isPublished && !isPublishable,
          [styles.closed]: isClosed,
        })}
      />
    </div>
  );
};
