import { Spacer } from 'components/Spacer/Spacer';
import React, { useContext } from 'react';
import { WidgetsConfigsContext } from 'screens/WidgetGenerator/contexts/WidgetsConfigsContext';
import { FieldTitle } from '../FieldTitle/FieldTitle';
import { CanvasWrapper } from './CanvasWrapper/CanvasWrapper';
import { SelectedWidget } from './SelectedWidget';

export const WidgetPreview = (): React.ReactElement => {
  const { widgetWidth, isValidData } = useContext(WidgetsConfigsContext);

  return (
    <div style={{ width: widgetWidth + 96 }}>
      {isValidData && (
        <>
          <FieldTitle title="Widget Preview:" />
          <Spacer orientation="vertical" size="xsmall" />
          <CanvasWrapper>
            <SelectedWidget />
          </CanvasWrapper>
        </>
      )}
    </div>
  );
};
