import { ZCDCheckbox } from '@zencity/common-ui';
import React, { useContext } from 'react';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';

export const SentimentBarOverallTableAdditionalFields = (): React.ReactElement => {
  const { handleBenchmarkCycle, isBenchmarked } = useContext(WidgetsConfigsContext);

  return (
    <ZCDCheckbox label="Add benchmark data to previous cycle" checked={isBenchmarked} onChange={handleBenchmarkCycle} />
  );
};
