import { configSetter } from 'components/NewGenericQuestion/NewGenericQuestion.helpers';
import styles from 'components/NewGenericQuestion/NewGenericQuestion.module.scss';
import { ScaleLabelInput } from 'components/ScaleLabelInput/ScaleLabelInput';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { setNewQuestionConfigs } from 'slices/questionsBank';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';

/**
 * Displays the specific form section of the `scale` question type.
 */
export const ScaleMainSection = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { newQuestionPayload } = useAppSelector((state: RootState) => state.questionsBank);

  const firstLabel = newQuestionPayload.configs.find(
    ({ config_name }) => config_name === QuestionConfigType.SCALE_LABEL_FIRST,
  )?.config_value;

  const middleLabel = newQuestionPayload.configs.find(
    ({ config_name }) => config_name === QuestionConfigType.SCALE_LABEL_MIDDLE,
  )?.config_value;

  const lastLabel = newQuestionPayload.configs.find(
    ({ config_name }) => config_name === QuestionConfigType.SCALE_LABEL_LAST,
  )?.config_value;

  const onScaleLabelChange = (configName: QuestionConfigType) => (value: string) =>
    dispatch(setNewQuestionConfigs(configSetter(newQuestionPayload, configName, value)));

  return (
    <div className={styles.sectionLayout}>
      <ScaleLabelInput
        scaleLabelType={QuestionConfigType.SCALE_LABEL_FIRST}
        stateValue={firstLabel}
        onChangeCallback={onScaleLabelChange}
      />
      <ScaleLabelInput
        scaleLabelType={QuestionConfigType.SCALE_LABEL_MIDDLE}
        stateValue={middleLabel}
        onChangeCallback={onScaleLabelChange}
      />
      <ScaleLabelInput
        scaleLabelType={QuestionConfigType.SCALE_LABEL_LAST}
        stateValue={lastLabel}
        onChangeCallback={onScaleLabelChange}
      />
    </div>
  );
};
