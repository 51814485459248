import { ZCDButton } from '@zencity/common-ui';
import { ActionButtonAsIcon } from 'components/ActionButtonAsIcon/ActionButtonAsIcon';
import { QuestionIndex } from 'components/QuestionIndex/QuestionIndex';
import { SingleTranslationForm } from 'components/SingleTranslationForm/SingleTranslationForm';
import { TranslationItem, TranslationSection } from 'components/SurveyTranslationsList/SurveyTranslationsList.helpers';
import { TranslationDialog } from 'components/TranslationDialog/TranslationDialog';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import styles from './TranslationPageItem.module.scss';

interface Props {
  translationSection: TranslationSection;
}

/**
 * Handles the translation for a single question + all of it's translatable relevant fields
 * (choices, configs, etc.) on the "Manage" page.
 */
export const TranslationPageItem = (props: Props): ReactElement => {
  const { translationSection } = props;
  const { t: translate } = useTranslation();
  const { selectedLanguage, selectedTypeformSurvey } = useAppSelector((state: RootState) => state.surveyManage);
  const [translateDialogTranslationItem, setTranslateDialogTranslationItem] = useState<TranslationItem>();

  const translatableFieldTranslationPath = 'surveyTranslationFormLayout.translatableFields';

  const handleOnEditClick = (translationItem: TranslationItem) => {
    setTranslateDialogTranslationItem(translationItem);
  };

  const { translationItems, resolvedIndex } = translationSection;

  // The page item translation form should only show the current text
  // and the translated text.
  const filteredTranslationItems = translationItems.map((translationItem) => {
    const { instanceId, textToTranslate, fieldName, translatedTexts } = translationItem;
    const filteredTranslationItem: TranslationItem = {
      instanceId,
      textToTranslate,
      fieldName,
      translatedTexts,
    };
    return filteredTranslationItem;
  });

  return (
    <>
      <div className={styles.translationSection}>
        {resolvedIndex && (
          <div className={styles.indexWrapper}>
            <QuestionIndex resolvedIndex={resolvedIndex} />
          </div>
        )}
        {translationItems.map((translationItem, index) => {
          const allowTranslationEditing =
            !selectedTypeformSurvey?.is_locked && !!translationItem.translatedTexts[selectedLanguage];
          return (
            <div
              key={`translation-item-field-${translationItem.fieldName}-instance-${translationItem.instanceId}`}
              className={styles.translationItem}
            >
              <p>{translate(`${translatableFieldTranslationPath}.${translationItem.fieldName}`)}</p>
              <section className={styles.translationFormContainer}>
                {/* The translation shown on the translation page should not show any original translations. */}
                <SingleTranslationForm allowEditMode={false} translationItem={filteredTranslationItems[index]} />
                {allowTranslationEditing && (
                  <ActionButtonAsIcon iconName="edit" handleOnButtonClick={() => handleOnEditClick(translationItem)} />
                )}
                {!translationItem.translatedTexts[selectedLanguage] && (
                  <ZCDButton
                    variant="secondary"
                    size="md"
                    onClick={() => handleOnEditClick(translationItem)}
                    text={translate('common.add')}
                  />
                )}
              </section>
              <TranslationDialog
                isOpen={translateDialogTranslationItem === translationItem}
                setTranslateDialogTranslationItem={setTranslateDialogTranslationItem}
                translationItem={translationItem}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
