import { format, parseISO } from 'date-fns';

export enum DateFormat {
  SLASHES = 'dd/MM/yyyy HH:mm',
  SLASHES_WITHOUT_TIME = 'dd/MM/yyyy',
  COMMAS_WITH_MONTH = 'MMM dd, yyyy HH:mm',
}

/**
 * Formats an ISO date string to a new format.
 *
 * @param dateISO - Date string in ISO format.
 * @param formatType - The string format for the returned string. See the accepted tokens here: https://date-fns.org/v1.30.1/docs/format.
 */
export const formatISODate = (dateISO: string, formatType: DateFormat): string => format(parseISO(dateISO), formatType);
