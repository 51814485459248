import { LeanClient } from 'types/client';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';
import { ListEntry } from 'wordcloud';

const apiUrl = generateVaultApiUrl('/api/v2/answers/word_cloud/');
const axiosInstance = createAxiosInstance(apiUrl);

interface WordCloudParams {
  generic_question: number;
  start_date: string;
  end_date: string;
}

/**
 * Fetch leading sub topic for a positive/negative sentiment generic questions (open ended).
 */
export async function fetchWordCloudData(params: WordCloudParams, client: LeanClient): Promise<ListEntry[]> {
  const result = await axiosInstance.get('', {
    params,
    headers: { 'X-Zc-Client-Id': client.zc_client_id },
  });
  return result.data?.results?.popular_words;
}
