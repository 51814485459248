import { getCyclesForScoring } from 'screens/WidgetGenerator/utils/cycles';
import { fetchCommunitySurveyGenericQuestionsScore } from 'services/communitySurveyScores';
import { LeanClient } from 'types/client';
import { logger } from 'utils/logger';
import { lowerCaseOnlyFirstLetter } from '../utils/helpers';
import {
  SelectedDateRange,
  ScoreTextWithSentimentBarWidgetData,
  SurveyCycle,
  OverallSatisfactionScoreQuestion,
} from '../utils/misc';

const FALLBACK_SCORE = { positive: 0, negative: 0, neutral: 0 };

export const getSatisfactionBarChartData = async (
  client: LeanClient,
  cycles: SurveyCycle[],
  selectedDateRange: SelectedDateRange,
): Promise<OverallSatisfactionScoreQuestion[]> => {
  const cyclesForScoring = getCyclesForScoring(selectedDateRange, cycles);
  const selectedCycleIndex = 0;
  const previousCycleIndex = 1;
  try {
    const genericQuestionScores = await fetchCommunitySurveyGenericQuestionsScore(client.id, {
      startDate: cyclesForScoring[selectedCycleIndex].startDate,
      endDate: cyclesForScoring[selectedCycleIndex].endDate,
    });
    if (!genericQuestionScores.length) {
      return [];
    }
    const genericQuestionScoresMapped = genericQuestionScores.map<OverallSatisfactionScoreQuestion>(
      (genericQuestionScore) => ({
        score: genericQuestionScore.scores[0].scores,
        genericQuestionId: genericQuestionScore.generic_question_id,
        text: genericQuestionScore.text,
        aspectValue: genericQuestionScore.aspect_value,
      }),
    );
    if (cyclesForScoring.length > 1) {
      const questionsScoresPreviousCycle = await fetchCommunitySurveyGenericQuestionsScore(client.id, {
        startDate: cyclesForScoring[previousCycleIndex].startDate,
        endDate: cyclesForScoring[previousCycleIndex].endDate,
      });
      const questionsScores = genericQuestionScoresMapped.map<OverallSatisfactionScoreQuestion>(
        (genericQuestionScore) => {
          const previousCycleQuestionData = questionsScoresPreviousCycle.find(
            (prevCycleGenericQuestionScore) =>
              prevCycleGenericQuestionScore.generic_question_id === genericQuestionScore.genericQuestionId,
          );
          const previousCycleScore = previousCycleQuestionData?.scores?.[0].scores;
          const previousCycle = previousCycleScore && { score: previousCycleScore };

          return {
            score: genericQuestionScore.score,
            previousCycle,
            genericQuestionId: genericQuestionScore.genericQuestionId,
            text: genericQuestionScore.text,
            aspectValue: genericQuestionScore.aspectValue,
          };
        },
      );
      return questionsScores;
    }
    return genericQuestionScoresMapped;
  } catch (error) {
    logger.error(error);
    return [];
  }
};
export const getQuestionScoreData = async (
  client: LeanClient,
  cycles: SurveyCycle[],
  selectedDateRange: SelectedDateRange,
  genericQuestionId?: number,
): Promise<ScoreTextWithSentimentBarWidgetData[]> => {
  const questionsScores: OverallSatisfactionScoreQuestion[] = await getSatisfactionBarChartData(
    client,
    cycles,
    selectedDateRange,
  );
  const questionScore = questionsScores.find((dataItem) => dataItem.genericQuestionId === genericQuestionId);
  const sentimentBarDefaultText = 'of residents are satisfied with the ';
  if (!questionScore) {
    return [{ score: FALLBACK_SCORE, text: sentimentBarDefaultText }];
  }
  return [
    {
      score: questionScore.score,
      previousCycle: questionScore.previousCycle,
      text: sentimentBarDefaultText + lowerCaseOnlyFirstLetter(questionScore?.text ?? ''),
    },
  ];
};
