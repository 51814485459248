import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './OverrideQuestion.module.scss';

interface ShowingOriginalProps {
  value?: string;
}
/**
 * Display a value with the "Original" prefix.
 *
 * The idea is to be able to display a layer of the original values
 * (i.e., the Generic Question's values), along with the form to override these
 * values.
 */
export const ShowingOriginalValue = ({ value }: ShowingOriginalProps): ReactElement => {
  const { t: translate } = useTranslation();

  const originalValue = value ?? translate('common.blank');

  return (
    <div className={styles.showingOriginal}>
      <span>{translate('common.original')}:</span>
      {originalValue}
    </div>
  );
};
