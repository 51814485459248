import { ZCDButton } from '@zencity/common-ui';
import {
  filterConfigsByQuestionType,
  resolveConfigValue,
} from 'components/GenericQuestionTeaserView/TeaserAdditionalInfo/ConfigsInfo/ConfigsInfo.helpers';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericQuestion } from 'types/genericQuestion';
import styles from '../../GenericQuestionTeaserView.module.scss';

interface Props {
  genericQuestion: GenericQuestion;
}

export const ConfigsInfo = (props: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const [showConfigs, setShowConfigs] = useState(false);

  const toggleShowConfigs = () => {
    setShowConfigs(!showConfigs);
  };

  const { genericQuestion } = props;

  const filteredConfigsByQuestionType = filterConfigsByQuestionType(genericQuestion);
  const resolvedConfigValues = filteredConfigsByQuestionType.reduce<string[]>((configsForDisplay, config) => {
    const resolvedConfigValue = resolveConfigValue(config.config_name, config.config_value);
    if (resolvedConfigValue) {
      return [...configsForDisplay, resolvedConfigValue];
    }
    return configsForDisplay;
  }, []);

  return (
    <div className={styles.additionalInfo}>
      {resolvedConfigValues.length !== 0 && (
        <ZCDButton text={`${translate('configsInfo.questionConfigs')}...`} variant="link" onClick={toggleShowConfigs} />
      )}
      {showConfigs && (
        <ul className={styles.configsList}>
          {resolvedConfigValues.map((configValue) => (
            <li key={configValue} className={styles.configListItem}>
              {configValue}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
