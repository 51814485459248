/* eslint-disable id-denylist */
import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { ASPECTS_LABELS, AspectValues } from 'types/aspect';
import { DocumentFragment } from '../documentFragment';
import { reportTemplateTable, reportTemplateTableCell, TABLE_DEFAULT_BORDER_COLOR } from '../utils/tableTemplate';
import { SurveysReportGeneratorDataItemKey, SurveysReportGeneratorFilterKey } from '../../constants';
import { lineBreakElement } from '../utils/lineBreakElement';

export const overallBenchmarkScore = (headerElement: ILandscapeHeaderElement): DocumentFragment => {
  const leftColumnOverallBenchmarkTable = reportTemplateTableCell({
    data: [
      {
        name: 'reactItem',
        attributes: {
          definitionKey: SurveysReportGeneratorDataItemKey.BENCHMARK_OVERALL_SATISFACTION,
        },
      },
      lineBreakElement,
      {
        name: 'reactItem',
        attributes: {
          definitionKey: SurveysReportGeneratorDataItemKey.BENCHMARK_TABLE_LEGEND,
        },
      },
    ],
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '35%',
  });
  const rightColumnOverallBenchmarkTable = reportTemplateTableCell({
    data: [
      {
        name: 'reactItem',
        attributes: {
          definitionKey: SurveysReportGeneratorDataItemKey.QUESTIONS_BENCHMARK_TABLE,
          params: {
            filters: {
              [SurveysReportGeneratorFilterKey.BENCHMARK_ASPECT_SELECT]: {
                value: AspectValues.QUALITY_OF_LIFE,
                label: ASPECTS_LABELS.quality_of_life,
              },
            },
          },
        },
      },
    ],
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '60%',
  });
  const middleColumnOverallBenchmarkTable = reportTemplateTableCell({
    data: [lineBreakElement],
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '5%',
  });
  const overallScoreBenchmarkTable = reportTemplateTable({
    tableColumns: [
      leftColumnOverallBenchmarkTable,
      middleColumnOverallBenchmarkTable,
      rightColumnOverallBenchmarkTable,
    ],
    tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  });

  return [
    headerElement,
    overallScoreBenchmarkTable,
    {
      name: 'pageBreak',
    },
  ];
};
