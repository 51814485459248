import React from 'react';
import { UnrepresentativeIndicator } from 'screens/WidgetGenerator/components/UnrepresentativeIndicator/UnrepresentativeIndicator';
import styles from './TableText.module.scss';

interface TableTextProps {
  text: string;
  description?: string;
  displayUnrepresentativeIndicator?: boolean;
  totalSubmissions?: number;
}
export const TableText = ({
  text,
  description,
  displayUnrepresentativeIndicator,
  totalSubmissions,
}: TableTextProps): JSX.Element => {
  const showUnrepresentativeIndicator = displayUnrepresentativeIndicator && totalSubmissions;
  return (
    <>
      <p className={styles.text}>
        {text} {showUnrepresentativeIndicator && <UnrepresentativeIndicator totalSubmissions={totalSubmissions} />}
      </p>
      {description && <p className="description">{description}</p>}
    </>
  );
};
