/* eslint-disable max-lines-per-function,max-statements */
import { Flex } from 'components/Flex/Flex';
import { ShareLinksDialog } from 'components/ShareLinksDialog/ShareLinksDialog';
import { PublishAction, TypeformPublishButton } from 'components/TypeformPublishButton/TypeformPublishButton';
import { determineIfSurveyIsRepublishable } from 'components/TypeformSurveyLanguageItem/TypeformSurveyLanguageItem.helpers';
import { TypeformSurveyTranslateButton } from 'components/TypeformSurveyTranslateButton/TypeformSurveyTranslateButton';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  setCurrentDisplay,
  setCurrentLanguage,
  setSelectedTypeformSurvey,
  SurveyManageDisplay,
} from 'slices/surveyManage';
import { RootState } from 'store';
import { TypeformSurvey, TypeformSurveyStatus } from 'types/typeformSurvey';
import { DateFormat, formatISODate } from 'utils/dates';
import { PublishStatusIcon } from './PublishStatusIcon';
import { ShareSurveyButton } from './ShareSurveyButton';
import styles from './TypeformSurveyLanguageItem.module.scss';

interface Props {
  typeformSurvey: TypeformSurvey;
}

/**
 * This component contains options for publishing, translating, and sharing a Typeform survey by language.
 */
export const TypeformSurveyLanguageItem = (props: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { questions } = useAppSelector((state: RootState) => state.questions);
  const [shareLinksDialogIsOpen, setShareLinksDialogIsOpen] = useState(false);
  const { languages, defaultLanguage } = useAppSelector((state: RootState) => state.globalConfigs);
  const { selectedTypeformSurvey } = useAppSelector((state: RootState) => state.surveyManage);
  const { genericQuestionsById } = useAppSelector((state: RootState) => state.genericQuestions);
  const { endScreens } = useAppSelector((state: RootState) => state.endScreens);
  const { typeformSurvey } = props;

  const { translationSections, currentSurveyId, typeformSurveysById } = useAppSelector(
    (state: RootState) => state.surveyManage,
  );

  const { published_date, id: typeformSurveyId, language, status, closed_date: closedDate } = typeformSurvey;
  const publishedDate = published_date ?? '';

  const missingTranslationsCount = useMemo(() => {
    const allTranslationItems = translationSections
      .map((translationSection) => translationSection.translationItems)
      .flat();
    const filteredTranslationItemsByLanguage = allTranslationItems.filter(
      (translationItem) => !translationItem.translatedTexts[typeformSurvey.language],
    );
    return filteredTranslationItemsByLanguage.length;
  }, [translationSections, typeformSurvey]);

  const isPublished = status === TypeformSurveyStatus.ACTIVE && !!publishedDate;
  const genericQuestions = Object.values(genericQuestionsById).filter((genericQuestion) =>
    questions.some((question) => question.generic_question === genericQuestion.id),
  );

  const isRepublishable = determineIfSurveyIsRepublishable({
    isPublished,
    missingTranslationsCount,
    questions,
    genericQuestions,
    publishedDate,
    typeformSurvey,
    endScreens,
  });
  const isClosed = status === TypeformSurveyStatus.CLOSED && !!closedDate;
  const isPublishable =
    missingTranslationsCount === 0 && status === TypeformSurveyStatus.DRAFTED && questions.length > 0;
  const surveyItemRadioId = `typeform-survey-${typeformSurveyId}`;

  // eslint-disable-next-line react/no-multi-comp
  const SurveyStatusText = () => {
    let statusText = '';
    if (missingTranslationsCount > 0) {
      statusText = `${translate('surveyManage.typeformSurvey.missingTranslations', { missingTranslationsCount })}`;
    }
    if (isPublished) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      statusText = `${translate('common.published')}: ${formatISODate(publishedDate!, DateFormat.COMMAS_WITH_MONTH)}`;
    } else if (isPublishable) {
      statusText = `${translate('surveyManage.typeformSurvey.available')}`;
    } else if (isClosed) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      statusText = `${translate('common.closed')}: ${formatISODate(closedDate!, DateFormat.COMMAS_WITH_MONTH)}`;
    } else if (isRepublishable) {
      statusText = `${translate('common.published')}: ${formatISODate(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        publishedDate!,
        DateFormat.COMMAS_WITH_MONTH,
      )}. New changes waiting to be published.`;
    }

    return <p className={styles.surveyStatusText}>{statusText}</p>;
  };

  const handleOnItemClick = (
    event: React.MouseEvent<HTMLDivElement | HTMLInputElement | HTMLButtonElement, MouseEvent>,
    surveyDisplay: SurveyManageDisplay,
  ) => {
    event.stopPropagation();
    dispatch(setSelectedTypeformSurvey(typeformSurvey));
    dispatch(setCurrentLanguage(language));
    dispatch(setCurrentDisplay(surveyDisplay));
  };

  const handleOnShareSurveyClick = () => {
    setShareLinksDialogIsOpen(true);
  };

  const panelVendorTypeformSurveys = Object.values(typeformSurveysById ?? {}).filter((currentTypeformSurvey) => {
    const isSameLanguage = typeformSurvey.language === currentTypeformSurvey.language;
    const isNotCurrentTypeformSurvey = typeformSurvey.id !== currentTypeformSurvey.id;
    const isCurrentSurveyId = currentTypeformSurvey.survey === currentSurveyId;
    const existsPanelSurvey = !!currentTypeformSurvey.panel;

    return isSameLanguage && isNotCurrentTypeformSurvey && isCurrentSurveyId && existsPanelSurvey;
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={styles.surveyItem} onClick={(event) => handleOnItemClick(event, SurveyManageDisplay.PREVIEW)}>
      <section className={styles.content}>
        <div className={styles.radioOption}>
          <label htmlFor={surveyItemRadioId}>
            <input
              type="radio"
              checked={selectedTypeformSurvey?.id === typeformSurveyId}
              onClick={(event) => handleOnItemClick(event, SurveyManageDisplay.PREVIEW)}
              readOnly
            />
            {languages[language]}
            <PublishStatusIcon isPublishable={isPublishable} isPublished={isPublished} isClosed={isClosed} />
          </label>
        </div>
        <SurveyStatusText />
        <Flex flexDirection="column">
          {isPublishable && (
            <TypeformPublishButton typeformSurvey={typeformSurvey} publishAction={PublishAction.PUBLISH} />
          )}
          {isRepublishable && (
            <TypeformPublishButton typeformSurvey={typeformSurvey} publishAction={PublishAction.REPUBLISH} />
          )}
          {isPublished && (
            <>
              <ShareSurveyButton onClick={handleOnShareSurveyClick} />
              <ShareLinksDialog
                isOpen={shareLinksDialogIsOpen}
                setIsOpen={setShareLinksDialogIsOpen}
                typeformSurvey={typeformSurvey}
                panelVendorTypeformSurveys={panelVendorTypeformSurveys}
              />
            </>
          )}
          {language !== defaultLanguage && (
            <TypeformSurveyTranslateButton
              onClickHandler={(event) => handleOnItemClick(event, SurveyManageDisplay.TRANSLATION)}
            />
          )}
        </Flex>
      </section>
    </div>
  );
};
