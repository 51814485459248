import i18next from 'i18next';

export interface Aspect {
  id: number;
  value: string;
  label: string;
}

export enum AspectValues {
  QUALITY_OF_LIFE = 'quality_of_life',
  TRANSPORTATION = 'transportation',
  PUBLIC_HEALTH = 'public_health',
  PUBLIC_SAFETY = 'public_safety',
  PARKS_AND_RECREATION = 'parks_and_recreation',
  CHARACTERISTIC = 'characteristic',
  GENERAL = 'general',
  DEMOGRAPHICS = 'demographics',
  POLICE_WORK = 'police_work',
  PERCEPTION_OF_SAFETY = 'perception_of_safety',
  APPROACHABILITY = 'approachability',
  LOCAL_PRESENCE = 'local_presence',
  PATROLLING = 'patrolling',
}
// TODO: Get these variables from Vault so they won't be hardcoded
// https://zencity.atlassian.net/browse/SRV-1075
export const ASPECTS_LABELS: Record<AspectValues, string> = {
  [AspectValues.QUALITY_OF_LIFE]: i18next.t('aspectsLabels.qualityOfLife'),
  [AspectValues.TRANSPORTATION]: i18next.t('aspectsLabels.transportation'),
  [AspectValues.PUBLIC_HEALTH]: i18next.t('aspectsLabels.publicHealth'),
  [AspectValues.PUBLIC_SAFETY]: i18next.t('aspectsLabels.publicSafety'),
  [AspectValues.PARKS_AND_RECREATION]: i18next.t('aspectsLabels.parksAndRecreation'),
  [AspectValues.CHARACTERISTIC]: i18next.t('aspectsLabels.characteristic'),
  [AspectValues.GENERAL]: i18next.t('aspectsLabels.general'),
  [AspectValues.DEMOGRAPHICS]: i18next.t('aspectsLabels.demographics'),
  [AspectValues.POLICE_WORK]: i18next.t('aspectsLabels.policeWork'),
  [AspectValues.PERCEPTION_OF_SAFETY]: i18next.t('aspectsLabels.perceptionOfSafety'),
  [AspectValues.APPROACHABILITY]: i18next.t('aspectsLabels.approachability'),
  [AspectValues.LOCAL_PRESENCE]: i18next.t('aspectsLabels.localPresence'),
  [AspectValues.PATROLLING]: i18next.t('aspectsLabels.patrolling'),
};
