import { DebounceDelay } from 'constants/misc';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { useDebouncedCallback } from 'use-debounce';
import { statusIsError, statusIsLoading, statusIsSuccessful } from 'utils/misc';
import styles from './BuildFormAutoSaveIndication.module.scss';

/**
 * An indication to show the "save status" on the Build Form.
 */
export const BuildFormAutoSaveIndication = (): ReactElement => {
  const { t: translate } = useTranslation();
  const { updateStatus } = useAppSelector((state: RootState) => state.questions);
  const [displaySaving, setDisplaySaving] = useState(false);
  const [displaySaved, setDisplaySaved] = useState(false);

  const displayIndication = displaySaving || displaySaved;

  const debouncedHideSaved = useDebouncedCallback(() => {
    setDisplaySaved(false);
  }, DebounceDelay.AUTO_SAVE_INDICATION);

  useEffect(() => {
    if (statusIsLoading(updateStatus)) {
      setDisplaySaved(false);
      setDisplaySaving(true);
    }
    if (statusIsSuccessful(updateStatus)) {
      setDisplaySaving(false);
      setDisplaySaved(true);
      debouncedHideSaved();
    }
    if (statusIsError(updateStatus)) {
      setDisplaySaving(false);
      setDisplaySaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <div className={styles.indicationWrapper}>
      {displayIndication && (
        <div className={styles.indication}>
          {displaySaving && translate('autoSaveIndication.saving')}
          {displaySaved && translate('autoSaveIndication.saved')}
        </div>
      )}
    </div>
  );
};
