import React, { ReactElement } from 'react';
import { QuestionWrapper } from 'components/QuestionWrapper/QuestionWrapper';
import { QuestionTypeProps } from 'components/QuestionWrapper/QuestionWrapper.declarations';
import { useTranslation } from 'react-i18next';
import styles from './YesNoQuestion.module.scss';

/**
 * A Yes/No question. This component will only have two options, yes or no.
 */
export const YesNoQuestion = (props: QuestionTypeProps): ReactElement => {
  const { questionItem } = props;
  const { t: translate } = useTranslation();

  const questionItemChoiceOption = `choice-option-${questionItem.id}`;
  const yesOptionId = `${questionItemChoiceOption}-yes`;
  const noOptionId = `${questionItemChoiceOption}-no`;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <QuestionWrapper {...props}>
      <div className={styles.yesNoInputContainer}>
        <div key={yesOptionId} className={styles.radioOption}>
          <label htmlFor={yesOptionId}>
            <input id={yesOptionId} type="radio" disabled />
            {translate('common.yes')}
          </label>
        </div>
        <div key={noOptionId} className={styles.radioOption}>
          <label htmlFor={noOptionId}>
            <input id={noOptionId} type="radio" disabled />
            {translate('common.no')}
          </label>
        </div>
      </div>
    </QuestionWrapper>
  );
};
