import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionType } from 'types/genericQuestion';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { sortByLabel } from 'components/Dropdown/DropdownSortHelper';

interface Props {
  stateValue: string;
  onChangeCallback: (v: string) => void;
  omitGroupQuestionType?: boolean;
  showEmptyOption?: boolean;
  dropdownClassName?: string;
}

/**
 * A Dropdown component for the "Question Type".
 */
export const QuestionTypeDropdown = (props: Props): ReactElement => {
  const {
    stateValue,
    onChangeCallback,
    omitGroupQuestionType = false,
    showEmptyOption = true,
    dropdownClassName,
  } = props;
  const { t: translate } = useTranslation();

  const options = sortByLabel(
    Object.values(QuestionType)
      .filter((questionType) => !omitGroupQuestionType || questionType !== QuestionType.GROUP)
      .map((questionType) => ({
        value: questionType,
        key: `question-type-${questionType}`,
        label: translate(`questionType.${questionType}`),
      })),
  );

  return (
    <Dropdown
      selectedValue={stateValue}
      onChangeCallback={onChangeCallback}
      emptyOptionLabel={showEmptyOption ? translate('common.questionType') : undefined}
      options={options}
      dropdownClassName={dropdownClassName}
    />
  );
};
