/* eslint-disable id-denylist */
import { format } from 'date-fns';
import { DocumentFragment, StructuredReport } from './templateParts/documentFragment';
import { SurveyCycle } from '../utils/misc';
import { header } from './templateParts/utils/header';
import { reportSummaryPage } from './templateParts/summaryReport/reportSummaryPage';
import { benchmarkExplanation } from './templateParts/benchmarkReport/benchmarkExplanation';
import { cohortExplanation } from './templateParts/benchmarkReport/cohortExplanation';
import { overallBenchmarkScore } from './templateParts/benchmarkReport/overallBenchmarkScore';
import { benchmarkServicesScore } from './templateParts/benchmarkReport/benchmarkServicesScore';
import { benchmarkCoverPage } from './templateParts/benchmarkReport/benchmarkCoverPage';

const formatCycleDateRange = (mostRecentCycle: SurveyCycle): string => {
  const startData = new Date(mostRecentCycle.startDate);
  const endDate = new Date(mostRecentCycle.endDate);
  const cycleStartMonth = format(startData, 'MMMM');
  const cycleEndMonth = format(endDate, 'MMMM');
  const cycleStartYear = format(startData, 'yyyy');
  const cycleEndYear = format(endDate, 'yyyy');
  const year = cycleStartYear === cycleEndYear ? cycleStartYear : `${cycleStartYear} - ${cycleEndYear}`;
  return `${cycleStartMonth} - ${cycleEndMonth} ${year}`;
};

export const benchmarkReportTemplate = (
  structuredBenchmarkReport: StructuredReport,
  withEndingPage = true,
): DocumentFragment => {
  const { client, mostRecentCycle } = structuredBenchmarkReport;
  const reportDateRange = formatCycleDateRange(mostRecentCycle);
  const clientName = client.state ? `${client.name}, ${client.state}` : client.name;
  const headerElement = header({ clientName, reportDateRange, headerTitle: 'Community Benchmark' });

  const endingPage = withEndingPage ? reportSummaryPage({ mostRecentCycle }) : [];

  return [
    ...benchmarkCoverPage(headerElement),
    ...benchmarkExplanation(clientName, headerElement),
    ...cohortExplanation(headerElement),
    ...overallBenchmarkScore(headerElement),
    ...benchmarkServicesScore(clientName, headerElement),
    ...endingPage,
  ];
};

export const generateBenchmarkReportTemplate = (
  structuredBenchmarkReport: StructuredReport,
  withEndingPage = true,
): DocumentFragment => {
  const summaryReportContent = benchmarkReportTemplate(structuredBenchmarkReport, withEndingPage);
  return summaryReportContent;
};
