import { Cadence } from '@zencity/survey-types';
import React, { ReactElement } from 'react';
import { SurveyEditClientsSection } from 'screens/SurveyEditStartScreen/components/SurveyEditClientsSection';
import { SurveyEditSurveyTypeSection } from 'screens/SurveyEditStartScreen/components/SurveyEditSurveyTypeSection';
import { SurveyEditTitlesSection } from 'screens/SurveyEditStartScreen/components/SurveyEditTitlesSection';
import { CurrentSurvey } from 'screens/SurveyEditStartScreen/SurveyEditStartScreen.helpers';
import styles from 'screens/SurveyEditStartScreen/SurveyEditStartScreen.module.scss';

interface Props {
  currentSurvey: CurrentSurvey;
  setCurrentSurvey: React.Dispatch<React.SetStateAction<CurrentSurvey>>;
  inCloneMode: boolean;
  relevantCadences: Cadence[];
  existsSurveyRequest: boolean;
}

export const SurveyForm = (props: Props): ReactElement => {
  const { currentSurvey, setCurrentSurvey, inCloneMode, relevantCadences, existsSurveyRequest } = props;
  const allowedToSelectCadence = !!relevantCadences.length;

  return (
    <div className={styles.surveyFormWrapper}>
      <SurveyEditClientsSection
        currentSurvey={currentSurvey}
        setCurrentSurvey={setCurrentSurvey}
        inCloneMode={inCloneMode}
        existsSurveyRequest={existsSurveyRequest}
      />

      {currentSurvey?.clientId && (
        <SurveyEditSurveyTypeSection
          allowedToSelectCadence={allowedToSelectCadence}
          currentSurvey={currentSurvey}
          relevantCadences={relevantCadences}
          setCurrentSurvey={setCurrentSurvey}
          inCloneMode={inCloneMode}
          existsSurveyRequest={existsSurveyRequest}
        />
      )}

      {currentSurvey?.clientId && currentSurvey?.surveyType && (
        <SurveyEditTitlesSection currentSurvey={currentSurvey} setCurrentSurvey={setCurrentSurvey} />
      )}
    </div>
  );
};
