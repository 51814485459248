/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchClients } from 'services/client';
import { ClientsById, LeanClient } from 'types/client';
import { RequestStatus } from 'types/misc';

export interface ClientsState {
  clientsSortedByName: LeanClient[];
  clientsById: ClientsById;
  fetchStatus: string;
  selectedClient?: LeanClient;
  errorMessage?: string;
}

const initialState: ClientsState = {
  clientsSortedByName: [],
  clientsById: {},
  fetchStatus: RequestStatus.IDLE,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchClients.pending, (state: ClientsState) => {
        state.fetchStatus = RequestStatus.LOADING;
      })
      .addCase(fetchClients.fulfilled, (state: ClientsState, action: PayloadAction<LeanClient[]>) => {
        state.fetchStatus = RequestStatus.SUCCESS;
        state.clientsSortedByName = action.payload;
        action.payload.forEach((client) => {
          state.clientsById[client.id] = client;
        });
      })
      .addCase(fetchClients.rejected, (state: ClientsState, action) => {
        state.fetchStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      });
  },
});

export default clientsSlice;
