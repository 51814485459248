/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { HorizontalSeparator, ZCDButton } from '@zencity/common-ui';
import { SessionStorageKeys } from '@zencity/reporting-types';
import { SurveyGroup } from '@zencity/survey-types';
import { Spacer } from 'components/Spacer/Spacer';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LeanClient } from 'types/client';
import { logger } from 'utils/logger';
import { ParsedRakeWeighterExecutions } from 'utils/rakeWeighterExecutions';
import { generateBenchmarkReportTemplate } from './reportGeneratorComponents/benchmarkReportTemplate';
import { SurveyCycle } from './utils/misc';
import { getSurveysDataByClientId } from './utils/utilsFunctions';
import { generateSummaryReportTemplate } from './reportGeneratorComponents/summaryReportTemplate';

const redirectToReport = (templateAsJsonString: string): void => {
  sessionStorage.setItem(SessionStorageKeys.NEW_REPORT_CONTENT, templateAsJsonString);
  const reportsPath = 'reports/new?layout=landscape';
  window.location.assign(`${window.location.origin}/${reportsPath}`);
};

interface Props {
  zcClientId?: string;
}
export const CommunitySurveyTemplateGenerator = (props: Props): React.ReactElement => {
  const { zcClientId } = props;
  const { t: translate } = useTranslation();

  const [vaultClient, setVaultClient] = React.useState<LeanClient>();
  const [mostRecentCycle, setMostRecentCycle] = React.useState<SurveyCycle>();
  const [parsedMostRecentCycle, setParsedMostRecentCycle] = React.useState<ParsedRakeWeighterExecutions>();
  const [communitySurveySurveyGroup, setCommunitySurveySurveyGroup] = React.useState<SurveyGroup>();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const getClientAndSurveyGroup = async () => {
      if (!zcClientId) {
        throw new Error('zcClientId is required');
      }
      const { client, surveyGroup, parsedRakeWeighterExecutions, clientSurveyCycles } = await getSurveysDataByClientId(
        zcClientId,
      );
      setVaultClient(client);
      setCommunitySurveySurveyGroup(surveyGroup);
      if (parsedRakeWeighterExecutions.length > 0) {
        setParsedMostRecentCycle(parsedRakeWeighterExecutions[0]);
      }
      if (clientSurveyCycles.length > 0) {
        setMostRecentCycle(clientSurveyCycles[0]);
      }
    };
    getClientAndSurveyGroup()
      .catch(logger.error)
      .finally(() => setIsLoading(false));
  }, [zcClientId]);

  const noClient = !zcClientId && !isLoading;
  const notSurveysClient = !vaultClient && !isLoading;
  const notCommunitySurveyClient = !communitySurveySurveyGroup && !isLoading;
  const noCycle = !mostRecentCycle && !isLoading;
  const isReady = zcClientId && vaultClient && communitySurveySurveyGroup && mostRecentCycle;
  const handleGenerateSummaryReport = async (): Promise<void> => {
    if (!isReady) {
      return;
    }

    const template = await generateSummaryReportTemplate({
      client: vaultClient,
      surveyGroup: communitySurveySurveyGroup,
      mostRecentCycle,
      parsedMostRecentCycle,
    });
    redirectToReport(JSON.stringify(template));
  };

  const handleGenerateBenchmarkReport = (): void => {
    if (!isReady) {
      return;
    }

    const template = generateBenchmarkReportTemplate({
      client: vaultClient,
      surveyGroup: communitySurveySurveyGroup,
      mostRecentCycle,
      parsedMostRecentCycle,
    });
    redirectToReport(JSON.stringify(template));
  };
  return (
    <>
      <h2>{translate('widgetGenerator.communitySurveyReportBuilder')}</h2>
      <p>{noClient && translate('widgetGenerator.emptyStateNoClient')}</p>
      <p>{notSurveysClient && translate('widgetGenerator.emptyStateNotSurveysClient')}</p>
      <p>{notCommunitySurveyClient && translate('widgetGenerator.emptyStateNotCommunitySurveyClient')}</p>
      <p>{noCycle && translate('widgetGenerator.emptyStateNoCycle')}</p>
      {isReady && (
        <>
          <p>
            {translate('widgetGenerator.client')}: {vaultClient?.name}
          </p>
          <p>
            {translate('widgetGenerator.cycle')}: {parsedMostRecentCycle?.label}
          </p>
          <Spacer orientation="vertical" size="xsmall" />
          <ZCDButton
            text={translate('widgetGenerator.generateReport')}
            onClick={handleGenerateSummaryReport}
            disabled={isLoading}
          />
          <Spacer orientation="vertical" size="xsmall" />
          <ZCDButton
            text={translate('widgetGenerator.generateBenchmarkReport')}
            onClick={handleGenerateBenchmarkReport}
            disabled={isLoading}
          />
        </>
      )}
      <Spacer orientation="vertical" size="small" />
      <HorizontalSeparator />
    </>
  );
};
