import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';

/* eslint-disable id-denylist */
interface HeaderProps {
  clientName: string;
  reportDateRange: string;
  headerTitle: string;
}
export const header = (props: HeaderProps): ILandscapeHeaderElement => {
  const { clientName, reportDateRange, headerTitle } = props;

  return {
    name: 'landscapeHeader',
    children: [
      {
        name: 'landscapeHeaderTitle',
        children: [
          {
            name: 'heading1',
            children: [
              {
                data: headerTitle,
                attributes: {
                  fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
                  fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
                },
              },
            ],
          },
        ],
      },
      {
        name: 'landscapeHeaderClientAndDate',
        children: [
          {
            name: 'paragraph',
            children: [
              {
                data: clientName,
                attributes: {
                  fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
                  fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
                },
              },
            ],
          },
          {
            name: 'paragraph',
            children: [
              {
                data: reportDateRange,
                attributes: {
                  fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
                  fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
                },
              },
            ],
          },
        ],
      },
      {
        name: 'landscapeHeaderPoweredBy',
      },
    ],
  };
};
