import { ZCDInput } from '@zencity/common-ui';
import React, { useContext } from 'react';
import { FieldTitle } from '../components/FieldTitle/FieldTitle';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';
import styles from './WidgetAdditionalFields.module.scss';

export const TopicsAdditionalFields = (): React.ReactElement => {
  const { handleScale, circleScale } = useContext(WidgetsConfigsContext);

  return (
    <div className={styles.fieldContainer}>
      <FieldTitle title="Circle Scale:" />
      <ZCDInput type="number" onChange={handleScale} value={circleScale} />
    </div>
  );
};
