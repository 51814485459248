import { ZCDSpinner } from '@zencity/common-ui';
import { Header } from 'components/Header/Header';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './VolumeTracker.module.scss';

interface Props {
  volumeTrackerUrl: string;
}

/**
 * Embeds the Metabase Volume Tracker dashboard via Iframe URL.
 */
export const VolumeTracker = ({ volumeTrackerUrl }: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  const stopIframeLoading = () => {
    setIsIframeLoading(false);
  };

  return (
    <>
      <Header titleText={translate('volumeTracker.name')} />
      <div className={styles.volumeTrackerContainer}>
        {isIframeLoading && <ZCDSpinner />}
        <iframe
          onLoad={stopIframeLoading}
          src={volumeTrackerUrl}
          title={translate('volumeTracker.name')}
          frameBorder="0"
          width="100%"
          height="100%"
          allowTransparency
        />
      </div>
    </>
  );
};
