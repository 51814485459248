import { MissingTranslation } from 'components/MissingTranslation/MissingTranslation';
import { QuestionWrapper } from 'components/QuestionWrapper/QuestionWrapper';
import { QuestionTypeProps } from 'components/QuestionWrapper/QuestionWrapper.declarations';
import { useAppSelector, useSelectedLanguage } from 'customHooks/hooks';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { QuestionChoice } from 'types/questionChoice';
import { QuestionConfigType } from 'types/questionConfig';
import { findQuestionConfig, resolveRandomizedConfig } from 'utils/genericAndQuestionConfigs';
import { shuffleArray } from 'utils/misc';
import styles from './MultipleChoiceQuestion.module.scss';

export const buildChoiceText = (choice: QuestionChoice, selectedLanguage?: string) => {
  const languageChoiceLabel = selectedLanguage ? choice.translations?.label[selectedLanguage] : choice.label;
  return `${languageChoiceLabel}${choice.config?.numeric_value ? ` [${choice.config.numeric_value}]` : ''}`;
};

/**
 * Displays all choices for a multiple choice question and handles single vs. multi-select questions
 */
export const MultipleChoiceQuestion = (props: QuestionTypeProps): ReactElement => {
  const { questionItem } = props;
  const { genericQuestionsById } = useAppSelector((state: RootState) => state.genericQuestions);
  const { t: translate } = useTranslation();
  const selectedLanguage = useSelectedLanguage();

  const genericQuestion = genericQuestionsById[questionItem.item.generic_question];

  const choicesToDisplay = useMemo(() => {
    if (!genericQuestion?.choices) {
      return [];
    }
    const questionIsRandomized = resolveRandomizedConfig(
      findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.RANDOMIZE, selectedLanguage)
        ?.configValue,
    );
    return questionIsRandomized ? shuffleArray(genericQuestion.choices) : genericQuestion.choices;
  }, [genericQuestion, questionItem.item, selectedLanguage]);

  const isMultiSelect = useMemo(
    () => !!findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.ALLOW_MULTI_CHOICE),
    [questionItem, genericQuestion],
  );

  const displayOther = useMemo(
    () => !!findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.DISPLAY_OTHER),
    [questionItem, genericQuestion],
  );
  const choiceOptionOther = 'choice-option-other';

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <QuestionWrapper {...props}>
      {genericQuestion && (
        <>
          {choicesToDisplay.map((choice) => {
            const choiceOptionId = `choice-option-${choice.id}`;
            const choiceText = buildChoiceText(choice, selectedLanguage);
            return (
              <div key={choiceOptionId} className={styles.radioOption}>
                <input id={choiceOptionId} type={isMultiSelect ? 'checkbox' : 'radio'} disabled />
                {choiceText || <MissingTranslation text={choice.label} />}
              </div>
            );
          })}
          {displayOther && (
            <div key={choiceOptionOther} className={styles.radioOption}>
              <label htmlFor={choiceOptionOther}>
                <input id={choiceOptionOther} type={isMultiSelect ? 'checkbox' : 'radio'} disabled />
                {translate('common.other')}
              </label>
            </div>
          )}
        </>
      )}
    </QuestionWrapper>
  );
};
