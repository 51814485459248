const fallbackWordsToExclude = ['for', 'to', 'and', 'of', 'a', 'an', 'the', 'in'];
interface CapitalizeStringInterface {
  string: string;
  wordsToExclude?: string[];
}

export const capitalizeString = ({
  string,
  wordsToExclude = fallbackWordsToExclude,
}: CapitalizeStringInterface): string =>
  string
    .toLowerCase()
    .split(' ')
    .map((word: string) => {
      if (wordsToExclude.includes(word)) return word;
      return word.charAt(0).toUpperCase() + word.substr(1);
    })
    .join(' ');

export const lowerCaseOnlyFirstLetter = (string: string): string => string.charAt(0).toLowerCase() + string.slice(1);
