/* eslint-disable id-denylist */
import { SelectedDateRange } from 'screens/Analysis/misc';
import {
  CommunitySurveyAreasScore,
  CommunitySurveyAspectsScore,
  CommunitySurveyDemographicsScore,
  CommunitySurveyGenericQuestionsScore,
  CommunitySurveyOverallScore,
} from 'types/communitySurveyScores';
import { generateVaultApiUrl, createAxiosInstance } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/survey/community_survey_scores/');
const axiosInstance = createAxiosInstance(apiUrl);

interface VaultScoresResponse<T> {
  data: T;
}

export const fetchCommunitySurveyOverallScore = async (clientId: number): Promise<CommunitySurveyOverallScore[]> => {
  const response = await axiosInstance.get<VaultScoresResponse<CommunitySurveyOverallScore[]>>(`${clientId}/overall/`);

  const { data } = response.data;
  return data;
};

export const fetchCommunitySurveyAspectsScore = async (clientId: number): Promise<CommunitySurveyAspectsScore> => {
  const response = await axiosInstance.get<VaultScoresResponse<CommunitySurveyAspectsScore>>(`${clientId}/aspects/`);

  const { data } = response.data;
  return data;
};

export const fetchCommunitySurveyDemographicsScore = async (
  clientId: number,
  dateRangeParameters: {
    start_date: string;
    end_date: string;
  },
): Promise<CommunitySurveyDemographicsScore[]> => {
  const response = await axiosInstance.get<VaultScoresResponse<CommunitySurveyDemographicsScore[]>>(
    `${clientId}/demographics/`,
    {
      params: dateRangeParameters,
    },
  );

  const { data } = response.data;
  return data;
};

export const fetchCommunitySurveyGenericQuestionsScore = async (
  clientId: number,
  dateRange: Omit<SelectedDateRange, 'value'>,
): Promise<CommunitySurveyGenericQuestionsScore[]> => {
  const { startDate: start_date, endDate: end_date } = dateRange;
  const response = await axiosInstance.get<VaultScoresResponse<CommunitySurveyGenericQuestionsScore[]>>(
    `${clientId}/generic_questions/`,
    {
      params: {
        start_date,
        end_date,
      },
    },
  );

  const { data } = response.data;
  return data;
};

export const fetchCommunitySurveyAreasScore = async (clientId: number): Promise<CommunitySurveyAreasScore> => {
  const response = await axiosInstance.get<VaultScoresResponse<CommunitySurveyAreasScore>>(`${clientId}/areas/`);

  const { data } = response.data;
  return data;
};
