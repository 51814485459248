export enum BenchmarkTextSatisfaction {
  HIGHER = 'higher',
  LOWER = 'lower',
  SIMILAR = 'similar',
  NONE = 'none',
}
export interface BenchmarkOverallScoreText {
  prefix: string;
  satisfactionCohort: BenchmarkTextSatisfaction;
  suffixCohort: string;
  satisfactionNational?: BenchmarkTextSatisfaction;
  suffixNational?: string;
  percentage?: number;
}
