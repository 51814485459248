import { QuestionWrapper } from 'components/QuestionWrapper/QuestionWrapper';
import { QuestionTypeProps } from 'components/QuestionWrapper/QuestionWrapper.declarations';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useMemo } from 'react';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';
import { findQuestionConfig } from 'utils/genericAndQuestionConfigs';
import styles from './ScaleQuestion.module.scss';

/**
 * Displays all steps for a scale question, based off the number of config steps.
 */
export const ScaleQuestion = (props: QuestionTypeProps): ReactElement => {
  const { questionItem } = props;
  const { genericQuestionsById } = useAppSelector((state: RootState) => state.genericQuestions);
  const { defaultScaleQuestionSteps } = useAppSelector((state: RootState) => state.globalConfigs);
  const genericQuestion = genericQuestionsById[questionItem.item.generic_question];

  const startAtOneConfigValue = findQuestionConfig(
    questionItem.item,
    genericQuestion,
    QuestionConfigType.START_AT_ONE,
  )?.configValue;

  const scaleStepsList = useMemo(() => {
    const numStepsValue = findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.STEPS)?.configValue;
    if (numStepsValue) {
      return Array.from(Array(parseInt(numStepsValue)).keys());
    }

    // There is no `steps` config in the question/generic question.
    return Array.from(Array(defaultScaleQuestionSteps).keys());
  }, [questionItem.item, genericQuestion, defaultScaleQuestionSteps]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <QuestionWrapper {...props}>
      <div className={styles.scaleQuestion}>
        {scaleStepsList.map((scaleStepNum) => {
          const scaleStepId = `scale-option-${scaleStepNum}-question-${questionItem.id}`;
          const displayedStepNum =
            !startAtOneConfigValue || startAtOneConfigValue === '0' ? scaleStepNum : scaleStepNum + 1;
          return (
            <div key={scaleStepId} className={styles.radioOption}>
              <label htmlFor={scaleStepId}>
                <input id={scaleStepId} type="radio" disabled />
                {displayedStepNum}
              </label>
            </div>
          );
        })}
      </div>
    </QuestionWrapper>
  );
};
