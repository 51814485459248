/* eslint-disable max-nested-callbacks */
import { Flex } from 'components/Flex/Flex';
import React from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { mapCrossDataToSentimentBarGroups } from 'screens/WidgetGenerator/helpers/crossTabs/crossTabs';
import { CrossTabsDataTypes, CrossTabsWidgetData } from '../../utils/misc';
import { ScoreTextWithSentimentBar } from '../ScoreTextWithSentimentBar/ScoreTextWithSentimentBar';
import styles from './CrossTabs.module.scss';
import { SentimentBarGroups } from '../SentimentBarRows/SentimentBarGroups';

interface CrossTabsProps {
  widgetData: CrossTabsWidgetData;
  hideBaseQuestionScore?: boolean;
}

export function CrossTabs(props: CrossTabsProps): React.ReactElement {
  const { widgetData, hideBaseQuestionScore } = props;
  const crossQuestionsData = widgetData?.[CrossTabsDataTypes.CROSS_QUESTIONS];
  const crossDemographicGeographicData = widgetData?.[CrossTabsDataTypes.CROSS_DEMOGRAPHIC_GEOGRAPHIC];
  const scoreTextWithSentimentBarWidgetData = widgetData?.[CrossTabsDataTypes.SCORE_TEXT_WITH_SENTIMENT_BAR];

  const crossQuestionsDataGroups = mapCrossDataToSentimentBarGroups(crossQuestionsData);
  const crossDemographicGeographicDataGroups = mapCrossDataToSentimentBarGroups(crossDemographicGeographicData);
  return (
    <Flex alignItems="flex-start" className={styles.crossTabs}>
      {!hideBaseQuestionScore && (
        <ScoreTextWithSentimentBar
          widgetData={scoreTextWithSentimentBarWidgetData}
          isBenchmarked
          showAllSatisfactionScores
          showSentimentBar
        />
      )}
      <div className={styles.sentimentBarGroups}>
        <SentimentBarGroups widgetData={crossDemographicGeographicDataGroups} key="crossDemographicGeographicData" />
        <Spacer customSize={12} orientation="vertical" />
        <SentimentBarGroups widgetData={crossQuestionsDataGroups} key="crossQuestionsData" />
      </div>
    </Flex>
  );
}
