import React, { ReactElement } from 'react';
import { BlockwiseClientDetails, CensusData, HandleStringChangeProps } from 'types/surveyClientConfiguration';
import CensusDataJsonTextInput from '../CensusDataJsonTextInput/CensusDataJsonTextInput';

interface Props {
  setBlockwiseClientDetails: React.Dispatch<React.SetStateAction<BlockwiseClientDetails>>;
  blockwiseClientDetails: BlockwiseClientDetails;
}

export const CensusDataSection = ({ setBlockwiseClientDetails, blockwiseClientDetails }: Props): ReactElement => {
  const { censusData } = blockwiseClientDetails;

  const handleStringChange = ({ event, setObjectValue, setStringValue, setValid }: HandleStringChangeProps): void => {
    const currentValue = event.target.value;
    try {
      const currentObjectValue = JSON.parse(currentValue);
      setObjectValue(currentObjectValue);
      setStringValue(currentValue);
      setValid(true);

      setBlockwiseClientDetails?.((prevState) => ({
        ...prevState,
        censusData: currentObjectValue as CensusData,
      }));
    } catch (error) {
      setStringValue(currentValue);
      setValid(false);
    }
  };

  return <CensusDataJsonTextInput onChange={handleStringChange} value={censusData} />;
};
