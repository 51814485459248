import { ZCDCheckbox } from '@zencity/common-ui';
import { Spacer } from 'components/Spacer/Spacer';
import React, { useContext } from 'react';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';
import styles from './WidgetAdditionalFields.module.scss';

export const OverallSatisfactionTextAdditionalFields = (): React.ReactElement => {
  const {
    handleBenchmarkedOverallBar,
    isBenchmarkedOverallBar,
    showAllSatisfactionScores,
    handleShowAllSatisfactionScores,
  } = useContext(WidgetsConfigsContext);

  return (
    <div className={styles.fieldRow}>
      <Spacer orientation="vertical" size="xsmall" />
      <ZCDCheckbox
        label="Show all scores"
        checked={showAllSatisfactionScores}
        onChange={handleShowAllSatisfactionScores}
      />
      <Spacer orientation="vertical" size="xsmall" />
      <ZCDCheckbox
        label="Show benchmark to previous cycle"
        checked={isBenchmarkedOverallBar}
        onChange={handleBenchmarkedOverallBar}
      />
    </div>
  );
};
