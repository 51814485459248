/* eslint-disable max-lines */
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import React from 'react';
import { LeanClient } from 'types/client';

import { CommunitySurveyDemographicsScore } from 'types/communitySurveyScores';
import {
  AreasSentimentBarTableWidgetData,
  AspectValueType,
  BenchmarkColumnKey,
  BenchmarkGenericQuestionScore,
  BenchmarkTextWidgetData,
  ChoicesQuestionChartData,
  ChoicesQuestionChartDisplayOrder,
  ChoicesQuestionChartDisplayType,
  ClassificationSubTopicResponses,
  CrossTabsWidgetData,
  MaintainAndFocusServicesWidgetData,
  OverallSatisfactionData,
  OverallSentimentBarTableWidgetData,
  QuestionnaireWidgetData,
  RawWidgetData,
  OverallSatisfactionScoreQuestion,
  ScoreTextWithSentimentBarWidgetData,
  SentimentBarGroupsWidgetData,
  SentimentBarRowsWidgetData,
  TableWrapperWidgetData,
  WidgetType,
  WordCloudQuestionSentiment,
  WordCloudWidgetData,
} from '../utils/misc';

export type WidgetsConfigsContextProps = BaseInterface & WidgetDataType;

interface BaseInterface {
  widgetType: WidgetType;
  rawData: null | WidgetDataType['rawData'];
  isValidData: boolean;
  widgetWidth: number;
  circleScale: number;
  wordCloudQuestionSentiments: WordCloudQuestionSentiment[];
  demographicsTableNumRowsPerTable: number;
  isBenchmarkedOverallBar: boolean;
  showAllSatisfactionScores: boolean;
  aspectValue: AspectValueType;
  isLabeledBar: boolean;
  servicesBarChartNumBarsPerRow: number;
  splitPositionBarChart: number;
  isBenchmarked: boolean;
  isBenchmarkedServices: boolean;
  columnName: string;
  selectedBenchmarkClient: LeanClient;
  selectedBenchmarkAspect?: string;
  handleBenchmarkColumns: ({ key, value }: { key: BenchmarkColumnKey; value: boolean }) => void;
  handleColumnName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleScale: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleServicesBarChartNumBarsPerRow: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSplitPositionBarChart: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleWordCloudCat: (value: WordCloudQuestionSentiment) => void;
  handleDemographicsTableNumRowsPerTable: (chunkSize: number) => void;
  handleBenchmarkCycle: () => void;
  handleSelectBenchmarkAspect: (aspect: string) => void;
  handleSelectBenchmarkClient: (client: LeanClient) => void;
  handleBenchmarkCycleServices: () => void;
  handleBenchmarkedOverallBar: () => void;
  handleShowAllSatisfactionScores: () => void;
  handleBenchmarkedAreas: () => void;
  handleBarLabel: () => void;
  handleSelectWidget: (selectedWidget: WidgetType) => void;
  handleWidgetSize: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUpdateCustomChartButton: () => void;
  rawDataTemp: string;
  handleFetchDataButton: () => Promise<void>;
  checkIsValidData: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleDefaultDataButton: () => void;
  handleDataUpload: (payload: JSON | null) => void;
  handleFileSaveAsButton: () => void;
  handleClearDataButton: () => void;
  handleFileNameInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dataError: string;
  choicesQuestionChartDisplayType: ChoicesQuestionChartDisplayType;
  setChoicesQuestionChartDisplayType: (choicesQuestionChartDisplayType: ChoicesQuestionChartDisplayType) => void;
  isLoadingData: boolean;
  isLoadingClientData: boolean;
  isShortBarChart: boolean;
  handleIsShortBarChartButton: () => void;
  selectedSurvey?: number;
  setSelectedSurvey: (surveyId?: number) => void;
  selectedGenericQuestionId?: number;
  setSelectedGenericQuestionId: (genericQuestionId?: number) => void;
  crossTabQuestionSelectedOptions?: SelectOption[];
  setCrossTabQuestionSelectedOptions: (genericQuestionOptions?: SelectOption[]) => void;
  selectedDemographicGeographic?: string[];
  setSelectedDemographicGeographic: (demographicGeographic?: string[]) => void;
  isBenchmarkedAreas: boolean;
  choicesQuestionChartDisplayOrder: ChoicesQuestionChartDisplayOrder;
  setChoicesQuestionChartDisplayOrder: (order: ChoicesQuestionChartDisplayOrder) => void;
  maintainAndFocusServices: OverallSatisfactionScoreQuestion[];
  setWidgetData: (payload: RawWidgetData) => void;
  setIsValidData: (isValid: boolean) => void;
  selectedAspects: number[];
  setSelectedAspects: (aspectIds?: number[]) => void;
  splitPositionQuestionnaire: number;
  setSplitPositionQuestionnaire: (splitPosition: number) => void;
}

export const WidgetsConfigsContext = React.createContext<WidgetsConfigsContextProps>({} as WidgetsConfigsContextProps);

interface WordCloudWidget {
  widgetType: WidgetType.WORD_CLOUD;
  rawData: WordCloudWidgetData;
}

interface TopicsBubbleChartWidget {
  widgetType: WidgetType.TOPICS_BUBBLE_CHART;
  rawData: ClassificationSubTopicResponses[];
}

interface SentimentBarRowsWidget {
  widgetType: WidgetType.SENTIMENT_BAR;
  rawData: SentimentBarRowsWidgetData[];
}

interface DemographicsWidget {
  widgetType: WidgetType.DEMOGRAPHICS;
  rawData: CommunitySurveyDemographicsScore[];
}

interface ScoreTextWithSentimentBarWidget {
  widgetType: WidgetType.SERVICE_SENTIMENT_BAR;
  rawData: ScoreTextWithSentimentBarWidgetData[];
}

interface SentimentBarGroupsWidget {
  widgetType: WidgetType.SENTIMENT_BAR_GROUPS;
  rawData: SentimentBarGroupsWidgetData[];
}

interface SatisfactionWidget {
  widgetType: WidgetType.OVERALL_SATISFACTION_TEXT;
  rawData: ScoreTextWithSentimentBarWidgetData[];
}

interface SatisfactionBarChartWidget {
  widgetType: WidgetType.SERVICES_BAR_CHART;
  rawData: OverallSatisfactionScoreQuestion[];
}

interface OverallSentimentBarTableWidget {
  widgetType: WidgetType.SENTIMENT_BAR_OVERALL_TABLE;
  rawData: OverallSentimentBarTableWidgetData;
}

interface AreasSentimentBarTableWidget {
  widgetType: WidgetType.SENTIMENT_BAR_AREAS_TABLE;
  rawData: AreasSentimentBarTableWidgetData[];
}

interface TableWrapperWidget {
  widgetType: WidgetType.GENERAL_TABLE;
  rawData: TableWrapperWidgetData[];
}

interface BenchmarkGenericQuestionsTableWidget {
  widgetType: WidgetType.BENCHMARK_GENERIC_QUESTIONS_TABLE;
  rawData: BenchmarkGenericQuestionScore[];
}

interface BenchmarkOverallSatisfactionTextWidget {
  widgetType: WidgetType.BENCHMARK_OVERALL_SATISFACTION_TEXT;
  rawData: BenchmarkTextWidgetData;
}

interface OverallBarChartWidget {
  widgetType: WidgetType.OVERALL_BAR_CHART;
  rawData: OverallSatisfactionData;
}

interface ChoicesQuestionChart {
  widgetType: WidgetType.CHOICES_QUESTION_CHART;
  rawData: ChoicesQuestionChartData;
}

export interface DefaultWidget {
  widgetType: WidgetType.DEFAULT;
  rawData: string[];
}

export interface MaintainAndFocusServicesWidget {
  widgetType: WidgetType.MAINTAIN_AND_FOCUS_SERVICES;
  rawData: MaintainAndFocusServicesWidgetData;
}

export interface QuestionnaireWidget {
  widgetType: WidgetType.QUESTIONNAIRE;
  rawData: QuestionnaireWidgetData[];
}
export interface CrossTabsWidget {
  widgetType: WidgetType.CROSS_TABS;
  rawData: CrossTabsWidgetData;
}

export type WidgetDataType =
  | WordCloudWidget
  | TopicsBubbleChartWidget
  | SentimentBarRowsWidget
  | DemographicsWidget
  | ScoreTextWithSentimentBarWidget
  | SentimentBarGroupsWidget
  | SatisfactionWidget
  | SatisfactionBarChartWidget
  | OverallSentimentBarTableWidget
  | AreasSentimentBarTableWidget
  | TableWrapperWidget
  | BenchmarkGenericQuestionsTableWidget
  | BenchmarkOverallSatisfactionTextWidget
  | OverallBarChartWidget
  | DefaultWidget
  | ChoicesQuestionChart
  | MaintainAndFocusServicesWidget
  | QuestionnaireWidget
  | CrossTabsWidget;
