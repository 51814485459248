/* eslint-disable max-statements, max-lines-per-function */
import { ZCDInput, ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { DestinationType, LogicActionItem } from 'types/logicAction';
import { QuestionItem } from 'types/questions';
import styles from './LogicActionForm.module.scss';

export const END_SCREEN_SELECT_OPTION: SelectOption = {
  value: 'end-screen',
  label: 'Default end screen',
};

interface Props {
  id: string | number;
  questionItem: QuestionItem;
  questionChoiceOptions: SelectOption[];
  defaultQuestionChoiceOption?: SelectOption;
  defaultDestinationOption?: SelectOption;
  setLogicActionItems: React.Dispatch<React.SetStateAction<LogicActionItem[]>>;
}

/**
 * The form for setting a logic action.
 */
export const LogicActionForm = (props: Props): ReactElement => {
  const {
    id,
    questionItem,
    questionChoiceOptions,
    defaultDestinationOption,
    defaultQuestionChoiceOption,
    setLogicActionItems,
  } = props;
  const { t: translate } = useTranslation();

  const { questions } = useAppSelector((state: RootState) => state.questions);
  const { endScreens } = useAppSelector((state: RootState) => state.endScreens);
  const { resolvedIndexes } = useAppSelector((state: RootState) => state.surveyManage);
  const questionItemResolvedIndex = resolvedIndexes[questionItem.id];

  const [logicActionItem, setLogicActionItem] = useState<LogicActionItem>({
    id,
    originQuestionId: questionItem.item.id,
    destinationOption: defaultDestinationOption,
    questionChoiceOption: defaultQuestionChoiceOption,
    deleted: false,
  });

  const destinationOptions = questions.reduce((destQuestions, question) => {
    if (question.index > questionItem.item.index) {
      destQuestions.push({
        value: `${DestinationType.QUESTION}-${question.id}`,
        label: `${resolvedIndexes[question.id]}: ${question.text}`,
      });
    }
    return destQuestions;
  }, [] as SelectOption[]);

  destinationOptions.push(
    ...endScreens.reduce((destScreens, screen) => {
      destScreens.push({
        value: `${DestinationType.ENDING_SCREEN}-${screen.id}`,
        label: screen.title,
      });
      return destScreens;
    }, [] as SelectOption[]),
  );

  // There should always be an option to jump to the end screen.
  destinationOptions.push(END_SCREEN_SELECT_OPTION);

  const handleOnQuestionChoiceChange = (newValue: unknown) => {
    const selectedOption = newValue as SelectOption;
    setLogicActionItem({
      ...logicActionItem,
      questionChoiceOption: selectedOption,
    });
  };

  const handleOnDestinationChange = (newValue: unknown) => {
    const selectedOption = newValue as SelectOption;
    setLogicActionItem({
      ...logicActionItem,
      destinationOption: selectedOption,
    });
  };

  /**
   * When the form is fully populated, update the logic action items list
   * with the new or updated logic action item.
   */
  useEffect(() => {
    const handleSetLogicActionItems = (originalLogicActionItems: LogicActionItem[]): LogicActionItem[] => {
      const updatedLogicActionItems = [...originalLogicActionItems];

      const existingLogicActionItem = updatedLogicActionItems.find(
        (item) => item.questionChoiceOption === logicActionItem.questionChoiceOption,
      );
      if (existingLogicActionItem) {
        existingLogicActionItem.destinationOption = logicActionItem.destinationOption;

        // Restore the logic action.
        if (existingLogicActionItem.deleted) {
          existingLogicActionItem.deleted = false;

          // Remove the duplicate logic action and replace the existing one.
          updatedLogicActionItems.pop();
          updatedLogicActionItems.splice(updatedLogicActionItems.indexOf(existingLogicActionItem), 1);
          return [...updatedLogicActionItems, existingLogicActionItem];
        }

        // Update the logic action in place.
        const existingLogicActionItemIndex = originalLogicActionItems.indexOf(existingLogicActionItem);
        updatedLogicActionItems.splice(existingLogicActionItemIndex, 1, existingLogicActionItem);

        return updatedLogicActionItems;
      }

      // Add the new logic action item.
      return [...originalLogicActionItems.filter((logicItem) => logicItem.id !== logicActionItem.id), logicActionItem];
    };

    if (!!logicActionItem?.destinationOption && !!logicActionItem?.questionChoiceOption) {
      setLogicActionItems(handleSetLogicActionItems);
    }
  }, [logicActionItem, setLogicActionItems]);

  return (
    <div className={styles.logicActionForm}>
      <b className={styles.originCondition}>{translate('common.if')}</b>
      <div className={styles.questionText}>
        <ZCDInput disabled value={`${questionItemResolvedIndex}: ${questionItem.item.text}`} />
      </div>
      <div className={styles.conditionOperator}>
        <ZCDInput disabled value={translate('common.is') as string} />
      </div>
      <div className={styles.questionChoiceSelect}>
        <ZCDSelect
          options={questionChoiceOptions}
          isDisabled={!!defaultQuestionChoiceOption}
          value={logicActionItem.questionChoiceOption}
          onChange={handleOnQuestionChoiceChange}
        />
      </div>
      <b className={styles.destinationText}>{translate('common.then')}</b>
      <div className={styles.actionOperator}>
        <ZCDInput disabled value={translate('logicActionDialog.goTo') as string} />
      </div>
      <div className={styles.destinationChoice}>
        <ZCDSelect
          options={destinationOptions}
          value={logicActionItem.destinationOption}
          onChange={handleOnDestinationChange}
        />
      </div>
    </div>
  );
};
