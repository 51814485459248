import { ZCDInput } from '@zencity/common-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from 'screens/WidgetGenerator/components/FieldTitle/FieldTitle';

interface Props {
  onChange: (splitPosition: number) => void;
  splitPosition: number;
}
export const SplitPosition = (props: Props) => {
  const { onChange, splitPosition } = props;
  const { t: translate } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(parseInt(value, 10));
  };

  return (
    <div>
      <FieldTitle title={translate('widgetGenerator.widgets.questionnaire.splitPosition')} />
      <ZCDInput type="number" onChange={handleChange} value={splitPosition} min={2} />
    </div>
  );
};
