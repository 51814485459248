import { ZCDSelect } from '@zencity/common-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetType } from '../../utils/misc';
import { FieldTitle } from '../FieldTitle/FieldTitle';

interface WidgetSelectionProps {
  onChange: (selectedWidget: WidgetType) => void;
  value: WidgetType;
  isDisabled: boolean;
}

const OPTIONS = [
  { value: WidgetType.WORD_CLOUD, label: 'Word Cloud' },
  { value: WidgetType.TOPICS_BUBBLE_CHART, label: 'Topics Bubble Chart' },
  { value: WidgetType.OVERALL_SATISFACTION_TEXT, label: 'Overall Satisfaction Text' },
  { value: WidgetType.OVERALL_BAR_CHART, label: 'Overall Satisfaction Chart' },
  { value: WidgetType.SERVICE_SENTIMENT_BAR, label: 'Service Satisfaction Sentiment Bar (Widget)' },
  { value: WidgetType.SENTIMENT_BAR, label: 'Sentiment Bar (Rows)' },
  { value: WidgetType.SENTIMENT_BAR_GROUPS, label: 'Sentiment Bar Groups (Rows)' },
  { value: WidgetType.SERVICES_BAR_CHART, label: 'Services Bar Chart' },
  { value: WidgetType.GENERAL_TABLE, label: 'Table Widget (Table)' },
  { value: WidgetType.DEMOGRAPHICS, label: 'Demographics Table' },
  { value: WidgetType.SENTIMENT_BAR_OVERALL_TABLE, label: 'Sentiment Bars for Overall (Table)' },
  { value: WidgetType.SENTIMENT_BAR_AREAS_TABLE, label: 'Sentiment Bars for Areas (Table)' },
  { value: WidgetType.BENCHMARK_GENERIC_QUESTIONS_TABLE, label: 'Questions Benchmark Table' },
  { value: WidgetType.BENCHMARK_OVERALL_SATISFACTION_TEXT, label: 'Overall Benchmark Scores Text' },
  { value: WidgetType.CROSS_TABS, label: 'Cross Tabs' },
  { value: WidgetType.CHOICES_QUESTION_CHART, label: 'Choices Question Chart' },
  { value: WidgetType.MAINTAIN_AND_FOCUS_SERVICES, label: 'Maintain and focus Bar Chart' },
  { value: WidgetType.QUESTIONNAIRE, label: 'Questionnaire Table' },
];

export const WidgetTypeSelection = ({ onChange, value, isDisabled }: WidgetSelectionProps): React.ReactElement => {
  const { t: translate } = useTranslation();
  const selectedValue = OPTIONS.find((option) => option.value === value);

  const handleSelect = (newValue: unknown) => {
    const selected = newValue as { value: WidgetType };
    onChange(selected.value);
  };

  return (
    <>
      <FieldTitle title={translate('widgetGenerator.selectType')} />
      <ZCDSelect
        placeholder={translate('widgetGenerator.selectType')}
        options={OPTIONS}
        onChange={handleSelect}
        value={selectedValue}
        isDisabled={isDisabled}
      />
    </>
  );
};
