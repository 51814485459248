import { Icon } from '@zencity/common-ui';
import { SurveyTranslationsList } from 'components/SurveyTranslationsList/SurveyTranslationsList';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { setCurrentDisplay, SurveyManageDisplay } from 'slices/surveyManage';
import { RootState } from 'store';
import styles from './SurveyTranslationFormLayout.module.scss';

/**
 * Layout for the survey translation display on the "manage" page.
 */
export const SurveyTranslationFormLayout = (): ReactElement => {
  const { t: translate } = useTranslation();
  const baseTranslationPath = 'surveyTranslationFormLayout';
  const dispatch = useAppDispatch();
  const { selectedLanguage } = useAppSelector((state: RootState) => state.surveyManage);
  const { languages } = useAppSelector((state: RootState) => state.globalConfigs);

  const handleOnSurveyPreviewButtonClick = () => {
    dispatch(setCurrentDisplay(SurveyManageDisplay.PREVIEW));
  };

  // eslint-disable-next-line react/no-multi-comp
  const SurveyPreviewBackLink = () => (
    <button type="button" className={styles.surveyPreviewBackLink} onClick={handleOnSurveyPreviewButtonClick}>
      <Icon name="arrow-left.svg" width={24} height={24} />
      <span className={styles.backLink}>{translate(`${baseTranslationPath}.surveyPreview`)}</span>
    </button>
  );

  return (
    <div className={styles.surveyTranslationFormLayout}>
      <SurveyPreviewBackLink />
      <h2>
        {translate(`${baseTranslationPath}.translateHeading`, {
          originLanguage: languages.en,
          translatedLanguage: languages[selectedLanguage],
        })}
      </h2>
      <SurveyTranslationsList />
    </div>
  );
};
