import { SurveyEditBreadcrumbs } from 'components/SurveyEditBreadcrumbs/SurveyEditBreadcrumbs';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './BreadcrumbToolbar.module.scss';

interface Props {
  previousText: string;
  previousLink: string;
  nextText: string;
  nextButtonOnClick?: () => void;
  nextButtonDisabled?: boolean;
  surveyId?: string;
}

/**
 * This is the toolbar that will be shown on the survey Start and Build pages.
 */
export const BreadcrumbToolbar = (props: Props): ReactElement => {
  const { previousText, nextText, previousLink, nextButtonOnClick, nextButtonDisabled, surveyId } = props;
  return (
    <div className={styles.toolbar}>
      <NavLink className={styles.backToMainPage} to={previousLink}>
        {previousText}
      </NavLink>
      <SurveyEditBreadcrumbs surveyId={surveyId} />
      <button type="button" className={styles.nextButton} disabled={nextButtonDisabled} onClick={nextButtonOnClick}>
        {nextText}
      </button>
    </div>
  );
};
