import React from 'react';
import { v4 } from 'uuid';
import {
  MAX_UNREPRESENTATIVE_INDICATOR,
  MIN_UNREPRESENTATIVE_INDICATOR,
} from 'screens/WidgetGenerator/components/UnrepresentativeIndicator/constants';
import { SentimentBarGroupsWidgetData } from '../../utils/misc';
import styles from './SentimentBarGroups.module.scss';
import { SentimentBarRow } from './SentimentBarRow';

interface SentimentBarGroupsProps {
  widgetData: null | SentimentBarGroupsWidgetData[];
}
const isUnrepresentativeSubmissions = (totalSubmissions: number): boolean =>
  totalSubmissions >= MIN_UNREPRESENTATIVE_INDICATOR && totalSubmissions < MAX_UNREPRESENTATIVE_INDICATOR;

const getUnrepresentativeNumOfSubmissions = (
  totalSubmissionsFirstGroup?: number,
  totalSubmissionsSecondGroup?: number,
): number | undefined => {
  if (totalSubmissionsSecondGroup && isUnrepresentativeSubmissions(totalSubmissionsSecondGroup)) {
    return totalSubmissionsSecondGroup;
  }
  if (totalSubmissionsFirstGroup && isUnrepresentativeSubmissions(totalSubmissionsFirstGroup)) {
    return totalSubmissionsFirstGroup;
  }
  return undefined;
};

export function SentimentBarGroups(props: SentimentBarGroupsProps): React.ReactElement {
  const { widgetData } = props;
  return (
    <div className={styles.sentimentBarGroups}>
      {(widgetData ?? []).map((group) => (
        <div className={styles.sentimentBarGroup} key={v4()}>
          <h3>{group.groupTitle ? group.groupTitle : ''}</h3>
          {group.groupItems.map((rowData) => {
            const { score, totalSubmissions } = rowData;
            if (totalSubmissions && totalSubmissions < MIN_UNREPRESENTATIVE_INDICATOR) {
              return <></>;
            }

            const previousCycleTotalSubmissions = rowData.previousCycle?.totalSubmissions;

            const previousCycleScore =
              previousCycleTotalSubmissions && previousCycleTotalSubmissions < MIN_UNREPRESENTATIVE_INDICATOR
                ? undefined
                : rowData.previousCycle?.score.positive;

            const unrepresentativeSubmissions = getUnrepresentativeNumOfSubmissions(
              totalSubmissions,
              previousCycleTotalSubmissions,
            );
            const displayUnrepresentativeIndicator = Boolean(unrepresentativeSubmissions);
            return (
              <SentimentBarRow
                positive={score?.positive}
                neutral={score?.neutral}
                negative={score?.negative}
                text={rowData.text}
                displayUnrepresentativeIndicator={displayUnrepresentativeIndicator}
                unrepresentativeSubmissions={unrepresentativeSubmissions}
                prevPositiveValue={previousCycleScore}
                isBenchmarked={Boolean(rowData.previousCycle)}
                key={v4()}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}
