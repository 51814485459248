import { createAsyncThunk } from '@reduxjs/toolkit';
import { Aspect } from 'types/aspect';
import { PaginatedResults } from 'types/misc';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/api/v2/aspect/');
const axiosInstance = createAxiosInstance(apiUrl);
interface FetchAspectsBySurveyParams {
  survey?: number;
}

export const fetchAspectsBySurvey = async (params: FetchAspectsBySurveyParams): Promise<PaginatedResults<Aspect>> => {
  const response = await axiosInstance.get<PaginatedResults<Aspect>>('', {
    params: {
      // Assuming that we'll never have so many aspects (more than 30), thus,
      // a single request will fetch the entire list.
      page_size: 60,
      survey: params.survey,
    },
  });
  return response.data;
};

export const fetchAspects = createAsyncThunk('aspect/fetchAspects', fetchAspectsBySurvey);
