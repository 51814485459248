import React, { useContext } from 'react';
import { ScoreTextWithSentimentBarAdditionalFields } from 'screens/WidgetGenerator/widgetAdditionalFields/ScoreTextWithSentimentBarAdditionalFields';
import { BenchmarkOverallScoresAdditionalFields } from 'screens/WidgetGenerator/widgetAdditionalFields/BenchmarkOverallScoresAdditionalFields';
import { WidgetType } from '../utils/misc';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';
import { BenchmarkQuestionsAdditionalFields } from './BenchmarkQuestionsAdditionalFields';
import { CrossTabsWidgetAdditionalFields } from './CrossTabsWidgetAdditionalFields';
import { DemographicsAdditionalFields } from './DemographicsAdditionalFields';
import { MaintainAndFocusServicesAdditionalFields } from './MaintainAndFocusServicesAdditionalFields';
import { ChoicesQuestionChartAdditionalFields } from './ChoicesQuestionChartAdditionalFields';
import { OverallBarChartAdditionalFields } from './OverallBarChartAdditionalFields';
import { QuestionnaireAdditionalFields } from './QuestionnaireAdditionalFields';
import { OverallSatisfactionTextAdditionalFields } from './OverallSatisfactionTextAdditionalFields';
import { SatisfactionBarChartAdditionalFields } from './SatisfactionBarChartAdditionalFields';
import { SentimentBarAreasTableAdditionalFields } from './SentimentBarAreasTableAdditionalFields';
import { SentimentBarOverallTableAdditionalFields } from './SentimentBarOverallTableAdditionalFields';
import { TopicsAdditionalFields } from './TopicsAdditionalFields';
import { WordCloudAdditionalFields } from './WordCloudAdditionalFields';

export const WidgetAdditionalFields = (): React.ReactElement | null => {
  const { widgetType, rawData } = useContext(WidgetsConfigsContext);

  switch (widgetType) {
    case WidgetType.BENCHMARK_GENERIC_QUESTIONS_TABLE:
      return <BenchmarkQuestionsAdditionalFields />;
    case WidgetType.BENCHMARK_OVERALL_SATISFACTION_TEXT:
      return <BenchmarkOverallScoresAdditionalFields />;
    case WidgetType.SERVICES_BAR_CHART:
      return <SatisfactionBarChartAdditionalFields />;
    case WidgetType.DEMOGRAPHICS:
      return <DemographicsAdditionalFields />;
    case WidgetType.TOPICS_BUBBLE_CHART:
      return <TopicsAdditionalFields />;
    case WidgetType.SERVICE_SENTIMENT_BAR:
      return <ScoreTextWithSentimentBarAdditionalFields />;
    case WidgetType.SENTIMENT_BAR_OVERALL_TABLE:
      return <SentimentBarOverallTableAdditionalFields />;
    case WidgetType.OVERALL_BAR_CHART:
      return <OverallBarChartAdditionalFields />;
    case WidgetType.OVERALL_SATISFACTION_TEXT:
      return <OverallSatisfactionTextAdditionalFields />;
    case WidgetType.WORD_CLOUD:
      return <WordCloudAdditionalFields />;
    case WidgetType.SENTIMENT_BAR_AREAS_TABLE:
      return <SentimentBarAreasTableAdditionalFields />;
    case WidgetType.CHOICES_QUESTION_CHART:
      return <ChoicesQuestionChartAdditionalFields />;
    case WidgetType.MAINTAIN_AND_FOCUS_SERVICES:
      return <MaintainAndFocusServicesAdditionalFields widgetData={rawData} />;
    case WidgetType.QUESTIONNAIRE:
      return <QuestionnaireAdditionalFields />;
    case WidgetType.CROSS_TABS:
      return <CrossTabsWidgetAdditionalFields />;
    default:
      return null;
  }
};
