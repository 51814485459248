import { ZCDIcon } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EditQuestionWarning.module.scss';

export const EditQuestionWarning = (): ReactElement => {
  const { t: translate } = useTranslation();
  return (
    <div className={styles.editQuestionWarning}>
      <ZCDIcon name="warning-filled" />
      <p>{translate('questionsBankDialog.newQuestion.editQuestionWarning')}</p>
    </div>
  );
};
