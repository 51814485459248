/* eslint-disable max-lines-per-function,max-statements */
import { Header } from 'components/Header/Header';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { ManageDropdownButton } from 'components/ManageDropdownButton/ManageDropdownButton';
import { SurveyManageList } from 'components/SurveyManageList/SurveyManageList';
import { SurveyPreview } from 'components/SurveyPreview/SurveyPreview';
import { SurveyTranslationFormLayout } from 'components/SurveyTranslationFormLayout/SurveyTranslationFormLayout';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import {
  useAppDispatch,
  useAppSelector,
  useCurrentGenericQuestions,
  useCurrentSurvey,
  useCurrentSurveyQuestions,
} from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RouterPath } from 'routerPaths';
import { fetchTypeformSurveysBySurveyId } from 'services/typeformSurvey';
import {
  setCurrentDisplay,
  setCurrentLanguage,
  setCurrentSurveyId as setCurrentTypeformSurveyId,
  SurveyManageDisplay,
} from 'slices/surveyManage';
import { RootState } from 'store';
import { TypeformSurveyStatus } from 'types/typeformSurvey';
import { logger } from 'utils/logger';
import { isNumeric, statusIsIdle, statusIsLoading } from 'utils/misc';
import styles from './SurveyManageScreen.module.scss';

/**
 * This page will allow a user to publish and view a preview of a survey.
 */
// eslint-disable-next-line complexity
export const SurveyManageScreen = (): ReactElement => {
  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();
  const { questions, fetchStatus: questionsFetchStatus } = useAppSelector((state: RootState) => state.questions);
  const { defaultLanguage } = useAppSelector((state: RootState) => state.globalConfigs);
  const { surveys, fetchStatus: surveysFetchStatus } = useAppSelector((state: RootState) => state.surveys);
  const {
    currentSurveyId: currentTypeformSurveyId,
    fetchStatus: typeformSurveysFetchStatus,
    errorMessage: typeformSurveysErrorMessage,
    typeformSurveysById,
    currentDisplay,
  } = useAppSelector((state: RootState) => state.surveyManage);

  const { t: translate } = useTranslation();

  const { surveyId = '' } = useParams();
  if (!isNumeric(surveyId)) {
    throw new Error(`Survey ID is not a number: ${surveyId}`);
  }
  const surveyIdAsNumber = parseInt(surveyId);

  const surveysError = useCurrentSurvey(surveyIdAsNumber);
  const questionsError = useCurrentSurveyQuestions(surveyIdAsNumber);
  const [loadingGenericQuestions, genericQuestionsError] = useCurrentGenericQuestions(questions);

  const existsPublishedTypeformSurveys = useMemo(
    () =>
      Object.values(typeformSurveysById)
        .filter((typeformSurvey) => typeformSurvey.survey === surveyIdAsNumber)
        .some((typeformSurvey) => typeformSurvey.status === TypeformSurveyStatus.ACTIVE),
    [typeformSurveysById, surveyIdAsNumber],
  );

  useEffect(() => {
    if (surveysError) {
      toastError();
      logger.error(surveysError.message);
    } else if (questionsError) {
      toastError();
      logger.error(questionsError);
    } else if (genericQuestionsError) {
      toastError();
      logger.error(genericQuestionsError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsError, surveysError, genericQuestionsError]);

  /**
   * Fetch the survey's typeform surveys if the fetched typeform surveys do not align with the survey ID,
   * or no typeform surveys have been fetched yet.
   */
  useEffect(() => {
    const currentTypeformSurveysAreNotFetched =
      statusIsIdle(typeformSurveysFetchStatus) || currentTypeformSurveyId !== surveyIdAsNumber;

    if (currentTypeformSurveysAreNotFetched) {
      dispatch(fetchTypeformSurveysBySurveyId(surveyIdAsNumber));
      dispatch(setCurrentTypeformSurveyId(surveyIdAsNumber));
    }

    if (typeformSurveysErrorMessage) {
      toastError();
      logger.error(typeformSurveysErrorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyIdAsNumber, typeformSurveysErrorMessage, typeformSurveysFetchStatus, currentTypeformSurveyId]);

  /**
   * Cleanup for when a user leaves the "Manage" screen.
   */
  useEffect(
    () => () => {
      dispatch(setCurrentLanguage(defaultLanguage));
      dispatch(setCurrentDisplay(SurveyManageDisplay.PREVIEW));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const surveyIsLocked = surveys[surveyIdAsNumber]?.is_locked;

  return (
    <LoaderMask
      isLoading={
        statusIsLoading(questionsFetchStatus) ||
        statusIsLoading(typeformSurveysFetchStatus) ||
        statusIsLoading(surveysFetchStatus) ||
        loadingGenericQuestions
      }
    >
      <Header
        titleText={surveys[surveyIdAsNumber]?.survey_group.title_for_display}
        backLink={{
          text: `<- ${translate('surveyManage.backLink')}`,
          path: RouterPath.MAIN,
        }}
        actions={[
          <ManageDropdownButton
            isLocked={surveyIsLocked}
            isPublished={existsPublishedTypeformSurveys}
            surveyId={surveyId}
          />,
        ]}
      />
      <div className={styles.surveyManageScreen}>
        <SurveyManageList surveyId={surveyIdAsNumber} />
        {currentDisplay === SurveyManageDisplay.PREVIEW && <SurveyPreview />}
        {currentDisplay === SurveyManageDisplay.TRANSLATION && <SurveyTranslationFormLayout />}
      </div>
    </LoaderMask>
  );
};
