import { Cadence } from '@zencity/survey-types';
import { BaseModel } from 'types/baseModel';
import { LeanClient } from 'types/client';
import { Translations } from 'types/misc';

export enum SurveyType {
  BLOCKWISE = 'blockwise',
  COMMUNITY_ASKS = 'community_asks',
  COMMUNITY_SURVEY = 'community_survey',
  CUSTOMER_EXPERIENCE = 'customer_experience',
  NETWORK_SPEED = 'network_speed',
  PULSE = 'pulse',
}

export const cadencesPerSurveyTypeMapping = {
  [SurveyType.BLOCKWISE]: [Cadence.MONTHLY, Cadence.QUARTERLY],
  [SurveyType.COMMUNITY_ASKS]: [],
  [SurveyType.COMMUNITY_SURVEY]: [Cadence.QUARTERLY, Cadence.SEMI_ANNUALLY, Cadence.YEARLY],
  [SurveyType.CUSTOMER_EXPERIENCE]: [],
  [SurveyType.NETWORK_SPEED]: [],
  [SurveyType.PULSE]: [],
};

export interface Survey extends BaseModel {
  id: number;
  internal_title: string;
  survey_group: SurveyGroup;
  is_locked: boolean;
}

export interface SurveyGroup extends Omit<BaseModel, 'created_by'> {
  id: number;
  title_for_display: string;
  type: string;
  type_display: string;
  cadence: string;
  client: LeanClient;
  survey_public_link?: string;
  translations?: {
    title_for_display: Translations;
  };
  is_locked: boolean;
}
