/* eslint-disable id-length */
import { parseISO } from 'date-fns';
import { EndScreen } from 'types/endScreen';
import { GenericQuestion } from 'types/genericQuestion';
import { QuestionChoice } from 'types/questionChoice';
import { Question } from 'types/questions';
import { TypeformSurvey } from 'types/typeformSurvey';

interface DetermineRepublishableSurveyParameters {
  isPublished: boolean;
  missingTranslationsCount: number;
  questions: Question[];
  genericQuestions: GenericQuestion[];
  publishedDate: string;
  endScreens: EndScreen[];
  typeformSurvey?: TypeformSurvey;
}

const checkQuestionsUpdatedAfterPublishDate = (questions: Question[], publishedDate: string): boolean =>
  questions.some((question) => parseISO(question.updated_at) > parseISO(publishedDate));

const checkGenericQuestionsUpdatedAfterPublishDate = (
  genericQuestions: GenericQuestion[],
  publishedDate: string,
): boolean =>
  genericQuestions.some((genericQuestion) => parseISO(genericQuestion.updated_at) > parseISO(publishedDate));

const checkQuestionChoicesUpdatedAfterPublishDate = (
  questionChoices: QuestionChoice[],
  publishedDate: string,
): boolean => questionChoices.some((questionChoice) => parseISO(questionChoice.updated_at) > parseISO(publishedDate));

const checkGenericQuestionConfigsUpdatedAfterPublishDate = (
  genericQuestionConfigs: GenericQuestion['configs'],
  publishedDate: string,
): boolean =>
  genericQuestionConfigs.some(
    (genericQuestionConfig) => parseISO(genericQuestionConfig.updated_at) > parseISO(publishedDate),
  );

const checkEndScreensUpdatedAfterPublishDate = (endScreens: EndScreen[], publishedDate: string): boolean =>
  endScreens.some((endScreen) => parseISO(endScreen.updated_at) > parseISO(publishedDate));

export const determineIfSurveyIsRepublishable = (params: DetermineRepublishableSurveyParameters): boolean => {
  const {
    isPublished,
    missingTranslationsCount,
    questions,
    publishedDate,
    typeformSurvey,
    genericQuestions,
    endScreens,
  } = params;
  if (!isPublished || missingTranslationsCount > 0) {
    return false;
  }
  const questionsHaveBeenUpdatedAfterPublishDate = checkQuestionsUpdatedAfterPublishDate(questions, publishedDate);
  const genericQuestionsUpdatedAfterPublishDate = checkGenericQuestionsUpdatedAfterPublishDate(
    genericQuestions,
    publishedDate,
  );

  const genericQuestionChoices = genericQuestions.flatMap((genericQuestion) => genericQuestion.choices);
  const questionChoicesUpdatedAfterPublishDate = checkQuestionChoicesUpdatedAfterPublishDate(
    genericQuestionChoices,
    publishedDate,
  );

  const genericQuestionConfigs = genericQuestions.flatMap((genericQuestion) => genericQuestion.configs);
  const genericQuestionConfigsUpdatedAfterPublishDate = checkGenericQuestionConfigsUpdatedAfterPublishDate(
    genericQuestionConfigs,
    publishedDate,
  );

  const endScreensUpdatedAfterPublishedDate = checkEndScreensUpdatedAfterPublishDate(endScreens, publishedDate);

  const modelsInstancesAreUpdated =
    questionChoicesUpdatedAfterPublishDate ||
    genericQuestionsUpdatedAfterPublishDate ||
    questionsHaveBeenUpdatedAfterPublishDate ||
    genericQuestionConfigsUpdatedAfterPublishDate ||
    endScreensUpdatedAfterPublishedDate;

  return isPublished && !typeformSurvey?.is_locked && modelsInstancesAreUpdated;
};
