import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintainAndFocusServicesWidgetData } from 'screens/WidgetGenerator/utils/misc';
import { SatisfactionBarChart } from '../BarChart/SatisfactionBarChart/SatisfactionBarChart';
import { Title } from './Title';

interface Props {
  widgetData: MaintainAndFocusServicesWidgetData;
  splitPositionBarChart: number;
  isBenchmarkedServices: boolean;
  isShortBarChart: boolean;
}
export const MaintainAndFocusServices = (props: Props) => {
  const { widgetData, splitPositionBarChart, isBenchmarkedServices, isShortBarChart } = props;
  const { t: translate } = useTranslation();

  return (
    <Flex alignItems="flex-end">
      {widgetData.maintain?.length > 0 && (
        <Flex flexDirection="column" justifyContent="space-between">
          <Title
            title={translate('widgetGenerator.widgets.maintainAndFocus.maintain.title')}
            subtitle={translate('widgetGenerator.widgets.maintainAndFocus.maintain.subtitle')}
          />
          <SatisfactionBarChart
            splitPositionIndex={splitPositionBarChart}
            isBenchmarked={isBenchmarkedServices}
            isShortBarChart={isShortBarChart}
            widgetData={widgetData.maintain}
            wideBars
          />
        </Flex>
      )}
      <Spacer orientation="horizontal" customSize={12} />
      {widgetData.focus?.length > 0 && (
        <Flex flexDirection="column" justifyContent="space-between">
          <Title
            title={translate('widgetGenerator.widgets.maintainAndFocus.focus.title')}
            subtitle={translate('widgetGenerator.widgets.maintainAndFocus.focus.subtitle')}
          />
          <SatisfactionBarChart
            splitPositionIndex={splitPositionBarChart}
            isBenchmarked={isBenchmarkedServices}
            isShortBarChart={isShortBarChart}
            widgetData={widgetData.focus}
            wideBars
          />
        </Flex>
      )}
    </Flex>
  );
};
