/* eslint-disable id-denylist */
import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { SurveysReportGeneratorDataItemKey, SurveysReportGeneratorFilterKey } from '../../constants';
import { DocumentFragment } from '../documentFragment';
import { sectionCoverPage } from '../utils/sectionCoverPage';
import { lineBreakElement } from '../utils/lineBreakElement';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import {
  TABLE_CELL_DEFAULT_PADDING,
  TABLE_DEFAULT_BORDER_COLOR,
  reportTemplateTable,
  reportTemplateTableCell,
} from '../utils/tableTemplate';

const choicesQuestionSectionDescription =
  'The rotating survey section focuses on one issue per survey cycle and can be updated as new areas of interest emerge.';

const choicesQuestionFocusCoverPage: DocumentFragment = sectionCoverPage({
  titleChunks: ['Rotating', 'Survey Section'],
  descriptionChunks: [choicesQuestionSectionDescription],
});

const leftColumnChoicesQuestionFocusTable = reportTemplateTableCell({
  data: [
    lineBreakElement,
    lineBreakElement,
    {
      name: 'heading2',
      children: [
        {
          data: `Your residents somewhat support this initiative`,
          attributes: {
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
            fontColor: TEMPLATE_TYPOGRAPHY.colors.red90,
          },
        },
      ],
    },
    lineBreakElement,
    lineBreakElement,
    {
      name: 'paragraph',
      children: [
        {
          data: `The question: ...`,
          attributes: {
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
            fontColor: TEMPLATE_TYPOGRAPHY.colors.red90,
          },
        },
      ],
    },
  ],
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  tableCellWidth: '40%',
});

const rightColumnChoicesQuestionFocusTable = reportTemplateTableCell({
  data: [
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.CHOICES_QUESTION_CHART,
        params: {
          filters: {
            [SurveysReportGeneratorFilterKey.CHOICES_QUESTION_CHART_DISPLAY_TYPE]: true,
          },
        },
      },
    },
  ],
  tableCellPadding: TABLE_CELL_DEFAULT_PADDING,
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  tableCellWidth: '60%',
});

const choicesQuestionFocusTable = reportTemplateTable({
  tableColumns: [leftColumnChoicesQuestionFocusTable, rightColumnChoicesQuestionFocusTable],
  tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
});

interface RotatingSurveySectionParams {
  headerElement: ILandscapeHeaderElement;
}

export const rotatingSurveySection = (props: RotatingSurveySectionParams): DocumentFragment => {
  const { headerElement } = props;

  return [
    headerElement,
    ...choicesQuestionFocusCoverPage,
    headerElement,
    choicesQuestionFocusTable,
    {
      name: 'pageBreak',
    },
    headerElement,
    choicesQuestionFocusTable,
    {
      name: 'pageBreak',
    },
    headerElement,
    choicesQuestionFocusTable,
    {
      name: 'pageBreak',
    },
    headerElement,
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.CROSS_TABS,
      },
    },
    {
      name: 'pageBreak',
    },
    headerElement,
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.CROSS_TABS,
      },
    },
    {
      name: 'pageBreak',
    },
  ];
};
