import { ZCDDialog, ZCDDropzone } from '@zencity/common-ui';
import { ConfirmationDialogFooter } from 'components/ConfirmationDialogFooter/ConfirmationDialogFooter';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch } from 'customHooks/hooks';
import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadQuestionImage } from 'services/questions';
import { QuestionItem } from 'types/questions';
import { logger } from 'utils/logger';
import { IMAGE_UPLOAD_MAX_FILE_SIZE, IMAGE_UPLOAD_ALLOWED_FILE_TYPES, validateUploadedImage } from 'utils/imageUpload';
import styles from './AddImageToQuestionDialog.module.scss';

interface Props {
  questionItem: QuestionItem;
  isOpen: boolean;
  closeDialog: () => void;
}

/**
 * Dialog to add an image to a question.
 */
export const AddImageToQuestionDialog = (props: Props): ReactElement => {
  const { questionItem, isOpen, closeDialog } = props;
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { toastError } = useContext(SurveyManagerToastContext);
  const [uploadedImage, setUploadedImage] = useState<FormData>();
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>();

  const handleOnAddImage = async () => {
    if (!uploadedImage) {
      return;
    }
    try {
      setIsUploadLoading(true);
      await dispatch(
        uploadQuestionImage({
          questionId: questionItem.item.id,
          image: uploadedImage,
        }),
      );
      closeDialog();
    } catch (error) {
      toastError(`Error uploading image to Question #${questionItem.item.id}`);
      logger.error(error);
    } finally {
      setIsUploadLoading(false);
    }
  };

  const handleOnUploadImage = (image: File) => {
    const errors = validateUploadedImage(image, IMAGE_UPLOAD_ALLOWED_FILE_TYPES, IMAGE_UPLOAD_MAX_FILE_SIZE);
    if (errors.length > 0) {
      errors.unshift(`Some issues with "${image.name}":`);
      setErrorMessages(errors);
      return Promise.resolve(false);
    }

    // Reset the error messages to display the success message when the file is
    // valid.
    setErrorMessages(undefined);

    const formData = new FormData();
    formData.append('file', image);
    setUploadedImage(formData);

    // The function is inherited from `ZCDDropzone` and
    // requires a return type of Promise<boolean>.
    return Promise.resolve(true);
  };

  return (
    <ZCDDialog
      isOpen={isOpen}
      onRequestClose={closeDialog}
      header={<h2>{translate('addImageDialog.header')}</h2>}
      footer={
        <ConfirmationDialogFooter
          isDisabled={!uploadedImage || isUploadLoading}
          handleOnCancel={closeDialog}
          handleOnConfirm={handleOnAddImage}
          confirmText={translate('questionActionsMenu.addImage')}
        />
      }
    >
      <LoaderMask isLoading={isUploadLoading}>
        <div className={styles.uploadImageContainer}>
          <ZCDDropzone
            isImage
            onUpload={handleOnUploadImage}
            imagePreview
            accept={IMAGE_UPLOAD_ALLOWED_FILE_TYPES}
            errorMessage={errorMessages}
          />
        </div>
      </LoaderMask>
    </ZCDDialog>
  );
};
