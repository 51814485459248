import { configureStore } from '@reduxjs/toolkit';
import AspectsSlice from 'slices/aspect';
import ClientsSlice from 'slices/client';
import GenericQuestionsSlice from 'slices/genericQuestion';
import SurveysSlice from 'slices/survey';
import QuestionsSlice from 'slices/questions';
import SurveyManageSlice from 'slices/surveyManage';
import GlobalConfigsSlice from 'slices/globalConfig';
import QuestionsBank from 'slices/questionsBank';
import QuestionsChoices from 'slices/questionsChoices';
import EndScreens from 'slices/endScreens';

const store = configureStore({
  reducer: {
    aspects: AspectsSlice.reducer,
    surveys: SurveysSlice.reducer,
    clients: ClientsSlice.reducer,
    questions: QuestionsSlice.reducer,
    genericQuestions: GenericQuestionsSlice.reducer,
    surveyManage: SurveyManageSlice.reducer,
    globalConfigs: GlobalConfigsSlice.reducer,
    questionsBank: QuestionsBank.reducer,
    questionsChoices: QuestionsChoices.reducer,
    endScreens: EndScreens.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
