import classnames from 'classnames';
import React from 'react';
import { BenchmarkGenericQuestionScore } from 'screens/WidgetGenerator/utils/misc';
import { BenchmarkType } from 'types/benchmarkType';
import { v4 } from 'uuid';
import {
  CLIENT_BENCHMARK_TYPE,
  DISPLAYED_BENCHMARK_TYPES,
} from 'screens/WidgetGenerator/widgets/BenchmarkWidgets/constants';
import styles from './BenchmarkComparisonScale.module.scss';

export interface BenchmarkComparisonScaleProps {
  benchmarkScore: BenchmarkGenericQuestionScore;
}

enum IconShape {
  CIRCLE = 'circle',
  SQUARE = 'square',
  TRIANGLE = 'triangle',
}

interface ScaleItem {
  score: number;
  benchmarkType: string;
}

const BENCHMARK_TYPE_SHAPE_MAP: Record<string, IconShape> = {
  [BenchmarkType.NATIONAL]: IconShape.TRIANGLE,
  [BenchmarkType.COHORT]: IconShape.SQUARE,
  [CLIENT_BENCHMARK_TYPE]: IconShape.CIRCLE,
};

/**
 * Displays a line with shapes at the given scores
 * for each Benchmark Type.
 */
export const BenchmarkComparisonScale = (props: BenchmarkComparisonScaleProps): React.ReactElement => {
  const { benchmarkScore } = props;

  const items = DISPLAYED_BENCHMARK_TYPES.map<ScaleItem>((benchmarkType) => ({
    score: benchmarkScore.benchmark_scores[benchmarkType].positive,
    benchmarkType,
  }));

  items.push({
    score: benchmarkScore.overall_score,
    benchmarkType: CLIENT_BENCHMARK_TYPE,
  });

  return (
    <div className={styles.barLine}>
      {items.map((item) => (
        <div
          className={classnames(styles.shape, styles[BENCHMARK_TYPE_SHAPE_MAP[item.benchmarkType]])}
          style={{ left: `${Math.round(item.score * 100)}%` }}
          key={v4()}
        />
      ))}
    </div>
  );
};
