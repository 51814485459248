import { ZCDFormGroup } from '@zencity/common-ui';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CensusData, HandleStringChangeProps } from 'types/surveyClientConfiguration';
import './CensusDataJsonTextInput.scss';

interface JsonTextInputProps {
  onChange: ({ event, setObjectValue, setStringValue, setValid }: HandleStringChangeProps) => void;
  value?: CensusData;
}

const CensusDataJsonTextInput: React.FC<JsonTextInputProps> = ({ onChange, value }) => {
  const [objectValue, setObjectValue] = useState<CensusData>({});
  const [stringValue, setStringValue] = useState('');
  const [valid, setValid] = useState(true);
  const { t: translate } = useTranslation();
  useEffect(() => {
    const currentValue = value || {};
    setObjectValue(currentValue);
    setStringValue(JSON.stringify(currentValue, null, ' '));
  }, [value]);

  const handleBlur = (): void => {
    if (!valid) {
      setStringValue(JSON.stringify(objectValue, null, ' '));
      setValid(true);
    }
  };

  return (
    <ZCDFormGroup>
      <span className={classNames('censusTitleContainer')}>{translate('clientConfiguration.censusTitle')}</span>
      <textarea
        className={classNames('form-control jsonTextInput', { 'has-error': !valid })}
        dir="ltr"
        value={stringValue}
        rows={10}
        onChange={(event) => onChange({ event, setObjectValue, setStringValue, setValid })}
        onBlur={handleBlur}
      />
    </ZCDFormGroup>
  );
};

export default CensusDataJsonTextInput;
