import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * This page displays for a 403 error when a user is not authorized to access the Survey Manager.
 */
export const UnauthorizedUser403Screen = (): ReactElement => {
  const { t: translate } = useTranslation();
  return (
    // eslint-disable-next-line i18next/no-literal-string
    <div>{translate('userNotAuthorized')}</div>
  );
};
