import React, { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { SurveyClientConfigurationScreen } from 'screens/SurveyClientConfigurationScreen/SurveyClientConfigurationScreen';
import store from 'store';
import { SurveyManagerToastContextProvider } from './contexts/SurveyManagerToastContext';
import './i18n';
import './index.scss';

interface Props {
  clientId: string;
}

const SurveyClientConfigurationEntry = ({ clientId }: Props): ReactElement => (
  <Provider store={store}>
    <SurveyManagerToastContextProvider>
      <SurveyClientConfigurationScreen clientId={clientId} />
    </SurveyManagerToastContextProvider>
  </Provider>
);

export default SurveyClientConfigurationEntry;
