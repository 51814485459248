import React, { ReactElement } from 'react';
import styles from './QuestionIndex.module.scss';

interface Props {
  resolvedIndex: string;
}

/**
 * Displays the Question's index.
 */
export const QuestionIndex = (props: Props): ReactElement => {
  const { resolvedIndex } = props;

  return (
    <div className={styles.indexContainer} data-testid="index">
      {resolvedIndex}
    </div>
  );
};
