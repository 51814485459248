import { getCyclesStartingFromSelectedCycle } from 'screens/WidgetGenerator/utils/cycles';
import { fetchWordCloudData } from 'services/communitySurveyReport/answersWordCloud';
import { LeanClient } from 'types/client';
import { logger } from 'utils/logger';
import { ListEntry } from 'wordcloud';
import { config } from '../../../../config/config';
import { SurveyCycle, WordCloudWidgetData, WordCloudQuestionSentiment, SelectedDateRange } from '../utils/misc';

export const getAnswersWordCloudData = async (
  client: LeanClient,
  cycles: SurveyCycle[],
  selectedDateRange: SelectedDateRange,
  selectedGenericQuestionId?: number,
): Promise<WordCloudWidgetData> => {
  const filteredCycles = getCyclesStartingFromSelectedCycle(selectedDateRange, cycles);
  const lastCycleIndex = 0;
  // the data from fetchWordCloud returns most popular words in a descending order
  try {
    // If there is a selected Generic Question, only fetch
    // the word cloud data for it.
    if (selectedGenericQuestionId) {
      const genericQuestionWords = await fetchWordCloudData(
        {
          generic_question: selectedGenericQuestionId,
          start_date: filteredCycles[lastCycleIndex].startDate,
          end_date: filteredCycles[lastCycleIndex].endDate,
        },
        client,
      );
      return {
        positive: [],
        negative: [],
        other: genericQuestionWords.map(([word, occurences]: ListEntry) => [
          word,
          occurences,
          WordCloudQuestionSentiment.OTHER,
        ]),
      };
    }
    let wordsPositiveOpenEndedQuestion = await fetchWordCloudData(
      {
        generic_question: config.communitySurvey.PositiveSentimentGenericQuestionId,
        start_date: filteredCycles[lastCycleIndex].startDate,
        end_date: filteredCycles[lastCycleIndex].endDate,
      },
      client,
    );
    wordsPositiveOpenEndedQuestion = wordsPositiveOpenEndedQuestion.map(([word, occurences]: ListEntry) => [
      word,
      occurences,
      WordCloudQuestionSentiment.POSITIVE,
    ]);
    let wordsNegativeOpenEndedQuestion = await fetchWordCloudData(
      {
        generic_question: config.communitySurvey.NegativeSentimentGenericQuestionId,
        start_date: filteredCycles[lastCycleIndex].startDate,
        end_date: filteredCycles[lastCycleIndex].endDate,
      },
      client,
    );
    wordsNegativeOpenEndedQuestion = wordsNegativeOpenEndedQuestion.map((word: ListEntry) => [
      word[0],
      word[1],
      WordCloudQuestionSentiment.NEGATIVE,
    ]);

    return {
      positive: wordsPositiveOpenEndedQuestion,
      negative: wordsNegativeOpenEndedQuestion,
    };
  } catch (error) {
    logger.error(error);
    return { positive: [], negative: [] };
  }
};
