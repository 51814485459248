import { ZCDFilter } from '@zencity/common-ui';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchClients } from 'services/client';
import { FilterOptionWithKey } from 'types/filters';
import { logger } from 'utils/logger';
import { statusIsError, statusIsIdle } from 'utils/misc';

interface Props {
  stateValue: number;
  onChangeCallback: (value: FilterOptionWithKey) => void;
}

export const ClientDropdown = (props: Props): ReactElement => {
  const { stateValue, onChangeCallback } = props;
  const { t: translate } = useTranslation();
  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();
  const { clientsSortedByName, fetchStatus, errorMessage } = useAppSelector((state) => state.clients);

  const options = useMemo(
    () => [
      {
        value: 0,
        key: 'client-0',
        label: translate('surveysListTable.anyClient'),
      },
      ...Object.values(clientsSortedByName).map((client) => ({
        value: client.id,
        key: `client-${client.id}`,
        label: client.state ? `${client.name}, ${client.state}` : client.name,
      })),
    ],
    [clientsSortedByName, translate],
  );

  /**
   * Fetch the clients from the server if still idled.
   */
  useEffect(() => {
    if (statusIsIdle(fetchStatus)) {
      dispatch(fetchClients());
    }
    if (errorMessage) {
      toastError();
      logger.error(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, errorMessage, fetchStatus]);

  if (errorMessage && statusIsError(fetchStatus)) {
    return <> </>;
  }
  const value = options.find((option) => option.value === stateValue) ?? {
    value: 0,
    key: 'client-0',
    label: translate('surveysListTable.client'),
  };
  return (
    <ZCDFilter<FilterOptionWithKey>
      value={value}
      onChange={(option) => onChangeCallback(option as FilterOptionWithKey)}
      label={translate('surveysListTable.client')}
      options={options}
    />
  );
};
