// The defined size is in MB.
export const IMAGE_UPLOAD_MAX_FILE_SIZE = 4;

export const IMAGE_UPLOAD_ALLOWED_FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];

export const validateUploadedImage = (image: File, allowedTypes?: string[], maxFileSizeInMB?: number): string[] => {
  const errorMessages: string[] = [];
  if (allowedTypes && !allowedTypes.includes(image.type)) {
    errorMessages.push(`• File type "${image.type}" is not supported.`);
  }
  if (maxFileSizeInMB) {
    // The size of `File` objects is in bytes, thus, convert.
    const maxSizeInBytes = maxFileSizeInMB * 1024 * 1024;
    if (maxFileSizeInMB && image.size > maxSizeInBytes) {
      const fileSizeInMB = parseFloat((image.size / 1024 / 1024).toString()).toFixed(3);
      errorMessages.push(`• Max file size is "${maxFileSizeInMB}MB" but the given file is "${fileSizeInMB}MB".`);
    }
  }

  return errorMessages;
};
