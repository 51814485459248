/* eslint-disable id-denylist */
import { DocumentFragment, IDocumentTableBlockElement, IDocumentTableCellElement } from '../documentFragment';

interface ReportTemplateTableProps {
  tableColumns: IDocumentTableCellElement[];
  tableAlignment?: string;
  tableWidth?: string;
  tableBorderColor?: string;
}
interface ReportTemplateTableCellProps {
  data: DocumentFragment;
  tableCellAlignment?: string;
  tableCellPadding?: string;
  tableCellWidth?: string;
  tableCellHeight?: string;
  tableCellBorderColor?: string;
  tableCellVerticalAlignment?: string;
}
export const TABLE_CELL_DEFAULT_PADDING = '30px';
export const TABLE_DEFAULT_BORDER_COLOR = 'transparent';

export const reportTemplateTableCell = (props: ReportTemplateTableCellProps): IDocumentTableCellElement => {
  const {
    data,
    tableCellVerticalAlignment = 'top',
    tableCellPadding,
    tableCellWidth,
    tableCellHeight,
    tableCellBorderColor,
  } = props;
  return {
    name: 'tableCell',
    attributes: {
      tableCellVerticalAlignment,
      tableCellWidth,
      tableCellHeight,
      tableCellPadding,
      tableCellBorderColor,
    },
    children: data,
  };
};

export const reportTemplateTable = (props: ReportTemplateTableProps): IDocumentTableBlockElement => {
  const { tableColumns, tableAlignment, tableBorderColor, tableWidth } = props;

  return {
    name: 'table',
    attributes: {
      tableAlignment,
      tableBorderColor,
      tableWidth,
    },
    children: [
      {
        name: 'tableRow',
        children: tableColumns,
      },
    ],
  };
};
