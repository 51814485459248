/* eslint-disable i18next/no-literal-string */
import { ErrorBoundaryWithIcon } from 'components/ErrorBoundaryWithIcon/ErrorBoundaryWithIcon';
import { useAppDispatch } from 'customHooks/hooks';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteObject, useRoutes } from 'react-router-dom';
import { RouterPath, SurveyChildPath } from 'routerPaths';
import { MainPage } from 'screens/MainPage/MainPage';
import { PageNotFound404Screen } from 'screens/PageNotFound404Screen/PageNotFound404Screen';
import { SurveyBuildScreen } from 'screens/SurveyBuildScreen/SurveyBuildScreen';
import { SurveyEditStartScreen } from 'screens/SurveyEditStartScreen/SurveyEditStartScreen';
import { SurveyManageScreen } from 'screens/SurveyManageScreen/SurveyManageScreen';
import { GlobalConfigsState, setGlobalConfigs } from 'slices/globalConfig';

interface Props {
  globalConfigs: GlobalConfigsState;
}

/*
 * This component generates the Routes for the Survey Manager.
 * We do this using the useRoutes hook with a RouteObject, instead of
 * with the <Routes><Route></Routes> elements format.
 *
 * See an example from the docs here: https://stackblitz.com/github/
 * remix-run/react-router/tree/main/examples/route-objects?file=src%2FApp.tsx
 *
 * Note that we use the useRoutes hook here instead of directly in the
 * SurveyManagerAppEntry component since the BrowserRouter component
 * needs to embed the element that uses useRoutes. It cannot use useRoutes
 * in the same component without throwing an error.
 */
export const SurveyManagerRoutes = (props: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const { globalConfigs } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setGlobalConfigs(globalConfigs));
  }, [dispatch, globalConfigs]);

  const routes: RouteObject[] = [
    {
      path: RouterPath.MAIN,
      element: <MainPage />,
      index: true,
    },
    {
      path: RouterPath.SURVEY,
      children: [
        {
          path: SurveyChildPath.START_CREATE_NEW,
          element: <SurveyEditStartScreen />,
        },
        {
          path: SurveyChildPath.BUILD,
          element: <SurveyBuildScreen />,
        },
        {
          path: SurveyChildPath.EDIT,
          element: <SurveyEditStartScreen />,
        },
        {
          path: SurveyChildPath.MANAGE,
          element: <SurveyManageScreen />,
        },
        {
          path: SurveyChildPath.CLONE,
          element: <SurveyEditStartScreen />,
        },
      ],
    },
    { path: '*', element: <PageNotFound404Screen /> },
  ];

  return (
    <ErrorBoundaryWithIcon
      errorDescription={translate('errorBoundary.description')}
      errorTitle={translate('errorBoundary.title')}
    >
      {useRoutes(routes)}
    </ErrorBoundaryWithIcon>
  );
};
