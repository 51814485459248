import { CheckboxAsSwitch } from 'components/CheckboxAsSwitch/CheckboxAsSwitch';
import { getNewQuestionConfig, configSetter } from 'components/NewGenericQuestion/NewGenericQuestion.helpers';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { setNewQuestionConfigs } from 'slices/questionsBank';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';
import styles from 'components/NewGenericQuestion/NewGenericQuestion.module.scss';
import { useTranslation } from 'react-i18next';

export const NumberRightSection = (): ReactElement => {
  const { t: translate } = useTranslation();
  const [numberQuestionAddRange, setNumberQuestionAddRange] = useState(false);

  const dispatch = useAppDispatch();
  const { newQuestionPayload } = useAppSelector((state: RootState) => state.questionsBank);

  const minValue = getNewQuestionConfig(QuestionConfigType.MIN_VALUE, newQuestionPayload)?.config_value;
  const maxValue = getNewQuestionConfig(QuestionConfigType.MAX_VALUE, newQuestionPayload)?.config_value;

  const onChangeMinValue = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.MIN_VALUE, event.target.value)));
  const onChangeMaxValue = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.MAX_VALUE, event.target.value)));

  const toggleQuestionAddRange = (isChecked: boolean) => {
    setNumberQuestionAddRange(isChecked);

    if (!isChecked) {
      // Delete the configs when disabling the range.
      const updatedConfigs = configSetter(newQuestionPayload, QuestionConfigType.MIN_VALUE, '');
      dispatch(
        setNewQuestionConfigs(
          configSetter({ ...newQuestionPayload, configs: updatedConfigs }, QuestionConfigType.MAX_VALUE, ''),
        ),
      );
    }
  };

  return (
    <>
      <CheckboxAsSwitch
        inputId="new-question-add-number-range"
        labelText={translate('questionConfig.addRange')}
        isChecked={numberQuestionAddRange}
        onCheckHandler={toggleQuestionAddRange}
      />
      {numberQuestionAddRange && (
        <div className={styles.numberRangeWrapper}>
          <input
            type="number"
            step="1"
            min="0"
            className={styles.rangeInput}
            placeholder={translate('common.any')}
            value={minValue}
            onChange={onChangeMinValue}
          />
          <span>{translate('common.to')}</span>
          <input
            type="number"
            step="1"
            min={minValue || 0}
            className={styles.rangeInput}
            placeholder={translate('common.any')}
            value={maxValue}
            onChange={onChangeMaxValue}
          />
        </div>
      )}
    </>
  );
};
