import { ZCDCheckbox } from '@zencity/common-ui';
import React, { useContext } from 'react';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';

export const SentimentBarAreasTableAdditionalFields = (): React.ReactElement => {
  const { handleBenchmarkedAreas, isBenchmarkedAreas } = useContext(WidgetsConfigsContext);
  // Additional configuration for sentiment bar areas table.
  // - currently we need only a checkbox for benchmarking previous cycle
  return (
    <ZCDCheckbox
      label="Add benchmark data to previous cycle"
      checked={isBenchmarkedAreas}
      onChange={handleBenchmarkedAreas}
    />
  );
};
