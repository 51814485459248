import { Button, Icon } from '@zencity/common-ui';
import { QuestionAddDialog } from 'components/QuestionAddDialog/QuestionAddDialog';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EmptyBuildState.module.scss';

/**
 * This screen will show when a survey has no questions on the "Build" page.
 */
export const EmptyBuildState = (): ReactElement => {
  const { t: translate } = useTranslation();
  const [addQuestionDialogIsOpen, setAddQuestionDialogIsOpen] = useState(false);

  const handleOnBuildClick = () => {
    setAddQuestionDialogIsOpen(true);
  };

  return (
    <div className={styles.emptyBuildState}>
      <Icon name="survey-data.svg" height={110} width={110} className={styles.icon} />
      <div className={styles.content}>
        <h2>{translate('buildEmptyState.title')}</h2>
        <p className={styles.description}>{translate('buildEmptyState.description')}</p>
        <Button type="button" size="lg" variant="primary" onClick={handleOnBuildClick}>
          {translate('buildEmptyState.buildNow')}
        </Button>
      </div>
      <QuestionAddDialog
        isOpen={addQuestionDialogIsOpen}
        setIsOpen={setAddQuestionDialogIsOpen}
        addQuestionInsideGroup={false}
      />
    </div>
  );
};
