/* eslint-disable max-lines-per-function, max-statements */
import { SurveyGroup } from '@zencity/survey-types';
import { ClientDropdown } from 'components/ClientDropdown/ClientDropdown';
import { Header } from 'components/Header/Header';
import { useAppSelector, useAsyncEffect } from 'customHooks/hooks';
import format from 'date-fns/format';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchRakeWeighterExecutions } from 'services/communitySurveyReport/rakeWighterExecutions';
import { FileTypes } from 'services/communitySurveyReport/util';
import { fetchSurveyGroups, fetchTotalSubmissionsBySurveyGroup } from 'services/surveyGroup';
import { RootState } from 'store';
import { LeanClient } from 'types/client';
import { FilterOptionWithKey } from 'types/filters';
import { logger } from 'utils/logger';
import { parseRakeWeighterExecutions } from 'utils/rakeWeighterExecutions';
import styles from './Analysis.module.scss';
import { CrossTabExporter } from './components/CrossTabExporter';
import { DateRangeFilter } from './components/DateRangeFilter';
import { FileExtensionFilter, FileExtensionOption } from './components/FileExtensionFilter';
import { SubmissionsExporter, VAULT_COMPATIBLE_DATE_FORMAT } from './components/SubmissionsExporter';
import { SurveyGroupFilter } from './components/SurveyGroupFilter';
import { SelectedDateRange } from './misc';

export const Analysis = (): ReactElement => {
  const { t: translate } = useTranslation();
  // Filters.
  const [clientId, setClientId] = useState<number>(0);
  const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>();
  const [surveyGroup, setSurveyGroup] = useState<SurveyGroup>();
  const [selectedFileFormat, setSelectedFileFormat] = useState<FileTypes>();

  const [formattedRakeWeighterExecutions, setFormattedRakeWeighterExecutions] = useState<FilterOptionWithKey[]>([]);
  const [surveyGroupOptions, setSurveyGroupOptions] = useState<
    { label: SurveyGroup['title_for_display']; value: SurveyGroup }[]
  >([]);
  const [client, setClient] = useState<LeanClient>({ id: -1, name: '', zc_client_id: '' });

  const [areRakeWeighterExecutionsLoading, setAreRakeWeighterExecutionsLoading] = useState<boolean>(false);
  const [areSurveyGroupsLoading, setAreSurveyGroupsLoading] = useState<boolean>(false);

  const { clientsById } = useAppSelector((state: RootState) => state.clients);

  useEffect(() => {
    if (!clientId) {
      return;
    }
    async function fetchData() {
      try {
        setAreRakeWeighterExecutionsLoading(true);
        setAreSurveyGroupsLoading(true);
        const communitySurveyGroups = await fetchSurveyGroups({
          client_id: clientId,
        });
        if (!communitySurveyGroups.length) {
          setSurveyGroupOptions([]);
          setSurveyGroup(undefined);
          return;
        }
        setSurveyGroup(communitySurveyGroups[0]);
        const options = communitySurveyGroups.map((fetchedSurveyGroup) => ({
          label: fetchedSurveyGroup.title_for_display,
          value: fetchedSurveyGroup,
        }));
        setSurveyGroupOptions(options);
        setClient(clientsById[clientId]);
      } catch (error) {
        logger.error(error);
      } finally {
        setAreSurveyGroupsLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  useEffect(() => {
    async function fetchData() {
      if (!surveyGroup) {
        setFormattedRakeWeighterExecutions([]);
        return;
      }
      try {
        setAreRakeWeighterExecutionsLoading(true);
        const rakeWeighterExecutions = await fetchRakeWeighterExecutions({ survey_group: surveyGroup.id });
        const parsedRakeWeighterExecutions = parseRakeWeighterExecutions(rakeWeighterExecutions);
        setFormattedRakeWeighterExecutions(parsedRakeWeighterExecutions);
      } catch (error) {
        logger.error(error);
      } finally {
        setAreRakeWeighterExecutionsLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyGroup]);

  const fetchTotalSubmissions = async (): Promise<number | undefined> => {
    if (!surveyGroup) {
      return undefined;
    }
    let startDate = selectedDateRange?.startDate;
    let endDate = selectedDateRange?.endDate;
    if (!startDate || !endDate) {
      // Use a default date range.
      startDate = '2020-01-01';
      const today = format(new Date(), VAULT_COMPATIBLE_DATE_FORMAT);
      endDate = today;
    }
    const fetchedTotalSubmissions = await fetchTotalSubmissionsBySurveyGroup({
      surveyGroupId: surveyGroup.id,
      startDate,
      endDate,
    });
    return fetchedTotalSubmissions.total_submissions;
  };

  const {
    fetchedData: surveyGroupTotalSubmissions,
    isLoading: isTotalSubmissionsLoading,
    error: totalSubmissionsError,
  } = useAsyncEffect(fetchTotalSubmissions, [surveyGroup, selectedDateRange]);

  if (totalSubmissionsError) {
    logger.error(totalSubmissionsError);
  }

  const parseSelectedDateRange = (newValue: FilterOptionWithKey & { totalSubmissions: number }) => {
    const dates = (newValue.value as string).split(' ');
    setSelectedDateRange({ value: newValue.value as string, startDate: dates[0], endDate: dates[1] });
  };

  return (
    <>
      <Header titleText={translate('analysis.main')} />
      <div className={styles.container}>
        <ClientDropdown
          stateValue={clientId}
          onChangeCallback={({ value }: FilterOptionWithKey) => {
            setClientId(value as number);
          }}
        />
        <SurveyGroupFilter
          surveyGroupOptions={surveyGroupOptions}
          surveyGroup={surveyGroup}
          isLoading={areSurveyGroupsLoading}
          setSurveyGroup={setSurveyGroup}
        />
        <DateRangeFilter
          selectedDateRange={selectedDateRange}
          onChangeCallback={parseSelectedDateRange}
          isLoading={areRakeWeighterExecutionsLoading}
          options={formattedRakeWeighterExecutions}
        />
        <FileExtensionFilter
          onChangeCallback={(option: FileExtensionOption) => setSelectedFileFormat(option.value)}
          selectedFileFormat={selectedFileFormat}
        />
        <div className={styles.widgets}>
          <SubmissionsExporter
            surveyGroup={surveyGroup}
            isLoading={isTotalSubmissionsLoading}
            selectedDateRange={selectedDateRange}
            client={client}
            totalSubmissions={surveyGroupTotalSubmissions}
            selectedFileFormat={selectedFileFormat}
          />
          <CrossTabExporter surveyGroupId={surveyGroup?.id} selectedDateRange={selectedDateRange} client={client} />
        </div>
      </div>
    </>
  );
};
