import { QuestionBankDialog } from 'components/QuestionBankDialog/QuestionBankDialog';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { addQuestion, updateIndexes } from 'services/questions';
import { handleOnGenericQuestionSelect } from 'slices/questionsBank';
import { RootState } from 'store';
import { QuestionType } from 'types/genericQuestion';
import { QuestionItem } from 'types/questions';
import { logger } from 'utils/logger';
import { statusIsLoading } from 'utils/misc';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addQuestionInsideGroup: boolean;
  questionItem?: QuestionItem;
}

/**
 * This dialog will appear when clicking the "Add" button
 * on a question's contextual toolbar.
 */
export const QuestionAddDialog = (props: Props): ReactElement => {
  const { toastError } = useContext(SurveyManagerToastContext);
  const { isOpen, setIsOpen, questionItem, addQuestionInsideGroup } = props;
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedGenericQuestionId } = useAppSelector((state: RootState) => state.questionsBank);
  const { currentSurveyId, addRequestStatus } = useAppSelector((state: RootState) => state.questions);

  const handleOnCloseModal = () => {
    dispatch(handleOnGenericQuestionSelect());
    setIsOpen(false);
  };

  const resolveGroupQuestionId = (): number => {
    if (questionItem?.item.question_type === QuestionType.GROUP) {
      return questionItem?.id;
    }
    return questionItem?.item.group_question as number;
  };

  /**
   * Add a single new question. The new question will be placed under
   * the clicked question.
   */
  const handleOnSubmit = async (genericQuestionId?: number) => {
    if (!genericQuestionId) {
      // eslint-disable-next-line no-param-reassign
      genericQuestionId = selectedGenericQuestionId as number;
    }

    try {
      const newIndex = questionItem ? questionItem.item.index + 1 : 0;
      const createQuestionPayload = {
        genericQuestionId,
        surveyId: currentSurveyId as number,
        newIndex,
        groupQuestionId: addQuestionInsideGroup ? resolveGroupQuestionId() : undefined,
      };
      await dispatch(addQuestion(createQuestionPayload));
      dispatch(updateIndexes());
    } catch (error) {
      toastError();
      logger.error(error);
    }
    handleOnCloseModal();
  };

  return (
    <QuestionBankDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={translate('addQuestionDialog.title')}
      isSelectable
      addQuestionInsideGroup={addQuestionInsideGroup}
      isSubmitting={statusIsLoading(addRequestStatus)}
      disableSubmitButton={!selectedGenericQuestionId}
      submitBtnLabel={translate('addQuestionDialog.addButton')}
      submitHandler={handleOnSubmit}
      closeHandler={handleOnCloseModal}
      newQuestionPostCreationCallback={handleOnSubmit}
      newQuestionSubmitBtnLabel={translate('addQuestionDialog.createAndAdd')}
    />
  );
};
