import { ZCDCheckbox } from '@zencity/common-ui';
import { Spacer } from 'components/Spacer/Spacer';
import React, { useContext } from 'react';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';
import styles from './WidgetAdditionalFields.module.scss';

export const OverallBarChartAdditionalFields = (): React.ReactElement => {
  const { handleBenchmarkedOverallBar, handleBarLabel, isBenchmarkedOverallBar, isLabeledBar } =
    useContext(WidgetsConfigsContext);

  return (
    <div className={styles.fieldRow}>
      <ZCDCheckbox label="Show label" checked={isLabeledBar} onChange={handleBarLabel} />
      <Spacer orientation="horizontal" size="small" />
      <ZCDCheckbox
        label="Add benchmark to previous cycle"
        checked={isBenchmarkedOverallBar}
        onChange={handleBenchmarkedOverallBar}
      />
      <Spacer orientation="horizontal" size="small" />
    </div>
  );
};
