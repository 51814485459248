/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useMemo } from 'react';
import classNames from 'classnames';
import { ZCDTable } from '@zencity/common-ui';
import { Column, Row } from 'react-table';

import styles from './GenericReactTable.module.scss';
import { DEFAULT_MAX_ITEMS } from './constants';

interface TableProps {
  columns: Column[];
  tableData: any[];
  hasFooter?: boolean;
  hideBorder?: boolean;
  maxItemsLimit?: number;
  condensed?: boolean;
  getRowProps?: (row: Row<Record<string, unknown>>) => Record<string, unknown>;
}

export const GenericReactTable = (props: TableProps): ReactElement => {
  const {
    columns,
    tableData,
    hasFooter,
    hideBorder,
    getRowProps,
    maxItemsLimit = DEFAULT_MAX_ITEMS,
    condensed,
  } = props;
  const tableColumns = useMemo(() => [...columns], [columns]);

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.genericTableWrapper, {
          [styles.hideTableBorder]: hideBorder,
          [styles.hasFooter]: hasFooter,
          [styles.condensed]: condensed,
        })}
      >
        <ZCDTable
          tableData={tableData}
          columnArray={tableColumns}
          hasFooter={hasFooter}
          maxItemsLimit={maxItemsLimit}
          getRowProps={getRowProps}
        />
      </div>
    </div>
  );
};
