import { useAppSelector } from 'customHooks/hooks';

export const CLIENT_TOKEN = '%client_name%';
/*
 * A helper hook to replace the client token name with the actual client name.
 */
export const useClientNameTokenReplacement = (): {
  clientNameTokenReplacement: (content: string) => string;
} => {
  const { surveys } = useAppSelector((state) => state.surveys);
  const { currentSurveyId: surveyManageCurrentSurveyId } = useAppSelector((state) => state.surveyManage);
  const { currentSurveyId: questionsCurrentSurveyId } = useAppSelector((state) => state.questions);

  const clientNameTokenReplacement = (content: string) => {
    const surveyId = surveyManageCurrentSurveyId || questionsCurrentSurveyId;
    if (!content || !surveyId || !surveys[surveyId]) {
      return content;
    }

    const clientName = surveys[surveyId].survey_group.client.name;
    return content.replaceAll(CLIENT_TOKEN, clientName);
  };

  return { clientNameTokenReplacement };
};
