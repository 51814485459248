import classNames from 'classnames';
import React from 'react';
import { v4 } from 'uuid';
import styles from './ChoicesQuestionChart.module.scss';

interface Props {
  segments: { label: string; segmentLabel?: string; colorClassName: string }[];
}

export const Segments = (props: Props): React.ReactElement | null => {
  const { segments } = props;

  return !segments?.length ? null : (
    <div className={styles.segmentsRoot}>
      {segments.map((segment) => (
        <div key={v4()} className={styles.segment}>
          <div className={classNames(segment.colorClassName, styles.segmentColor)} />
          <span>{segment.label}</span>
        </div>
      ))}
    </div>
  );
};
