import { zcdConfirm, ZCDIconButton, zcdNotify } from '@zencity/common-ui';
import { useAppDispatch } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteGenericQuestion } from 'services/genericQuestion';
import { deleteGenericQuestion as deleteGenericQuestionFromSlice } from 'slices/genericQuestion';
import { GenericQuestion } from 'types/genericQuestion';

interface Props {
  genericQuestion: GenericQuestion;
}

export const DeleteGenericQuestion = ({ genericQuestion }: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOnDelete = async () => {
    const userResponse = await zcdConfirm({
      title: translate('common.warning'),
      message: translate('deleteGenericQuestionDialog.message'),
      confirmButtonText: translate('common.delete'),
      cancelButtonText: translate('common.cancel'),
    });
    if (userResponse) {
      try {
        await deleteGenericQuestion(genericQuestion.id);
        dispatch(deleteGenericQuestionFromSlice(genericQuestion.id));
      } catch (error) {
        const asError = error as Error;
        zcdNotify.error(asError.message);
      }
    }
  };

  return <ZCDIconButton onClick={handleOnDelete} type="button" icon="delete" variant="menu" />;
};
