import { SelectedDateRange } from 'screens/Analysis/misc';
import { SurveyCycle } from 'screens/WidgetGenerator/utils/misc';

/**
 * Returns all cycles including and after the selected cycle.
 */
export const getCyclesStartingFromSelectedCycle = (
  selectedDateRange: SelectedDateRange,
  cycles: SurveyCycle[],
): SurveyCycle[] => {
  const selectedDateRangeIndex = cycles.findIndex(
    (cycle) => cycle.startDate === selectedDateRange.startDate && cycle.endDate === selectedDateRange.endDate,
  );
  return cycles.slice(selectedDateRangeIndex);
};

/**
 * A "comparison cycle" is the most recent cycle with `display_on_dashboard` set to true.
 * It is assumed that the cycles are sorted in descending order from most recent to least recent.
 */
export const findComparisonCycle = (cycles: SurveyCycle[]): SurveyCycle | undefined =>
  cycles.find((cycle) => cycle.displayOnDashboard);

export const getCyclesForScoring = (selectedDateRange: SelectedDateRange, cycles: SurveyCycle[]): SurveyCycle[] => {
  const filteredCycles = getCyclesStartingFromSelectedCycle(selectedDateRange, cycles);
  const selectedCycle = filteredCycles[0];
  const cyclesWithoutSelectedCycle = filteredCycles.slice(1);
  const comparisonCycle = findComparisonCycle(cyclesWithoutSelectedCycle);
  const dateRangesForScoring = [selectedCycle, ...(comparisonCycle ? [comparisonCycle] : [])];
  return dateRangesForScoring;
};

// Check if the selected cycle is the Client's first cycle (cycles order is descending).
export const isFirstCycle = (selectedDateRange: SelectedDateRange, cycles: SurveyCycle[]): boolean => {
  const { startDate, endDate } = selectedDateRange;
  const selectedCycleIndex = cycles.findIndex((cycle) => cycle.startDate === startDate && cycle.endDate === endDate);
  const firstCycleIndex = cycles.length - 1;
  return selectedCycleIndex !== -1 && selectedCycleIndex === firstCycleIndex;
};
