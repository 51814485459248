import { Nullable } from 'types/misc';

export enum TypeformSurveyStatus {
  DRAFTED = 'drafted',
  ACTIVE = 'active',
  CLOSED = 'closed',
  DELETED = 'deleted',
}

export interface PanelVendor {
  id: number;
  name: string;
}

export enum KnownPanelVendor {
  TAP = 'Tap',
  PURESPECTRUM = 'PureSpectrum',
}

export interface TypeformSurvey {
  id: number;
  survey: number;
  language: string;
  typeform_id: Nullable<string>;
  published_date: Nullable<string>; // In ISO format.
  status: TypeformSurveyStatus;
  closed_date: Nullable<string>; // In ISO format.
  panel: Nullable<PanelVendor>;
  is_locked: boolean;
}
