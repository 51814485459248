import { GenericQuestion, GenericQuestionConfig } from 'types/genericQuestion';
import { QuestionConfigType, QuestionConfig } from 'types/questionConfig';

import { Question } from 'types/questions';

/**
 * Convert the given instance's "configs" to a key/value object.
 */
export const normalizeGenericQuestionConfigs = (instance: GenericQuestion): Record<QuestionConfigType, string> =>
  instance.configs.reduce(
    (accum, config) => ({
      ...accum,
      [config.config_name]: config.config_value,
    }),
    {} as Record<QuestionConfigType, string>,
  );

/**
 * Get a specific config from the given Generic Question instance.
 */
export const getGenericQuestionConfig = (
  instance: GenericQuestion,
  configName: QuestionConfigType,
): GenericQuestionConfig | undefined => instance.configs?.find((config) => config.config_name === configName);

/**
 * Get a specific config value from the given Generic Question instance.
 */
export const getGenericQuestionConfigValue = (
  instance: GenericQuestion,
  configName: QuestionConfigType,
): string | undefined => getGenericQuestionConfig(instance, configName)?.config_value;

/**
 * Get a specific config from the given Question instance.
 */
export const getQuestionConfig = (instance: Question, configName: QuestionConfigType): QuestionConfig | undefined =>
  instance.configs?.find((config) => config.config_name === configName);

/**
 * Get a specific config value the given Question instance.
 */
export const getQuestionConfigValue = (instance: Question, configName: QuestionConfigType): string | undefined =>
  getQuestionConfig(instance, configName)?.config_value;

interface QuestionConfigItem {
  configValue?: string;
  isOverridden: boolean;
}

/**
 * Finds a question's config value. First checks for overridden configs in the Question,
 * and then checks the GenericQuestion to see if it exists there.
 */
// eslint-disable-next-line max-statements
export const findQuestionConfig = (
  question: Question,
  genericQuestion: GenericQuestion,
  configName: QuestionConfigType,
  translatedLanguage?: string,
  // eslint-disable-next-line consistent-return
): QuestionConfigItem | undefined => {
  const questionConfig = getQuestionConfig(question, configName);
  if (questionConfig) {
    if (translatedLanguage) {
      return {
        configValue: questionConfig.translations?.config_value[translatedLanguage],
        isOverridden: true,
      };
    }

    return {
      configValue: questionConfig.config_value,
      isOverridden: true,
    };
  }

  if (genericQuestion) {
    const genericQuestionConfig = getGenericQuestionConfig(genericQuestion, configName);
    if (genericQuestionConfig) {
      if (translatedLanguage) {
        return {
          configValue: genericQuestionConfig.translations?.config_value[translatedLanguage],
          isOverridden: false,
        };
      }
      return {
        configValue: genericQuestionConfig.config_value,
        isOverridden: false,
      };
    }
  }
};

const resolveBooleanConfig = ({ value = '0' }: { value: string | undefined }) => value !== '0';
export const resolveRequiredConfig = (value: string | undefined): boolean => resolveBooleanConfig({ value });
export const resolveRandomizedConfig = (value: string | undefined): boolean => resolveBooleanConfig({ value });
export const resolveAlphabeticalOrderConfig = (value: string | undefined): boolean => resolveBooleanConfig({ value });
export const resolveStartAtOneConfig = (value: string | undefined): boolean => resolveBooleanConfig({ value });
export const resolveDisplayOnLiveFeedConfig = (value: string | undefined): boolean => resolveBooleanConfig({ value });
export const resolveHideFromLiveFeedConfig = (value: string | undefined): boolean => resolveBooleanConfig({ value });
export const resolveDisplayOtherConfig = (value: string | undefined): boolean => resolveBooleanConfig({ value });
