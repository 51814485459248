import { GenericQuestion, QuestionType } from 'types/genericQuestion';
import { QuestionChoice } from 'types/questionChoice';
import { Question, QuestionItem, ResolvedIndexes } from 'types/questions';
import { convertNumberToLetter } from 'utils/misc';

/**
 * Given a list of questions, finds all of the related generic question ID's that
 * are not in the genericQuestions slice.
 */
export const findMissingGenericQuestions = (
  questions: Question[],
  genericQuestionsById: { [genericQuestionId: number]: GenericQuestion },
): number[] =>
  questions.reduce((genericQuestionIds, currentQuestion) => {
    const { generic_question: genericQuestionId } = currentQuestion;
    if (!genericQuestionsById[genericQuestionId]) {
      genericQuestionIds.push(genericQuestionId);
    }
    return genericQuestionIds;
  }, [] as number[]);

const resolveQuestionIndex = (index: number, parentIndex?: number): string =>
  parentIndex || parentIndex === 0 ? `${parentIndex + 1}${convertNumberToLetter(index, false)}` : `${index + 1}`;

/**
 * Resolve the indexes of the given questions.
 */
export const resolvedQuestionsIndexes = (reducedQuestionItems: QuestionItem[]): ResolvedIndexes => {
  const resolvedIndexes = {} as ResolvedIndexes;

  reducedQuestionItems.forEach((questionItem, index) => {
    resolvedIndexes[questionItem.id] = resolveQuestionIndex(index);
    if (questionItem.item.question_type === QuestionType.GROUP) {
      const { children } = questionItem;
      children.forEach((childQuestionItem, childIndex) => {
        resolvedIndexes[childQuestionItem.id] = resolveQuestionIndex(childIndex, index);
      });
    }
  });

  return resolvedIndexes;
};

export const sortQuestionChoices = (questionChoices: QuestionChoice[]): QuestionChoice[] =>
  questionChoices.sort((first, second) => 0 - (first.label > second.label ? -1 : 1));
