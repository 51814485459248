/**
 * Takes a full name, assumed in the format "Firstname Lastname",
 * and returns a string in the format "Firstname L.".
 * @param fullName - The user's full name.
 */
export const abbreviateLastName = (fullName = 'Unknown'): string => {
  const nameSplit = fullName.split(' ');
  if (nameSplit.length > 1 && nameSplit[1].length > 0) {
    return `${nameSplit[0]} ${nameSplit[1].charAt(0)}.`;
  }
  return fullName;
};
