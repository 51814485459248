import { ZCDDialog } from '@zencity/common-ui';
import { ConfirmationDialogFooter } from 'components/ConfirmationDialogFooter/ConfirmationDialogFooter';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch } from 'customHooks/hooks';
import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeSurvey } from 'services/survey';
import { setTypeformSurveys } from 'slices/surveyManage';
import { logger } from 'utils/logger';

interface Props {
  surveyId: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * This dialog will display when requesting to close a survey.
 * When submitted, it will set all Typeform Surveys that belong this Survey to the "Closed" status.
 */
export const CloseSurveyDialog = (props: Props): ReactElement => {
  const { isOpen, setIsOpen, surveyId } = props;
  const { t: translate } = useTranslation();
  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const closeDialog = () => setIsOpen(false);

  const handleOnCloseClick = async () => {
    try {
      setIsLoading(true);
      const updatedTypeformSurveys = await closeSurvey(surveyId);
      dispatch(setTypeformSurveys(updatedTypeformSurveys));
    } catch (error) {
      toastError();
      logger.error(error);
    } finally {
      setIsLoading(false);
      closeDialog();
    }
  };

  return (
    <ZCDDialog
      isOpen={isOpen}
      onRequestClose={closeDialog}
      header={<h2>{translate('closeSurveyDialog.header')}</h2>}
      footer={
        <ConfirmationDialogFooter
          isDisabled={isLoading}
          handleOnCancel={closeDialog}
          handleOnConfirm={handleOnCloseClick}
          confirmText={translate('closeSurveyDialog.closeButton')}
        />
      }
    >
      <LoaderMask isLoading={isLoading}>
        <p>{translate('closeSurveyDialog.description')}</p>
      </LoaderMask>
    </ZCDDialog>
  );
};
