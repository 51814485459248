/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchQuestionsBySurveyId,
  deleteQuestion,
  updateQuestion,
  writeQuestionConfig,
  resetAllOverrides,
  addQuestion,
  updateQuestionOverriddenTextTranslation,
  updateQuestionConfigTranslation,
  updateQuestionLogicActions,
  uploadQuestionImage,
} from 'services/questions';
import { RequestStatus, PaginatedResults } from 'types/misc';
import { QuestionConfig } from 'types/questionConfig';
import { Question } from 'types/questions';

interface QuestionsState {
  questions: Question[];
  fetchStatus: RequestStatus;
  deleteRequestStatus: RequestStatus;
  updateStatus: RequestStatus;
  addRequestStatus: RequestStatus;
  errorMessage?: string;
  currentSurveyId?: number;
}

const initialState: QuestionsState = {
  questions: [],
  fetchStatus: RequestStatus.IDLE,
  addRequestStatus: RequestStatus.IDLE,
  deleteRequestStatus: RequestStatus.IDLE,
  updateStatus: RequestStatus.IDLE,
};

const slice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setCurrentSurveyId: (state: QuestionsState, action: PayloadAction<number>) => {
      const surveyId = action.payload;
      state.currentSurveyId = surveyId;
    },
    updateQuestionsOrder: (state: QuestionsState, action: PayloadAction<Question[]>) => {
      const questions = action.payload;
      state.questions = questions;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchQuestionsBySurveyId.pending, (state: QuestionsState) => {
        state.fetchStatus = RequestStatus.LOADING;
      })
      .addCase(
        fetchQuestionsBySurveyId.fulfilled,
        (state: QuestionsState, action: PayloadAction<PaginatedResults<Question>>) => {
          state.fetchStatus = RequestStatus.SUCCESS;
          state.questions = action.payload.results;
        },
      )
      .addCase(fetchQuestionsBySurveyId.rejected, (state: QuestionsState, action) => {
        state.fetchStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(deleteQuestion.pending, (state: QuestionsState) => {
        state.deleteRequestStatus = RequestStatus.LOADING;
      })
      .addCase(
        deleteQuestion.fulfilled,
        (state: QuestionsState, action: PayloadAction<{ deletedQuestionsIds: number[] }>) => {
          state.deleteRequestStatus = RequestStatus.SUCCESS;
          const { deletedQuestionsIds } = action.payload;
          const questionsCopy = [...state.questions];
          deletedQuestionsIds.forEach((questId) => {
            // eslint-disable-next-line max-nested-callbacks
            const index = questionsCopy.findIndex((question) => question.id === questId);
            if (index > -1) {
              questionsCopy.splice(index, 1);
            }
          });
          state.questions = questionsCopy;
        },
      )
      .addCase(deleteQuestion.rejected, (state: QuestionsState, action) => {
        state.deleteRequestStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(updateQuestion.pending, (state: QuestionsState) => {
        state.updateStatus = RequestStatus.LOADING;
      })
      .addCase(updateQuestion.fulfilled, (state: QuestionsState, action: PayloadAction<Question>) => {
        state.updateStatus = RequestStatus.SUCCESS;
        const updatedQuestion = action.payload;
        state.questions = state.questions.map((instance) =>
          instance.id === updatedQuestion.id ? updatedQuestion : instance,
        );
      })
      .addCase(updateQuestion.rejected, (state: QuestionsState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(writeQuestionConfig.pending, (state: QuestionsState) => {
        state.updateStatus = RequestStatus.LOADING;
      })
      .addCase(writeQuestionConfig.fulfilled, (state: QuestionsState, action: PayloadAction<Question>) => {
        state.updateStatus = RequestStatus.SUCCESS;
        const updatedQuestion = action.payload;
        state.questions = state.questions.map((instance) =>
          instance.id === updatedQuestion.id ? updatedQuestion : instance,
        );
      })
      .addCase(writeQuestionConfig.rejected, (state: QuestionsState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(resetAllOverrides.pending, (state: QuestionsState) => {
        state.updateStatus = RequestStatus.LOADING;
      })
      .addCase(resetAllOverrides.fulfilled, (state: QuestionsState, action: PayloadAction<Question>) => {
        state.updateStatus = RequestStatus.SUCCESS;
        const updatedQuestion = action.payload;
        state.questions = state.questions.map((instance) =>
          instance.id === updatedQuestion.id ? updatedQuestion : instance,
        );
      })
      .addCase(resetAllOverrides.rejected, (state: QuestionsState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(addQuestion.pending, (state: QuestionsState) => {
        state.addRequestStatus = RequestStatus.LOADING;
      })
      .addCase(
        addQuestion.fulfilled,
        (state: QuestionsState, action: PayloadAction<{ newQuestion: Question; newIndex: number }>) => {
          state.addRequestStatus = RequestStatus.SUCCESS;
          const { newQuestion, newIndex } = action.payload;
          state.questions.splice(newIndex, 0, newQuestion);
        },
      )
      .addCase(addQuestion.rejected, (state: QuestionsState, action) => {
        state.errorMessage = action.error.message;
        state.addRequestStatus = RequestStatus.ERROR;
      })
      .addCase(updateQuestionOverriddenTextTranslation.rejected, (state: QuestionsState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(
        updateQuestionOverriddenTextTranslation.fulfilled,
        (state: QuestionsState, action: PayloadAction<Question>) => {
          const updatedQuestion = action.payload;
          state.questions = state.questions.map((instance) => {
            if (instance.id === updatedQuestion.id) {
              const overriddenTextTranslations = instance.translations?.overridden_text ?? {};
              const updatedLanguages = Object.keys(updatedQuestion.translations?.overridden_text ?? {});
              if (updatedLanguages.length) {
                const updatedLanguage = updatedLanguages[0];
                overriddenTextTranslations[updatedLanguage] =
                  updatedQuestion.translations?.overridden_text[updatedLanguage] ?? '';
              }
            }
            return instance;
          });
        },
      )
      .addCase(updateQuestionConfigTranslation.rejected, (state: QuestionsState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(
        updateQuestionConfigTranslation.fulfilled,
        (state: QuestionsState, action: PayloadAction<QuestionConfig>) => {
          const updatedQuestionConfig = action.payload;
          state.questions = state.questions.map((question) => {
            // eslint-disable-next-line max-nested-callbacks
            const questionConfigIds = question.configs.map((config) => config.id);
            const questionConfigIndex = questionConfigIds.indexOf(updatedQuestionConfig.id);
            if (questionConfigIndex > -1) {
              question.configs[questionConfigIndex] = updatedQuestionConfig;
            }
            return question;
          });
        },
      )
      // Logic Actions Cases
      .addCase(updateQuestionLogicActions.pending, (state: QuestionsState) => {
        state.updateStatus = RequestStatus.LOADING;
      })
      .addCase(updateQuestionLogicActions.rejected, (state: QuestionsState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(updateQuestionLogicActions.fulfilled, (state: QuestionsState, action: PayloadAction<Question>) => {
        const updatedQuestion = action.payload;
        state.updateStatus = RequestStatus.SUCCESS;
        state.questions = state.questions.map((instance) =>
          instance.id === updatedQuestion.id ? updatedQuestion : instance,
        );
      })
      .addCase(uploadQuestionImage.pending, (state: QuestionsState) => {
        state.updateStatus = RequestStatus.LOADING;
      })
      .addCase(uploadQuestionImage.rejected, (state: QuestionsState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(uploadQuestionImage.fulfilled, (state: QuestionsState, action: PayloadAction<Question>) => {
        const updatedQuestion = action.payload;
        state.updateStatus = RequestStatus.SUCCESS;
        state.questions = state.questions.map((instance) =>
          instance.id === updatedQuestion.id ? updatedQuestion : instance,
        );
      });
  },
});

export const { setCurrentSurveyId, updateQuestionsOrder } = slice.actions;
export default slice;
