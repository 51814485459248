import React, { ReactElement } from 'react';
import { useAppSelector } from 'customHooks/hooks';
import { ScaleRightSection } from 'components/NewGenericQuestion/subComponents/NewQuestionRightSectionComponents/ScaleRightSection';
import { QuestionType } from 'types/genericQuestion';
import { RootState } from 'store';
import { ChoicesRightSection } from 'components/NewGenericQuestion/subComponents/NewQuestionRightSectionComponents/ChoicesRightSection';
import { NumberRightSection } from 'components/NewGenericQuestion/subComponents/NewQuestionRightSectionComponents/NumberRightSection';

/**
 * A helper component to edit some GenericQuestion configurations per "question type".
 */
export const NewQuestionRightSection = (): ReactElement => {
  const { newQuestionPayload } = useAppSelector((state: RootState) => state.questionsBank);

  switch (newQuestionPayload.question_type) {
    case QuestionType.CHOICES:
      return <ChoicesRightSection />;

    case QuestionType.SCALE:
      return <ScaleRightSection />;

    case QuestionType.NUMBER:
      return <NumberRightSection />;

    default:
      return <></>;
  }
};
