import { Flex } from 'components/Flex/Flex';
import React from 'react';
import { QuestionnaireWidgetData } from 'screens/WidgetGenerator/utils/misc';
import { QuestionType } from 'types/genericQuestion';
import { QuestionConfigType } from 'types/questionConfig';
import { getQuestionConfig } from 'utils/genericAndQuestionConfigs';
import { generateMainDisplayText } from '../helpers';
import styles from './QuestionnaireChoices.module.scss';

interface Props {
  question: QuestionnaireWidgetData['questions'][0];
}

export const QuestionnaireChoices = (props: Props) => {
  const { question } = props;
  const { choices, question_type } = question;
  const isScaleQuestion = question_type === QuestionType.SCALE;
  const isDisplayOther = getQuestionConfig(question, QuestionConfigType.DISPLAY_OTHER);
  const steps = getQuestionConfig(question, QuestionConfigType.STEPS)?.config_value;
  const startAtOne = getQuestionConfig(question, QuestionConfigType.START_AT_ONE);
  const scaleLabelFirst = getQuestionConfig(question, QuestionConfigType.SCALE_LABEL_FIRST);
  const scaleLabelLast = getQuestionConfig(question, QuestionConfigType.SCALE_LABEL_LAST);
  const choicesText = generateMainDisplayText({
    questionType: question_type,
    isDisplayOther: !!isDisplayOther,
    choices,
    steps,
    startAtOne: !!startAtOne,
  });

  return (
    <Flex className={styles.container} flexDirection="column" justifyContent="center">
      <div className={styles.mainTitle}>{choicesText}</div>
      {isScaleQuestion && (
        <div className={styles.subtitle}>{`(${scaleLabelFirst?.config_value} - ${scaleLabelLast?.config_value})`}</div>
      )}
    </Flex>
  );
};
