/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */

import { Spacer } from 'components/Spacer/Spacer';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAsyncEffect } from 'customHooks/hooks';
import FileSaver from 'file-saver';
import React, { useCallback, useEffect, useState } from 'react';
import { fetchRakeWeighterExecutions } from 'services/communitySurveyReport/rakeWighterExecutions';
import { RootState } from 'store';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { LeanClient } from 'types/client';
import { logger } from 'utils/logger';
import { parseRakeWeighterExecutions } from 'utils/rakeWeighterExecutions';
import { DEFAULT_DEMOGRAPHIC_TABLE_ROWS_PER_TABLE } from 'screens/WidgetGenerator/widgets/DemographicsTable/DemographicsTable';
import { SurveyGroup, SurveyType } from '@zencity/survey-types';
import { fetchSurveyGroups } from 'services/surveyGroup';
import { fetchBenchmarkCycleExecutions } from 'services/benchmarkCycleExecutions';
import { ZCDNotificationContainer } from '@zencity/common-ui';
import { WidgetConfig } from './components/WidgetConfig/WidgetConfig';
import { WidgetPreview } from './components/WidgetPreview/WidgetPreview';
import { DEFAULT_DATA, DEFAULT_WIDGET_SIZE } from './utils/defaultValues';
import {
  AspectValue,
  BenchmarkColumnKey,
  ChoicesQuestionChartDisplayOrder,
  ChoicesQuestionChartDisplayType,
  RawWidgetData,
  OverallSatisfactionScoreQuestion,
  SelectedDateRange,
  SurveyCycle,
  WidgetType,
  WordCloudQuestionSentiment,
} from './utils/misc';
import { fetchDataByWidgetType } from './utils/utilsFunctions';
import styles from './WidgetGenerator.module.scss';
import { WidgetsConfigsContext, WidgetsConfigsContextProps } from './contexts/WidgetsConfigsContext';
import { WidgetGeneratorContext, WidgetGeneratorContextProps } from './contexts/WidgetGeneratorContext';
import { CommunitySurveyTemplateGenerator } from './TemplateGenerator';

interface Props {
  zcClientId?: string;
}

export const WidgetGenerator = (props: Props): React.ReactElement => {
  const { zcClientId } = props;
  const { t: translate } = useTranslation();
  const [client, setClient] = useState<LeanClient>();
  const [cycles, setCycles] = useState<SurveyCycle[]>([]);
  const [fileName, setFileName] = useState('react-chart.png');
  const [clientName, setClientName] = useState('');
  const [widgetWidth, setWidgetWidth] = useState<number>(600);
  const [widgetType, setWidgetType] = useState(WidgetType.DEFAULT);
  const [selectedSurveyGroup, setSelectedSurveyGroup] = useState<SurveyGroup>();
  const [widgetData, setWidgetData] = useState<RawWidgetData>(['']);
  const [aspectValue, setAspectValue] = useState(AspectValue.CHARACTERISTIC);
  const [selectedBenchmarkAspect, setSelectedBenchmarkAspect] = useState<string>();
  const [stringifiedWidgetData, setStringifiedWidgetData] = useState('');
  const [isValidData, setIsValidData] = useState(false);
  const [dataError, setDataError] = useState('');
  const [circleScale, setCircleScale] = useState(4);
  const [wordCloudQuestionSentiments, setWordCloudQuestionSentiments] = useState<WordCloudQuestionSentiment[]>([]);
  const [columnName, setColumnName] = useState('');
  const [isLabeledBar, setIsLabeledBar] = useState(false);
  const [isBenchmarked, setIsBenchmarked] = useState(false);
  const [isBenchmarkedServices, setIsBenchmarkedServices] = useState(false);
  const [isShortBarChart, setIsShortBarChart] = useState(false);
  const [isBenchmarkedOverallBar, setIsBenchmarkedOverallBar] = useState(false);
  const [showAllSatisfactionScores, setShowAllSatisfactionScores] = useState(false);
  const [isBenchmarkedAreas, setIsBenchmarkedAreas] = useState(false);
  const [servicesBarChartNumBarsPerRow, setServicesBarChartNumBarsPerRow] = useState(0);
  const [demographicsTableNumRowsPerTable, setDemographicsTableNumRowsPerTable] = useState(
    DEFAULT_DEMOGRAPHIC_TABLE_ROWS_PER_TABLE,
  );
  const [splitPositionBarChart, setSplitPositionBarChart] = useState(0);
  const [choicesQuestionChartDisplayType, setChoicesQuestionChartDisplayType] = useState(
    ChoicesQuestionChartDisplayType.INTEGER,
  );
  const [selectedSurvey, setSelectedSurvey] = useState<number>();
  const [selectedGenericQuestionId, setSelectedGenericQuestionId] = useState<number>();
  const [crossTabQuestionSelectedOptions, setCrossTabQuestionSelectedOptions] = useState<SelectOption[]>();
  const [selectedDemographicGeographic, setSelectedDemographicGeographic] = useState<string[]>();
  const [choicesQuestionChartDisplayOrder, setChoicesQuestionChartDisplayOrder] =
    useState<ChoicesQuestionChartDisplayOrder>(ChoicesQuestionChartDisplayOrder.APPEARANCE);
  const [splitPositionQuestionnaire, setSplitPositionQuestionnaire] = useState(7);
  const [selectedAspects, setSelectedAspects] = useState<number[]>([]);

  const [benchmarkColumns, setBenchmarkColumns] = useState({
    national: false,
    cohort: false,
  });
  const [cyclesOptions, setCyclesOptions] = useState<SelectOption[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingClientData, setIsLoadingClientData] = useState(false);
  const [maintainAndFocusServices, setMaintainAndFocusServices] = useState<OverallSatisfactionScoreQuestion[]>([]);
  const [selectedBenchmarkClient, setSelectedBenchmarkClient] = useState<LeanClient>();
  const { clientsById } = useAppSelector((state: RootState) => state.clients);

  useEffect(() => {
    // update stringified data when widget data changes (currently only used for maintain and focus services)
    if (widgetType !== WidgetType.MAINTAIN_AND_FOCUS_SERVICES) return;
    setStringifiedWidgetData(JSON.stringify(widgetData, null, 4));
  }, [widgetData, widgetType]);

  const handleDataUpload = (payload: JSON | null) => {
    // stringify data to display the returned json in the textarea readable (for future editing)
    setStringifiedWidgetData(JSON.stringify(payload, null, 4));
    setWidgetData(JSON.parse(JSON.stringify(payload, null, 4)));
    setDataError('');
    setIsValidData(true);
  };

  const fetchClientSurveyGroups = async (): Promise<SurveyGroup[]> => {
    if (!client) {
      return [];
    }
    const fetchedSurveyGroups = await fetchSurveyGroups({ client_id: client.id });
    return fetchedSurveyGroups;
  };

  const { fetchedData: surveyGroups, error: fetchSurveyGroupsError } = useAsyncEffect(fetchClientSurveyGroups, [
    client,
  ]);
  if (fetchSurveyGroupsError) {
    logger.error(fetchSurveyGroupsError);
  }

  const handleFetchDataButton = async () => {
    try {
      setIsLoadingData(true);
      let fetchedData;
      if (widgetType === WidgetType.SERVICE_SENTIMENT_BAR || widgetType === WidgetType.CHOICES_QUESTION_CHART) {
        fetchedData = await fetchDataByWidgetType({
          widgetType,
          client: client!,
          cycles,
          questionId: selectedGenericQuestionId,
          surveyGroupId: selectedSurveyGroup?.id,
          selectedDateRange: selectedDateRange!,
        });
      } else if (widgetType === WidgetType.CROSS_TABS) {
        fetchedData = await fetchDataByWidgetType({
          widgetType,
          client: client!,
          cycles,
          questionId: selectedGenericQuestionId,
          crossTabQuestionSelectedOptions,
          demographicGeographic: selectedDemographicGeographic,
          surveyGroupId: selectedSurveyGroup?.id,
          selectedDateRange: selectedDateRange!,
        });
      } else {
        fetchedData = await fetchDataByWidgetType({
          widgetType,
          client: client!,
          cycles,
          surveyId: selectedSurvey,
          selectedAspectIds: selectedAspects,
          selectedDateRange: selectedDateRange!,
          selectedBenchmarkClient,
        });
      }
      if (widgetType === WidgetType.MAINTAIN_AND_FOCUS_SERVICES) {
        setMaintainAndFocusServices(fetchedData as OverallSatisfactionScoreQuestion[]);
      } else {
        setWidgetData(fetchedData);
        setStringifiedWidgetData(JSON.stringify(fetchedData, null, 4));
      }
      setDataError('');
      setIsValidData(false);
      setIsLoadingData(false);
    } catch (error) {
      const asError = error as Error;
      logger.error(error);
      setDataError(`Failed to fetch data: ${asError.message}`);
      setIsLoadingData(false);
    }
  };

  const handleWidgetSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWidgetWidth(parseInt(event.target.value, 10));
    setIsValidData(false);
  };

  const handleDefaultDataButton = () => {
    try {
      const newData = JSON.parse(DEFAULT_DATA[widgetType]);
      setWidgetData(newData);
      setDataError('');
      setIsValidData(false);
      setStringifiedWidgetData(DEFAULT_DATA[widgetType]);
      if (widgetType === WidgetType.MAINTAIN_AND_FOCUS_SERVICES) {
        setMaintainAndFocusServices([...newData.maintain, ...newData.focus]);
      }
    } catch (error) {
      setDataError('Syntax Error');
    }
  };
  const clearData = () => {
    setWidgetData([]);
    setDataError('');
    setIsValidData(false);
    setStringifiedWidgetData('');
  };
  const handleClearDataButton = () => {
    clearData();
  };

  const checkIsValidData = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStringifiedWidgetData(event.target.value);
    if (isValidData !== false) {
      setIsValidData(false);
    }
  };

  const handleSelectWidget = (selectedWidget: WidgetType) => {
    setWidgetType(selectedWidget);
    setWidgetWidth(DEFAULT_WIDGET_SIZE[selectedWidget]);
    setIsLabeledBar(false);
    clearData();
  };

  const handleAspectType = (aspect: AspectValue) => {
    setAspectValue(aspect);
    setIsValidData(false);
  };

  const handleClientNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientName(event.target.value);
    setIsValidData(false);
  };

  const handleFileNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setFileName(`${event.target.value}.png`);
    } else {
      setFileName('react-chart.png');
    }
  };

  const handleUpdateCustomChartButton = () => {
    try {
      if (widgetType === WidgetType.WORD_CLOUD && wordCloudQuestionSentiments.length === 0) return;
      const newData = JSON.parse(stringifiedWidgetData);
      const widgetTypeObjectDataType = [
        WidgetType.OVERALL_SATISFACTION_TEXT,
        WidgetType.WORD_CLOUD,
        WidgetType.OVERALL_BAR_CHART,
        WidgetType.CHOICES_QUESTION_CHART,
        WidgetType.MAINTAIN_AND_FOCUS_SERVICES,
        WidgetType.SENTIMENT_BAR_OVERALL_TABLE,
        WidgetType.CROSS_TABS,
        WidgetType.BENCHMARK_OVERALL_SATISFACTION_TEXT,
      ];
      if (!widgetTypeObjectDataType.includes(widgetType) && !Array.isArray(newData)) {
        throw new Error('Data must include an array');
      }
      setWidgetData(newData);
      setDataError('');
      setIsValidData(true);
    } catch (error: unknown) {
      let errorMessage = '';
      if (error instanceof Error) {
        errorMessage = error.message;
        if (error instanceof SyntaxError) {
          errorMessage = 'Syntax Error';
        }
      }
      setDataError(errorMessage);
    }
  };

  const handleBarLabel = () => {
    setIsLabeledBar((prev) => !prev);
    setIsValidData(false);
  };

  const handleBenchmarkedOverallBar = () => {
    setIsBenchmarkedOverallBar((prev) => !prev);
    setIsValidData(false);
  };

  const handleShowAllSatisfactionScores = () => {
    setShowAllSatisfactionScores((prev) => !prev);
    setIsValidData(false);
  };
  const handleBenchmarkedAreas = () => {
    setIsBenchmarkedAreas((prev) => !prev);
    setIsValidData(false);
  };

  const handleBenchmarkCycle = () => {
    setIsBenchmarked((prev) => !prev);
    setIsValidData(false);
  };

  const handleBenchmarkCycleServices = () => {
    setIsBenchmarkedServices((prev) => !prev);
    setIsValidData(false);
  };

  const handleWordCloudCat = (value: WordCloudQuestionSentiment) => {
    setWordCloudQuestionSentiments((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value],
    );
    setIsValidData(false);
  };
  const handleBenchmarkColumns = ({ key, value }: { key: BenchmarkColumnKey; value: boolean }) => {
    setBenchmarkColumns((prev) => ({ ...prev, [key]: value }));
    setIsValidData(false);
  };

  const handleColumnName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColumnName(event.target.value);
    setIsValidData(false);
  };

  const handleScale = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCircleScale(parseInt(event.target.value));
    setIsValidData(false);
  };

  const handleDemographicsTableNumRowsPerTable = useCallback(
    (chunkSize: number) => {
      setDemographicsTableNumRowsPerTable(chunkSize);
      setIsValidData(false);
    },
    [setDemographicsTableNumRowsPerTable, setIsValidData],
  );

  const handleServicesBarChartNumBarsPerRow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServicesBarChartNumBarsPerRow(parseInt(event.target.value));
    setIsValidData(false);
  };

  const handleSplitPositionBarChart = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSplitPositionBarChart(parseInt(event.target.value));
    setIsValidData(false);
  };

  const handleIsShortBarChartButton = () => {
    setIsShortBarChart((prev) => !prev);
    setIsValidData(false);
  };

  const handleFileSaveAsButton = () => {
    const canvas = document.querySelector('canvas');
    const dataURL = canvas?.toDataURL();
    if (dataURL) {
      FileSaver.saveAs(dataURL, fileName);
    }
  };

  const handleClient = (id: number) => {
    const vaultClient = clientsById[id];
    setClient(vaultClient);
    setSelectedDateRange(undefined);
    setCyclesOptions([]);
    setWidgetType(WidgetType.DEFAULT);
    clearData();
  };

  const parseSelectedDateRange = (key: string) => {
    const dates = key.split(' ');
    setSelectedDateRange({ value: key, startDate: dates[0], endDate: dates[1] });
    clearData();
  };

  const handleSelectSurveyGroup = (selectedOption: unknown) => {
    const surveyGroupOption = selectedOption as { value: number };
    const selectedSurveyGroupId = surveyGroupOption.value;
    const selectedSurveyGroupFromOption = surveyGroups?.find((surveyGroup) => surveyGroup.id === selectedSurveyGroupId);
    setSelectedSurveyGroup(selectedSurveyGroupFromOption);
  };

  const handleSelectBenchmarkClient = (selectedClient: LeanClient) => {
    setSelectedBenchmarkClient(selectedClient);
  };

  const handleSelectBenchmarkAspect = (selectedAspect: string) => {
    setSelectedBenchmarkAspect(selectedAspect);
  };

  const fetchBenchmarkCycles = async () => {
    const fetchedBenchmarkCycles = await fetchBenchmarkCycleExecutions();
    const parsedBenchmarkCycles = fetchedBenchmarkCycles.map((benchmarkCycle) => ({
      startDate: benchmarkCycle.start_date,
      endDate: benchmarkCycle.end_date,
      displayOnDashboard: true,
    }));
    setCycles(parsedBenchmarkCycles);
    const benchmarkCyclesOptions = parsedBenchmarkCycles.map((benchmarkCycle) => ({
      value: `${benchmarkCycle.startDate} ${benchmarkCycle.endDate}`,
      label: `${benchmarkCycle.startDate} to ${benchmarkCycle.endDate}`,
    }));
    setCyclesOptions(benchmarkCyclesOptions);
  };

  const fetchSurveyGroupRakeWeighterExectutions = async (surveyGroup: SurveyGroup) => {
    const fetchedRakeWeighterExecutions = await fetchRakeWeighterExecutions({ survey_group: surveyGroup.id });
    const clientSurveyCycles = fetchedRakeWeighterExecutions.map((rakeWeighterExecution) => ({
      startDate: rakeWeighterExecution.start_date,
      endDate: rakeWeighterExecution.end_date,
      displayOnDashboard: rakeWeighterExecution.display_on_dashboard,
    }));
    setCycles(clientSurveyCycles);
    const parsedRakeWeighterExecutions = parseRakeWeighterExecutions(fetchedRakeWeighterExecutions);
    if (parsedRakeWeighterExecutions.length > 0) {
      setCyclesOptions(parsedRakeWeighterExecutions);
    }
  };

  useEffect(() => {
    if (!client) {
      return;
    }
    async function fetchClientCycles() {
      try {
        setIsLoadingClientData(true);
        if (selectedSurveyGroup) {
          if (selectedSurveyGroup.type === SurveyType.BENCHMARK) {
            await fetchBenchmarkCycles();
          } else {
            await fetchSurveyGroupRakeWeighterExectutions(selectedSurveyGroup);
          }
        }
        setIsLoadingClientData(false);
      } catch (error) {
        logger.error(error);
      }
    }
    if (client) {
      fetchClientCycles();
    }
  }, [client, selectedSurveyGroup]);
  return (
    <>
      <WidgetGeneratorContext.Provider
        value={
          {
            client,
            selectedDateRange,
            selectedSurveyGroup,
            cycles,
          } as WidgetGeneratorContextProps
        }
      >
        <WidgetsConfigsContext.Provider
          value={
            {
              widgetType,
              rawData: widgetData,
              isValidData,
              widgetWidth,
              circleScale,
              wordCloudQuestionSentiments,
              demographicsTableNumRowsPerTable,
              isBenchmarkedOverallBar,
              isBenchmarkedAreas,
              aspectValue,
              selectedBenchmarkAspect,
              clientName,
              isLabeledBar,
              servicesBarChartNumBarsPerRow,
              splitPositionBarChart,
              isBenchmarked,
              isBenchmarkedServices,
              columnName,
              benchmarkColumns,
              showAllSatisfactionScores,
              selectedBenchmarkClient,
              handleBenchmarkColumns,
              handleSelectBenchmarkAspect,
              handleColumnName,
              handleScale,
              handleServicesBarChartNumBarsPerRow,
              handleSplitPositionBarChart,
              handleWordCloudCat,
              handleClientNameInput,
              handleDemographicsTableNumRowsPerTable,
              handleAspectType,
              handleBenchmarkCycle,
              handleBenchmarkCycleServices,
              handleSelectBenchmarkClient,
              handleBenchmarkedOverallBar,
              handleBenchmarkedAreas,
              handleBarLabel,
              handleSelectWidget,
              handleWidgetSize,
              handleUpdateCustomChartButton,
              rawDataTemp: stringifiedWidgetData,
              handleFetchDataButton,
              checkIsValidData,
              handleDefaultDataButton,
              handleDataUpload,
              handleFileSaveAsButton,
              handleClearDataButton,
              handleFileNameInput,
              dataError,
              choicesQuestionChartDisplayType,
              setChoicesQuestionChartDisplayType,
              isLoadingData,
              isLoadingClientData,
              isShortBarChart,
              handleIsShortBarChartButton,
              selectedSurvey,
              setSelectedSurvey,
              selectedGenericQuestionId,
              setSelectedGenericQuestionId,
              crossTabQuestionSelectedOptions,
              setCrossTabQuestionSelectedOptions,
              selectedDemographicGeographic,
              setSelectedDemographicGeographic,
              choicesQuestionChartDisplayOrder,
              setChoicesQuestionChartDisplayOrder,
              maintainAndFocusServices,
              setWidgetData,
              setIsValidData,
              splitPositionQuestionnaire,
              setSplitPositionQuestionnaire,
              selectedAspects,
              setSelectedAspects,
              handleShowAllSatisfactionScores,
            } as WidgetsConfigsContextProps
          }
        >
          <div className={styles.app}>
            <CommunitySurveyTemplateGenerator zcClientId={zcClientId} />
            <Spacer orientation="vertical" size="small" />
            <h2>{translate('widgetGenerator.main')}</h2>
            <WidgetConfig
              surveyGroups={surveyGroups ?? []}
              handleClient={handleClient}
              handleSelectSurveyGroup={handleSelectSurveyGroup}
              parseSelectedDateRange={parseSelectedDateRange}
              cyclesOptions={cyclesOptions}
            />
            <Spacer orientation="vertical" size="medium" />
            <WidgetPreview />
            <ZCDNotificationContainer />
          </div>
        </WidgetsConfigsContext.Provider>
      </WidgetGeneratorContext.Provider>
    </>
  );
};
