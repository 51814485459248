import { SortableOptions } from 'sortablejs';
import '../styles/common.scss';

export enum DebounceDelay {
  AUTO_SAVE_INDICATION = 4000,
  DRAG_AND_DROP = 3000,
  TEXT_INPUT = 1000,
}

export const REACT_SORTABLE_OPTIONS: SortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  forceFallback: true,
  // Specify the area that is able to be dragged by class.
  handle: '.draggableArea',
  swapThreshold: 0.65,
  // The class for the placeholder area when being dragged.
  ghostClass: 'reactSortableGhostClass',
  // The class for the chosen dragging item.
  chosenClass: 'reactSortableChosenClass',
};
