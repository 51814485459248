import { ZCDSelect } from '@zencity/common-ui';
import { QuestionWrapper } from 'components/QuestionWrapper/QuestionWrapper';
import { QuestionTypeProps } from 'components/QuestionWrapper/QuestionWrapper.declarations';
import { useAppSelector, useSelectedLanguage } from 'customHooks/hooks';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';
import { findQuestionConfig, resolveRandomizedConfig } from 'utils/genericAndQuestionConfigs';
import { shuffleArray } from 'utils/misc';
import { sortQuestionChoices } from 'utils/question';
import styles from './DropdownQuestion.module.scss';

/**
 * A Dropdown question component.
 */
export const DropdownQuestion = (props: QuestionTypeProps): ReactElement => {
  const { questionItem } = props;
  const { t: translate } = useTranslation();
  const { genericQuestionsById } = useAppSelector((state: RootState) => state.genericQuestions);
  const selectedLanguage = useSelectedLanguage();

  const genericQuestion = genericQuestionsById[questionItem.item.generic_question];

  const choicesToDisplay = useMemo(() => {
    if (!genericQuestion?.choices) {
      return [];
    }
    const questionIsRandomized = resolveRandomizedConfig(
      findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.RANDOMIZE, selectedLanguage)
        ?.configValue,
    );
    const questionIsAlphabetical = resolveRandomizedConfig(
      findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.ALPHABETICAL_ORDER, selectedLanguage)
        ?.configValue,
    );
    const randomizedChoices = questionIsRandomized ? shuffleArray(genericQuestion.choices) : genericQuestion.choices;
    const alphabeticalChoices = questionIsAlphabetical
      ? sortQuestionChoices([...randomizedChoices])
      : randomizedChoices;

    return alphabeticalChoices.map((choice) => {
      const choiceText = selectedLanguage ? choice.translations?.label[selectedLanguage] : choice.label;
      return {
        value: choice.id,
        label: choiceText,
      };
    });
  }, [genericQuestion, questionItem.item, selectedLanguage]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <QuestionWrapper {...props}>
      {genericQuestion && (
        <div className={styles.dropdownQuestionContainer}>
          <ZCDSelect options={choicesToDisplay} placeholder={translate('dropdownQuestion.placeholder')} />
          <div className={styles.description}>
            {translate('dropdownQuestion.numberOfOptions', { numberOfOptions: choicesToDisplay.length })}
          </div>
        </div>
      )}
    </QuestionWrapper>
  );
};
