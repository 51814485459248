import { ZCDButton } from '@zencity/common-ui';
import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  blockwiseClientDetailsInitialValues,
  submitCensusData,
} from 'screens/SurveyClientConfigurationScreen/SurveyClientConfigurationScreen.helpers';
import {
  createCensusData,
  partialUpdateCensusData,
  retrieveBlockwiseClientDetails,
  retrieveCensusData,
} from 'services/surveyClientConfiguration';
import { BlockwiseClientDetails } from 'types/surveyClientConfiguration';
import { logger } from 'utils/logger';

import { CensusDataSection } from '../CensusDataSection/CensusDataSection';
import styles from './SurveyClientForm.module.scss';
import { placeHolderCensusData } from './utils';

interface Props {
  clientId: string;
}

export const SurveyClientForm = ({ clientId }: Props): ReactElement => {
  const [blockwiseClientDetails, setBlockwiseClientDetails] = useState<BlockwiseClientDetails>(
    blockwiseClientDetailsInitialValues,
  );
  const [censusDataErrorMessage, setCensusDataErrorMessage] = useState<string>('');
  const [censusDataSuccessMessage, setCensusDataSuccessMessage] = useState<string>('');
  const { t: translate } = useTranslation();
  useEffect(() => {
    const fetchBlockwiseClientDetails = async () => {
      try {
        const blockWiseClientDetailsInstance = await retrieveBlockwiseClientDetails(clientId);

        setBlockwiseClientDetails((prevState) => ({
          ...prevState,
          policeDepartmentName: blockWiseClientDetailsInstance?.police_department_name,
          policeDepartmentLogoUrl: blockWiseClientDetailsInstance?.police_department_logo,
          blockwiseClientDetailsPk: blockWiseClientDetailsInstance.id,
        }));
      } catch (error) {
        logger.warn(error);
      }
    };
    fetchBlockwiseClientDetails();
  }, [clientId]);

  useEffect(() => {
    const fetchCensusData = async () => {
      const { results: censusDataResults } = await retrieveCensusData(clientId);
      const [latestCensusData] = censusDataResults;
      if (!latestCensusData) {
        setBlockwiseClientDetails((prevState) => ({
          ...prevState,
          censusData: placeHolderCensusData,
        }));
      } else {
        setBlockwiseClientDetails((prevState) => ({
          ...prevState,
          censusData: latestCensusData,
        }));
      }
    };
    fetchCensusData();
  }, [clientId]);

  const surveyClientConfigurationSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitCensusData({
      createCensusData,
      clientId,
      blockwiseClientDetails,
      setBlockwiseClientDetails,
      partialUpdateCensusData,
      setCensusDataErrorMessage,
      setCensusDataSuccessMessage,
    });
  };
  return (
    <form onSubmit={surveyClientConfigurationSubmitHandler}>
      <div className={styles.surveyClientFormContainer}>
        <CensusDataSection
          setBlockwiseClientDetails={setBlockwiseClientDetails}
          blockwiseClientDetails={blockwiseClientDetails}
        />
        <ZCDButton type="submit" size="md" text={translate('common.save')} variant="primary" />
        {censusDataErrorMessage && <p className={styles.errorMessage}>{censusDataErrorMessage}</p>}
        {censusDataSuccessMessage && <p className={styles.successMessage}>{censusDataSuccessMessage}</p>}
      </div>
    </form>
  );
};
