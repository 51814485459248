import { Cadence } from '@zencity/survey-types';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyCadenceSelect } from 'screens/SurveyEditStartScreen/components/SurveyTypeSectionSubComponents/SurveyCadenceSelect';
import { SurveyTypeSelect } from 'screens/SurveyEditStartScreen/components/SurveyTypeSectionSubComponents/SurveyTypeSelect';
import { CurrentSurvey, isFormInEditMode } from 'screens/SurveyEditStartScreen/SurveyEditStartScreen.helpers';
import styles from 'screens/SurveyEditStartScreen/SurveyEditStartScreen.module.scss';

interface Props {
  allowedToSelectCadence: boolean;
  currentSurvey: CurrentSurvey;
  relevantCadences: Cadence[];
  setCurrentSurvey: React.Dispatch<React.SetStateAction<CurrentSurvey>>;
  inCloneMode: boolean;
  existsSurveyRequest: boolean;
}

export const SurveyEditSurveyTypeSection = (props: Props): ReactElement => {
  const {
    allowedToSelectCadence,
    currentSurvey,
    relevantCadences,
    setCurrentSurvey,
    inCloneMode,
    existsSurveyRequest,
  } = props;
  const { t: translate } = useTranslation();

  const inEditMode = isFormInEditMode(currentSurvey, inCloneMode);

  useEffect(() => {
    // Set a default cadence if a relevant one is not already set.
    if (allowedToSelectCadence) {
      const selectedCadenceIsRelevant =
        currentSurvey?.surveyCadence && relevantCadences.includes(currentSurvey.surveyCadence);
      if (!currentSurvey?.surveyCadence || !selectedCadenceIsRelevant) {
        // Arbitrary, select the first relevant cadence.
        setCurrentSurvey((prevCurrentSurvey) => ({
          ...prevCurrentSurvey,
          surveyCadence: relevantCadences[0],
        }));
      }
    }
  }, [allowedToSelectCadence, currentSurvey, setCurrentSurvey, relevantCadences]);

  return (
    <div className={styles.sectionGrid}>
      <div className={styles.sectionTitle}>{translate('surveyEdit.selectSurveyType')}</div>
      <div>
        <SurveyTypeSelect
          inEditMode={inEditMode}
          existsSurveyRequest={existsSurveyRequest}
          currentSurvey={currentSurvey}
          setCurrentSurvey={setCurrentSurvey}
        />
        <SurveyCadenceSelect
          allowedToSelectCadence={allowedToSelectCadence}
          relevantCadences={relevantCadences}
          currentSurvey={currentSurvey}
          setCurrentSurvey={setCurrentSurvey}
        />
      </div>
    </div>
  );
};
