import { ZCDNotificationContainer } from '@zencity/common-ui';
import { CopyTextToClipboard } from 'components/GenericQuestionsListTable/components/Actions/CopyTextToClipboard';
import { DeleteGenericQuestion } from 'components/GenericQuestionsListTable/components/Actions/DeleteGenericQuestion';
import { EditGenericQuestion } from 'components/GenericQuestionsListTable/components/Actions/EditGenericQuestion';
import React from 'react';
import { GenericQuestion } from 'types/genericQuestion';
import styles from './GenericQuestionActions.module.scss';

interface Props {
  genericQuestion: GenericQuestion;
  switchToNewQuestionTab?: () => void;
}

export const GenericQuestionActions = ({ genericQuestion, switchToNewQuestionTab }: Props): JSX.Element => (
  <div className={styles.wrapper}>
    <CopyTextToClipboard genericQuestion={genericQuestion} />
    <EditGenericQuestion genericQuestion={genericQuestion} switchToNewQuestionTab={switchToNewQuestionTab} />
    <DeleteGenericQuestion genericQuestion={genericQuestion} />
    <ZCDNotificationContainer position="top-center" />
  </div>
);
