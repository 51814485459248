import { ZCDInput } from '@zencity/common-ui';
import { configSetter } from 'components/NewGenericQuestion/NewGenericQuestion.helpers';
import { RemoveButton } from 'components/NewGenericQuestion/subComponents/NewQuestionMainSectionComponents/ChoicesMainSection/RemoveButton';
import { OptionWrapper } from 'components/NewGenericQuestion/subComponents/OptionWrapper';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { setNewQuestionConfigs } from 'slices/questionsBank';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';

interface Props {
  isMultipleSelection: boolean;
}

export const OtherChoiceOption = (props: Props): ReactElement => {
  const { isMultipleSelection } = props;
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { newQuestionPayload } = useAppSelector((state: RootState) => state.questionsBank);
  const toggleIsDisplayingOther = (value: string) =>
    dispatch(setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.DISPLAY_OTHER, value)));
  return (
    <OptionWrapper asCheckbox={isMultipleSelection} isDraggable={false}>
      <ZCDInput type="text" placeholder={translate('common.other')} disabled />
      <RemoveButton closeCallback={() => toggleIsDisplayingOther('')} />
    </OptionWrapper>
  );
};
