import React, { ReactElement } from 'react';
import { ZCDButton } from '@zencity/common-ui';
import { useTranslation } from 'react-i18next';
import { LeanClient } from 'types/client';
import { logger } from 'utils/logger';
import { exportCrossTabBySurveyGroup } from 'services/surveyGroup';
import { SelectedDateRange } from '../misc';

interface Props {
  surveyGroupId?: number;
  selectedDateRange?: SelectedDateRange;
  client: LeanClient;
}

export const CrossTabExporter = (props: Props): ReactElement => {
  const [isExporting, setIsExporting] = React.useState(false);
  const { surveyGroupId, selectedDateRange, client } = props;
  const { t: translate } = useTranslation();
  const propsAreDefined = client && surveyGroupId && selectedDateRange;

  const exportCrossTab = async () => {
    if (!propsAreDefined) {
      return;
    }
    try {
      setIsExporting(true);
      await exportCrossTabBySurveyGroup(surveyGroupId, client, {
        start_date: selectedDateRange.startDate,
        end_date: selectedDateRange.endDate,
        include_ignored_questions: true,
      });
    } catch (error) {
      logger.error(error);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <ZCDButton
      onClick={exportCrossTab}
      disabled={!propsAreDefined || isExporting}
      text={translate(`analysis.widgets.${isExporting ? 'exportingSubmissions' : 'exportCrossTab'}`)}
    />
  );
};
