import React from 'react';
import { AreasSentimentBarTableWidgetData } from 'screens/WidgetGenerator/utils/misc';
import { SentimentBarTable } from './SentimentBarTable';

interface AreasSentimentBarTableProps {
  widgetData: AreasSentimentBarTableWidgetData[];
  isBenchmarked: boolean;
}

export function AreasSentimentBarTable(props: AreasSentimentBarTableProps): React.ReactElement {
  const { widgetData, isBenchmarked } = props;
  const sortedData = Object.values(widgetData)?.sort((value, prevValue) =>
    value.name?.localeCompare(prevValue.name, 'en', {
      numeric: true,
    }),
  );

  return (
    <div className="AreasSentimentBarTable">
      <SentimentBarTable
        widgetData={sortedData}
        firstColumHeader="area"
        secondColumHeader="Resident satisfaction"
        columnsWidths={['auto', 265]}
        isBenchmarked={isBenchmarked}
        type="areas"
      />
    </div>
  );
}
