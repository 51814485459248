import { ZCDTextArea } from '@zencity/common-ui';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import styles from './QuestionTextArea.module.scss';

interface Props {
  value: string;
  useDefaultValue?: boolean;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  className?: string;
}

/**
 * A helper component for uniform styling of text areas in the question components.
 */
export const QuestionTextArea = (props: Props): ReactElement => {
  const { value, placeholder, onChange, disabled, className, useDefaultValue } = props;

  return (
    <ZCDTextArea // eslint-disable-next-line react/jsx-props-no-spreading
      {...(useDefaultValue ? { key: value, defaultValue: value } : { value })} // If useDefaultValue is true, then we use defaultValue, otherwise we use value.
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      className={classNames(styles.textArea, className)}
    />
  );
};
