/* eslint-disable id-denylist */
import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { sectionCoverPage } from '../utils/sectionCoverPage';
import { DocumentFragment } from '../documentFragment';
import { SurveysReportGeneratorDataItemKey } from '../../constants';

interface DemographicsBreakdownParams {
  headerElement: ILandscapeHeaderElement;
}
const demographicNoteChunk1 = `Smaller sample sizes can lead to unreliable estimates. For this reason, we hide scores for groups under 30 respondents.`;
const demographicNoteChunk2 = `For groups between 31 and 50 respondents, we highlight the small sample size using an asterisk (*). Use these scores with caution.`;

const demographicsBreakdownCoverPage = sectionCoverPage({
  titleChunks: ['Demographic', 'Breakdown'],
  descriptionChunks: [demographicNoteChunk1, demographicNoteChunk2],
});

export const demographicsBreakdown = (props: DemographicsBreakdownParams): DocumentFragment => {
  const { headerElement } = props;

  return [
    headerElement,
    ...demographicsBreakdownCoverPage,
    headerElement,
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.DEMOGRAPHICS_TABLE,
      },
    },
    {
      name: 'pageBreak',
    },
  ];
};
