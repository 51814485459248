import React, { ReactElement } from 'react';
import { SurveyClientForm } from './components/SurveyClientForm/SurveyClientForm';

interface Props {
  clientId: string;
}

export const SurveyClientConfigurationScreen = ({ clientId }: Props): ReactElement => (
  <>
    <SurveyClientForm clientId={clientId} />
  </>
);
