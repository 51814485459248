/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable id-denylist */
import { REPORT_BUILDER_PATH } from '../../constants';
import { DocumentFragment } from '../documentFragment';
import { lineBreakElement } from '../utils/lineBreakElement';
import {
  TABLE_CELL_DEFAULT_PADDING,
  TABLE_DEFAULT_BORDER_COLOR,
  reportTemplateTable,
  reportTemplateTableCell,
} from '../utils/tableTemplate';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import { generateLineBreaks } from '../utils/utilsFunctions';

const COMMUNITY_SURVEY_IMAGE = `${REPORT_BUILDER_PATH}/community-survey.png`;
const ZENCITY_LOGO = `${REPORT_BUILDER_PATH}/logo.png`;

const aboutTheSurvey = (): DocumentFragment => {
  const leftColumnAboutTheSurvey = reportTemplateTableCell({
    data: [
      {
        name: 'imageBlock',
        attributes: {
          src: COMMUNITY_SURVEY_IMAGE,
        },
      },
    ],
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '60%',
  });
  const rightColumnAboutTheSurvey = reportTemplateTableCell({
    data: [
      lineBreakElement,
      {
        name: 'heading3',
        children: [
          {
            data: `The Zencity`,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
      {
        name: 'heading3',
        children: [
          {
            data: `Community Survey`,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
      lineBreakElement,
      {
        name: 'paragraph',
        children: [
          {
            data: `A recurring survey that never stops running, the Zencity Community Survey measures how satisfied residents are with their community and with local government-provided services and allows officials to compare these scores over time and against a cohort of similar communities.`,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray90,
            },
          },
        ],
      },
    ],
    tableCellPadding: TABLE_CELL_DEFAULT_PADDING,
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '40%',
  });
  const tableAboutTheSurvey = reportTemplateTable({
    tableColumns: [leftColumnAboutTheSurvey, rightColumnAboutTheSurvey],
    tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  });

  return [
    ...generateLineBreaks(4),
    tableAboutTheSurvey,
    {
      name: 'pageBreak',
    },
  ];
};
const aboutTheMethodologySection = (
  clientName: string,
  reportDateRange: string,
  totalSubmissions: string,
): DocumentFragment => {
  const aboutTheMethodologyText = `${totalSubmissions} respondents were digitally recruited (e.g. over social media, mobile apps, local websites, and survey panels­) between ${reportDateRange}. An additional [----Responses----] responses were collected through the county’s distribution efforts, including printed and/or dial-in phone surveys, which were used to supplement the Zencity-recruited responses for free-text questions. Zencity built a representative sample by matching respondent data to the U.S. Census Bureau’s race, ethnicity, age, and gender distributions in ${clientName}. Finally, rake-weighting was applied as a statistical safeguard to balance out any remaining discrepancies in distribution, so no demographic group is overrepresented or underrepresented in the final score.`;

  const leftColumnAboutTheMethodologyTable = reportTemplateTableCell({
    data: [
      {
        name: 'heading3',
        children: [
          {
            data: `Survey Methodology`,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
      lineBreakElement,
      {
        name: 'paragraph',
        children: [
          {
            data: aboutTheMethodologyText,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.red90,
            },
          },
        ],
      },
    ],
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '65%',
  });
  const rightColumnAboutTheMethodologyTable = reportTemplateTableCell({
    data: [
      {
        name: 'heading3',
        children: [
          {
            data: `Score Calculation`,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
      lineBreakElement,
      {
        name: 'paragraph',
        children: [
          {
            data: `The overall satisfaction score is calculated by averaging how each resident rated quality of life and community characteristics on a numeric scale (1-5), and classifying this average as satisfied, neutral, or not satisfied. The resulting score, then, is the weighted percentage of residents who gave an overall satisfied rating.`,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray90,
            },
          },
        ],
      },
    ],
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '30%',
  });
  const middleColumnAboutTheMethodologyTable = reportTemplateTableCell({
    data: [lineBreakElement],
    tableCellPadding: TABLE_CELL_DEFAULT_PADDING,
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '5%',
  });
  const aboutTheMethodologyTable = reportTemplateTable({
    tableColumns: [
      leftColumnAboutTheMethodologyTable,
      middleColumnAboutTheMethodologyTable,
      rightColumnAboutTheMethodologyTable,
    ],
    tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  });

  const aboutTheMethodologyLogoTable: DocumentFragment = [
    {
      name: 'paragraph',
      children: [
        {
          name: 'imageInline',
          attributes: {
            src: ZENCITY_LOGO,
            width: '100px',
          },
        },
      ],
    },
  ];

  return [
    lineBreakElement,
    lineBreakElement,
    ...aboutTheMethodologyLogoTable,
    ...generateLineBreaks(3),
    aboutTheMethodologyTable,
    {
      name: 'pageBreak',
    },
  ];
};
interface ReportIntroductionProps {
  clientName: string;
  reportDateRange?: string;
  totalSubmissions?: number;
}
export const reportIntroduction = (props: ReportIntroductionProps): DocumentFragment => {
  const { clientName, reportDateRange, totalSubmissions } = props;

  const totalSubmissionsText = totalSubmissions ? `${totalSubmissions}` : '[-----Total Submissions----]';
  const aboutTheSurveyPage = aboutTheSurvey();
  const aboutTheMethodologyPage = aboutTheMethodologySection(
    clientName,
    reportDateRange || '[Cycle Date Range]',
    totalSubmissionsText,
  );

  return [...aboutTheSurveyPage, ...aboutTheMethodologyPage];
};
