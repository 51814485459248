/* eslint-disable id-denylist */
import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { sectionCoverPage } from '../utils/sectionCoverPage';
import { SurveysReportGeneratorDataItemKey } from '../../constants';
import { DocumentFragment } from '../documentFragment';

const crossTabsStrengthDescriptionChunk1 = `This community characteristic was selected because it had a high correlation with satisfaction AND received a notably high score.`;
const crossTabsFocusDescriptionChunk1 = `This community characteristic was selected because it had a high correlation with satisfaction AND received a notably low score.`;
const crossTabsDescriptionChunk2 = `The percentages shown indicate the percent of respondents who responded positively (4 or 5). The bars indicate the proportion of respondents who were positive, neutral (3), or negative (1 or 2).`;
const crossTabsDescriptionChunk3 = `For groups between 31 and 50 respondents, we highlight the small sample size using an asterisk (*). Use these scores with caution.`;

const crossTabsCoverPageStrength = sectionCoverPage({
  titleChunks: ['Strengths'],
  descriptionChunks: [crossTabsStrengthDescriptionChunk1, crossTabsDescriptionChunk2, crossTabsDescriptionChunk3],
});

const crossTabsCoverPageFocus = sectionCoverPage({
  titleChunks: ['In Focus'],
  descriptionChunks: [crossTabsFocusDescriptionChunk1, crossTabsDescriptionChunk2, crossTabsDescriptionChunk3],
});

interface CrossTabsSectionProps {
  headerElement: ILandscapeHeaderElement;
}

export const crossTabsSection = (props: CrossTabsSectionProps): DocumentFragment => {
  const { headerElement } = props;

  return [
    headerElement,
    ...crossTabsCoverPageStrength,
    headerElement,
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.CROSS_TABS,
      },
    },
    {
      name: 'pageBreak',
    },
    headerElement,
    ...crossTabsCoverPageFocus,
    headerElement,
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.CROSS_TABS,
      },
    },
    {
      name: 'pageBreak',
    },
  ];
};
