/* eslint-disable max-statements */
/* eslint-disable id-denylist */
import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { getSurveysOptions } from 'screens/WidgetGenerator/widgetAdditionalFields/utilsFunctionsAdditionalFields/SurveysSelection';
import { ASPECTS_LABELS, AspectValues } from 'types/aspect';
import { getAspectsOptions } from '../../components/QuestionnaireHOC/AspectsFilter';
import {
  QUESTIONNAITE_DEFAULT_SPLIT_POSITION,
  SurveysReportGeneratorDataItemKey,
  SurveysReportGeneratorFilterKey,
} from '../../constants';
import { DocumentFragment } from '../documentFragment';
import { sectionCoverPage } from '../utils/sectionCoverPage';
import { TABLE_DEFAULT_BORDER_COLOR, reportTemplateTable, reportTemplateTableCell } from '../utils/tableTemplate';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import { lineBreakElement } from '../utils/lineBreakElement';

const questionnaireCoverPage = sectionCoverPage({
  titleChunks: ['The', 'Questionnaire'],
});
const getAspectOptionByLabel = (aspectLabel: string, options: SelectOption[]) =>
  options.find((option) => option.label === aspectLabel);

interface QuestionnaireSectionParams {
  sectionNumber: number;
  sectionTitleChunks: string[];
  selectedSurvey: SelectOption;
  selectedAspect: SelectOption;
  aspect: AspectValues;
  tableIndexToDisplay?: number;
}
const SPLIT_POSITION = {
  [AspectValues.CHARACTERISTIC]: 9,
  [AspectValues.DEMOGRAPHICS]: 6,
  [AspectValues.GENERAL]: QUESTIONNAITE_DEFAULT_SPLIT_POSITION,
  [AspectValues.QUALITY_OF_LIFE]: QUESTIONNAITE_DEFAULT_SPLIT_POSITION,
} as { [key in AspectValues]: number };

const generateSectionTitleChunks = (sectionTitleChunks: string[]): DocumentFragment =>
  sectionTitleChunks.map((chunk) => ({
    name: 'heading2',
    children: [
      {
        data: chunk,
        attributes: {
          fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
          fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
        },
      },
    ],
  }));

const generateQuestionnaireTable = (params: QuestionnaireSectionParams): DocumentFragment => {
  const { sectionNumber, sectionTitleChunks, selectedSurvey, selectedAspect, aspect, tableIndexToDisplay } = params;

  const leftColumnQuestionnaireTable = reportTemplateTableCell({
    data: [
      lineBreakElement,
      lineBreakElement,
      {
        name: 'heading3',
        children: [
          {
            data: `Section ${sectionNumber}`,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
            },
          },
        ],
      },
      ...generateSectionTitleChunks(sectionTitleChunks),
    ],
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '25%',
  });

  const rightColumnQuestionnaireTable = reportTemplateTableCell({
    data: [
      {
        name: 'reactItem',
        attributes: {
          definitionKey: SurveysReportGeneratorDataItemKey.QUESTIONNAIRE,
          params: {
            filters: {
              [SurveysReportGeneratorFilterKey.QUESTIONNAIRE_SURVEY_SINGLE_SELECT]: selectedSurvey,
              [SurveysReportGeneratorFilterKey.QUESTIONNAIRE_ASPECTS_SELECT]: [selectedAspect],
              [SurveysReportGeneratorFilterKey.QUESTIONNAIRE_TABLE_SPLIT_POSITION]: SPLIT_POSITION[aspect],
              [SurveysReportGeneratorFilterKey.QUESTIONNAIRE_TABLE_TO_DISPLAY]: tableIndexToDisplay,
            },
          },
        },
      },
    ],
  });

  const table = reportTemplateTable({
    tableColumns: [leftColumnQuestionnaireTable, rightColumnQuestionnaireTable],
    tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  });

  return [
    table,
    {
      name: 'pageBreak',
    },
  ];
};
interface QuestionnaireSectionProps {
  selectedSurveyGroupId: number;
  headerElement: ILandscapeHeaderElement;
}
export const questionnaireSectionTemplate = async (props: QuestionnaireSectionProps): Promise<DocumentFragment> => {
  const { selectedSurveyGroupId, headerElement } = props;

  const mappedSurveyOptions = await getSurveysOptions(selectedSurveyGroupId);
  if (!mappedSurveyOptions.length) {
    return [];
  }
  const selectedSurveyOption = mappedSurveyOptions[0];

  const mappedAspectsOptions = await getAspectsOptions(selectedSurveyOption.value as number);
  if (!mappedAspectsOptions.length) {
    return [];
  }

  // TODO: Get these variables from Vault so they won't be hardcoded
  const generalOption = getAspectOptionByLabel(ASPECTS_LABELS.quality_of_life, mappedAspectsOptions);
  const characteristicOption = getAspectOptionByLabel(ASPECTS_LABELS.characteristic, mappedAspectsOptions);
  const qualityOfLifeOption = getAspectOptionByLabel(ASPECTS_LABELS.general, mappedAspectsOptions);
  const demographicsOption = getAspectOptionByLabel(ASPECTS_LABELS.demographics, mappedAspectsOptions);

  // Assums that the most recent survey is the relevant one
  const generalSection: DocumentFragment = generalOption
    ? generateQuestionnaireTable({
        sectionNumber: 1,
        sectionTitleChunks: ['General', 'Satisfaction'],
        selectedSurvey: selectedSurveyOption,
        selectedAspect: generalOption,
        aspect: AspectValues.GENERAL,
      })
    : [];
  const characteristicSection: DocumentFragment = characteristicOption
    ? generateQuestionnaireTable({
        sectionNumber: 2,
        sectionTitleChunks: ['Community', 'Characteristics'],
        selectedSurvey: selectedSurveyOption,
        selectedAspect: characteristicOption,
        aspect: AspectValues.CHARACTERISTIC,
      })
    : [];
  const qualityOfLifeSection: DocumentFragment = qualityOfLifeOption
    ? generateQuestionnaireTable({
        sectionNumber: 3,
        sectionTitleChunks: ['Rotating', 'Survey Section'],
        selectedSurvey: selectedSurveyOption,
        selectedAspect: qualityOfLifeOption,
        aspect: AspectValues.QUALITY_OF_LIFE,
      })
    : [];
  // Since the demographics table is long, it is splitted into two tables
  const demographicsFirstSection: DocumentFragment = demographicsOption
    ? generateQuestionnaireTable({
        sectionNumber: 4,
        sectionTitleChunks: ['Demographics'],
        selectedSurvey: selectedSurveyOption,
        selectedAspect: demographicsOption,
        tableIndexToDisplay: 1,
        aspect: AspectValues.DEMOGRAPHICS,
      })
    : [];
  const demographicsSecondSection: DocumentFragment = demographicsOption
    ? generateQuestionnaireTable({
        sectionNumber: 4,
        sectionTitleChunks: ['Demographics'],
        selectedSurvey: selectedSurveyOption,
        selectedAspect: demographicsOption,
        tableIndexToDisplay: 2,
        aspect: AspectValues.DEMOGRAPHICS,
      })
    : [];

  return [
    headerElement,
    ...questionnaireCoverPage,
    headerElement,
    ...generalSection,
    headerElement,
    ...characteristicSection,
    headerElement,
    ...qualityOfLifeSection,
    headerElement,
    ...demographicsFirstSection,
    headerElement,
    ...demographicsSecondSection,
  ];
};
