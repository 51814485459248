import React, { ReactElement } from 'react';
import { HorizontalSeparator } from '@zencity/common-ui';
import { useTranslation } from 'react-i18next';
import { ActionButtonAsIcon } from 'components/ActionButtonAsIcon/ActionButtonAsIcon';
import styles from './EndScreenToolbar.module.scss';

interface Props {
  handleOnDeleteClick: () => void;
  handleOnAddClick: () => void;
}

/**
 * Toolbar with buttons to add or delete an end screen in the "Build" page.
 */
export const EndScreenToolbar = (props: Props): ReactElement => {
  const { handleOnDeleteClick, handleOnAddClick } = props;
  const { t: translate } = useTranslation();

  return (
    <div className={styles.endScreenToolbar}>
      <ActionButtonAsIcon
        iconName="delete"
        handleOnButtonClick={handleOnDeleteClick}
        tooltipText={translate('common.delete')}
      />
      <HorizontalSeparator />
      <ActionButtonAsIcon
        iconName="more"
        handleOnButtonClick={handleOnAddClick}
        tooltipText={translate('questionContextualToolbar.addEndScreen')}
      />
    </div>
  );
};
