/* eslint-disable max-lines-per-function */
/* eslint-disable id-denylist */
import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { DocumentFragment } from '../documentFragment';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import { lineBreakElement } from '../utils/lineBreakElement';
import {
  reportTemplateTable,
  reportTemplateTableCell,
  TABLE_CELL_DEFAULT_PADDING,
  TABLE_DEFAULT_BORDER_COLOR,
} from '../utils/tableTemplate';
import { generateLineBreaks } from '../utils/utilsFunctions';

export const cohortExplanation = (headerElement: ILandscapeHeaderElement): DocumentFragment => {
  const leftColumnCohortExplanationTable = reportTemplateTableCell({
    data: [
      {
        name: 'heading3',
        children: [
          {
            data: 'These are some of the communities represented in your cohort',
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
      {
        name: 'paragraph',
        children: [
          {
            data: 'Density',
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
              bold: true,
            },
          },
          {
            data: ' is calculated by people per square mile of land area.',
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
            },
          },
        ],
      },
      {
        name: 'paragraph',
        children: [
          {
            data: 'Diversity',
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
              bold: true,
            },
          },
          {
            data: ' is measured by percentage of people who are not in the largest race or ethnicity group.',
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
            },
          },
        ],
      },
      {
        name: 'paragraph',
        children: [
          {
            data: 'Median income',
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
              bold: true,
            },
          },
          {
            data: ' is the median annual dollars of income per household.',
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
            },
          },
        ],
      },
      ...generateLineBreaks(6),
      {
        name: 'paragraph',
        children: [
          {
            data: `The data displayed on this page was sourced from the U.S. Census Bureau’s 2020 Census of Population and Housing.`,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
              italic: true,
            },
          },
        ],
      },
    ],
    tableCellPadding: TABLE_CELL_DEFAULT_PADDING,
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '50%',
  });
  const rightColumnCohortExplanationTable = reportTemplateTableCell({
    data: [
      {
        name: 'paragraph',
        children: [
          {
            data: '[table]',
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.red90,
            },
          },
        ],
      },
      lineBreakElement,
      {
        name: 'paragraph',
        children: [
          {
            data: '+ more communities with similar characteristics',
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodySmall,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
    ],
    tableCellPadding: TABLE_CELL_DEFAULT_PADDING,
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '50%',
  });
  const cohortExplanationTable = reportTemplateTable({
    tableColumns: [leftColumnCohortExplanationTable, rightColumnCohortExplanationTable],
    tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  });

  return [
    headerElement,
    lineBreakElement,
    cohortExplanationTable,
    {
      name: 'pageBreak',
    },
  ];
};
