import React from 'react';
import { ToastsInit, ToastVariants } from '@zencity/common-ui';
import { useTranslation } from 'react-i18next';

interface SurveyManagerToastContextInterface {
  toastError: (content?: string) => void;
  toastSuccess: (content?: string) => void;
}

export const SurveyManagerToastContext = React.createContext<SurveyManagerToastContextInterface>(
  {} as SurveyManagerToastContextInterface,
);

interface SurveyManagerToastContextProviderProps {
  children: React.ReactNode;
}

export const SurveyManagerToastContextProvider: React.FunctionComponent<SurveyManagerToastContextProviderProps> = ({
  children,
}: SurveyManagerToastContextProviderProps) => {
  const { t: translate } = useTranslation();
  const { toastElements, toast } = ToastsInit();
  const AUTO_CLOSE_TIMER = 3;

  const contextValue: SurveyManagerToastContextInterface = {
    toastError: (content?) => {
      toast({
        content: content ?? translate('toastMessages.serverRequestError'),
        variant: ToastVariants.ERROR,
        autoCloseTimer: AUTO_CLOSE_TIMER,
      });
    },
    toastSuccess: (content?) => {
      toast({
        content: content ?? translate('toastMessages.serverRequestSuccess'),
        variant: ToastVariants.SUCCESS,
        autoCloseTimer: AUTO_CLOSE_TIMER,
      });
    },
  };

  return (
    <SurveyManagerToastContext.Provider value={contextValue}>
      {children}
      {toastElements}
    </SurveyManagerToastContext.Provider>
  );
};
