import { ZCDCheckbox, ZCDInput, ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { Spacer } from 'components/Spacer/Spacer';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from '../components/FieldTitle/FieldTitle';
import { MaintainAndFocusServicesWidgetData, OverallSatisfactionScoreQuestion } from '../utils/misc';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';

const formatOptions = (options: OverallSatisfactionScoreQuestion[]) => {
  if (!options) return [];
  return options.map((option) => ({
    value: option.genericQuestionId,
    label: option.text,
  }));
};

interface Props {
  widgetData: MaintainAndFocusServicesWidgetData;
}

export const MaintainAndFocusServicesAdditionalFields = (props: Props): React.ReactElement => {
  const { widgetData } = props;
  const {
    maintainAndFocusServices,
    setWidgetData,
    setIsValidData,
    splitPositionBarChart,
    handleSplitPositionBarChart,
    isShortBarChart,
    handleIsShortBarChartButton,
  } = useContext(WidgetsConfigsContext);
  const selectOptions = formatOptions(maintainAndFocusServices);
  const { t: translate } = useTranslation();

  const handleMaintainChange = (newValue: unknown) => {
    const newSelection = newValue as SelectOption[];
    const newMaintain = newSelection.map((option) =>
      maintainAndFocusServices.find((service) => service.genericQuestionId === option.value),
    ) as OverallSatisfactionScoreQuestion[];
    setWidgetData({ ...widgetData, maintain: newMaintain });
    setIsValidData(false);
  };

  const handleFocusChange = (newValue: unknown) => {
    const newSelection = newValue as SelectOption[];
    const newFocus = newSelection.map((option) =>
      maintainAndFocusServices.find((service) => service.genericQuestionId === option.value),
    ) as OverallSatisfactionScoreQuestion[];
    setWidgetData({ ...widgetData, focus: newFocus });
    setIsValidData(false);
  };

  return (
    <div>
      <FieldTitle title={translate('widgetGenerator.widgets.maintainAndFocus.maintain.selectionTitle')} />
      <ZCDSelect
        isDisabled={selectOptions.length === 0}
        isMulti
        options={selectOptions}
        value={formatOptions(widgetData?.maintain)}
        onChange={(newValue) => handleMaintainChange(newValue)}
      />
      <Spacer orientation="vertical" size="xsmall" />
      <FieldTitle title={translate('widgetGenerator.widgets.maintainAndFocus.focus.selectionTitle')} />
      <ZCDSelect
        isDisabled={selectOptions?.length === 0}
        isMulti
        options={selectOptions}
        value={formatOptions(widgetData?.focus)}
        onChange={(newValue) => handleFocusChange(newValue)}
      />
      <Spacer orientation="vertical" size="xsmall" />
      <ZCDCheckbox
        label="Short version for bar height"
        checked={isShortBarChart}
        onChange={handleIsShortBarChartButton}
      />
      <Spacer orientation="vertical" size="xsmall" />
      <FieldTitle title={translate('widgetGenerator.widgets.maintainAndFocus.splitPosition')} />
      <ZCDInput type="number" value={splitPositionBarChart} onChange={handleSplitPositionBarChart} />
    </div>
  );
};
