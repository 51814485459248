import React from 'react';
import { BenchmarkTextWidgetData, SelectedDateRange } from 'screens/WidgetGenerator/utils/misc';
import {
  colorsByBenchmarkTextSatisfaction,
  filterBenchmarkOverallScoresBySelectedDate,
  findBenchmarkScoreByType,
  getMostRecentCommunitySurveyOverallScore,
} from 'screens/WidgetGenerator/widgets/BenchmarkWidgets/BenchmarkOverallSatisfactionText/BenchmarkOverallSatisfactionText.helpers';
import { CLIENT_BENCHMARK_TYPE } from 'screens/WidgetGenerator/widgets/BenchmarkWidgets/constants';
import { BenchmarkType } from 'types/benchmarkType';
import { LeanClient } from 'types/client';
import benchmarkStyles from '../benchmarkColors.module.scss';
import styles from './BenchmarkOverallSatisfactionText.module.scss';

interface Props {
  widgetData: BenchmarkTextWidgetData;
  selectedClient: LeanClient;
  selectedDateRange: SelectedDateRange;
}

export const BenchmarkOverallSatisfactionText: React.FC<Props> = (props: Props) => {
  const { widgetData, selectedClient, selectedDateRange } = props;
  const { benchmarkOverallScores, communitySurveyOverallScores, overallScoreText } = widgetData;

  // For the text, we compare the client Community Survey Score with the
  // "National" Benchmark score and the "Cohort" Benchmark Score.
  const mostRecentClientOverallScore = getMostRecentCommunitySurveyOverallScore(communitySurveyOverallScores ?? []);
  const selectedDateRangeBenchmarkOverallScores = filterBenchmarkOverallScoresBySelectedDate(
    selectedDateRange,
    benchmarkOverallScores,
  );
  const nationalBenchmarkScore = findBenchmarkScoreByType(
    selectedDateRangeBenchmarkOverallScores,
    BenchmarkType.NATIONAL,
  );
  const cohortBenchmarkScore = findBenchmarkScoreByType(selectedDateRangeBenchmarkOverallScores, BenchmarkType.COHORT);

  return (
    <div className={styles.container}>
      <h1>
        <span>{overallScoreText.prefix}</span>
        <span
          style={{
            color: colorsByBenchmarkTextSatisfaction[overallScoreText.satisfactionCohort],
          }}
        >
          {' '}
          {overallScoreText.percentage && `${overallScoreText.percentage}% `}
          {overallScoreText.satisfactionCohort}
        </span>
        <span> {overallScoreText.suffixCohort}</span>
        {overallScoreText.satisfactionNational && (
          <>
            <span
              style={{
                color: colorsByBenchmarkTextSatisfaction[overallScoreText.satisfactionNational],
              }}
            >
              {' '}
              {overallScoreText.satisfactionNational}
            </span>
            <span> {overallScoreText.suffixNational}</span>
          </>
        )}
      </h1>
      <div className={styles.scoresContainer}>
        <div className={styles.score}>
          <h2
            style={{
              color: benchmarkStyles[CLIENT_BENCHMARK_TYPE],
            }}
            className={styles.scoreText}
          >
            {mostRecentClientOverallScore?.scores.positive}%
          </h2>
          <p className={styles.scoreName}>{selectedClient.name}</p>
        </div>
        <div className={styles.score}>
          <h2
            style={{
              color: benchmarkStyles[BenchmarkType.COHORT],
            }}
          >
            {cohortBenchmarkScore?.scores.positive}%
          </h2>
          <p className={styles.scoreName}>Cohort</p>
        </div>
        <div className={styles.score}>
          <h2
            style={{
              color: benchmarkStyles[BenchmarkType.NATIONAL],
            }}
          >
            {nationalBenchmarkScore?.scores.positive}%
          </h2>
          <p className={styles.scoreName}>National</p>
        </div>
      </div>
    </div>
  );
};
