import { getCyclesForScoring } from 'screens/WidgetGenerator/utils/cycles';
import { LeanClient } from 'types/client';
import { logger } from 'utils/logger';
import { fetchCommunitySurveyOverallScore } from 'services/communitySurveyScores';
import {
  OverallSatisfactionData,
  ScoreTextWithSentimentBarWidgetData,
  SelectedDateRange,
  SurveyCycle,
} from '../utils/misc';

export const FALLBACK_RESPONSE: OverallSatisfactionData = {
  score: { positive: 0, negative: 0, neutral: 0 },
  totalSubmissions: 0,
  previousCycle: {
    score: { positive: 0, negative: 0, neutral: 0 },
    totalSubmissions: 0,
  },
};

export const getOverallScoreData = async (
  client: LeanClient,
  cycles: SurveyCycle[],
  selectedDateRange: SelectedDateRange,
): Promise<OverallSatisfactionData> => {
  const dateRangesForScoring = getCyclesForScoring(selectedDateRange, cycles);
  try {
    const overallScoreCycles = await fetchCommunitySurveyOverallScore(client.id);
    if (!overallScoreCycles || overallScoreCycles.length === 0) return FALLBACK_RESPONSE;

    const lastCycleIndex = overallScoreCycles.findIndex((overallScore) => {
      const { start_date, end_date } = overallScore;
      return start_date === dateRangesForScoring[0].startDate && end_date === dateRangesForScoring[0].endDate;
    });
    const previousCycleIndex = overallScoreCycles.findIndex((overallScore) => {
      const { start_date, end_date } = overallScore;
      return start_date === dateRangesForScoring[1]?.startDate && end_date === dateRangesForScoring[1]?.endDate;
    });
    const scoreDefaultValue = { positive: 0, negative: 0, neutral: 0 };
    const lastCycleScore = overallScoreCycles[lastCycleIndex]?.scores ?? scoreDefaultValue;
    const lastCycleScoreToReturn = {
      positive: lastCycleScore.positive,
      neutral: lastCycleScore.neutral,
      negative: lastCycleScore.negative,
    };
    const prevCycleScore =
      overallScoreCycles.length > 1 && previousCycleIndex !== -1
        ? overallScoreCycles[previousCycleIndex]?.scores
        : scoreDefaultValue;

    const prevCycleScoreToReturn = {
      positive: prevCycleScore.positive,
      neutral: prevCycleScore.neutral,
      negative: prevCycleScore.negative,
    };
    return {
      score: lastCycleScoreToReturn,
      totalSubmissions: overallScoreCycles[lastCycleIndex]?.total_submissions,
      previousCycle: {
        score: prevCycleScoreToReturn,
        totalSubmissions: overallScoreCycles[previousCycleIndex]?.total_submissions ?? 0,
      },
    };
  } catch (error) {
    logger.error(error);
    return FALLBACK_RESPONSE;
  }
};

export const getOverallSatisfcationWidgetData = async (
  client: LeanClient,
  cycles: SurveyCycle[],
  selectedDateRange: SelectedDateRange,
): Promise<ScoreTextWithSentimentBarWidgetData[]> => {
  const { score, totalSubmissions, previousCycle } = await getOverallScoreData(client, cycles, selectedDateRange);
  const defaultSatisfactionWidgetText = `of ${totalSubmissions} surveyed residents are satisfied with life in ${client.name}`;
  return [
    {
      score,
      previousCycle,
      text: defaultSatisfactionWidgetText,
      totalSubmissions,
    },
  ];
};
