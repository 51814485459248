import { MissingTranslation } from 'components/MissingTranslation/MissingTranslation';
import { NewEndScreenDialog } from 'components/NewEndScreenDialog/NewEndScreenDialog';
import { useAppDispatch, useAppSelector, useSelectedLanguage } from 'customHooks/hooks';
import React, { ReactElement, useMemo, useState } from 'react';
import { deleteEndScreen } from 'services/endScreen';
import { updateQuestionLogicActions } from 'services/questions';
import { AppDispatch, RootState } from 'store';
import { EndScreen as EndScreenType } from 'types/endScreen';
import { DestinationType } from 'types/logicAction';
import { logger } from 'utils/logger';
import { getDeletedDestinationLogicActions } from 'utils/questionLogicAction';
import { EndScreenToolbar } from './components/EndScreenToolbar';
import styles from './EndScreen.module.scss';

interface Props {
  endScreen: EndScreenType;
  allowEditMode: boolean;
}

/**
 * Displays the End Screen.
 */
// eslint-disable-next-line complexity,max-lines-per-function
export const EndScreen = ({ endScreen, allowEditMode = true }: Props): ReactElement => {
  const selectedLanguage = useSelectedLanguage();
  const [isToolbarVisible, setIsToolbarVisible] = useState(false);
  const [addEndScreenDialogIsOpen, setAddEndScreenDialogIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { questions } = useAppSelector((state: RootState) => state.questions);

  const screenTitleTranslated = useMemo(() => {
    if (!selectedLanguage) {
      // Use the default language title.
      return endScreen.title;
    }

    // Use the translated title.
    const translatedTitle = endScreen.translations?.title[selectedLanguage];
    if (translatedTitle) {
      return translatedTitle;
    }

    return endScreen.translations?.title[selectedLanguage] || '';
  }, [endScreen, selectedLanguage]);

  const screenDescriptionTranslated = useMemo(() => {
    if (!selectedLanguage) {
      // Use the default language description.
      return endScreen.description || '';
    }

    // Use the translated description.
    const translatedDesc = endScreen.translations?.description[selectedLanguage];
    if (translatedDesc) {
      return translatedDesc;
    }

    return endScreen.translations?.description[selectedLanguage] || '';
  }, [endScreen, selectedLanguage]);

  const handleOnMouseEnter = () => {
    setIsToolbarVisible(true);
  };

  const handleOnMouseLeave = () => {
    if (addEndScreenDialogIsOpen) {
      // Avoid hiding the toolbar when the dialog is open.
      return;
    }

    setIsToolbarVisible(false);
  };

  const deleteEndScreenDestinationLogicActions = () => {
    const { id: endScreenId } = endScreen;
    const deletedLogicActionsByQuestionId = getDeletedDestinationLogicActions(
      endScreenId,
      questions,
      DestinationType.ENDING_SCREEN,
    );

    const allLogicActionDispatches: AppDispatch[] = [];
    Object.entries(deletedLogicActionsByQuestionId).forEach(([questionId, logicActions]) => {
      allLogicActionDispatches.push(
        dispatch(
          updateQuestionLogicActions({
            questionId: parseInt(questionId),
            logicActions,
          }),
        ),
      );
    });
    return allLogicActionDispatches;
  };

  const handleOnDeleteClick = () => {
    try {
      const { id: endScreenId } = endScreen;
      // Delete any Question Logic Actions that have this End Screen as its destination.
      // Then, delete the End Screen.
      const allLogicActionDeleteDispatches = deleteEndScreenDestinationLogicActions();
      Promise.all(allLogicActionDeleteDispatches).then(() => {
        dispatch(deleteEndScreen({ endScreenId }));
      });
    } catch (error) {
      logger.error(error);
    }
  };

  const handleOnAddClick = () => setAddEndScreenDialogIsOpen(true);

  return (
    <div className={styles.container} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <div className={styles.endScreenWrapper}>
        <div className={styles.topBorder} />
        <div className={styles.content}>
          <span className={styles.title}>
            {screenTitleTranslated}
            {selectedLanguage && !screenTitleTranslated && <MissingTranslation text={endScreen.title} />}
          </span>
          {endScreen.description && (
            <span className={styles.description}>
              {screenDescriptionTranslated}
              {selectedLanguage && !screenDescriptionTranslated && <MissingTranslation text={endScreen.description} />}
            </span>
          )}
        </div>
        {isToolbarVisible && allowEditMode && (
          <EndScreenToolbar handleOnDeleteClick={handleOnDeleteClick} handleOnAddClick={handleOnAddClick} />
        )}
        <NewEndScreenDialog isOpen={addEndScreenDialogIsOpen} setIsOpen={setAddEndScreenDialogIsOpen} />
      </div>
    </div>
  );
};
