import { ZCDButton, ZCDInput } from '@zencity/common-ui';
import { Spacer } from 'components/Spacer/Spacer';
import React, { useContext } from 'react';
import { ReportUploader } from 'screens/WidgetGenerator/components/FileUploader';
import { WidgetType } from 'screens/WidgetGenerator/utils/misc';
import { WidgetAdditionalFields } from 'screens/WidgetGenerator/widgetAdditionalFields/WidgetAdditionalFields';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { SurveyGroupSelection } from 'screens/WidgetGenerator/widgetAdditionalFields/utilsFunctionsAdditionalFields/SurveyGroupSelection';
import { WidgetsConfigsContext } from 'screens/WidgetGenerator/contexts/WidgetsConfigsContext';
import { WidgetGeneratorContext } from 'screens/WidgetGenerator/contexts/WidgetGeneratorContext';
import { SurveyGroup } from '@zencity/survey-types';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { FieldTitle } from '../FieldTitle/FieldTitle';
import { CycleSelection } from './CycleSelection';
import { ClientSelection } from './ClientSelection';
import styles from './WidgetConfig.module.scss';
import { WidgetTypeSelection } from './WidgetTypeSelection';
import { WidgetWidthInput } from './WidgetWidthInput';

interface WidgetConfigProps {
  handleClient: (clientId: number) => void;
  handleSelectSurveyGroup: (newValue: unknown) => void;
  parseSelectedDateRange: (key: string) => void;
  cyclesOptions: SelectOption[];
  surveyGroups: SurveyGroup[];
}
// eslint-disable-next-line max-lines-per-function
export const WidgetConfig = (props: WidgetConfigProps): React.ReactElement => {
  const {
    widgetWidth,
    widgetType,
    rawData,
    isValidData,
    handleSelectWidget,
    handleWidgetSize,
    handleUpdateCustomChartButton,
    rawDataTemp,
    handleFetchDataButton,
    checkIsValidData,
    handleDefaultDataButton,
    handleDataUpload,
    handleFileSaveAsButton,
    handleClearDataButton,
    handleFileNameInput,
    dataError,
    isLoadingData,
    isLoadingClientData,
    wordCloudQuestionSentiments,
  } = useContext(WidgetsConfigsContext);

  const { client, selectedDateRange, selectedSurveyGroup } = useContext(WidgetGeneratorContext);
  const { handleClient, handleSelectSurveyGroup, parseSelectedDateRange, cyclesOptions, surveyGroups } = props;
  const disableUpdateWidget =
    isValidData ||
    !rawDataTemp ||
    !client?.id ||
    !selectedDateRange ||
    (widgetType === WidgetType.WORD_CLOUD && wordCloudQuestionSentiments.length === 0);

  return (
    <div className={styles.widgetConfig}>
      <div className={styles.widgetSettings}>
        <div className={styles.widgetSettingsContainer}>
          <LoaderMask isLoading={isLoadingClientData}>
            <div>
              <ClientSelection stateValue={client?.id} onChangeCallback={handleClient} />
              <Spacer orientation="vertical" size="xsmall" />
              <SurveyGroupSelection
                surveyGroups={surveyGroups}
                selectedSurveyGroup={selectedSurveyGroup}
                handleSelectSurveyGroup={handleSelectSurveyGroup}
              />
              <Spacer orientation="vertical" size="xsmall" />
              <CycleSelection
                stateValue={selectedDateRange?.value}
                onChangeCallback={parseSelectedDateRange}
                options={cyclesOptions}
                isDisabled={!client?.id || !selectedSurveyGroup}
              />
              <Spacer orientation="vertical" size="xsmall" />
              <WidgetTypeSelection
                value={widgetType}
                onChange={handleSelectWidget}
                isDisabled={!client?.id || !selectedDateRange}
              />
              <Spacer orientation="vertical" size="xsmall" />
              {widgetType !== WidgetType.DEFAULT && (
                <WidgetWidthInput onChange={handleWidgetSize} value={widgetWidth} />
              )}
              <WidgetAdditionalFields />
            </div>
          </LoaderMask>
          <div>
            <Spacer orientation="vertical" size="medium" />
            <ZCDButton onClick={handleUpdateCustomChartButton} text="Update widget" disabled={disableUpdateWidget} />
          </div>
        </div>
      </div>
      <div className={styles.widgetData}>
        <div>
          <FieldTitle title="Data (JSON format):" />
          <textarea id="rawData" value={rawDataTemp} onChange={checkIsValidData} />
          {dataError && <p>Error: {dataError} </p>}
        </div>
        <div className={styles.actionButtons}>
          <LoaderMask isLoading={isLoadingData}>
            <div className={styles.buttonsRow}>
              <ZCDButton
                variant="primary"
                onClick={handleFetchDataButton}
                text="Fetch Data"
                disabled={widgetType === WidgetType.DEFAULT}
              />
              <Spacer orientation="horizontal" size="xsmall" />
              <ZCDButton
                variant="secondary"
                onClick={handleDefaultDataButton}
                text="Use Default Data"
                disabled={widgetType === WidgetType.DEFAULT}
              />
              <Spacer orientation="horizontal" size="xsmall" />
              <ZCDButton variant="secondary" onClick={handleClearDataButton} text="Clear Data" disabled={!rawData} />
              <Spacer orientation="horizontal" size="xsmall" />
              <ReportUploader setData={handleDataUpload} widgetType={widgetType} />
            </div>
          </LoaderMask>

          <div className={styles.buttonsRow}>
            <div className={styles.fileNameButton}>
              <ZCDInput type="text" placeholder="File Name" onChange={handleFileNameInput} />
            </div>
            <Spacer orientation="horizontal" size="xsmall" />
            <ZCDButton onClick={handleFileSaveAsButton} text="Save as PNG" disabled={!isValidData || !rawData} />
          </div>
        </div>
      </div>
    </div>
  );
};
