import { Spacer } from 'components/Spacer/Spacer';
import React, { ReactElement } from 'react';

export const SharingLink = ({ name, linkUrl }: { name: string; linkUrl: string }): ReactElement => (
  <>
    <div>{name}</div>
    <a href={linkUrl} target="_blank" rel="noreferrer">
      {linkUrl}
    </a>
    <Spacer orientation="vertical" size="small" />
  </>
);
