import {
  ChoicesQuestionChartDisplayOrder,
  ChoicesQuestionChartDisplayType,
  ChoicesQuestionChartData,
} from 'screens/WidgetGenerator/utils/misc';
import { getPercentageCount } from './utilFunctions';

interface Props {
  widgetData: ChoicesQuestionChartData;
  selectedDisplayType: ChoicesQuestionChartDisplayType;
  selectedOrder: ChoicesQuestionChartDisplayOrder;
}

export const useChoicesQuestionChartDisplayOrder = (props: Props) => {
  const { widgetData, selectedDisplayType, selectedOrder } = props;
  const { total_responses } = widgetData;
  const totalWeights = widgetData.total_weights;
  // If the order is by appearance, then return the choices as it is.
  if (selectedOrder === ChoicesQuestionChartDisplayOrder.APPEARANCE) {
    return {
      sortedChoices: widgetData.choices,
    };
  }

  const sortedChoices = [...widgetData.choices]?.sort((elementA, elementB) => {
    // If the sort is by popularity, then sort by the max count of the response options.
    if (selectedOrder === ChoicesQuestionChartDisplayOrder.POPULARITY) {
      // handle the case where the display type is integer.
      if (selectedDisplayType === ChoicesQuestionChartDisplayType.INTEGER) {
        const maxCountA = elementA.responseOptions?.reduce<number>((current, option) => {
          if (option.count > current) return option.count;
          return current;
        }, 0);

        const maxCountB = elementB.responseOptions?.reduce<number>((current, option) => {
          if (option.count > current) return option.count;
          return current;
        }, 0);

        return maxCountB - maxCountA;
      }
      // Handle the case where the display type is percentage.
      if (selectedDisplayType === ChoicesQuestionChartDisplayType.PERCENTAGE) {
        const maxCountA = elementA.responseOptions?.reduce<number>((current, option) => {
          const count = getPercentageCount({
            totalWeights,
            totalResponses: total_responses,
            count: option.count,
            weightsSum: option.weightsSum,
          });
          if (count > current) return count;
          return current;
        }, 0);

        const maxCountB = elementB.responseOptions?.reduce<number>((current, option) => {
          const count = getPercentageCount({
            totalWeights,
            totalResponses: total_responses,
            count: option.count,
            weightsSum: option.weightsSum,
          });
          if (count > current) return count;
          return current;
        }, 0);

        return maxCountB - maxCountA;
      }
    }
    return 0;
  });

  return {
    sortedChoices,
  };
};
