import React from 'react';

interface FlexProps {
  children?: React.ReactNode;
  className?: string;
  flexDirection?: 'row' | 'column';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number;
  width?: string | number;
  height?: string | number;
  maxWidth?: string;
  maxHeight?: string;
}
export const Flex = (props: FlexProps): React.ReactElement => {
  const {
    children,
    className,
    justifyContent = 'flex-start',
    alignItems = 'flex-start',
    flexBasis = 'auto',
    flexGrow = 0,
    flexShrink = 1,
    flexDirection = 'row',
    flexWrap = 'nowrap',
    width = 'auto',
    height,
    maxWidth = 'none',
    maxHeight = 'none',
  } = props;

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        justifyContent,
        flexDirection,
        flexGrow,
        flexBasis,
        flexShrink,
        flexWrap,
        alignItems,
        width,
        ...(height && {
          height,
        }),
        maxWidth,
        maxHeight,
      }}
    >
      {children}
    </div>
  );
};
