import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';
import { CrossQuestionData } from 'screens/WidgetGenerator/utils/misc';

const apiUrl = generateVaultApiUrl('/api/v2/survey_group/');
const axiosInstance = createAxiosInstance(apiUrl);

/**
 * Fetch cross questions data.
 */
export async function fetchCrossTabQuestions(params: {
  start_date: string;
  end_date: string;
  include_ignored_questions: boolean;
  question_id?: number;
  cross_question_ids?: number[];
  surveyGroupId?: number;
}): Promise<CrossQuestionData> {
  const { surveyGroupId, start_date, end_date, include_ignored_questions, question_id, cross_question_ids } = params;

  let crossQuestionIdsString = '';
  cross_question_ids?.forEach((cross_question_id) => {
    crossQuestionIdsString += `cross_question_id=${cross_question_id}&`;
  });

  const resultsScoresEndpoint = crossQuestionIdsString
    ? `${surveyGroupId}/cross_question_scores/?${crossQuestionIdsString.slice(0, -1)}` // remove last &
    : `${surveyGroupId}/cross_question_scores/`;

  const result = await axiosInstance.get<CrossQuestionData>(resultsScoresEndpoint, {
    params: {
      start_date,
      end_date,
      question_id,
      include_ignored_questions,
    },
  });

  return result.data;
}
