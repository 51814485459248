import { logger } from 'utils/logger';
import { BlockwiseClientDetails, BlockwiseClientDetailsPromise, CensusData } from 'types/surveyClientConfiguration';

interface SubmitSurveyClientConfigurationDetailsFormProps {
  createBlockwiseClientDetails: (
    blockwiseClientFormData: FormData,
    clientId: string | undefined,
  ) => Promise<BlockwiseClientDetailsPromise>;
  blockwiseClientDetails: BlockwiseClientDetails;
  clientId: string | undefined;
  setBlockwiseClientDetails: React.Dispatch<React.SetStateAction<BlockwiseClientDetails>>;
  blockwiseClientDetailsExists: boolean;
  setBlockwiseClientDetailsExists: React.Dispatch<React.SetStateAction<boolean>>;
  partialUpdateBlockwiseClientDetails: (
    blockwiseClientDetailsPk: number | undefined,
    blockwiseClientFormData: FormData,
  ) => Promise<BlockwiseClientDetailsPromise>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}

interface SubmitCensusDataFormProps {
  createCensusData: (censusData: CensusData, clientId?: string) => Promise<CensusData>;
  clientId?: string;
  blockwiseClientDetails: BlockwiseClientDetails;
  setBlockwiseClientDetails: React.Dispatch<React.SetStateAction<BlockwiseClientDetails>>;
  partialUpdateCensusData: (censusData: CensusData) => Promise<CensusData>;
  setCensusDataErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setCensusDataSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const blockwiseClientDetailsInitialValues: BlockwiseClientDetails = {
  policeDepartmentName: '',
  titleForDisplay: '',
  cadence: { label: '', value: '' },
  sampleSize: '',
  censusData: {},
  policeDepartmentLogoUrl: '',
};

export const handleBlockwiseClientFormData = (formData: BlockwiseClientDetails) => {
  const blockwiseClientFormData = new FormData();
  if (formData.clientLogo) blockwiseClientFormData.append('police_department_logo', formData.clientLogo as Blob);
  if (formData.policeDepartmentName)
    blockwiseClientFormData.append('police_department_name', formData.policeDepartmentName);
  return blockwiseClientFormData;
};

const setBlockwiseClientDetailsHandler = (
  newInstance: BlockwiseClientDetailsPromise,
  setBlockwiseClientDetails: React.Dispatch<React.SetStateAction<BlockwiseClientDetails>>,
) => {
  setBlockwiseClientDetails((prevState) => ({
    ...prevState,
    policeDepartmentName: newInstance.police_department_name,
    policeDepartmentLogoUrl: newInstance.police_department_logo,
    blockwiseClientDetailsPk: newInstance.id,
  }));
};

const handleOnError = (error: unknown, setErrorMessage: React.Dispatch<React.SetStateAction<string>>) => {
  setErrorMessage(`${error}`);
  logger.error(`Error in submitSurveyClientConfigurationDetails: ${error}`);
};

const resetMessages = (
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>,
) => {
  setErrorMessage('');
  setSuccessMessage('');
};

const handleOnSuccessfulResponse = (
  setBlockwiseClientDetailsExists: React.Dispatch<React.SetStateAction<boolean>>,
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>,
) => {
  setBlockwiseClientDetailsExists(true);
  setSuccessMessage('Successfully updated client details');
};

export const submitSurveyClientConfigurationDetails = async (
  props: SubmitSurveyClientConfigurationDetailsFormProps,
) => {
  const {
    createBlockwiseClientDetails,
    blockwiseClientDetails,
    clientId,
    setBlockwiseClientDetails,
    blockwiseClientDetailsExists,
    setBlockwiseClientDetailsExists,
    partialUpdateBlockwiseClientDetails,
    setErrorMessage,
    setSuccessMessage,
  } = props;
  try {
    resetMessages(setErrorMessage, setSuccessMessage);
    const blockwiseClientFormData = handleBlockwiseClientFormData(blockwiseClientDetails);
    if (blockwiseClientDetailsExists) {
      const { blockwiseClientDetailsPk } = blockwiseClientDetails;
      const newInstance = await partialUpdateBlockwiseClientDetails(blockwiseClientDetailsPk, blockwiseClientFormData);
      setBlockwiseClientDetailsHandler(newInstance, setBlockwiseClientDetails);
      if (newInstance) setSuccessMessage('Successfully updated client details');
    } else {
      const newInstance = await createBlockwiseClientDetails(blockwiseClientFormData, clientId);
      setBlockwiseClientDetailsHandler(newInstance, setBlockwiseClientDetails);
      if (newInstance) handleOnSuccessfulResponse(setBlockwiseClientDetailsExists, setSuccessMessage);
    }
  } catch (error) {
    handleOnError(error, setErrorMessage);
  }
};

export const submitCensusData = async (props: SubmitCensusDataFormProps) => {
  const {
    createCensusData,
    clientId,
    blockwiseClientDetails,
    setBlockwiseClientDetails,
    partialUpdateCensusData,
    setCensusDataErrorMessage,
    setCensusDataSuccessMessage,
  } = props;
  const { censusData } = blockwiseClientDetails;
  try {
    resetMessages(setCensusDataErrorMessage, setCensusDataSuccessMessage);
    if (censusData?.id) {
      const newInstance = await partialUpdateCensusData(censusData);
      setBlockwiseClientDetails((prevState) => ({
        ...prevState,
        censusData: newInstance,
      }));
      if (newInstance) setCensusDataSuccessMessage('Successfully updated census data');
    } else if (censusData && !censusData?.id) {
      const newInstance = await createCensusData(censusData, clientId);
      setBlockwiseClientDetails((prevState) => ({
        ...prevState,
        censusData: newInstance,
      }));
      if (newInstance) setCensusDataSuccessMessage('Successfully added census data');
    } else {
      return;
    }
  } catch (error) {
    setCensusDataErrorMessage(`${error}`);
    logger.error(`Error in submitCensusData: ${error}`);
  }
};
