interface TooltipPosition {
  bottomPixel: string;
  leftPixel: string;
}

/**
 * Calculates the pixels for a tooltip above a reference element.
 */
export const calculateTopTooltipPlacement = (
  referenceElement: HTMLDivElement,
  tooltipElement: HTMLDivElement,
): TooltipPosition => {
  const { innerHeight: windowHeight } = window;
  const { top: referenceTopPosition, left: referenceLeftPosition } = referenceElement.getBoundingClientRect();

  // The tooltip should be above the element, with a 12px margin.
  const tooltipBottomPosition = windowHeight - referenceTopPosition + 12;

  // The tooltip should be centered in the middle of the referenced element.
  // This is calculated using the same left position as the element,
  // but requires extra calculation for centering the arrow,
  // with a margin for half of the element.
  const tooltipWidth = tooltipElement.clientWidth;
  const referenceElementWidth = referenceElement.clientWidth;
  const tooltipLeftPosition = referenceLeftPosition + referenceElementWidth / 2 - tooltipWidth / 2;

  return {
    leftPixel: `${tooltipLeftPosition}px`,
    bottomPixel: `${tooltipBottomPosition}px`,
  };
};

/**
 * Calculates the pixels for a tooltip to the right of a reference element.
 */
export const calculateRightTooltipPlacement = (referenceElement: HTMLDivElement): TooltipPosition => {
  const { innerHeight: windowHeight } = window;
  const { right: referenceRightPosition, bottom: referenceBottomPosition } = referenceElement.getBoundingClientRect();

  // The tooltip should be the same height as the element, centered.
  const referenceElementHeight = referenceElement.clientHeight;
  const tooltipBottomPosition = windowHeight - referenceBottomPosition - referenceElementHeight / 2;

  // The tooltip should be to the right of the element, with a 24px margin
  // and centered.
  const tooltipLeftPosition = referenceRightPosition + 24;

  return {
    leftPixel: `${tooltipLeftPosition}px`,
    bottomPixel: `${tooltipBottomPosition}px`,
  };
};
