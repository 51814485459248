/* eslint-disable max-lines,max-lines-per-function,max-statements */
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { getNewQuestionConfig } from 'components/NewGenericQuestion/NewGenericQuestion.helpers';
import styles from 'components/NewGenericQuestion/NewGenericQuestion.module.scss';
import { AddOptionButtons } from 'components/NewGenericQuestion/subComponents/NewQuestionMainSectionComponents/ChoicesMainSection/AddOptionButtons';
import {
  convertQuestionChoicesToChoiceOptions,
  convertChoiceOptionToQuestionChoice,
} from 'components/NewGenericQuestion/subComponents/NewQuestionMainSectionComponents/ChoicesMainSection/ChoicesMainSection.helpers';
import { OtherChoiceOption } from 'components/NewGenericQuestion/subComponents/NewQuestionMainSectionComponents/ChoicesMainSection/OtherChoiceOption';
import {
  ChoiceOption,
  NON_SUBSTANTIABLE_QUESTION_CHOICE_OPTION_TOKEN,
  QuestionChoiceSelector,
} from 'components/NewGenericQuestion/subComponents/NewQuestionMainSectionComponents/ChoicesMainSection/QuestionChoiceSelector';
import { REACT_SORTABLE_OPTIONS } from 'constants/misc';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { createQuestionChoice, fetchQuestionsChoices } from 'services/questionChoice';
import { setNewQuestionPayload, setShowNumericValues } from 'slices/questionsBank';
import { addQuestionChoice } from 'slices/questionsChoices';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';
import { logger } from 'utils/logger';
import { statusIsError, statusIsIdle, statusIsSuccessful } from 'utils/misc';

interface Props {
  allowOtherOption: boolean;
}

/**
 * Displays the specific form section of the `choices_options` question type.
 */
export const ChoicesMainSection = (props: Props): ReactElement => {
  const { allowOtherOption } = props;
  const { newQuestionPayload } = useAppSelector((state: RootState) => state.questionsBank);
  const [selectedChoices, setSelectedChoices] = useState<ChoiceOption[]>(
    convertQuestionChoicesToChoiceOptions(newQuestionPayload.choices ?? []),
  );

  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();

  const { fetchStatus, nextPage, questionsChoicesSortedByLabel } = useAppSelector(
    (state: RootState) => state.questionsChoices,
  );
  const [choicesOptions, setChoicesOptions] = useState<ChoiceOption[]>([]);
  const [createQuestionChoiceIsLoading, setCreateQuestionChoiceIsLoading] = useState(false);

  const isMultipleSelection =
    getNewQuestionConfig(QuestionConfigType.ALLOW_MULTI_CHOICE, newQuestionPayload)?.config_value === '1';

  const isDisplayingOther =
    getNewQuestionConfig(QuestionConfigType.DISPLAY_OTHER, newQuestionPayload)?.config_value === '1';

  const displayOtherOption = isDisplayingOther && allowOtherOption;

  /**
   * Fetch the entire `QuestionChoice` instances.
   */
  useEffect(() => {
    if (statusIsIdle(fetchStatus) && nextPage) {
      dispatch(fetchQuestionsChoices());
    }
    if (statusIsError(fetchStatus)) {
      toastError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus, nextPage]);

  /**
   * Whenever the process of fetching the `QuestionChoice` instances is
   * complete, convert them and update the list to select from.
   */
  useEffect(() => {
    if (statusIsSuccessful(fetchStatus) && questionsChoicesSortedByLabel) {
      if (newQuestionPayload.id) {
        const choicesHaveSomeConfigs = newQuestionPayload.choices?.some(
          ({ numeric_value, is_non_substantiative }) => is_non_substantiative || !!numeric_value,
        );
        if (choicesHaveSomeConfigs) {
          dispatch(setShowNumericValues(true));
        } else {
          dispatch(setShowNumericValues(false));
        }
      }
      const options = questionsChoicesSortedByLabel.map(({ label, id, parent_group }) => {
        if (parent_group) {
          return {
            id,
            label: `${label} {parent_group: ${parent_group.label}}`,
          };
        }
        return { id, label };
      });
      setChoicesOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus, questionsChoicesSortedByLabel, newQuestionPayload.id]);

  /**
   * Update the payload with the changes of the options.
   */
  useEffect(() => {
    // Clean the selected choices (remove empty choices / duplications).
    const potentialChoicesSet = new Set(
      selectedChoices.reduce<ChoiceOption[]>((choicesSet, selectedChoice) => {
        if (selectedChoice.label) {
          choicesSet.push({
            id: selectedChoice.id,
            label: selectedChoice.label,
            numericValue: selectedChoice.numericValue,
          });
        }
        return choicesSet;
      }, []),
    );
    const choicesSet = Array.from(potentialChoicesSet);

    setChoicesOptions((prevChoicesOptions) =>
      // Remove the choices that are already selected.
      // eslint-disable-next-line max-nested-callbacks
      prevChoicesOptions.filter(({ id }) => !choicesSet.some((choice) => choice.id === id)),
    );

    dispatch(
      setNewQuestionPayload({
        ...newQuestionPayload,
        choices: choicesSet.map(convertChoiceOptionToQuestionChoice),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChoices]);

  const updateSelectedChoice = (newChoice: ChoiceOption, index: number) => {
    const selectedChoicesClone = [...selectedChoices];
    selectedChoicesClone[index] = newChoice;
    setSelectedChoices(selectedChoicesClone);
  };

  const addNewOption = () => {
    setSelectedChoices((prevSelectedChoices) => [...prevSelectedChoices, { id: prevSelectedChoices.length + 1 }]);
  };

  const removeOption = (index: number) => {
    const alteredSelectedChoices = [...selectedChoices];
    alteredSelectedChoices.splice(index, 1);
    setSelectedChoices(alteredSelectedChoices);
  };

  /**
   * Creates a new question choice, adds it to the slice, and adjusts accordingly.
   */
  const handleOnCreateChoice = async (inputLabel: string, index: number) => {
    try {
      setCreateQuestionChoiceIsLoading(true);
      const formattedNewQuestionChoice = inputLabel.trim();
      const newQuestionChoice = await createQuestionChoice(formattedNewQuestionChoice);
      dispatch(addQuestionChoice(newQuestionChoice));
      const { id, label } = newQuestionChoice;
      updateSelectedChoice(
        {
          id,
          label,
        },
        index,
      );
    } catch (error) {
      toastError();
      logger.error(error);
    } finally {
      setCreateQuestionChoiceIsLoading(false);
    }
  };

  const handleOnNumericValueChange = (inputValue: string, selectedChoice: ChoiceOption) => {
    const selectedChoicesClone = [...selectedChoices];
    const selectedChoiceIndex = selectedChoicesClone.findIndex(
      (choice) => choice.id === selectedChoice.id && choice.label === selectedChoice.label,
    );
    if (!inputValue) {
      selectedChoicesClone[selectedChoiceIndex] = {
        ...selectedChoicesClone[selectedChoiceIndex],
        numericValue: undefined,
      };
    } else if (inputValue === NON_SUBSTANTIABLE_QUESTION_CHOICE_OPTION_TOKEN) {
      selectedChoicesClone[selectedChoiceIndex] = {
        ...selectedChoicesClone[selectedChoiceIndex],
        numericValue: NON_SUBSTANTIABLE_QUESTION_CHOICE_OPTION_TOKEN,
      };
    } else if (!Number.isNaN(parseInt(inputValue))) {
      selectedChoicesClone[selectedChoiceIndex] = {
        ...selectedChoicesClone[selectedChoiceIndex],
        numericValue: parseInt(inputValue),
      };
    }
    setSelectedChoices(selectedChoicesClone);
  };

  return (
    <LoaderMask isLoading={!statusIsSuccessful(fetchStatus) || createQuestionChoiceIsLoading}>
      <div className={styles.sectionLayout}>
        <ReactSortable
          list={selectedChoices}
          setList={setSelectedChoices}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...REACT_SORTABLE_OPTIONS}
          className={styles.sectionLayout}
        >
          {selectedChoices.map((selectedChoice, index) => (
            <QuestionChoiceSelector
              key={`selected-choice-${selectedChoice.id}`}
              index={index}
              selectedChoice={selectedChoice}
              choicesOptions={choicesOptions}
              handleOnCreateChoice={handleOnCreateChoice}
              updateSelectedChoice={updateSelectedChoice}
              removeOption={removeOption}
              handleOnNumericValueChange={handleOnNumericValueChange}
              isMultipleSelection={isMultipleSelection}
            />
          ))}
        </ReactSortable>
        {displayOtherOption && <OtherChoiceOption isMultipleSelection={isMultipleSelection} />}
        <AddOptionButtons
          addNewOption={addNewOption}
          isDisplayingOther={isDisplayingOther}
          allowOtherOption={allowOtherOption}
        />
      </div>
    </LoaderMask>
  );
};
