/* eslint-disable id-denylist */
import { SurveyType } from '@zencity/survey-types';
import { calculateSatisfactionScoreByQuestionChoice } from '@zencity/surveys-common-utilities';
import {
  BenchmarkGenericQuestionScore,
  BenchmarkOverallScore,
  BenchmarkTextWidgetData,
  SelectedDateRange,
} from 'screens/WidgetGenerator/utils/misc';
import { fetchRakeWeighterExecutions } from 'services/communitySurveyReport/rakeWighterExecutions';
import { fetchSurveyGroupQuestionScores, fetchSurveyGroups } from 'services/surveyGroup';
import { LeanClient } from 'types/client';
import { SurveyGroupQuestionScore } from 'types/surveyGroup';
import { generateVaultApiUrl, createAxiosInstance } from 'utils/Api';
import {
  filterBenchmarkOverallScoresBySelectedDate,
  findBenchmarkScoreByType,
  generateOverallScoreText,
  getMostRecentCommunitySurveyOverallScore,
} from 'screens/WidgetGenerator/widgets/BenchmarkWidgets/BenchmarkOverallSatisfactionText/BenchmarkOverallSatisfactionText.helpers';
import { BenchmarkType } from 'types/benchmarkType';
import { fetchCommunitySurveyOverallScore } from './communitySurveyScores';

const apiUrl = generateVaultApiUrl('/survey/benchmark_scores/');
const axiosInstance = createAxiosInstance(apiUrl);

const fetchClientMostRecentQuestionScores = async (client: LeanClient): Promise<SurveyGroupQuestionScore[]> => {
  // Get the most recent Survey Group.
  const surveyGroups = await fetchSurveyGroups({
    client_id: client.id,
    survey_type: SurveyType.COMMUNITY_SURVEY,
  });
  if (!surveyGroups.length) {
    return [];
  }
  //  Expecting only 1 `SurveyGroup` for a Client with scores.
  const mostRecentSurveyGroup = surveyGroups[0];

  // Get the `SurveyGroup`s `RakeWeighterExecution` with `display_on_dashboard`.
  const rakeWeighterExecutions = await fetchRakeWeighterExecutions({ survey_group: mostRecentSurveyGroup.id });
  const validRakeWeighterExecutions = rakeWeighterExecutions.filter(
    (rakeWeighterExecution) => rakeWeighterExecution.display_on_dashboard,
  );
  if (!validRakeWeighterExecutions.length) {
    return [];
  }
  const latestCycle = validRakeWeighterExecutions.sort(
    (firstCycle, secondCycle) => new Date(secondCycle.start_date).getTime() - new Date(firstCycle.start_date).getTime(),
  )[0];

  // Fetch the Question Scores for the most recent `SurveyGroup` cycle.
  const questionScores = await fetchSurveyGroupQuestionScores(
    mostRecentSurveyGroup.id,
    latestCycle.start_date,
    latestCycle.end_date,
  );
  return questionScores.question_scores;
};

export const fetchBenchmarkGenericQuestionScores = async (
  client: LeanClient,
  selectedDateRange: SelectedDateRange,
): Promise<BenchmarkGenericQuestionScore[]> => {
  const dateParams = {
    start_date: selectedDateRange.startDate,
    end_date: selectedDateRange.endDate,
  };
  const response = await axiosInstance.get<{ data: BenchmarkGenericQuestionScore[] }>(
    `${client.id}/generic_questions/`,
    {
      params: dateParams,
    },
  );
  const { data } = response.data;

  // Get the overall score from the Client's most recent Question Scores.
  const questionScores = await fetchClientMostRecentQuestionScores(client);

  // Replace the `overall_score` with the Question's Score.
  const benchmarkScoresWithOverallScores = data.reduce<BenchmarkGenericQuestionScore[]>(
    (formattedBenchmarkScores, benchmarkScore) => {
      const { generic_question_text: benchmarkGenericQuestionText } = benchmarkScore;
      const communitySurveyQuestionScore = questionScores.find(
        ({ question_text: questionText }) => questionText === benchmarkGenericQuestionText,
      );

      // Skip if there is no matching Question Score
      // for the Benchmark Question.
      if (!communitySurveyQuestionScore) {
        return formattedBenchmarkScores;
      }

      const overallSatisfactionScore = calculateSatisfactionScoreByQuestionChoice(
        communitySurveyQuestionScore?.question_choices ?? [],
      );
      if (!overallSatisfactionScore) {
        return formattedBenchmarkScores;
      }
      const updatedBenchmarkScore = {
        ...benchmarkScore,
        overall_score: overallSatisfactionScore.positive / 100,
      };
      formattedBenchmarkScores.push(updatedBenchmarkScore);
      return formattedBenchmarkScores;
    },
    [],
  );

  return benchmarkScoresWithOverallScores;
};

export const fetchBenchmarkOverallScores = async (clientId: number): Promise<BenchmarkOverallScore[]> => {
  const response = await axiosInstance.get<{ data: BenchmarkOverallScore[] }>(`${clientId}/overall/`);
  const { data } = response.data;
  return data;
};

export const getBenchmarkOverallSatisfactionData = async (
  client: LeanClient,
  selectedDateRange: SelectedDateRange,
): Promise<BenchmarkTextWidgetData> => {
  const benchmarkOverallScores = await fetchBenchmarkOverallScores(client.id);
  const communitySurveyOverallScores = await fetchCommunitySurveyOverallScore(client.id);
  // For the text, we compare the client Community Survey Score with the
  // "National" Benchmark score and the "Cohort" Benchmark Score.
  const mostRecentClientOverallScore = getMostRecentCommunitySurveyOverallScore(communitySurveyOverallScores ?? []);
  const selectedDateRangeBenchmarkOverallScores = filterBenchmarkOverallScoresBySelectedDate(
    selectedDateRange,
    benchmarkOverallScores,
  );
  const nationalBenchmarkScore = findBenchmarkScoreByType(
    selectedDateRangeBenchmarkOverallScores,
    BenchmarkType.NATIONAL,
  );
  const cohortBenchmarkScore = findBenchmarkScoreByType(selectedDateRangeBenchmarkOverallScores, BenchmarkType.COHORT);
  const overallScoreText = generateOverallScoreText(
    client.name,
    mostRecentClientOverallScore,
    nationalBenchmarkScore,
    cohortBenchmarkScore,
  );

  const fetchedData = {
    benchmarkOverallScores,
    communitySurveyOverallScores,
    overallScoreText,
  };
  return fetchedData;
};
