import { ZCDIcon, ZCDIconColor } from '@zencity/common-ui';
import { Flex } from 'components/Flex/Flex';
import React from 'react';
import {
  MAX_UNREPRESENTATIVE_INDICATOR,
  MIN_UNREPRESENTATIVE_INDICATOR,
  UNREPRESENTATIVE_ICON_TEST_ID,
} from './constants';

interface Props {
  totalSubmissions?: number;
}

export const UnrepresentativeIndicator = (props: Props): React.ReactElement => {
  const { totalSubmissions = 0 } = props;

  if (totalSubmissions >= MIN_UNREPRESENTATIVE_INDICATOR && totalSubmissions < MAX_UNREPRESENTATIVE_INDICATOR) {
    return <span>*</span>;
  }

  return (
    <Flex data-testid={UNREPRESENTATIVE_ICON_TEST_ID}>
      <ZCDIcon name="warning-outline" color={ZCDIconColor.BLUE} />
    </Flex>
  );
};
