/* eslint-disable camelcase */
import { LeanClient } from 'types/client';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';
import { exportDataToFile, FileTypes } from './util';

const apiUrl = generateVaultApiUrl('/api/v2/export_survey_submissions_with_enrichments/');
const axiosInstance = createAxiosInstance(apiUrl);

interface ExportSubmissionsParams {
  page: number;
  page_size: number;
  survey_group: number;
  format: FileTypes;
  start_date?: string;
  end_date?: string;
}

/**
 * Export answers by survey group to csv file.
 */
export async function exportSubmissionsBySurveyGroup(
  params: ExportSubmissionsParams,
  client: LeanClient,
): Promise<number> {
  const result = await axiosInstance.get('', {
    params,
    responseType: 'blob',
    headers: { 'X-Zc-Client-Id': client.zc_client_id },
  });

  exportDataToFile(
    params.format,
    result.data,
    `${result.headers?.['content-disposition']
      ?.split('filename=')?.[1]
      ?.replaceAll('"', '')
      ?.replaceAll(`.${params.format}`, '')}-${params.page}` || 'export',
  );

  return result.status;
}
