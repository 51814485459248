import { Card, ZCDButton } from '@zencity/common-ui';
import classnames from 'classnames';
import { Header } from 'components/Header/Header';
import { NewSurveyLink } from 'components/NewSurveyLink/NewSurveyLink';
import { QuestionBankDialog } from 'components/QuestionBankDialog/QuestionBankDialog';
import { SurveysListTable } from 'components/SurveysListTable/SurveysListTable';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyChildPath } from 'routerPaths';
import { fetchSurveys } from 'services/survey';
import { RootState } from 'store';
import { statusIsIdle } from 'utils/misc';
import styles from './MainPage.module.scss';

/**
 * The MainPage of the survey manager. This is the first screen shown when a user goes to the survey manager,
 * and includes a list of all existing surveys.
 */
export const MainPage = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { fetchStatus, errorMessage } = useAppSelector((state: RootState) => state.surveys);
  const [questionsBankModalIsOpen, setQuestionsBankModalIsOpen] = useState(false);

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (statusIsIdle(fetchStatus)) {
      dispatch(fetchSurveys({}));
    }

    if (errorMessage) {
      throw new Error(errorMessage);
    }
  }, [fetchStatus, dispatch, errorMessage]);

  const headerActions = [
    <ZCDButton
      type="button"
      variant="secondary"
      size="lg"
      key="browse-question-bank"
      onClick={() => setQuestionsBankModalIsOpen(true)}
      text={translate('mainPage.browseQuestionBank')}
    />,
    <NewSurveyLink key={SurveyChildPath.START_CREATE_NEW} />,
  ];

  return (
    <>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <Header titleText={translate('mainPage.header')} actions={headerActions} />
      <div className={styles.content}>
        <Card.Wrapper className={classnames(styles.surveysListWrapper, styles.wrapper)}>
          <SurveysListTable />
        </Card.Wrapper>
      </div>
      <QuestionBankDialog
        isOpen={questionsBankModalIsOpen}
        setIsOpen={setQuestionsBankModalIsOpen}
        title={translate('mainPage.browseQuestionBank')}
      />
    </>
  );
};
