interface PercentageCountArguments {
  totalWeights: number;
  totalResponses: number;
  count: number;
  weightsSum: number;
  validCount: number;
}

/**
 * Calculates the percentage count for a response option.
 * If there are valid respondents (respondents with weights),
 * it will use the weighted count and the total valid respondent to calculate the percentage.
 * If there are no valid respondents (respondents with weights),
 * it will use count and the total respondent to calculate the percentage.
 */
export const getPercentageCount = ({
  totalWeights,
  totalResponses,
  count,
  weightsSum,
}: Omit<PercentageCountArguments, 'validCount'>) => {
  if (totalWeights) {
    return Math.round((weightsSum / totalWeights) * 100);
  }
  return Math.round((count / totalResponses) * 100);
};

export const getOptionCount = ({
  isPercentage,
  totalWeights,
  totalResponses,
  count,
  weightsSum,
  validCount,
}: PercentageCountArguments & {
  isPercentage: boolean;
}) =>
  isPercentage
    ? getPercentageCount({
        totalWeights,
        totalResponses,
        count,
        weightsSum,
      })
    : validCount;
