import { LeanClient } from 'types/client';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/api/v2/answers/multichoice-summary/');
const axiosInstance = createAxiosInstance(apiUrl);

interface AnswersChoicesQuestionParams {
  generic_question_id?: number;
  start_date: string;
  end_date: string;
  survey_group_id?: number;
  include_weights: boolean;
}

type Choice = {
  label: string;
  responses: number;
  valid_responses: number;
  weights_sum: number;
  id: number;
};
export type AnswersSummaryData = {
  question_id: string;
  total_responses: number;
  total_valid_responses: number;
  total_weights: number;
  choices: Choice[];
};

/**
 * Fetch answers summary for a specific question.
 */
export async function fetchAnswersChoicesQuestionSummary(
  params: AnswersChoicesQuestionParams,
  client: LeanClient,
): Promise<AnswersSummaryData> {
  const result = await axiosInstance.get('', {
    params,
    headers: { 'X-Zc-Client-Id': client.zc_client_id },
  });

  return result.data.results;
}
