import { createAsyncThunk } from '@reduxjs/toolkit';
import { TypeformSurvey } from 'types/typeformSurvey';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/api/v2/typeform_survey/');
const axiosInstance = createAxiosInstance(apiUrl);

/**
 * Fetch a list of typeform surveys, given a Survey ID.
 */
export const fetchTypeformSurveysBySurveyId = createAsyncThunk(
  'surveyManage/fetchTypeformSurveysBySurveyId',
  async (surveyId: number) => {
    const response = await axiosInstance.get('', {
      params: {
        bypass_client_filter: true,
        page_size: 10,
        survey: surveyId,
      },
    });
    return response.data;
  },
);

/**
 * Publish a typeform survey.
 */
export const publishTypeformSurvey = async (typeformSurveyId: number): Promise<TypeformSurvey> => {
  const response = await axiosInstance.post(`${typeformSurveyId}/publish/`, {});
  return response.data;
};

export const republishTypeformSurvey = async (typeformSurveyId: number): Promise<TypeformSurvey[]> => {
  const response = await axiosInstance.post<TypeformSurvey[]>(`${typeformSurveyId}/republish/`);
  return response.data;
};

/**
 * Create a new typeform survey instance.
 */
export const createNewTypeformSurvey = async (language: string, surveyId: number): Promise<TypeformSurvey> => {
  const response = await axiosInstance.post('', {
    survey: surveyId,
    language,
  });
  return response.data;
};

/**
 * Add a new survey with a Panel Provider.
 */
export const createNewPanelTypeformSurvey = async (
  typeformSurveyId: number,
  panelVendorId: number,
): Promise<TypeformSurvey> => {
  const response = await axiosInstance.post<TypeformSurvey>(
    `${typeformSurveyId}/add_panel_typeform_survey/${panelVendorId}/`,
  );
  return response.data;
};
