import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from '../FieldTitle/FieldTitle';

interface Props {
  stateValue: string;
  onChangeCallback: (v: string) => void;
  options: SelectOption[];
  isDisabled: boolean;
}

export const CycleSelection = (props: Props): ReactElement => {
  const { stateValue, onChangeCallback, options, isDisabled } = props;
  const selectedValue = options.find((option) => option.value === stateValue);
  const { t: translate } = useTranslation();

  const handleCycleSelection = (newValue: unknown) => {
    const selected = newValue as { value: string };
    onChangeCallback(selected.value);
  };

  return (
    <>
      <FieldTitle title={translate('widgetGenerator.selectCycle')} />
      <ZCDSelect
        placeholder={translate('widgetGenerator.selectCycle')}
        options={options}
        onChange={handleCycleSelection}
        value={selectedValue}
        isDisabled={isDisabled}
      />
    </>
  );
};
