/* eslint-disable max-lines-per-function */
import { ZCDDialog } from '@zencity/common-ui';
import { ConfirmationDialogFooter } from 'components/ConfirmationDialogFooter/ConfirmationDialogFooter';
import { SingleTranslationForm } from 'components/SingleTranslationForm/SingleTranslationForm';
import { TranslationItem } from 'components/SurveyTranslationsList/SurveyTranslationsList.helpers';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateEndScreenDescriptionTranslation, updateEndScreenTitleTranslation } from 'services/endScreen';
import { updateGenericQuestionConfigTranslation, updateGenericQuestionTextTranslation } from 'services/genericQuestion';
import { updateQuestionChoiceTranslation } from 'services/questionChoice';
import { updateQuestionConfigTranslation, updateQuestionOverriddenTextTranslation } from 'services/questions';
import { updateSurveyGroupTitleForDisplay } from 'services/surveyGroup';
import { RootState } from 'store';
import { TranslationRequestPayload } from 'types/misc';
import { TranslatableField } from 'types/translation';
import { logger } from 'utils/logger';
import { statusIsError } from 'utils/misc';
import { updateTranslationSections } from 'utils/surveyManage';

interface Props {
  isOpen: boolean;
  setTranslateDialogTranslationItem: React.Dispatch<React.SetStateAction<TranslationItem | undefined>>;
  translationItem: TranslationItem;
}

/**
 * Allows for the editing of a translation.
 */
// eslint-disable-next-line max-statements
export const TranslationDialog = (props: Props): ReactElement => {
  const { toastError } = useContext(SurveyManagerToastContext);
  const { isOpen, setTranslateDialogTranslationItem, translationItem } = props;
  const dispatch = useAppDispatch();
  const { selectedLanguage, currentSurveyId } = useAppSelector((state: RootState) => state.surveyManage);
  const { updateStatus: genericQuestionsUpdateStatus } = useAppSelector((state: RootState) => state.genericQuestions);
  const { surveys, updateStatus: surveysUpdateStatus } = useAppSelector((state: RootState) => state.surveys);
  const { defaultLanguage } = useAppSelector((state: RootState) => state.globalConfigs);
  const { updateStatus: questionsUpdateStatus } = useAppSelector((state: RootState) => state.questions);
  const { updateStatus: endScreensUpdateStatus } = useAppSelector((state: RootState) => state.endScreens);
  const [translatedText, setTranslatedText] = useState('');
  const { t: translate } = useTranslation();

  const translatableFieldTranslationPath = 'surveyTranslationFormLayout.translatableFields';
  const closeTranslationDialog = () => setTranslateDialogTranslationItem(undefined);

  const handleOnEditTranslatedText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTranslatedText(event.currentTarget.value);
  };

  useEffect(() => {
    const translationError =
      statusIsError(genericQuestionsUpdateStatus) ||
      statusIsError(surveysUpdateStatus) ||
      statusIsError(questionsUpdateStatus) ||
      statusIsError(endScreensUpdateStatus);
    if (translationError) {
      toastError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endScreensUpdateStatus, genericQuestionsUpdateStatus, questionsUpdateStatus, surveysUpdateStatus]);

  // eslint-disable-next-line complexity
  const handleOnSubmitTranslation = async () => {
    try {
      const { instanceId } = translationItem;
      // The translation requests use the same variables for patching a translation
      // in any translated instance.
      const commonTranslationRequestPayload: TranslationRequestPayload = {
        instanceId,
        language: selectedLanguage,
        translatedText,
      };
      switch (translationItem.fieldName) {
        case TranslatableField.SURVEY_GROUP_TITLE_FOR_DISPLAY:
          await dispatch(
            updateSurveyGroupTitleForDisplay({
              survey: surveys[currentSurveyId as number],
              language: selectedLanguage,
              translatedTitleForDisplay: translatedText,
              defaultLanguage,
            }),
          );
          break;
        case TranslatableField.GENERIC_QUESTION_TEXT:
          await dispatch(updateGenericQuestionTextTranslation(commonTranslationRequestPayload));
          break;
        case TranslatableField.QUESTION_OVERRIDDEN_TEXT:
          await dispatch(updateQuestionOverriddenTextTranslation(commonTranslationRequestPayload));
          break;
        case TranslatableField.GENERIC_QUESTION_SCALE_LABEL:
        case TranslatableField.GENERIC_QUESTION_DESCRIPTION:
          await dispatch(updateGenericQuestionConfigTranslation(commonTranslationRequestPayload));
          break;
        case TranslatableField.QUESTION_SCALE_LABEL:
        case TranslatableField.QUESTION_DESCRIPTION:
          await dispatch(updateQuestionConfigTranslation(commonTranslationRequestPayload));
          break;
        case TranslatableField.QUESTION_CHOICE_LABEL:
          await dispatch(updateQuestionChoiceTranslation(commonTranslationRequestPayload));
          break;
        case TranslatableField.END_SCREEN_TITLE:
          await dispatch(updateEndScreenTitleTranslation(commonTranslationRequestPayload));
          break;
        case TranslatableField.END_SCREEN_DESCRIPTION:
          await dispatch(updateEndScreenDescriptionTranslation(commonTranslationRequestPayload));
          break;
        default:
          toastError();
      }
      dispatch(updateTranslationSections(currentSurveyId as number));
      setTranslateDialogTranslationItem(undefined);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <ZCDDialog
      isOpen={isOpen}
      header={
        <h2>{`${translate('common.translate')}: ${translate(
          `${translatableFieldTranslationPath}.${translationItem.fieldName}`,
        )}`}</h2>
      }
      footer={
        <ConfirmationDialogFooter
          isDisabled={false}
          handleOnCancel={closeTranslationDialog}
          handleOnConfirm={handleOnSubmitTranslation}
        />
      }
      onRequestClose={closeTranslationDialog}
    >
      <SingleTranslationForm
        translationItem={translationItem}
        allowEditMode
        handleOnEdit={handleOnEditTranslatedText}
      />
    </ZCDDialog>
  );
};
