import { ZCDButton } from '@zencity/common-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createAxiosInstance } from 'utils/Api';
import { logger } from 'utils/logger';
import { WidgetType } from '../utils/misc';

interface ReportUploaderProps {
  widgetType: WidgetType;
  setData: (payload: JSON | null) => void;
}

const FETCH_URL = {
  [WidgetType.WORD_CLOUD]: 'count',
  [WidgetType.SENTIMENT_BAR_GROUPS]: 'groups',
  [WidgetType.BENCHMARK_GENERIC_QUESTIONS_TABLE]: 'benchmark',
  [WidgetType.GENERAL_TABLE]: 'table',
  [WidgetType.SERVICES_BAR_CHART]: 'services',
};

// Button triggers the file input element.
export const ReportUploader = (props: ReportUploaderProps): React.ReactElement | null => {
  const { setData, widgetType } = props;
  const [file, setFile] = useState<BlobPart | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileBrowserDialog = useCallback(() => {
    if (!fileInputRef || !fileInputRef.current) {
      logger.error('Can not open file browser dialog because the file input element does not exist.');
    }
    fileInputRef.current?.click();
    return null;
  }, [fileInputRef]);

  const triggerFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    setFile(files?.[0]);
    if (fileInputRef.current?.value) {
      fileInputRef.current.value = '';
    }
  };

  const uploadFileToStorage = useCallback(async () => {
    // break in case the file is not set
    if (!file) return;
    const ENDPOINT = `/${FETCH_URL[widgetType as keyof typeof FETCH_URL]}`;
    const axiosInstance = createAxiosInstance(ENDPOINT);
    try {
      setIsLoading(true);
      const payload = new FormData();
      payload.append('file', new Blob([file], { type: 'text/csv' }), 'file');
      const response = await axiosInstance.post('', payload);
      setData(response.data);
    } catch (error) {
      logger.error(error);
      setData(null);
    }
    setIsLoading(false);
  }, [file, setData, widgetType]);

  useEffect(() => {
    uploadFileToStorage();
  }, [file, uploadFileToStorage, widgetType]);

  // if widget type in not one of the following, return null.
  if (
    widgetType !== WidgetType.SENTIMENT_BAR_GROUPS &&
    widgetType !== WidgetType.GENERAL_TABLE &&
    widgetType !== WidgetType.BENCHMARK_GENERIC_QUESTIONS_TABLE
  )
    return null;

  return (
    <div>
      <ZCDButton text="Upload Data" onClick={openFileBrowserDialog} disabled={isLoading} />
      <input
        type="file"
        id="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={triggerFileUpload}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        data-testid="hidden-input-upload-file"
      />
    </div>
  );
};
