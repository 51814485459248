import { ZCDIcon } from '@zencity/common-ui';
import classnames from 'classnames';
import { QuestionTextArea } from 'components/QuestionTextArea/QuestionTextArea';
import { TranslationItem } from 'components/SurveyTranslationsList/SurveyTranslationsList.helpers';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import styles from './SingleTranslationForm.module.scss';

interface Props {
  allowEditMode: boolean;
  translationItem: TranslationItem;
  handleOnEdit?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

/**
 * Contains the inputs and labels to edit a translation.
 */
export const SingleTranslationForm = (props: Props): ReactElement => {
  const { translationItem, allowEditMode, handleOnEdit } = props;
  const { textToTranslate, originalTextToTranslate, translatedTexts, originalTranslatedTexts } = translationItem;
  const { selectedLanguage } = useAppSelector((state: RootState) => state.surveyManage);
  const { languages } = useAppSelector((state: RootState) => state.globalConfigs);
  const { t: translate } = useTranslation();

  const translatedText = translatedTexts[selectedLanguage];
  const originalTranslatedText = originalTranslatedTexts ? originalTranslatedTexts[selectedLanguage] : undefined;
  const showTranslatedInput = allowEditMode || translatedText;

  return (
    <div className={styles.translationForm}>
      {/* The origin language will always be "English (en)". */}
      {allowEditMode && <p className={styles.originLanguageLabel}>{languages.en}</p>}
      <QuestionTextArea
        disabled
        value={textToTranslate}
        className={classnames(styles.contentInput, styles.originLanguageInput)}
      />
      {originalTextToTranslate && (
        <p className={classnames(styles.previousText, styles.originLanguagePreviousText)}>{`${translate(
          'common.original',
        )}: ${originalTextToTranslate}`}</p>
      )}
      <div className={styles.arrowsIcon}>
        <ZCDIcon name="sort" />
      </div>
      {showTranslatedInput && (
        <>
          {allowEditMode && <p className={styles.translatedLanguageLabel}>{languages[selectedLanguage]}</p>}
          <QuestionTextArea
            disabled={!allowEditMode}
            useDefaultValue
            value={translatedText}
            className={classnames(styles.contentInput, styles.translatedLanguageInput)}
            onChange={handleOnEdit}
          />
          {originalTranslatedText && (
            <p className={classnames(styles.previousText, styles.translatedLanguagePreviousText)}>
              {`${translate('common.original')}: ${originalTranslatedText}`}
            </p>
          )}
        </>
      )}
    </div>
  );
};
