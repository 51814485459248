import { ZCDFilter, ZCDSpinner } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { SurveyGroup } from '@zencity/survey-types';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../Analysis.module.scss';

interface SurveyGroupSelectOption {
  label: SurveyGroup['title_for_display'];
  value: SurveyGroup;
}

interface Props {
  surveyGroupOptions: SurveyGroupSelectOption[];
  setSurveyGroup: (surveyGroupId: SurveyGroup) => void;
  surveyGroup?: SurveyGroup;
  isLoading: boolean;
}

export const SurveyGroupFilter = ({
  surveyGroupOptions,
  surveyGroup,
  setSurveyGroup,
  isLoading,
}: Props): ReactElement => {
  const surveyGroupValue = surveyGroupOptions.find(
    (surveyGroupOption) => surveyGroupOption.value.id === surveyGroup?.id,
  );
  const { t: translate } = useTranslation();
  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <ZCDSpinner />
      </div>
    );
  }
  return (
    <ZCDFilter<SurveyGroupSelectOption>
      // type casting is necessary as react select types are not accurate
      value={surveyGroupValue as unknown as SelectOption}
      onChange={(option) => {
        const typedOption = option as SurveyGroupSelectOption;
        setSurveyGroup(typedOption.value);
      }}
      label={translate('analysis.surveyGroup')}
      // type casting is necessary as react select types are not accurate
      options={surveyGroupOptions as unknown as SelectOption[]}
      isDisabled={!surveyGroupOptions.length}
    />
  );
};
