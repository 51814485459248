import { BenchmarkType } from 'types/benchmarkType';
import styles from './benchmarkColors.module.scss';

export const DISPLAYED_BENCHMARK_TYPES = [BenchmarkType.NATIONAL, BenchmarkType.COHORT];

export const CLIENT_BENCHMARK_TYPE = 'client';

export const BENCHMARK_TYPE_COLOR = {
  [BenchmarkType.NATIONAL]: styles[BenchmarkType.NATIONAL],
  [BenchmarkType.COHORT]: styles[BenchmarkType.COHORT],
  [CLIENT_BENCHMARK_TYPE]: styles[CLIENT_BENCHMARK_TYPE],
};
