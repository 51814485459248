import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ShareLinksDialog.module.scss';

export const Header = (): ReactElement => {
  const { t: translate } = useTranslation();
  return (
    <div className={styles.headerWrapper}>
      <h2>{translate('shareLinksDialog.title')}</h2>
      <p>{translate('shareLinksDialog.description')}</p>
    </div>
  );
};
