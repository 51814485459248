import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 404 - Page Not Found screen.
 */
export const PageNotFound404Screen = (): ReactElement => {
  const { t: translate } = useTranslation();
  // eslint-disable-next-line i18next/no-literal-string
  return <div>{translate('pageNotFound')}</div>;
};
