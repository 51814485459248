/* eslint-disable id-denylist */
import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { ASPECTS_LABELS, AspectValues } from 'types/aspect';
import { DocumentFragment } from '../documentFragment';
import { reportTemplateTable, reportTemplateTableCell, TABLE_DEFAULT_BORDER_COLOR } from '../utils/tableTemplate';
import { generateLineBreaks } from '../utils/utilsFunctions';
import { SurveysReportGeneratorDataItemKey, SurveysReportGeneratorFilterKey } from '../../constants';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import { lineBreakElement } from '../utils/lineBreakElement';

export const benchmarkServicesScore = (
  clientName: string,
  headerElement: ILandscapeHeaderElement,
): DocumentFragment => {
  const leftColumnServicesBenchmarkTable = reportTemplateTableCell({
    data: [
      lineBreakElement,
      {
        name: 'heading3',
        children: [
          {
            data: `Satisfaction with Life in ${clientName}`,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
      {
        name: 'paragraph',
        children: [
          {
            data: 'Breakdown by characteristic',
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
            },
          },
        ],
      },
      ...generateLineBreaks(8),
      {
        name: 'reactItem',
        attributes: {
          definitionKey: SurveysReportGeneratorDataItemKey.BENCHMARK_TABLE_LEGEND,
        },
      },
    ],
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '35%',
  });
  const rightColumnServicesBenchmarkTable = reportTemplateTableCell({
    data: [
      {
        name: 'reactItem',
        attributes: {
          definitionKey: SurveysReportGeneratorDataItemKey.QUESTIONS_BENCHMARK_TABLE,
          params: {
            filters: {
              [SurveysReportGeneratorFilterKey.BENCHMARK_ASPECT_SELECT]: {
                value: AspectValues.CHARACTERISTIC,
                label: ASPECTS_LABELS.characteristic,
              },
            },
          },
        },
      },
    ],
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '60%',
  });
  const middleColumnServicesBenchmarkTable = reportTemplateTableCell({
    data: [lineBreakElement],
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '5%',
  });
  const servicesScoreBenchmarkTable = reportTemplateTable({
    tableColumns: [
      leftColumnServicesBenchmarkTable,
      middleColumnServicesBenchmarkTable,
      rightColumnServicesBenchmarkTable,
    ],
    tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  });

  return [
    headerElement,
    servicesScoreBenchmarkTable,
    {
      name: 'pageBreak',
    },
  ];
};
