/* eslint-disable id-denylist */
import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { SurveysReportGeneratorDataItemKey } from '../../constants';
import { sectionCoverPage } from '../utils/sectionCoverPage';
import { DocumentFragment } from '../documentFragment';
import { lineBreakElement } from '../utils/lineBreakElement';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import {
  reportTemplateTableCell,
  reportTemplateTable,
  TABLE_DEFAULT_BORDER_COLOR,
  TABLE_CELL_DEFAULT_PADDING,
} from '../utils/tableTemplate';

const overallSatisfactionScoreBarsPart1 = `The bars on the right show the proportion of responses that are positive (4 or 5), neutral (3), or negative (1 or 2). The percentages show the percent in the positive category.`;
const overallSatisfactionScoreBarsPart2 = `We take the average of the Community Characteristics, then classify the score as positive (3+), neutral (1.5 to <3), or negative (≤1.5). The percentages represent the percent of respondents in each category.
`;
const overallSatisfactionCoverDescriptionChunk1 = `The overall satisfaction score is calculated from the questions in the two main sections of the survey: general quality of life and satisfaction with different characteristics of life in your community.`;
const overallSatisfactionCoverDescriptionChunk2 = `We take the average of these questions, then classify the score as positive (3+), neutral (1.5 to <3), or negative (≤1.5). The percentages represent the percent of respondents in each category. `;
const comparisonDescription = `If you have a previous cycle, the up (↑) and down (↓) arrows will show the change in percentage points. `;

const overallSatisfactionCoverPage = sectionCoverPage({
  titleChunks: ['Overall', 'Satisfaction'],
  descriptionChunks: [
    overallSatisfactionCoverDescriptionChunk1,
    overallSatisfactionCoverDescriptionChunk2,
    comparisonDescription,
  ],
});

const leftColumnBarChartTable = reportTemplateTableCell({
  data: [
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.OVERALL_SATISFACTION_TEXT,
      },
    },
  ],
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  tableCellWidth: '50%',
});

const rightColumnBarChartTable = reportTemplateTableCell({
  data: [
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.OVERALL_SATISFACTION_CHART,
      },
    },
  ],
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  tableCellWidth: '50%',
});

const leftColumnSentimentChartTable = reportTemplateTableCell({
  data: [
    {
      name: 'heading3',
      children: [
        {
          data: `These are the main measures of satisfaction in your community`,
          attributes: {
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
            fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
          },
        },
      ],
    },
    lineBreakElement,
    {
      name: 'paragraph',
      children: [
        {
          data: `${overallSatisfactionScoreBarsPart1}`,
          attributes: {
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
            fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
          },
        },
      ],
    },
    lineBreakElement,
    {
      name: 'paragraph',
      children: [
        {
          data: `${overallSatisfactionScoreBarsPart2}`,
          attributes: {
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
            fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
          },
        },
      ],
    },
    lineBreakElement,
    {
      name: 'paragraph',
      children: [
        {
          data: `${comparisonDescription}`,
          attributes: {
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
            fontColor: TEMPLATE_TYPOGRAPHY.colors.gray80,
          },
        },
      ],
    },
  ],
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  tableCellWidth: '40%',
});

const rightColumnSentimentChartTable = reportTemplateTableCell({
  data: [
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.SENTIMENT_BAR_OVERALL_TABLE,
      },
    },
  ],
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  tableCellWidth: '55%',
});
const middleColumnBarChartTable = reportTemplateTableCell({
  data: [lineBreakElement],
  tableCellPadding: TABLE_CELL_DEFAULT_PADDING,
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  tableCellWidth: '5%',
});
const overallSatisfactionBarChartTable = reportTemplateTable({
  tableColumns: [leftColumnBarChartTable, rightColumnBarChartTable],
  tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
});

const overallSatisfactionSentimentChartTable = reportTemplateTable({
  tableColumns: [leftColumnSentimentChartTable, middleColumnBarChartTable, rightColumnSentimentChartTable],
  tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
});

interface OverallSatisfactionProps {
  headerElement: ILandscapeHeaderElement;
}
export const overallSatisfaction = (props: OverallSatisfactionProps): DocumentFragment => {
  const { headerElement } = props;

  return [
    headerElement,
    ...overallSatisfactionCoverPage,
    headerElement,
    overallSatisfactionBarChartTable,
    {
      name: 'pageBreak',
    },
    headerElement,
    overallSatisfactionSentimentChartTable,
    {
      name: 'pageBreak',
    },
  ];
};
