import { ZCDIconButton } from '@zencity/common-ui';
import { ChoiceOption } from 'components/NewGenericQuestion/subComponents/NewQuestionMainSectionComponents/ChoicesMainSection/QuestionChoiceSelector';
import { useAppDispatch } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { setNewQuestionPayload } from 'slices/questionsBank';
import { GenericQuestion } from 'types/genericQuestion';

interface Props {
  genericQuestion: GenericQuestion;
  switchToNewQuestionTab?: () => void;
}

export const EditGenericQuestion = ({ genericQuestion, switchToNewQuestionTab }: Props): ReactElement => {
  const dispatch = useAppDispatch();

  const handleOnQuestionEdit = () => {
    const genericQuestionChoiceOptions: ChoiceOption[] = genericQuestion.choices.map((choice) => ({
      id: choice.id,
      label: choice.label,
      numeric_value: choice.config?.numeric_value,
      is_non_substantiative: choice.config?.is_non_substantiative,
    }));
    dispatch(
      setNewQuestionPayload({
        ...genericQuestion,
        aspect: genericQuestion.aspect?.id,
        choices: genericQuestionChoiceOptions,
      }),
    );
    switchToNewQuestionTab?.();
  };

  return <ZCDIconButton onClick={handleOnQuestionEdit} type="button" icon="edit" variant="menu" />;
};
