import React, { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import { Icon } from '@zencity/common-ui';
import styles from 'components/NewGenericQuestion/NewGenericQuestion.module.scss';

interface Props {
  children: ReactNode;
  isDraggable?: boolean;
  asCheckbox?: boolean;
}
/**
 * Displays an option with an icon and a disabled input indication.
 */
export const OptionWrapper = (props: Props): ReactElement => {
  const { asCheckbox = false, isDraggable = true, children } = props;

  return (
    <div className={styles.optionWrapper}>
      <Icon
        width={10}
        height={6}
        name="six-small-circles.svg"
        className={classnames({
          draggableArea: isDraggable,
          [styles.hidden]: !isDraggable,
        })}
      />
      <input type={asCheckbox ? 'checkbox' : 'radio'} disabled />
      {children}
    </div>
  );
};
