import { useLocation, matchRoutes } from 'react-router-dom';

export enum RouterPath {
  MAIN = '/',
  SURVEY = '/survey',
}

export enum SurveyChildPath {
  START_CREATE_NEW = 'new',
  BUILD = ':surveyId/build',
  EDIT = ':surveyId/edit',
  MANAGE = ':surveyId/manage',
  CLONE = ':surveyId/clone',
}

const stripWrappedSlashes = (value: string) => value.replace(/^\/|\/$/g, '');

/**
 * Get the current path pattern based off location.
 */
export const useCurrentSurveyPath = (): string => {
  const location = useLocation();

  // This mapping is required since this hook cannot be moved to `router.tsx`, where this mapping is already defined,
  // due to a dependency cycle that cannot be avoided.
  const routes = Object.values(SurveyChildPath as unknown as string).map((route) => ({
    path: `${RouterPath.SURVEY}/${route}`,
  }));

  const routeMatches = matchRoutes(routes, location);
  if (!routeMatches) {
    return 'ROUTE-NOT-FOUND';
  }
  const routeCollectedPaths: string[] = [];
  routeMatches.forEach((routeMatch) => {
    // Some routes are not defined with a `path` (usually when defined as an
    // `index`), thus, fallback to an empty string.
    const potentialPath = routeMatch.route.path || '';

    // Collect the potential path after striping its wrapped slashes.
    routeCollectedPaths.push(stripWrappedSlashes(potentialPath));
  });

  // Build the route by the collected paths (filter out empty strings).
  return `/${routeCollectedPaths.filter(Boolean).join('/')}`;
};
