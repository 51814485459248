/* eslint-disable react/jsx-props-no-spreading */
import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { CheckboxAsSwitch } from 'components/CheckboxAsSwitch/CheckboxAsSwitch';
import { getNewQuestionConfig, configSetter } from 'components/NewGenericQuestion/NewGenericQuestion.helpers';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import i18next from 'i18next';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { setNewQuestionConfigs } from 'slices/questionsBank';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';
import { useTranslation } from 'react-i18next';
import styles from 'components/NewGenericQuestion/subComponents/NewQuestionRightSection.module.scss';

const MIN_MAX_SELECTION_OPTIONS: SelectOption[] = [
  {
    label: i18next.t('questionConfig.minMaxSelection.unlimited'),
    value: 'unlimited',
  },
  {
    label: i18next.t('questionConfig.minMaxSelection.exactNumber'),
    value: 'exact-number',
  },
  {
    label: i18next.t('questionConfig.minMaxSelection.range'),
    value: 'range',
  },
];

// eslint-disable-next-line max-statements,max-lines-per-function,complexity
export const ChoicesRightSection = (): ReactElement => {
  const { t: translate } = useTranslation();
  const [minMaxSelectionOption, setMinMaxSelectionOption] = useState<SelectOption>(MIN_MAX_SELECTION_OPTIONS[0]);
  const [exactNumberValue, setExactNumberValue] = useState<string>();

  const dispatch = useAppDispatch();
  const { newQuestionPayload } = useAppSelector((state: RootState) => state.questionsBank);
  const selectedChoices = newQuestionPayload.choices ?? [];

  const questionAllowsMultiChoice = getNewQuestionConfig(QuestionConfigType.ALLOW_MULTI_CHOICE, newQuestionPayload);
  const maxSelectionValue = getNewQuestionConfig(QuestionConfigType.MAX_SELECTION, newQuestionPayload)?.config_value;
  const minSelectionValue = getNewQuestionConfig(QuestionConfigType.MIN_SELECTION, newQuestionPayload)?.config_value;
  const isOtherOptionToggled = !!getNewQuestionConfig(QuestionConfigType.DISPLAY_OTHER, newQuestionPayload);
  const questionIsRequired = getNewQuestionConfig(QuestionConfigType.REQUIRED, newQuestionPayload);

  const setEqualMaxAndMinSelectionValue = (value: string) => {
    const newQuestionPayloadClone = { ...newQuestionPayload };
    const maxSelectionConfigs = configSetter(newQuestionPayloadClone, QuestionConfigType.MAX_SELECTION, value);
    newQuestionPayloadClone.configs = maxSelectionConfigs;
    const minSelectionConfigs = configSetter(newQuestionPayloadClone, QuestionConfigType.MIN_SELECTION, value);
    dispatch(setNewQuestionConfigs(minSelectionConfigs));
  };

  const removeMaxAndMinSelectionValues = () => {
    const newQuestionPayloadClone = { ...newQuestionPayload };
    const maxSelectionConfigs = configSetter(newQuestionPayloadClone, QuestionConfigType.MAX_SELECTION, '');
    newQuestionPayloadClone.configs = maxSelectionConfigs;
    const minSelectionConfigs = configSetter(newQuestionPayloadClone, QuestionConfigType.MIN_SELECTION, '');
    dispatch(setNewQuestionConfigs(minSelectionConfigs));
  };

  const handleOnChangeMinMaxSelectionOption = (newSelectOption: SelectOption) => {
    switch (newSelectOption) {
      case MIN_MAX_SELECTION_OPTIONS[0]:
        removeMaxAndMinSelectionValues();
        break;
      case MIN_MAX_SELECTION_OPTIONS[1]:
        if (exactNumberValue) {
          setExactNumberValue(exactNumberValue);
        }
        break;
      case MIN_MAX_SELECTION_OPTIONS[2]:
        break;
      default:
        break;
    }

    setMinMaxSelectionOption(newSelectOption);
  };

  const onChangeMaxSelectionValue = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(
      setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.MAX_SELECTION, event.target.value)),
    );
  const onChangeMinSelectionValue = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(
      setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.MIN_SELECTION, event.target.value)),
    );
  const onChangeExactNumber = (event: ChangeEvent<HTMLInputElement>) => {
    // When setting the exact number, the `min_selection` and `max_selection`
    // values should be equal.
    setExactNumberValue(event.target.value);
    setEqualMaxAndMinSelectionValue(event.target.value);
  };

  const toggleQuestionAllowMultiChoice = (isChecked: boolean) =>
    dispatch(
      setNewQuestionConfigs(
        configSetter(newQuestionPayload, QuestionConfigType.ALLOW_MULTI_CHOICE, isChecked ? '1' : ''),
      ),
    );

  const isMultipleChoice = !!questionAllowsMultiChoice?.config_value;
  const selectedChoicesCount = isOtherOptionToggled ? selectedChoices.length + 1 : selectedChoices.length;
  const minInputNumber = questionIsRequired ? '1' : '0';
  const maxInputNumber = selectedChoicesCount === 0 ? minInputNumber : selectedChoicesCount;

  const commonNumberInputProps = {
    type: 'number',
    step: '1',
    min: minInputNumber,
    max: maxInputNumber,
    className: styles.rangeInput,
    placeholder: `${minInputNumber} - ${maxInputNumber}`,
  };
  return (
    <>
      <CheckboxAsSwitch
        inputId="new-question-allow-multi-choice"
        labelText={translate('questionConfig.multipleSelection')}
        isChecked={isMultipleChoice}
        onCheckHandler={toggleQuestionAllowMultiChoice}
      />
      {isMultipleChoice && (
        <div className={styles.minMaxSelectionContainer}>
          <div className={styles.minMaxSelectionDropdown}>
            <ZCDSelect
              options={MIN_MAX_SELECTION_OPTIONS}
              value={minMaxSelectionOption}
              onChange={(newValue) => handleOnChangeMinMaxSelectionOption(newValue as SelectOption)}
            />
          </div>
          {minMaxSelectionOption === MIN_MAX_SELECTION_OPTIONS[2] && (
            <div className={styles.numberRangeWrapper}>
              <input {...commonNumberInputProps} value={minSelectionValue} onChange={onChangeMinSelectionValue} />
              <span>{translate('common.to')}</span>
              <input
                {...commonNumberInputProps}
                min={minSelectionValue}
                value={maxSelectionValue}
                onChange={onChangeMaxSelectionValue}
                required={!!minSelectionValue}
              />
            </div>
          )}
          {minMaxSelectionOption === MIN_MAX_SELECTION_OPTIONS[1] && (
            <input {...commonNumberInputProps} value={exactNumberValue} onChange={onChangeExactNumber} />
          )}
        </div>
      )}
    </>
  );
};
