import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';

interface LogicActionConditionVariable {
  type: string;
  value: string;
}

/**
 * Note: Currently, this condition interface is only allowing for
 * conditions of a single select, multi choice question.
 */
interface LogicActionCondition {
  op: string;
  vars: [LogicActionConditionVariable, LogicActionConditionVariable];
}

export interface LogicAction {
  id: number;
  origin_question: number;
  destination_question: number | null;
  destination_end_screen: number | null;
  destination_type: string;
  action_type: string;
  condition: LogicActionCondition;
}

export interface LogicActionItem {
  id: string | number;
  originQuestionId: number;
  questionChoiceOption?: SelectOption;
  destinationOption?: SelectOption;
  deleted: boolean;
}

export enum ActionType {
  JUMP = 'jump',
}

export enum DestinationType {
  ENDING_SCREEN = 'ending_screen',
  QUESTION = 'question',
}

export enum OperationType {
  IS = 'is',
}

export enum VarType {
  FIELD = 'field',
  CHOICE = 'choice',
}

export interface LogicActionPayloadItem extends Omit<LogicAction, 'id'> {
  id?: number;
  deleted: boolean;
}
