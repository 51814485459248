/* eslint-disable max-statements, max-lines */
import { ParsedRakeWeighterExecutions, parseRakeWeighterExecutions } from 'utils/rakeWeighterExecutions';
import { fetchSurveyGroups } from 'services/surveyGroup';
import { SurveyGroup, SurveyType } from '@zencity/survey-types';
import { logger } from 'utils/logger';
import { fetchRakeWeighterExecutions } from 'services/communitySurveyReport/rakeWighterExecutions';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { fetchBenchmarkGenericQuestionScores, getBenchmarkOverallSatisfactionData } from 'services/benchmarkScores';
import { LeanClient } from 'types/client';
import { zcdNotify } from '@zencity/common-ui';
import { getAnswersLeadingSubTopicsData } from '../helpers/answersLeadingSubTopics';
import { getAnswersChoicesQuestionSummary } from '../helpers/answersChoicesQuestionSummary';
import { getAnswersWordCloudData } from '../helpers/answersWordCloud';
import { getAreasScoreData } from '../helpers/areasScore';
import { getCrossTabsData } from '../helpers/crossTabs/crossTabs';
import { getDemographicScoreByGroups, getDemographicsScoreData } from '../helpers/demographicsScore';
import { fetchQuestionnaireData } from '../helpers/fetchQuestionnaireData';
import { fetchSentimentBarOverallData } from '../helpers/fetchSentimentBarOverallData';
import { getOverallSatisfcationWidgetData, getOverallScoreData } from '../helpers/overallScore';
import { getQuestionScoreData, getSatisfactionBarChartData } from '../helpers/questionsScore';
import { AspectValue, RawWidgetData, SelectedDateRange, SurveyCycle, WidgetType } from './misc';

export function getGenericObjectKeys<T extends Record<string, unknown>>(myObj: T): (keyof T)[] {
  return Object.keys(myObj) as (keyof T)[];
}

export function getGenericObjectValue<T extends Record<string, unknown>>(myObj: T): T[keyof T][] {
  return Object.values(myObj) as T[keyof T][];
}

interface FetchDataArguments {
  widgetType: WidgetType;
  client: LeanClient;
  cycles: SurveyCycle[];
  selectedDateRange: SelectedDateRange;
  questionId?: number;
  crossTabQuestionSelectedOptions?: SelectOption[];
  demographicGeographic?: string[];
  surveyGroupId?: number;
  surveyId?: number;
  selectedAspectIds?: number[];
  selectedBenchmarkClient?: LeanClient;
}

// eslint-disable-next-line max-lines-per-function
export const fetchDataByWidgetType = async (params: FetchDataArguments): Promise<RawWidgetData> => {
  const {
    widgetType,
    client,
    cycles,
    questionId,
    crossTabQuestionSelectedOptions,
    demographicGeographic,
    surveyGroupId,
    selectedAspectIds,
    selectedDateRange,
    surveyId,
    selectedBenchmarkClient,
  } = params;

  let fetchedData: RawWidgetData;
  switch (widgetType) {
    case WidgetType.SERVICES_BAR_CHART:
      fetchedData = await getSatisfactionBarChartData(client, cycles, selectedDateRange);
      break;
    case WidgetType.MAINTAIN_AND_FOCUS_SERVICES:
      {
        const response = await getSatisfactionBarChartData(client, cycles, selectedDateRange);
        fetchedData = response.filter((question) => question.aspectValue === AspectValue.CHARACTERISTIC);
      }
      break;
    case WidgetType.WORD_CLOUD:
      fetchedData = await getAnswersWordCloudData(client, cycles, selectedDateRange);
      break;
    case WidgetType.TOPICS_BUBBLE_CHART:
      fetchedData = await getAnswersLeadingSubTopicsData(client, cycles, selectedDateRange);
      break;
    case WidgetType.OVERALL_SATISFACTION_TEXT:
      fetchedData = await getOverallSatisfcationWidgetData(client, cycles, selectedDateRange);
      break;
    case WidgetType.OVERALL_BAR_CHART:
      fetchedData = await getOverallScoreData(client, cycles, selectedDateRange);
      break;
    case WidgetType.DEMOGRAPHICS:
      fetchedData = await getDemographicsScoreData(client, cycles, selectedDateRange);
      break;
    case WidgetType.SENTIMENT_BAR_AREAS_TABLE:
      fetchedData = await getAreasScoreData(client, cycles, selectedDateRange);
      break;
    case WidgetType.SERVICE_SENTIMENT_BAR:
      fetchedData = await getQuestionScoreData(client, cycles, selectedDateRange, questionId);
      break;
    case WidgetType.SENTIMENT_BAR:
      fetchedData = await getSatisfactionBarChartData(client, cycles, selectedDateRange);
      break;
    case WidgetType.SENTIMENT_BAR_GROUPS:
      fetchedData = await getDemographicScoreByGroups(client, cycles, selectedDateRange);
      break;
    case WidgetType.SENTIMENT_BAR_OVERALL_TABLE:
      fetchedData = await fetchSentimentBarOverallData({
        client,
        cycles,
        selectedDateRange,
      });
      break;
    case WidgetType.CHOICES_QUESTION_CHART:
      fetchedData = await getAnswersChoicesQuestionSummary({
        client,
        cycles,
        questionId,
        surveyGroupId,
        selectedDateRange,
      });
      break;
    case WidgetType.QUESTIONNAIRE:
      fetchedData = await fetchQuestionnaireData({
        client,
        surveyId,
        aspectIds: selectedAspectIds,
      });
      break;
    case WidgetType.CROSS_TABS:
      fetchedData = await getCrossTabsData({
        client,
        cycles,
        selectedDateRange,
        selectedBaseGenericQuestionId: questionId,
        crossQuestionSelectedOptions: crossTabQuestionSelectedOptions,
        demographicGeographic,
        surveyGroupId,
      });
      break;
    case WidgetType.BENCHMARK_GENERIC_QUESTIONS_TABLE:
      if (!selectedBenchmarkClient) {
        zcdNotify.error('Please select a benchmark client.');
        return [];
      }
      fetchedData = await fetchBenchmarkGenericQuestionScores(selectedBenchmarkClient, selectedDateRange);
      break;
    case WidgetType.BENCHMARK_OVERALL_SATISFACTION_TEXT:
      if (!selectedBenchmarkClient) {
        zcdNotify.error('Please select a benchmark client.');
        return [];
      }
      fetchedData = await getBenchmarkOverallSatisfactionData(selectedBenchmarkClient, selectedDateRange);
      break;
    default:
      fetchedData = [''];
  }
  return fetchedData;
};
export interface CyclesData {
  parsedRakeWeighterExecutions: ParsedRakeWeighterExecutions[];
  clientSurveyCycles: SurveyCycle[];
}
const fetchClientCycles = async (surveyGroup: SurveyGroup): Promise<CyclesData> => {
  try {
    if (surveyGroup) {
      const rakeWeighterExecutions = await fetchRakeWeighterExecutions({ survey_group: surveyGroup.id });
      const clientSurveyCycles = rakeWeighterExecutions.reduce<SurveyCycle[]>((surveyCycles, rakeWeighterExecution) => {
        if (!rakeWeighterExecution.display_on_dashboard) {
          return surveyCycles;
        }
        surveyCycles.push({
          startDate: rakeWeighterExecution.start_date,
          endDate: rakeWeighterExecution.end_date,
          displayOnDashboard: rakeWeighterExecution.display_on_dashboard,
        });
        return surveyCycles;
      }, []);
      const displayedRakeWeighterExecutions = rakeWeighterExecutions.filter(
        (rakeWeighterExecution) => rakeWeighterExecution.display_on_dashboard,
      );
      const parsedRakeWeighterExecutions = parseRakeWeighterExecutions(displayedRakeWeighterExecutions);
      if (parsedRakeWeighterExecutions.length && clientSurveyCycles.length) {
        return { parsedRakeWeighterExecutions, clientSurveyCycles };
      }
    }
  } catch (error) {
    logger.error(error);
  }
  return { parsedRakeWeighterExecutions: [], clientSurveyCycles: [] };
};

export interface SurveysData {
  client: LeanClient;
  surveyGroup: SurveyGroup;
  parsedRakeWeighterExecutions: ParsedRakeWeighterExecutions[];
  clientSurveyCycles: SurveyCycle[];
}
export const getSurveysDataByClientId = async (zcClientId: string): Promise<SurveysData> => {
  const communitySurveySurveyGroups = await fetchSurveyGroups({
    zc_client_id: zcClientId,
    survey_type: SurveyType.COMMUNITY_SURVEY,
  });
  if (communitySurveySurveyGroups.length !== 1) {
    throw new Error('Client should only have one `SurveyGroup` for Survey Type `Community Survey`');
  }
  const surveyGroup = communitySurveySurveyGroups[0];
  if (!surveyGroup) {
    throw new Error('Client should have a `SurveyGroup`');
  }
  const { parsedRakeWeighterExecutions, clientSurveyCycles } = await fetchClientCycles(surveyGroup);
  if (communitySurveySurveyGroups.length !== 1) {
    throw new Error('Client should only have one `SurveyGroup` for Survey Type `Community Survey`');
  }
  const { client } = surveyGroup;
  const leanClient: LeanClient = {
    id: client.id,
    name: client.name,
    zc_client_id: client.zc_client_id,
    state: client.state || undefined,
  };
  return { client: leanClient, surveyGroup, parsedRakeWeighterExecutions, clientSurveyCycles };
};
