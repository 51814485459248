import { ZCDSpinner } from '@zencity/common-ui';
import { SurveyManagerToastContextProvider } from 'contexts/SurveyManagerToastContext';
import { useValidateAuthorization } from 'customHooks/useValidateAuthorization';
import React from 'react';
import { Provider } from 'react-redux';
import { UnauthorizedUser403Screen } from 'screens/UnauthorizedUser403Screen/UnauthorizedUser403Screen';
import { VolumeTracker } from 'screens/VolumeTracker/VolumeTracker';
import store from 'store';
import { User } from 'types/user';
import './i18n';
import './index.scss';

interface Props {
  user: User;
}

const VolumeTrackerAppEntry: React.FC<Props> = (props: Props) => {
  const { user } = props;

  const { globalConfigs, isUserAuthorized, checkingForUserAuthorization } = useValidateAuthorization(user);
  if (checkingForUserAuthorization) {
    return <ZCDSpinner />;
  }

  if (globalConfigs && isUserAuthorized) {
    return (
      <Provider store={store}>
        <SurveyManagerToastContextProvider>
          <VolumeTracker volumeTrackerUrl={globalConfigs.volumeTrackerUrl} />
        </SurveyManagerToastContextProvider>
      </Provider>
    );
  }

  return <UnauthorizedUser403Screen />;
};

export default VolumeTrackerAppEntry;
