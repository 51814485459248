import { getCyclesStartingFromSelectedCycle } from 'screens/WidgetGenerator/utils/cycles';
import { fetchAnswersLeadingSubTopics } from 'services/communitySurveyReport/answersLeadingSubTopics';
import { LeanClient } from 'types/client';
import { SurveyType } from 'types/survey';
import { logger } from 'utils/logger';
import { config } from '../../../../config/config';
import { ClassificationSubTopicResponses, SelectedDateRange, subTopicToExclude, SurveyCycle } from '../utils/misc';

const getThreeLeadingSubTopicsData = (leadingSubTopics: ClassificationSubTopicResponses[]) =>
  leadingSubTopics.filter((subTopic) => !subTopicToExclude.includes(subTopic.classificationSubTopicValue)).splice(0, 3);

export const getAnswersLeadingSubTopicsData = async (
  client: LeanClient,
  cycles: SurveyCycle[],
  selectedDateRange: SelectedDateRange,
): Promise<ClassificationSubTopicResponses[]> => {
  const filteredCycles = getCyclesStartingFromSelectedCycle(selectedDateRange, cycles);
  const lastCycleIndex = 0;
  try {
    const positiveQuestionSubTopics = await fetchAnswersLeadingSubTopics(
      {
        survey_type: SurveyType.COMMUNITY_SURVEY,
        // Generic question ID for question
        // What is your favorite thing about living in %client_name%?
        generic_question: config.communitySurvey.PositiveSentimentGenericQuestionId,
        start_date: filteredCycles[lastCycleIndex].startDate,
        end_date: filteredCycles[lastCycleIndex].endDate,
      },
      client,
    );
    const negativeQuestionSubTopics = await fetchAnswersLeadingSubTopics(
      {
        survey_type: SurveyType.COMMUNITY_SURVEY,
        // Generic question ID for question
        // What is the thing you would most want to change about life in %client_name%?
        generic_question: config.communitySurvey.NegativeSentimentGenericQuestionId,
        start_date: filteredCycles[lastCycleIndex].startDate,
        end_date: filteredCycles[lastCycleIndex].endDate,
      },
      client,
    );
    return [
      ...getThreeLeadingSubTopicsData(positiveQuestionSubTopics),
      ...getThreeLeadingSubTopicsData(negativeQuestionSubTopics),
    ];
  } catch (error) {
    logger.error(error);
    return [];
  }
};
