const { merge } = require('webpack-merge');
import common from './common';
import development from './development';
import testing from './testing';
import staging from './staging';
import production from './production';

interface Config {
  environment: string;
  apiBaseUrl: string;
  vaultBaseUrl: string;
  insights: {
    domain: string;
  };
  communitySurvey: {
    PositiveSentimentGenericQuestionId: number;
    NegativeSentimentGenericQuestionId: number;
    CharacteristicsAspectId: number;
  };
  surveyManager: {
    baseRouterUrl: string;
  };
}

let config: Config;

switch (process.env.NODE_ENV) {
  case 'development':
    config = merge(common, development) as Config;
    break;
  case 'testing':
    config = merge(common, testing) as Config;
    break;
  case 'staging':
    config = merge(common, staging) as Config;
    break;
  case 'production':
    config = merge(common, production) as Config;
    break;
  default:
    throw new Error(`invalid environment provided - "${process.env.NODE_ENV}"`);
}

// Replace Vault's base URL if provided in `.env` file.
if (process.env.VAULT_BASE_URL) {
  config.vaultBaseUrl = process.env.VAULT_BASE_URL;
}

export { config };
