import { Translations } from 'types/misc';

export interface QuestionConfigForNewQuestion {
  config_name: QuestionConfigType;
  config_value: string;
}

export interface QuestionConfigBase extends QuestionConfigForNewQuestion {
  id: number;
  translations?: {
    config_value: Translations;
  };
}

export interface QuestionConfig extends QuestionConfigBase {
  question: number;
}

export enum QuestionConfigType {
  DESCRIPTION = 'description',
  STEPS = 'steps',
  ALLOW_MULTI_CHOICE = 'allow_multi_choice',
  RANDOMIZE = 'randomize',
  DISPLAY_OTHER = 'display_other',
  REQUIRED = 'required',
  SCALE_LABEL_FIRST = 'scale_label_first',
  SCALE_LABEL_MIDDLE = 'scale_label_middle',
  SCALE_LABEL_LAST = 'scale_label_last',
  DEMOGRAPHIC = 'demographic',
  MIN_VALUE = 'min_value',
  MAX_VALUE = 'max_value',
  VERTICAL_ALIGNMENT = 'vertical_alignment',
  BUTTON_TEXT = 'button_text',
  SHOW_BUTTON = 'show_button',
  ALPHABETICAL_ORDER = 'alphabetical_order',
  MAX_SELECTION = 'max_selection',
  MIN_SELECTION = 'min_selection',
  IMAGE_URL = 'image_url',
  START_AT_ONE = 'start_at_one',
  DISPLAY_ON_LIVE_FEED = 'display_on_live_feed',
  HIDE_FROM_LIVE_FEED = 'hide_from_live_feed',
}
