import { ZCDButton } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ConfirmationDialogFooter.module.scss';

interface Props {
  isDisabled: boolean;
  handleOnConfirm: () => void;
  handleOnCancel: () => void;
  cancelText?: string;
  confirmText?: string;
}

/**
 * Generic footer for a confirmation dialog.
 */
export const ConfirmationDialogFooter = (props: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const {
    isDisabled,
    handleOnConfirm,
    handleOnCancel,
    cancelText = translate('common.cancel'),
    confirmText = translate('common.confirm'),
  } = props;
  return (
    <div className={styles.confirmationDialogFooter}>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <ZCDButton variant="link" disabled={isDisabled} text={cancelText!} onClick={handleOnCancel} />
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <ZCDButton variant="primary" disabled={isDisabled} text={confirmText!} onClick={handleOnConfirm} />
    </div>
  );
};
