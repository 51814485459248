import { ZCDIconButton } from '@zencity/common-ui';
import React, { ReactElement } from 'react';

interface Props {
  closeCallback: () => void;
}

export const RemoveButton = ({ closeCallback }: Props): ReactElement => (
  <ZCDIconButton onClick={closeCallback} icon="close-medium" variant="menu" />
);
