import { createAsyncThunk } from '@reduxjs/toolkit';
import { SurveyGroup } from '@zencity/survey-types';
import { LeanClient } from 'types/client';
import { PaginatedResults } from 'types/misc';
import { SurveyType, Survey } from 'types/survey';
import { SurveyGroupQuestionScore } from 'types/surveyGroup';
import { generateVaultApiUrl, createAxiosInstance } from 'utils/Api';
import { exportDataToFile, FileTypes } from './communitySurveyReport/util';

const apiUrl = generateVaultApiUrl('/api/v2/survey_group/');
const axiosInstance = createAxiosInstance(apiUrl);

/**
 * Edit a translated survey group title field.
 */
export const updateSurveyGroupTitleForDisplay = createAsyncThunk(
  'surveys/updateSurveyGroupTitle',
  async (payload: {
    survey: Survey;
    language: string;
    translatedTitleForDisplay: string;
    defaultLanguage: string;
  }): Promise<{ language: string; surveyId: number; translatedText: string }> => {
    const { survey, language, translatedTitleForDisplay, defaultLanguage } = payload;
    const isDefaultLanguage = language === defaultLanguage;
    const params = {
      bypass_client_filter: true,
      ...(!isDefaultLanguage && { translations_languages: language }),
    };
    const requestPayload = isDefaultLanguage
      ? { title_for_display: translatedTitleForDisplay }
      : {
          [`title_for_display_${language}`]: translatedTitleForDisplay,
        };
    await axiosInstance.patch(`${survey.survey_group.id}/`, requestPayload, {
      params,
    });
    return { language, surveyId: survey.id, translatedText: translatedTitleForDisplay };
  },
);

interface UpdateSurveyGroupCadencePayload {
  survey: Survey;
  cadence: string;
}

export const updateSurveyGroupCadence = createAsyncThunk(
  'surveys/updateSurveyGroupCadence',
  async (payload: UpdateSurveyGroupCadencePayload): Promise<{ surveyId: number; cadence: string }> => {
    const { survey, cadence } = payload;
    const { survey_group: surveyGroup } = survey;
    await axiosInstance.patch(
      `${surveyGroup.id}/`,
      {
        cadence,
      },
      { params: { bypass_client_filter: true } },
    );
    return { surveyId: survey.id, cadence };
  },
);

/**
 * Fetch a list of survey groups by a client ID and survey type.
 */
export const fetchSurveyGroupsByClientAndSurveyType = async (
  clientId: number,
  surveyType: SurveyType,
): Promise<PaginatedResults<SurveyGroup>> => {
  const response = await axiosInstance.get('', {
    params: {
      client_id: clientId,
      survey_type: surveyType,
      bypass_client_filter: true,
    },
  });
  return response.data;
};

/**
 * Fetch a client's survey groups by type.
 */
export async function fetchSurveyGroups(params: {
  client_id?: number;
  survey_type?: string;
  zc_client_id?: string;
}): Promise<SurveyGroup[]> {
  const response = await axiosInstance.get<PaginatedResults<SurveyGroup>>('', {
    params: {
      ...params,
      bypass_client_filter: true,
    },
  });

  return response.data.results;
}

interface ExportCrossTabParams {
  start_date: string;
  end_date: string;
  include_ignored_questions: boolean;
}

/**
 * Export cross question by survey group to csv file.
 */
export async function exportCrossTabBySurveyGroup(
  survey_group: number,
  client: LeanClient,
  params: ExportCrossTabParams,
): Promise<number> {
  const result = await axiosInstance.get(`${survey_group}/export_cross_question_table/`, {
    params,
    responseType: 'blob',
  });

  exportDataToFile(FileTypes.CSV, result.data, `Cross_Tab_${client.name}_${params.start_date}_${params.end_date}`);

  return result.status;
}

interface SurveyGroupTotalSubmissionsRequestParams {
  surveyGroupId: number;
  startDate: string;
  endDate: string;
}

interface SurveyGroupTotalSubmissionsResponse {
  total_submissions: number;
  total_valid_submissions: number;
}

export const fetchTotalSubmissionsBySurveyGroup = async (
  params: SurveyGroupTotalSubmissionsRequestParams,
): Promise<SurveyGroupTotalSubmissionsResponse> => {
  const { surveyGroupId, startDate, endDate } = params;
  const response = await axiosInstance.get(`${surveyGroupId}/total_submissions/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
  return response.data;
};

interface QuestionScoresResponse {
  total_submissions: number;
  question_scores: SurveyGroupQuestionScore[];
}

export const fetchSurveyGroupQuestionScores = async (
  surveyGroupId: number,
  startDate: string,
  endDate: string,
): Promise<QuestionScoresResponse> => {
  const response = await axiosInstance.get<QuestionScoresResponse>(`${surveyGroupId}/question_scores/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
  return response.data;
};
