/* eslint-disable complexity,max-statements */
import { Icon } from '@zencity/common-ui';
import { BreadcrumbToolbar } from 'components/BreadcrumbToolbar/BreadcrumbToolbar';
import { BuildForm, BuildFormScreen } from 'components/BuildForm/BuildForm';
import { BuildFormAutoSaveIndication } from 'components/BuildFormAutoSaveIndication/BuildFormAutoSaveIndication';
import { EmptyBuildState } from 'components/EmptyBuildState/EmptyBuildState';
import { Header } from 'components/Header/Header';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import {
  useAppSelector,
  useCurrentGenericQuestions,
  useCurrentSurvey,
  useCurrentSurveyEndScreens,
  useCurrentSurveyQuestions,
} from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouterPath, SurveyChildPath } from 'routerPaths';
import { RootState } from 'store';
import { LocationState } from 'types/misc';
import { logger } from 'utils/logger';
import { isNumeric, statusIsLoading, statusIsSuccessful } from 'utils/misc';
import styles from './SurveyBuildScreen.module.scss';

/**
 * This is the screen where a user will build and create the surveys questions.
 */
export const SurveyBuildScreen = (): ReactElement => {
  const { toastError } = useContext(SurveyManagerToastContext);
  const { questions, fetchStatus: questionsFetchStatus } = useAppSelector((state: RootState) => state.questions);
  const { surveys } = useAppSelector((state: RootState) => state.surveys);
  const { t: translate } = useTranslation();
  const { state } = useLocation();
  const locationState = state as LocationState;
  const navigate = useNavigate();

  const getPreviousLink = () => locationState?.previousPath || RouterPath.MAIN;

  const { surveyId = '' } = useParams();
  if (!isNumeric(surveyId)) {
    throw new Error(`Survey ID is not a number: ${surveyId}`);
  }
  const surveyIdAsNumber = parseInt(surveyId);

  const surveysError = useCurrentSurvey(surveyIdAsNumber);
  const questionsError = useCurrentSurveyQuestions(surveyIdAsNumber);
  const [loadingGenericQuestions, genericQuestionsError] = useCurrentGenericQuestions(questions);
  const endScreensError = useCurrentSurveyEndScreens(surveyIdAsNumber);

  useEffect(() => {
    if (surveysError) {
      toastError();
      logger.error(surveysError.message);
    } else if (questionsError) {
      toastError();
      logger.error(questionsError);
    } else if (genericQuestionsError) {
      toastError();
      logger.error(genericQuestionsError);
    } else if (endScreensError) {
      toastError();
      logger.error(endScreensError);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsError, surveysError, genericQuestionsError, endScreensError]);

  const handleOnDoneClick = () => {
    const surveManagePath = generatePath(`${RouterPath.SURVEY}/${SurveyChildPath.MANAGE}`, {
      surveyId,
    });
    navigate(surveManagePath);
  };

  const surveyIsLocked = surveys[surveyIdAsNumber]?.is_locked;

  return (
    <>
      <Header titleText={surveys[surveyIdAsNumber]?.survey_group.title_for_display} />
      <BreadcrumbToolbar
        previousText={translate('common.previous')}
        nextText={questions.length === 0 ? translate('common.next') : translate('common.done')}
        previousLink={getPreviousLink()}
        surveyId={surveyId}
        nextButtonOnClick={handleOnDoneClick}
        nextButtonDisabled={questions.length === 0}
      />
      <LoaderMask isLoading={statusIsLoading(questionsFetchStatus) || loadingGenericQuestions}>
        <div className={styles.surveyBuildScreen}>
          {statusIsSuccessful(questionsFetchStatus) && questions.length === 0 && <EmptyBuildState />}
          {statusIsSuccessful(questionsFetchStatus) && questions.length > 0 && (
            <>
              {/* Naive message for when a survey is locked. */}
              {surveyIsLocked && (
                <div className={styles.lockedSurvey}>
                  <Icon name="lock.svg" width={24} height={24} />
                  <p>The survey already contains submissions, and cannot be edited.</p>
                </div>
              )}
              <BuildFormAutoSaveIndication />
              <BuildForm screen={BuildFormScreen.BUILD} />
            </>
          )}
        </div>
      </LoaderMask>
    </>
  );
};
