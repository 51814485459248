/* eslint-disable max-lines-per-function,max-statements */
import { ZCDButton, ZCDCard, ZCDIconColor } from '@zencity/common-ui';
import { CloseSurveyDialog } from 'components/CloseSurveyDialog/CloseSurveyDialog';
import { useOutsideClickListener } from 'customHooks/hooks';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { RouterPath, SurveyChildPath } from 'routerPaths';
import { ActionItemProps, ActionItem } from './ActionItem';
import styles from './ManageDropdownButton.module.scss';

enum ActionItemKey {
  CLONE = 'clone-action-item',
  CLOSE = 'end-survey-action-item',
  EDIT = 'edit-action-item',
}

interface Props {
  isLocked: boolean;
  isPublished: boolean;
  surveyId: string;
}

/**
 * Displayed on the Manage screen, this button will display a list of actions for managing a survey.
 */
export const ManageDropdownButton = (props: Props): ReactElement => {
  const { isLocked, isPublished, surveyId } = props;
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [shouldShowDropdown, setShouldShowDropdown] = useState(false);
  const [actionItems, setActionItems] = useState<ReactElement<ActionItemProps>[]>([]);
  const [isCloseSurveyDialogOpen, setIsCloseSurveyDialogOpen] = useState(false);

  const closeDropdown = () => {
    setShouldShowDropdown(false);
  };
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClickListener(wrapperRef, closeDropdown);

  const toggleDropdown = () => {
    setShouldShowDropdown(!shouldShowDropdown);
  };

  const handleOnManageButtonClick = () => {
    toggleDropdown();
  };

  const handleOnEditClick = () => {
    const surveysBuildPath = generatePath(`${RouterPath.SURVEY}/${SurveyChildPath.BUILD}`, {
      surveyId,
    });
    const surveysManagePath = generatePath(`${RouterPath.SURVEY}/${SurveyChildPath.MANAGE}`, {
      surveyId,
    });

    navigate(surveysBuildPath, {
      state: {
        previousPath: surveysManagePath,
      },
    });
  };

  const handleOnCloneClick = () => {
    const surveysCloneScreenPath = generatePath(`${RouterPath.SURVEY}/${SurveyChildPath.CLONE}`, {
      surveyId,
    });

    navigate(surveysCloneScreenPath);
  };

  const handleOnCloseSurveyClick = () => {
    setIsCloseSurveyDialogOpen(true);
  };

  const invokeCallbackAndCloseDropdown = (callbackToInvoke: () => void) => () => {
    callbackToInvoke();
    closeDropdown();
  };

  useEffect(() => {
    const manageActionItems: ReactElement<ActionItemProps>[] = [];

    // Edit Action Item.
    if (!isLocked) {
      const editActionItem = (
        <ActionItem
          key={ActionItemKey.EDIT}
          iconName="edit"
          text={translate('common.edit')}
          onClick={invokeCallbackAndCloseDropdown(handleOnEditClick)}
        />
      );
      manageActionItems.push(editActionItem);
    }

    // Always add the "Clone" button for all surveys.
    const cloneActionItem = (
      <ActionItem
        key={ActionItemKey.CLONE}
        iconName="copy"
        text={translate('surveyManage.clone')}
        onClick={invokeCallbackAndCloseDropdown(handleOnCloneClick)}
      />
    );
    manageActionItems.push(cloneActionItem);

    // If a survey is published and active, it should be able to be closed.
    if (isPublished) {
      const endSurveyActionItem = (
        <ActionItem
          key={ActionItemKey.CLOSE}
          iconName="close-large"
          text={translate('surveyManage.endSurvey')}
          color={ZCDIconColor.RED}
          onClick={invokeCallbackAndCloseDropdown(handleOnCloseSurveyClick)}
        />
      );
      manageActionItems.push(endSurveyActionItem);
    }

    setActionItems(manageActionItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked, isPublished, surveyId]);

  return (
    <>
      <div className={styles.manageDropdownWrapper} ref={wrapperRef}>
        <div className={styles.manageButton}>
          <ZCDButton
            variant="secondary"
            onClick={handleOnManageButtonClick}
            postIcon="chevron-down"
            text={translate('surveyManage.manage')}
          />
        </div>
        {shouldShowDropdown && (
          <div className={styles.actionsDropdown}>
            <ZCDCard elevated>{actionItems.map((actionItem) => actionItem)}</ZCDCard>
          </div>
        )}
      </div>
      <CloseSurveyDialog surveyId={surveyId} isOpen={isCloseSurveyDialogOpen} setIsOpen={setIsCloseSurveyDialogOpen} />
    </>
  );
};
