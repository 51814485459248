import React, { useState } from 'react';
import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { useAsyncEffect } from 'customHooks/hooks';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from 'screens/WidgetGenerator/components/FieldTitle/FieldTitle';
import {
  ReportBuilderFilterProps,
  SurveysReportGeneratorFilterKey,
} from 'screens/WidgetGenerator/reportGeneratorComponents/constants';
import { fetchAspectsBySurvey } from 'services/aspect';

import { logger } from 'utils/logger';

export const getAspectsOptions = async (surveyId: number): Promise<SelectOption[]> => {
  const { results: fetchedAspects } = await fetchAspectsBySurvey({ survey: surveyId as number });
  const aspectsOptions = fetchedAspects.map((aspect) => ({
    value: aspect.id,
    label: aspect.label,
  }));
  return aspectsOptions;
};

export const AspectsFilter = (props: ReportBuilderFilterProps) => {
  const { value: filterValue, onChange, filterValues } = props;
  const { t: translate } = useTranslation();

  const selectedSurveyOption = filterValues?.[SurveysReportGeneratorFilterKey.QUESTIONNAIRE_SURVEY_SINGLE_SELECT] as
    | SelectOption
    | undefined;

  const [selectedAspects, setSelectedAspects] = useState<SelectOption[]>(filterValue as SelectOption[]);

  const getAspectsBySurvey = async () => {
    if (!selectedSurveyOption?.value) return [];
    const { value: surveyId } = selectedSurveyOption;
    const aspectsOptions = await getAspectsOptions(surveyId as number);

    return aspectsOptions;
  };
  const { fetchedData: aspectsOptions, isLoading, error } = useAsyncEffect(getAspectsBySurvey, [selectedSurveyOption]);

  if (error) {
    logger.error(error);
  }

  const handleOnSelectAspect = (selectedAspectOptions: unknown) => {
    const selectedOptions = selectedAspectOptions as SelectOption[];
    setSelectedAspects(selectedOptions);
    onChange(selectedOptions);
  };

  return (
    <LoaderMask isLoading={isLoading}>
      <FieldTitle title={translate('widgetGenerator.filters.questionnaire.selectAspect')} />
      <ZCDSelect
        isMulti
        placeholder={translate('widgetGenerator.filters.questionnaire.selectAspect')}
        options={aspectsOptions}
        onChange={handleOnSelectAspect}
        value={selectedAspects}
      />
    </LoaderMask>
  );
};
