import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { useAsyncEffect } from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { WidgetsConfigsContext } from 'screens/WidgetGenerator/contexts/WidgetsConfigsContext';
import { BenchmarkGenericQuestionScore } from 'screens/WidgetGenerator/utils/misc';
import { fetchAllClients } from 'services/client';
import { LeanClient } from 'types/client';
import { FieldTitle } from '../components/FieldTitle/FieldTitle';
import styles from './WidgetAdditionalFields.module.scss';

export const BenchmarkQuestionsAdditionalFields = (): ReactElement => {
  const { handleSelectBenchmarkClient, handleSelectBenchmarkAspect, rawData } = useContext(WidgetsConfigsContext);
  const [aspectOptions, setAspectOptions] = useState<SelectOption[]>([]);

  const benchmarkScores = rawData as BenchmarkGenericQuestionScore[] | undefined;

  useEffect(() => {
    if (benchmarkScores?.length) {
      const uniqueAspectValues = Array.from(new Set(benchmarkScores.map((score) => score.aspect_value)));
      const uniqueAspectOptions = uniqueAspectValues.map<SelectOption>((aspectValue) => ({
        value: aspectValue,
        label: aspectValue,
      }));
      setAspectOptions(uniqueAspectOptions);
    }
  }, [benchmarkScores]);

  const { fetchedData: clients, isLoading, error } = useAsyncEffect(fetchAllClients, []);
  if ((!clients || error) && !isLoading) {
    throw new Error(`Error fetching clients: ${error?.message}`);
  }

  const clientOptions = clients?.map<SelectOption>((client) => ({
    value: client.id,
    label: client.state ? `${client.name}, ${client.state}` : client.name,
  }));

  const clientsById = clients?.reduce<{
    [key: number]: LeanClient;
  }>(
    (clientsByIdObject, client) => ({
      ...clientsByIdObject,
      [client.id]: client,
    }),
    {},
  );

  const handleOnClientSelect = (selectedValue: unknown) => {
    const selectedClientOption = selectedValue as { value: number };
    const selectedClient = clientsById?.[selectedClientOption.value];
    if (!selectedClient) return;
    handleSelectBenchmarkClient(selectedClient);
  };

  const handleOnAspectSelect = (selectedValue: unknown) => {
    const selectedAspectOption = selectedValue as { value: string };
    handleSelectBenchmarkAspect(selectedAspectOption.value);
  };

  return (
    <>
      <div className={styles.fieldContainer}>
        <FieldTitle title="Choose the Client to get their Benchmark Question Scores:" />
        <ZCDSelect isLoading={isLoading} options={clientOptions} onChange={handleOnClientSelect} />
      </div>
      {aspectOptions.length !== 0 && (
        <div className={styles.fieldContainer}>
          <FieldTitle title="Choose an Aspect to display its Benchmark Question Scores:" />
          <ZCDSelect options={aspectOptions} onChange={handleOnAspectSelect} />
        </div>
      )}
    </>
  );
};
