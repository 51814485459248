import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { SurveyGroup } from '@zencity/survey-types';
import { CLIENT_TOKEN } from 'customHooks/useClientNameTokenReplacement/useClientNameTokenReplacement';
import { SelectedDateRange } from 'screens/Analysis/misc';
import { AspectValue } from 'screens/WidgetGenerator/utils/misc';
import { fetchGenericQuestionsByIds } from 'services/genericQuestion';
import { fetchGroupedQuestions } from 'services/questions';
import { QuestionType } from 'types/genericQuestion';

interface GenericQuestionsParams {
  aspect?: AspectValue;
  selectedDateRange?: SelectedDateRange;
  selectedSurveyGroup?: SurveyGroup;
  questionTypesToInclude?: QuestionType[];
}

/**
 * Returns the GenericQuestion options by fetching the grouped Questions,
 * then fetching the GenericQuestions by the ids of the grouped questions.
 * The GenericQuestions are filtered by the aspect and question type, and then
 * based off if they are scored or not.
 */
export const getGenericQuestionsData = async (params: GenericQuestionsParams): Promise<SelectOption[]> => {
  const { aspect, selectedDateRange, selectedSurveyGroup, questionTypesToInclude } = params;
  if (!selectedSurveyGroup) {
    return [];
  }
  const questionsResult = await fetchGroupedQuestions({
    survey_group: selectedSurveyGroup.id,
    start_date: selectedDateRange?.startDate,
    end_date: selectedDateRange?.endDate,
    hide_from_live_feed: false,
  });
  const genericQuestionIds = questionsResult.map((question) => question.generic_question);
  const fetchedGenericQuestions = await fetchGenericQuestionsByIds(genericQuestionIds);

  const genericQuestionSelectOptions: SelectOption[] = [];

  const genericQuestions = fetchedGenericQuestions.results.filter((genericQuestion) => {
    const questionTypeIncluded = questionTypesToInclude?.includes(genericQuestion.question_type) ?? true; // Use all question types if none are specified.
    const isScoredQuestion =
      genericQuestion.question_type === QuestionType.SCALE || genericQuestion.choices.some((choice) => !!choice.config);
    if (aspect) {
      return genericQuestion.aspect.value === aspect && questionTypeIncluded;
    }
    if (questionTypesToInclude) {
      // The question type is included takes precedence over using the scored questions.
      return questionTypeIncluded;
    }
    return isScoredQuestion;
  });

  genericQuestions.forEach((genericQuestion) => {
    genericQuestionSelectOptions.push({
      value: genericQuestion.id,
      label: genericQuestion.text.replace(CLIENT_TOKEN, selectedSurveyGroup.client.name),
    });
  });

  return genericQuestionSelectOptions;
};
