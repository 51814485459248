import { createAsyncThunk } from '@reduxjs/toolkit';
import { LeanClient } from 'types/client';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/api/clients/');
const axiosInstance = createAxiosInstance(apiUrl);

export const fetchAllClients = async (): Promise<LeanClient[]> => {
  const response = await axiosInstance.get<LeanClient[]>('', {
    params: {
      ordering: 'name',
    },
  });
  return response.data;
};

export const fetchClients = createAsyncThunk('survey/fetchClients', fetchAllClients);

export const fetchClientByZcClientId = async (zcClientId: string): Promise<LeanClient | undefined> => {
  const response = await axiosInstance.get<LeanClient[]>('', {
    params: {
      zc_client_id: zcClientId,
    },
  });
  return response.data[0] ?? undefined;
};
