import { ZCDRadioButton, ZCDSelect } from '@zencity/common-ui';
import { Spacer } from 'components/Spacer/Spacer';
import i18next from 'i18next';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionType } from 'types/genericQuestion';
import { FieldTitle } from '../components/FieldTitle/FieldTitle';
import { ChoicesQuestionChartDisplayOrder, ChoicesQuestionChartDisplayType } from '../utils/misc';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';
import { WidgetGeneratorContext } from '../contexts/WidgetGeneratorContext';
import { GenericQuestionSelection } from './utilsFunctionsAdditionalFields/GenericQuestionSelection/GenericQuestionSelection';
import styles from './WidgetAdditionalFields.module.scss';

const ORDER_OPTIONS = [
  {
    value: ChoicesQuestionChartDisplayOrder.APPEARANCE,
    label: i18next.t(
      `widgetGenerator.widgets.choicesQuestionChart.order.${ChoicesQuestionChartDisplayOrder.APPEARANCE}`,
    ),
  },
  {
    value: ChoicesQuestionChartDisplayOrder.POPULARITY,
    label: i18next.t(
      `widgetGenerator.widgets.choicesQuestionChart.order.${ChoicesQuestionChartDisplayOrder.POPULARITY}`,
    ),
  },
];

export const ChoicesQuestionChartAdditionalFields = (): React.ReactElement => {
  const {
    choicesQuestionChartDisplayOrder,
    choicesQuestionChartDisplayType,
    setChoicesQuestionChartDisplayType,
    setChoicesQuestionChartDisplayOrder,
  } = useContext(WidgetsConfigsContext);
  const { selectedSurveyGroup } = useContext(WidgetGeneratorContext);
  const { t: translate } = useTranslation();
  const orderValue = ORDER_OPTIONS.find(({ value }) => value === choicesQuestionChartDisplayOrder);

  const handleRadioButtonChange = (newValue: ChoicesQuestionChartDisplayType) => {
    setChoicesQuestionChartDisplayType(newValue);
  };

  const handleOrderChange = (newValue: unknown) => {
    const selected = newValue as { value: ChoicesQuestionChartDisplayOrder };
    setChoicesQuestionChartDisplayOrder(selected.value);
  };

  return (
    <div className={styles.fieldContainer}>
      <GenericQuestionSelection selectedSurveyGroup={selectedSurveyGroup} questionType={QuestionType.CHOICES} />
      <Spacer orientation="vertical" size="xsmall" />
      <>
        <FieldTitle title={translate(`widgetGenerator.widgets.choicesQuestionChart.order.title`)} />
        <ZCDSelect
          placeholder={translate(`widgetGenerator.widgets.choicesQuestionChart.order.title`)}
          options={ORDER_OPTIONS}
          onChange={handleOrderChange}
          value={orderValue}
        />
      </>
      <Spacer orientation="vertical" size="xsmall" />
      <ZCDRadioButton
        value={choicesQuestionChartDisplayType}
        checked={choicesQuestionChartDisplayType === ChoicesQuestionChartDisplayType.INTEGER}
        onSelect={() => handleRadioButtonChange(ChoicesQuestionChartDisplayType.INTEGER)}
        label={translate('widgetGenerator.widgets.choicesQuestionChart.integerLabel')}
      />
      <ZCDRadioButton
        value={choicesQuestionChartDisplayType}
        checked={choicesQuestionChartDisplayType === ChoicesQuestionChartDisplayType.PERCENTAGE}
        onSelect={() => handleRadioButtonChange(ChoicesQuestionChartDisplayType.PERCENTAGE)}
        label={translate('widgetGenerator.widgets.choicesQuestionChart.percentageLabel')}
      />
    </div>
  );
};
