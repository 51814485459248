import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';
import { GenericQuestion, GenericQuestionConfig, NewGenericQuestionPayload } from 'types/genericQuestion';
import { PaginatedResults, TranslationRequestPayload } from 'types/misc';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { DebounceDelay } from 'constants/misc';

const apiUrl = generateVaultApiUrl('/api/v2/generic_questions/');
const axiosInstance = createAxiosInstance(apiUrl);

const genericQuestionConfigApiUrl = generateVaultApiUrl('/api/v2/generic_question_configs/');
const genericQuestionConfigAxiosInstance = createAxiosInstance(genericQuestionConfigApiUrl);

interface GenericQuestionsParams {
  page?: number;
  text?: string;
  question_type?: string;
}

export const fetchGenericQuestionsByParams = async (
  params: GenericQuestionsParams,
): Promise<PaginatedResults<GenericQuestion>> => {
  const response = await axiosInstance.get('', {
    params: {
      ...params,
      // Always fetch non-deprecated instances so an editor won't be able to
      // pick one by mistake.
      deprecated: false,
    },
  });

  return response.data;
};

export const debouncedFetchGenericQuestionsByParams = AwesomeDebouncePromise(
  fetchGenericQuestionsByParams,
  DebounceDelay.TEXT_INPUT,
);

export const loadGenericQuestions = createAsyncThunk(
  'genericQuestions/fetchGenericQuestions',
  fetchGenericQuestionsByParams,
);

export const fetchGenericQuestionsByIds = async (
  genericQuestionIds: number[],
  translations_languages?: string,
): Promise<PaginatedResults<GenericQuestion>> => {
  // Convert the number list to a comma-separated string.
  const formattedGenericQuestionIds = genericQuestionIds.join(',');

  const response = await axiosInstance.get('', {
    params: {
      ids: formattedGenericQuestionIds,
      translations_languages,
      page_size: 60,
    },
  });
  return response.data;
};

/**
 * Add a new question to the survey and update the Questions state.
 */
export const addNewGenericQuestion = async (payload: NewGenericQuestionPayload): Promise<GenericQuestion> => {
  const response = await axiosInstance.post('', payload);
  return response.data;
};

/**
 * PATCH the given generic question with the given payload.
 */
export const updateGenericQuestion = async (
  genericQuestionData: NewGenericQuestionPayload,
): Promise<GenericQuestion> => {
  const { id: genericQuestionId, ...payload } = genericQuestionData;
  const response = await axiosInstance.patch(`${genericQuestionId}/?bypass_client_filter=true`, payload);

  return response.data;
};

/**
 * Update a generic question's text translation.
 */
export const updateGenericQuestionTextTranslation = createAsyncThunk(
  'genericQuestions/updateGenericQuestionTextTranslation',
  async (payload: TranslationRequestPayload): Promise<GenericQuestion> => {
    const { instanceId: genericQuestionId, language, translatedText } = payload;
    const response = await axiosInstance.patch(
      `${genericQuestionId}/`,
      {
        [`text_${language}`]: translatedText,
      },
      {
        params: {
          translations_languages: language,
          bypass_client_filter: true,
        },
      },
    );
    return response.data;
  },
);

export const deleteGenericQuestion = async (genericQuestionId: number): Promise<void> => {
  await axiosInstance.delete(`${genericQuestionId}/`);
};

/**
 * Update a generic question config's `config_value` translation.
 */
export const updateGenericQuestionConfigTranslation = createAsyncThunk(
  'genericQuestions/updateGenericQuestionConfigTranslation',
  async (payload: TranslationRequestPayload): Promise<GenericQuestionConfig> => {
    const { instanceId: GenericQuestionConfigId, language, translatedText } = payload;
    const response = await genericQuestionConfigAxiosInstance.patch(
      `${GenericQuestionConfigId}/`,
      {
        [`config_value_${language}`]: translatedText,
      },
      {
        params: {
          translations_languages: language,
          bypass_client_filter: true,
        },
      },
    );
    return response.data;
  },
);
