import { SentimentScore } from '@zencity/survey-types';
import classnames from 'classnames';
import { Flex } from 'components/Flex/Flex';
import { ScoreIndicator } from 'screens/WidgetGenerator/components/ScoreIndicator/ScoreIndicator';
import { DiffIndicator } from 'screens/WidgetGenerator/components/DiffIndicator/DiffIndicator';
import React from 'react';
import { BarChartWidgetType } from '../../utils/misc';
import { AdditionalScores } from './AdditionalScores';
import styles from './BarChart.module.scss';
import {
  HIGH_SATISFACTION_THRESHOLD,
  HIGH_SATISFACTION_THRESHOLD_SHORT_BAR,
  LOW_SATISFACTION_THRESHOLD,
  LOW_SATISFACTION_THRESHOLD_SHORT_BAR,
  LOW_SATISFACTION_THRESHOLD_SINGLE,
} from './barChartUtils';

interface BarChartProps {
  isLabeled?: boolean;
  label?: string;
  fillColor: string;
  isShortBarChart?: boolean;
  showSpan?: boolean;
  isBenchmarked?: boolean;
  benchmarkScore?: SentimentScore | number;
  widgetType: BarChartWidgetType.MULTIPLE | BarChartWidgetType.SINGLE;
  wideBars?: boolean;
}
export enum LabelColor {
  WHITE = 'white',
  BLACK = 'black',
}
interface BarChartSingle extends BarChartProps {
  widgetType: BarChartWidgetType.SINGLE;
  score: number;
  benchmarkScore?: number;
  labelColor?: LabelColor;
}

interface BarChartMultiple extends BarChartProps {
  widgetType: BarChartWidgetType.MULTIPLE;
  score: SentimentScore;
  benchmarkScore?: SentimentScore;
  labelColor?: LabelColor;
}

const BAR_WIDTH = '133';
const WIDE_BAR_WIDTH = '160';
const BAR_HEIGHT = '428';
const SHORT_BAR_HEIGHT = '328';

const isLowBar = (score: number, isMultipleScore: boolean, isShortBarChart?: boolean): boolean => {
  if (isMultipleScore) {
    return isShortBarChart ? score < LOW_SATISFACTION_THRESHOLD_SHORT_BAR : score < LOW_SATISFACTION_THRESHOLD;
  }
  return score < LOW_SATISFACTION_THRESHOLD_SINGLE;
};

export function BarChart(props: BarChartSingle | BarChartMultiple): React.ReactElement {
  const {
    score,
    isLabeled,
    label,
    isShortBarChart,
    fillColor,
    labelColor = LabelColor.WHITE,
    showSpan,
    isBenchmarked,
    benchmarkScore,
    widgetType,
    wideBars,
  } = props;

  const barScore = widgetType === BarChartWidgetType.SINGLE ? { positive: score } : score;
  const barBenchmarkScore: SentimentScore | undefined =
    widgetType === BarChartWidgetType.SINGLE && isBenchmarked && benchmarkScore
      ? { positive: benchmarkScore, neutral: 0, negative: 0 }
      : (benchmarkScore as SentimentScore);

  const isMultipleScore = widgetType === BarChartWidgetType.MULTIPLE;
  const isHeightScore = isShortBarChart
    ? barScore.positive > HIGH_SATISFACTION_THRESHOLD_SHORT_BAR
    : barScore.positive > HIGH_SATISFACTION_THRESHOLD;
  const isLowScore = isLowBar(barScore.positive, isMultipleScore, isShortBarChart);
  const hideScoreColor = !isMultipleScore || (isMultipleScore && !isLowScore);
  const barHeight = isShortBarChart ? SHORT_BAR_HEIGHT : BAR_HEIGHT;
  const barWidth = wideBars ? WIDE_BAR_WIDTH : BAR_WIDTH;
  const labelTextColor = `${labelColor}LabelColor`;

  return (
    <Flex
      className={classnames(styles.barChart)}
      height={`${barHeight}px`}
      flexDirection="column"
      justifyContent="flex-end"
      flexWrap="wrap"
      width={`${barWidth}px`}
    >
      <div
        className={classnames(
          styles.barFill,
          { [styles.benchmarkBar]: isBenchmarked },
          { [styles.lowBar]: isLowScore },
          { [styles.highBar]: isHeightScore },
          { [styles.shortBarChart]: isShortBarChart },
        )}
        style={{
          height: `${barScore.positive}%`,
          backgroundColor: fillColor,
        }}
      >
        {isLabeled && (
          <div className={styles.barFillContainer}>
            {isMultipleScore && (
              <AdditionalScores
                score={barScore as SentimentScore}
                isBenchmarked={isBenchmarked}
                barBenchmarkScore={barBenchmarkScore as SentimentScore}
              />
            )}
            <div className={classnames(styles.barLabel, styles[labelTextColor])}>
              <Flex justifyContent="space-between" alignItems="center" className={styles.scoreText}>
                <ScoreIndicator value={barScore.positive} type="positive" hideScoreColor={hideScoreColor} />
                <div className={styles.diffIndicatorWrapper}>
                  {isBenchmarked && (
                    <DiffIndicator
                      currentValue={barScore.positive}
                      prevValue={barBenchmarkScore?.positive ?? barBenchmarkScore}
                    />
                  )}
                </div>
              </Flex>
              {showSpan && <span>Satisfaction</span>}
              <p className={styles.scoreDescription}>{label || ''}</p>
            </div>
          </div>
        )}
      </div>
    </Flex>
  );
}
