import { ZCDFilter } from '@zencity/common-ui';
import { sortByLabel } from 'components/Dropdown/DropdownSortHelper';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterOptionWithKey } from 'types/filters';
import { SurveyType } from 'types/survey';

interface Props {
  stateValue: string;
  onChangeCallback: (value: FilterOptionWithKey) => void;
}

export const SurveyTypeDropdown = (props: Props): ReactElement => {
  const { stateValue, onChangeCallback } = props;
  const { t: translate } = useTranslation();

  const options = [
    {
      value: 'Any',
      key: 'survey-type-0',
      label: translate('surveyType.anySurvey'),
    },
    ...sortByLabel(
      Object.values(SurveyType).map((surveyType) => ({
        value: surveyType,
        key: `survey-type-${surveyType}`,
        label: translate(`surveyType.${surveyType}`),
      })),
    ),
  ];
  const value = options.find((option) => option.key === stateValue);
  return (
    <ZCDFilter<FilterOptionWithKey>
      value={value}
      onChange={(option) => onChangeCallback(option as FilterOptionWithKey)}
      label={translate('common.surveyType')}
      options={options}
    />
  );
};
