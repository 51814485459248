import React, { ReactElement } from 'react';
import { generatePath, useMatch } from 'react-router-dom';
import { BreadcrumbLink } from 'components/BreadcrumbLink/BreadcrumbLink';
import { useTranslation } from 'react-i18next';
import { RouterPath, SurveyChildPath } from 'routerPaths';
import styles from './SurveyEditBreadcrumbs.module.scss';
import { BreadcrumbsDivider } from './BreadcrumbsDivider';

interface Props {
  surveyId?: string;
}

/**
 * The breadcrumbs component of "Survey edit" pages.
 *
 * The known pages are "Start" and "Build".
 */
export const SurveyEditBreadcrumbs = (props: Props): ReactElement => {
  const { surveyId = '' } = props;
  const { t: translate } = useTranslation();

  const surveyBasePath = `${RouterPath.SURVEY}/`;

  const surveyBuildPath = generatePath(surveyBasePath + SurveyChildPath.BUILD, {
    surveyId,
  });
  const surveyEditPath = generatePath(surveyBasePath + SurveyChildPath.EDIT, {
    surveyId,
  });
  const surveyCreatePath = generatePath(surveyBasePath + SurveyChildPath.START_CREATE_NEW);

  const isNewSurveyPath = !!useMatch(surveyCreatePath);
  const isEditSurveyPath = !!useMatch(surveyEditPath);
  const isBuildSurveyPath = !!useMatch(surveyBuildPath);

  return (
    <div className={styles.breadcrumbsWrapper}>
      <BreadcrumbLink
        text={translate('surveyEdit.start')}
        route={surveyId ? surveyEditPath : surveyCreatePath}
        active={isNewSurveyPath || isEditSurveyPath}
        disabled={isNewSurveyPath || isEditSurveyPath}
        completed={isEditSurveyPath || isBuildSurveyPath}
      />
      <BreadcrumbsDivider />
      <BreadcrumbLink
        text={translate('surveyEdit.build')}
        route={surveyBuildPath}
        active={isBuildSurveyPath}
        disabled={isBuildSurveyPath || isNewSurveyPath}
      />
    </div>
  );
};
