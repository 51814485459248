import React from 'react';
import { v4 } from 'uuid';
import { SentimentBarRowsWidgetData } from '../../utils/misc';
import { SentimentBarRow } from './SentimentBarRow';

interface SentimentBarRowsProps {
  widgetData: SentimentBarRowsWidgetData[];
}

export function SentimentBarRows(props: SentimentBarRowsProps): React.ReactElement {
  const { widgetData } = props;
  return (
    <div>
      {widgetData.map((element) => {
        const score = element.score ? element.score : element.scores;
        return (
          <SentimentBarRow
            positive={score?.positive ?? ''}
            neutral={score?.neutral ?? ''}
            negative={score?.negative ?? ''}
            text={element.text}
            key={v4()}
          />
        );
      })}
    </div>
  );
}
