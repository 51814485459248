import React from 'react';
import { UnrepresentativeIndicator } from 'screens/WidgetGenerator/components/UnrepresentativeIndicator/UnrepresentativeIndicator';
import { SentimentBar } from 'screens/WidgetGenerator/components/SentimentBar/SentimentBar';
import { Flex } from 'components/Flex/Flex';
import styles from './SentimentBarRow.module.scss';

interface SentimentBarRowProps {
  positive: number;
  negative: number;
  neutral: number;
  text: string;
  unrepresentativeSubmissions?: number;
  displayUnrepresentativeIndicator?: boolean;
  prevPositiveValue?: number;
  isBenchmarked?: boolean;
}

export function SentimentBarRow(props: SentimentBarRowProps): React.ReactElement {
  const {
    positive,
    negative,
    neutral,
    text,
    displayUnrepresentativeIndicator,
    unrepresentativeSubmissions,
    prevPositiveValue,
    isBenchmarked,
  } = props;
  return (
    <Flex alignItems="center" justifyContent="space-between" className={styles.sentimentBarRow}>
      <p className={styles.rowText}>
        {text}{' '}
        {displayUnrepresentativeIndicator && (
          <UnrepresentativeIndicator totalSubmissions={unrepresentativeSubmissions} />
        )}
      </p>
      <SentimentBar
        positive={positive}
        neutral={neutral}
        negative={negative}
        positiveText
        prevPositiveValue={prevPositiveValue}
        isBenchmarked={isBenchmarked}
      />
    </Flex>
  );
}
