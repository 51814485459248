import { ZCDCheckbox, ZCDInput } from '@zencity/common-ui';
import { Spacer } from 'components/Spacer/Spacer';
import React, { useContext } from 'react';
import { WordCloudQuestionSentiment } from '../utils/misc';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';
import styles from './WidgetAdditionalFields.module.scss';

export const WordCloudAdditionalFields = (): React.ReactElement => {
  const { handleWordCloudCat, handleColumnName, columnName, wordCloudQuestionSentiments } =
    useContext(WidgetsConfigsContext);
  return (
    <div className={styles.fieldRow}>
      <ZCDCheckbox
        label="Negative"
        checked={wordCloudQuestionSentiments.includes(WordCloudQuestionSentiment.NEGATIVE)}
        onChange={() => handleWordCloudCat(WordCloudQuestionSentiment.NEGATIVE)}
      />
      <Spacer orientation="horizontal" size="small" />
      <ZCDCheckbox
        label="Positive"
        checked={wordCloudQuestionSentiments.includes(WordCloudQuestionSentiment.POSITIVE)}
        onChange={() => handleWordCloudCat(WordCloudQuestionSentiment.POSITIVE)}
      />
      <Spacer orientation="horizontal" size="small" />
      <ZCDCheckbox
        label="Other"
        checked={wordCloudQuestionSentiments.includes(WordCloudQuestionSentiment.OTHER)}
        onChange={() => handleWordCloudCat(WordCloudQuestionSentiment.OTHER)}
      />
      {wordCloudQuestionSentiments.includes(WordCloudQuestionSentiment.OTHER) && (
        <>
          <Spacer orientation="horizontal" size="xsmall" />
          <ZCDInput type="text" placeholder="Column name" onChange={handleColumnName} value={columnName} />
        </>
      )}
    </div>
  );
};
