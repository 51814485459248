import { ZCDInput } from '@zencity/common-ui';
import React, { useContext, useState } from 'react';
import { DEFAULT_DEMOGRAPHIC_TABLE_ROWS_PER_TABLE } from 'screens/WidgetGenerator/widgets/DemographicsTable/DemographicsTable';
import { FieldTitle } from '../components/FieldTitle/FieldTitle';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';
import styles from './WidgetAdditionalFields.module.scss';

export const DemographicsAdditionalFields = (): React.ReactElement => {
  const { handleDemographicsTableNumRowsPerTable } = useContext(WidgetsConfigsContext);
  const [numBarsPerRow, setNumBarsPerRow] = useState(DEFAULT_DEMOGRAPHIC_TABLE_ROWS_PER_TABLE);

  const handleNumBarsPerRowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNumBarsPerRow(parseInt(value));
    handleDemographicsTableNumRowsPerTable(parseInt(value));
  };

  return (
    <div className={styles.fieldContainer}>
      <FieldTitle title="Number of rows per table:" />
      <ZCDInput
        type="number"
        placeholder={numBarsPerRow.toString()}
        value={numBarsPerRow}
        onChange={handleNumBarsPerRowChange}
      />
    </div>
  );
};
