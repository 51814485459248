/* eslint-disable id-denylist */
import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { SurveysReportGeneratorDataItemKey, SurveysReportGeneratorFilterKey } from '../../constants';
import { DocumentFragment } from '../documentFragment';
import { sectionCoverPage } from '../utils/sectionCoverPage';
import { TABLE_DEFAULT_BORDER_COLOR, reportTemplateTable, reportTemplateTableCell } from '../utils/tableTemplate';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import { lineBreakElement } from '../utils/lineBreakElement';

const servicesSatisfactionDescriptionChunk1 = `The bars show the percent of respondents who reported positively (4 or 5) in response to questions about community characteristics.`;
const servicesSatisfactionDescriptionChunk2 = `We also display the percent of respondents who were neutral (3, shown with a gray dot) or negative (1 or 2, shown with a red dot).`;

const maintainAndFocusHeading = `The ratings residents gave these parts of life showed a connection to their overall satisfaction`;
const maintainAndFocusText = `In order to appear here, a characteristic must a) demonstrate a strong correlation with how residents rated their overall satisfaction and  b) receive a notably high or low satisfaction score`;

const servicesSatisfactionCoverPage = (clientName: string) =>
  sectionCoverPage({
    titleChunks: ['Life in', clientName],
    descriptionChunks: [servicesSatisfactionDescriptionChunk1, servicesSatisfactionDescriptionChunk2],
  });

const leftColumnMaintainAndFocusTable = reportTemplateTableCell({
  data: [
    lineBreakElement,
    {
      name: 'heading2',
      children: [
        {
          data: maintainAndFocusHeading,
          attributes: {
            fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
          },
        },
      ],
    },
    lineBreakElement,
    {
      name: 'paragraph',
      children: [
        {
          data: maintainAndFocusText,
          attributes: {
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
            fontColor: TEMPLATE_TYPOGRAPHY.colors.gray90,
          },
        },
      ],
    },
  ],
  tableCellWidth: '40%',
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
});

const rightColumnMaintainAndFocusTable = reportTemplateTableCell({
  data: [
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.MAINTAIN_AND_FOCUS_BAR_CHARTS,
      },
    },
  ],
  tableCellWidth: '54%',
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
});
const middleColumnMaintainAndFocusTable = reportTemplateTableCell({
  data: [lineBreakElement],
  tableCellWidth: '5%',
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
});

const maintainAndFocusTable = reportTemplateTable({
  tableColumns: [leftColumnMaintainAndFocusTable, middleColumnMaintainAndFocusTable, rightColumnMaintainAndFocusTable],
  tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
});

interface ServicesSatisfactionProps {
  clientName: string;
  headerElement: ILandscapeHeaderElement;
}
export const servicesSatisfaction = (props: ServicesSatisfactionProps): DocumentFragment => {
  const { clientName, headerElement } = props;

  return [
    headerElement,
    ...servicesSatisfactionCoverPage(clientName),
    headerElement,
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.SERVICES_BAR_CHART,
        params: {
          filters: {
            [SurveysReportGeneratorFilterKey.SERVICES_BAR_CHART_ROW_TO_DISPLAY]: 1,
          },
        },
      },
    },
    {
      name: 'pageBreak',
    },
    headerElement,
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.SERVICES_BAR_CHART,
        params: {
          filters: {
            [SurveysReportGeneratorFilterKey.SERVICES_BAR_CHART_ROW_TO_DISPLAY]: 2,
          },
        },
      },
    },
    {
      name: 'pageBreak',
    },
    headerElement,
    lineBreakElement,
    maintainAndFocusTable,
    {
      name: 'pageBreak',
    },
  ];
};
