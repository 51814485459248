import { ZCDCreatableSelect, ZCDInput } from '@zencity/common-ui';
import { Flex } from 'components/Flex/Flex';
import { RemoveButton } from 'components/NewGenericQuestion/subComponents/NewQuestionMainSectionComponents/ChoicesMainSection/RemoveButton';
import { OptionWrapper } from 'components/NewGenericQuestion/subComponents/OptionWrapper';
import { Spacer } from 'components/Spacer/Spacer';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { RootState } from 'store';
import { statusIsLoading, statusIsSuccessful } from 'utils/misc';
import styles from '../../../NewGenericQuestion.module.scss';

// eslint-disable-next-line id-length
export const NON_SUBSTANTIABLE_QUESTION_CHOICE_OPTION_TOKEN = '#';

// Implement the interface as react-select expects to get (via the `Select`
// component of Common-UI).
export interface ChoiceOption {
  id: number;
  label?: string;
  numericValue?: number | '#';
}

interface Props {
  isMultipleSelection: boolean;
  index: number;
  selectedChoice: ChoiceOption;
  choicesOptions: ChoiceOption[];
  handleOnCreateChoice: (inputValue: string, index: number) => void;
  updateSelectedChoice: (newValue: ChoiceOption, index: number) => void;
  removeOption: (index: number) => void;
  handleOnNumericValueChange: (inputValue: string, selectedChoice: ChoiceOption) => void;
}

export const QuestionChoiceSelector = (props: Props): ReactElement => {
  const {
    isMultipleSelection,
    selectedChoice,
    choicesOptions,
    index,
    handleOnCreateChoice,
    updateSelectedChoice,
    removeOption,
    handleOnNumericValueChange,
  } = props;
  const { fetchStatus } = useAppSelector((state: RootState) => state.questionsChoices);
  const { showNumericValues } = useAppSelector((state: RootState) => state.questionsBank);
  return (
    <OptionWrapper asCheckbox={isMultipleSelection}>
      <Flex className={styles.selectDropdown}>
        <div className={styles.choiceOption}>
          <ZCDCreatableSelect
            isLoading={statusIsLoading(fetchStatus)}
            options={choicesOptions}
            value={selectedChoice}
            onCreateOption={(inputValue: string) => handleOnCreateChoice(inputValue, index)}
            onChange={(newValue) => updateSelectedChoice(newValue as ChoiceOption, index)}
          />
        </div>
        <Spacer orientation="horizontal" size="small" />
        {statusIsSuccessful(fetchStatus) && showNumericValues && (
          <ZCDInput
            type="text"
            placeholder="#"
            value={selectedChoice.numericValue}
            disabled={!selectedChoice.label}
            onChange={(event) => handleOnNumericValueChange(event.currentTarget.value, selectedChoice)}
          />
        )}
      </Flex>

      <RemoveButton closeCallback={() => removeOption(index)} />
    </OptionWrapper>
  );
};
