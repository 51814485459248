import { Nullable, Translations } from './misc';

// TODO: support URL_REDIRECT
export enum ScreenType {
  END_SCREEN = 'end_screen',
  // URL_REDIRECT = 'url_redirect',
}

export interface EndScreen {
  id: number;
  survey: number;
  title: string;
  description: Nullable<string>;
  screen_type: ScreenType;
  updated_at: string;
  url: Nullable<string>;
  translations?: {
    title: Translations;
    description: Translations;
  };
}

export interface NewEndScreenPayload {
  surveyId: number;
  title: string;
  screenType: ScreenType;
  description?: string;
  url?: string;
}

export interface EndScreenUpdatePayload {
  endScreenId: number;
  title: Nullable<string>;
  description: Nullable<string>;
}
