import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentSurvey } from 'screens/SurveyEditStartScreen/SurveyEditStartScreen.helpers';
import styles from 'screens/SurveyEditStartScreen/SurveyEditStartScreen.module.scss';
import { RootState } from 'store';

interface Props {
  currentSurvey: CurrentSurvey;
  setCurrentSurvey: React.Dispatch<React.SetStateAction<CurrentSurvey>>;
}

export const SurveyEditTitlesSection = (props: Props): ReactElement => {
  const { currentSurvey, setCurrentSurvey } = props;
  const { recurringSurveyTypes } = useAppSelector((state: RootState) => state.globalConfigs);
  const { t: translate } = useTranslation();

  const onSurveyTitleForDisplayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSurvey({
      ...currentSurvey,
      titleForDisplay: event.currentTarget.value,
    });
  };

  const onSurveyInternalTitleSuffixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSurvey({
      ...currentSurvey,
      internalTitleSuffix: event.currentTarget.value,
    });
  };

  return (
    <div className={styles.sectionGrid}>
      <div className={styles.sectionTitle}>{translate('surveyEdit.giveItTitle')}</div>
      <div className={styles.titlesGrid}>
        <div>
          <div>{translate('surveyEdit.titleForResidents')}</div>
          <input
            type="text"
            className={styles.titleForDisplay}
            value={currentSurvey.titleForDisplay || ''}
            onChange={onSurveyTitleForDisplayChange}
          />
        </div>
        <div>
          <div>{translate('surveyEdit.titleForInternalUse')}</div>
          <input
            type="text"
            className={styles.internalTitlePattern}
            disabled
            value={currentSurvey.internalTitlePattern || ''}
          />
          <input
            type="text"
            className={styles.internalTitleSuffix}
            value={currentSurvey.internalTitleSuffix || ''}
            placeholder={translate(
              `surveyEdit.titleSuffixPlaceholder.${
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                recurringSurveyTypes.includes(currentSurvey.surveyType!) ? 'recurringSurvey' : 'nonRecurringSurvey'
              }`,
            )}
            onChange={onSurveyInternalTitleSuffixChange}
          />
        </div>
      </div>
    </div>
  );
};
