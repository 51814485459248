import React, { ReactElement } from 'react';
import { GenericQuestion } from 'types/genericQuestion';
import { TeaserAdditionalInfo } from './TeaserAdditionalInfo/TeaserAdditionalInfo';

interface Props {
  instance: GenericQuestion;
}

/**
 * A helper component to display the additional information of a
 * `choices_options` question.
 */
export const Choices = ({ instance }: Props): ReactElement => {
  const { choices } = instance;
  const items = choices.map((choice) => ({
    id: choice.id.toString(),
    label: choice.label,
    numericValue: choice.config?.numeric_value,
  }));

  return <TeaserAdditionalInfo items={items} keysPrefix="choice" allowToggle genericQuestion={instance} />;
};
