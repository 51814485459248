/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewGenericQuestionPayload, QuestionType } from 'types/genericQuestion';
import { QuestionConfigForNewQuestion } from 'types/questionConfig';

interface QuestionsBankState {
  selectedGenericQuestionId?: number;
  newQuestionPayload: NewGenericQuestionPayload;
  showNumericValues: boolean;
}

export const newQuestionPayloadInitialState: NewGenericQuestionPayload = {
  text: '',
  question_type: QuestionType.STATEMENT,
  aspect: 0,
  choices: [],
  configs: [],
};

const initialState: QuestionsBankState = {
  newQuestionPayload: newQuestionPayloadInitialState,
  showNumericValues: false,
};

const slice = createSlice({
  name: 'questionsBank',
  initialState,
  reducers: {
    handleOnGenericQuestionSelect: (state: QuestionsBankState, action: PayloadAction<number | undefined>) => {
      state.selectedGenericQuestionId = action.payload;
    },
    setNewQuestionPayload: (state: QuestionsBankState, action: PayloadAction<NewGenericQuestionPayload>) => {
      state.newQuestionPayload = action.payload;
    },
    setNewQuestionConfigs: (state: QuestionsBankState, action: PayloadAction<QuestionConfigForNewQuestion[]>) => {
      state.newQuestionPayload.configs = action.payload;
    },
    setShowNumericValues: (state: QuestionsBankState, action: PayloadAction<boolean>) => {
      state.showNumericValues = action.payload;
    },
  },
});

export const { handleOnGenericQuestionSelect, setNewQuestionPayload, setNewQuestionConfigs, setShowNumericValues } =
  slice.actions;
export default slice;
