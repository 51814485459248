import React from 'react';
import { ChoicesQuestionChartData, Segment } from 'screens/WidgetGenerator/utils/misc';
import { DiffIndicator } from 'screens/WidgetGenerator/components/DiffIndicator/DiffIndicator';
import { v4 } from 'uuid';
import { Flex } from 'components/Flex/Flex';
import styles from './ChoicesQuestionChart.module.scss';
import { QuestionItemBar } from './QuestionItemBar';
import { getOptionCount } from './utilFunctions';

interface Props {
  responseOption: ChoicesQuestionChartData['choices'][0];
  segments: Segment[];
  fallBackColor: string;
  totalRespondents: number;
  totalWeights: number;
  maxTotalSubmissions: number;
  isPercentage: boolean;
  previousCycleTotalWeights?: number;
  previousCycleTotalRespondents?: number;
}
const BAR_HEIGHT_MAPPING: Record<number, number> = {
  1: 24,
  2: 16,
  3: 16,
};
const FALLBACK_HEIGHT = 24;
const BAR_MAX_WIDTH = 380;

export const QuestionItem = (props: Props): React.ReactElement => {
  const {
    responseOption,
    totalRespondents,
    totalWeights,
    segments,
    fallBackColor,
    maxTotalSubmissions,
    isPercentage,
    previousCycleTotalWeights,
    previousCycleTotalRespondents,
  } = props;

  return (
    <div className={styles.questionItem}>
      <div className={styles.questionTitleContainer}>
        <span className={styles.questionTitle}>{responseOption.responseContent}</span>
      </div>
      <div className={styles.responseOption}>
        {responseOption.responseOptions?.map((response) => {
          const color =
            segments.find((segment) => segment.segmentLabel === response.segmentLabel)?.colorClassName || fallBackColor;

          const count = getOptionCount({
            isPercentage,
            totalWeights,
            totalResponses: totalRespondents,
            count: response.count,
            weightsSum: response.weightsSum,
            validCount: response.validCount,
          });
          if (count === undefined) return null;

          const countPreviousCycle =
            response.previousCycle && previousCycleTotalWeights && previousCycleTotalRespondents
              ? getOptionCount({
                  isPercentage,
                  totalWeights: previousCycleTotalWeights,
                  totalResponses: previousCycleTotalRespondents,
                  count: response.previousCycle.count,
                  weightsSum: response.previousCycle.weightsSum,
                  validCount: response.previousCycle.validCount,
                })
              : undefined;

          const { responseOptions } = responseOption;
          const barWidth = (count / maxTotalSubmissions) * BAR_MAX_WIDTH;
          return (
            <div key={v4()} className={styles.barContainer}>
              <QuestionItemBar
                width={barWidth}
                height={BAR_HEIGHT_MAPPING[responseOptions.length] ?? FALLBACK_HEIGHT}
                colorClassName={color}
              />
              <div className={styles.questionItemCount} style={{ color }}>
                <Flex alignItems="center">
                  <span className={styles.count}>
                    {count}
                    {isPercentage ? '%' : ''}
                  </span>
                  <span className={styles.diffWrapper}>
                    {isPercentage && countPreviousCycle !== undefined ? (
                      <DiffIndicator currentValue={count} prevValue={countPreviousCycle} withSeparator />
                    ) : null}
                  </span>
                </Flex>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
