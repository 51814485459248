import React from 'react';
import { ZCDIconColor } from '@zencity/common-ui';
import { Flex } from 'components/Flex/Flex';
import { DiffIcon } from './DiffIcon';
import styles from './DiffIndicator.module.scss';

interface DiffIndicatorProps {
  currentValue: number;
  prevValue: number;
  color?: ZCDIconColor;
  arrowColor?: ZCDIconColor;
  withSeparator?: boolean;
}

export function DiffIndicator(props: DiffIndicatorProps): React.ReactElement {
  const { currentValue, prevValue, arrowColor, withSeparator = false, color } = props;
  const diff = Math.round(currentValue - prevValue);
  return (
    <Flex alignItems="center" className={styles.diffWrapper}>
      {withSeparator && (
        <span className={styles.separator} style={{ color }}>
          •
        </span>
      )}
      <DiffIcon diff={diff} arrowColor={arrowColor} />
      <p className={styles.diffText} style={{ color }}>{`${Math.abs(diff)}%`}</p>
    </Flex>
  );
}
