import { BaseModel } from 'types/baseModel';
import { Aspect } from 'types/aspect';
import { QuestionChoice } from 'types/questionChoice';
import { QuestionConfigType, QuestionConfigForNewQuestion } from 'types/questionConfig';
import { Translations } from 'types/misc';

export enum QuestionType {
  CHOICES = 'choices_options',
  OPEN_ENDED = 'open_ended',
  SCALE = 'scale',
  YES_NO = 'yes_no',
  EMAIL = 'email',
  ZIP_CODE = 'zip_code',
  NUMBER = 'number',
  GROUP = 'group',
  STATEMENT = 'statement',
  DROPDOWN = 'dropdown',
}

export interface GenericQuestionConfig {
  id: number;
  config_name: QuestionConfigType;
  config_value: string;
  updated_at: string;
  translations?: {
    config_value: Translations;
  };
}

export interface GenericQuestion extends BaseModel {
  id: number;
  text: string;
  text_to_speech: string;
  question_type: QuestionType;
  aspect: Aspect;
  choices: QuestionChoice[];
  deprecated: boolean;
  configs: GenericQuestionConfig[];
  created_at: string;
  updated_at: string;
  translations?: {
    text: Translations;
    text_to_speech: Translations;
  };
}

export interface NewGenericQuestionPayload {
  id?: number;
  text: string;
  question_type: QuestionType;
  aspect: number;
  configs: QuestionConfigForNewQuestion[];
  choices?: NewGenericQuestionChoice[];
}

export interface NewGenericQuestionChoice {
  id: number;
  label?: string;
  numeric_value?: number;
  is_non_substantiative?: boolean;
}
