import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionConfigType, QuestionConfigForNewQuestion } from 'types/questionConfig';
import styles from './ScaleLabelInput.module.scss';

const SCALE_LABEL_MAX_LENGTH = 24;

interface Props {
  onChangeCallback: (
    configName: QuestionConfigType,
  ) => (value: string) => { payload: QuestionConfigForNewQuestion[]; type: string };
  scaleLabelType:
    | QuestionConfigType.SCALE_LABEL_FIRST
    | QuestionConfigType.SCALE_LABEL_MIDDLE
    | QuestionConfigType.SCALE_LABEL_LAST;
  stateValue?: string;
}

/**
 * A component to edit a scale label (of a "scale" question).
 */
export const ScaleLabelInput = (props: Props): ReactElement => {
  const { stateValue = '', scaleLabelType, onChangeCallback } = props;
  const { t: translate } = useTranslation();

  const setScaleLabel = onChangeCallback(scaleLabelType);
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScaleLabel(event.currentTarget.value.substring(0, SCALE_LABEL_MAX_LENGTH));
  };

  return (
    <div className={styles.scaleLabelWrapper}>
      <div className={styles.inputWrapper}>
        <span>{translate(`scaleLabel.${scaleLabelType}`)}</span>
        <input
          type="text"
          className={styles.scaleLabelInput}
          placeholder={translate('scaleLabel.optionalLabel')}
          value={stateValue}
          onChange={onInputChange}
        />
      </div>
      <div className={styles.letterCount}>
        {stateValue?.length || 0} / {SCALE_LABEL_MAX_LENGTH}
      </div>
    </div>
  );
};
