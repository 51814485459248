import i18next from 'i18next';
import '../../../i18n';

export interface ReportBuilderFilterProps {
  onChange: (newValue: unknown) => void;
  value: unknown;
  filterValues: Record<string, unknown>;
}

export enum SurveysReportGeneratorFilterKey {
  CYCLES = 'surveysCycles',
  SERVICES_BAR_CHART_ROW_TO_DISPLAY = 'servicesBarChartRowToDisplay',
  WORD_CLOUD_GENERIC_QUESTION_SELECT = 'wordCloudGenericQuestionSelect',
  WORD_CLOUD_SHOW_QUESTION_TEXT = 'wordCloudShowQuestionText',
  QUESTIONNAIRE_SURVEY_SINGLE_SELECT = 'questionnaireSurveySingleSelect',
  QUESTIONNAIRE_ASPECTS_SELECT = 'questionnaireAspectsSelect',
  QUESTIONNAIRE_TABLE_SPLIT_POSITION = 'questionnaireTableSplitPosition',
  QUESTIONNAIRE_TABLE_TO_DISPLAY = 'questionnaireTableToDisplay',
  DEMOGRAPHICS_TABLE_ROWS_PER_TABLE = 'demographicsTableRowsPerTable',
  MAINTAIN_AND_FOCUS_FILTERS = 'maintainAndFocusFilters',
  TOPICS_BUBBLE_CHART_SCALE_SELECT = 'topicsBubbleChartFiltersScaleSelect',
  TOPICS_BUBBLE_CHART_SENTIMENT_SELECT = 'topicsBubbleChartFiltersSentimentSelect',
  CROSS_TABS_FILTERS = 'crossTabsFilters',
  SERVICE_SENTIMENT_BAR_QUESTION_SELECT = 'serviceSentimentBar',
  CHOICES_QUESTION_CHART_DISPLAY_TYPE = 'choicesQuestionChartDisplayType',
  CHOICES_QUESTION_CHART_QUESTION_SELECT = 'choicesQuestionChartQuestionSelect',
  CHOICES_QUESTION_CHART_ORDER_BY = 'choicesQuestionChartOrderBy',
  BENCHMARK_ASPECT_SELECT = 'benchmarkAspectsSelect',
}

export enum SurveysReportGeneratorDataItemKey {
  OVERALL_SATISFACTION_TEXT = 'overall-satisfaction-text',
  OVERALL_SATISFACTION_CHART = 'overall-satisfaction-chart',
  SENTIMENT_BAR_OVERALL_TABLE = 'sentiment-bar-overall-table',
  WORD_CLOUD = 'word-cloud',
  AREAS_SENTIMENT_TABLE = 'areas-sentiment-table',
  QUESTIONNAIRE = 'questionnaire',
  TOPICS_BUBBLE_CHART = 'topics-bubble-chart',
  SERVICES_BAR_CHART = 'services-bar-chart',
  DEMOGRAPHICS_TABLE = 'demographics-table',
  MAINTAIN_AND_FOCUS_BAR_CHARTS = 'maintain-and-focus-bar-charts',
  CROSS_TABS = 'cross-tabs',
  SERVICE_SENTIMENT_BAR = 'service-sentiment-bar',
  CHOICES_QUESTION_CHART = 'choices-question-chart',
  BENCHMARK_OVERALL_SATISFACTION = 'benchmark-overall-satisfaction',
  QUESTIONS_BENCHMARK_TABLE = 'questions-benchmark-table',
  BENCHMARK_TABLE_LEGEND = 'benchmark-table-legend',
}

export enum SurveysReportGeneratorBundleKey {
  OVERALL_BUNDLE = 'overall-bundle',
}
export const QUESTIONNAITE_DEFAULT_SPLIT_POSITION = 7;

export const FILTERS_LABELS: Record<SurveysReportGeneratorFilterKey, string> = {
  [SurveysReportGeneratorFilterKey.CYCLES]: i18next.t('widgetGenerator.selectCycle'),
  [SurveysReportGeneratorFilterKey.MAINTAIN_AND_FOCUS_FILTERS]: i18next.t(
    'widgetGenerator.filters.maintainAndFocus.filters',
  ),
  [SurveysReportGeneratorFilterKey.SERVICES_BAR_CHART_ROW_TO_DISPLAY]: i18next.t(
    'widgetGenerator.filters.servicesBarChart.rowToDisplay',
  ),
  [SurveysReportGeneratorFilterKey.WORD_CLOUD_GENERIC_QUESTION_SELECT]: i18next.t(
    'widgetGenerator.filters.wordCloud.genericQuestionFilterName',
  ),
  [SurveysReportGeneratorFilterKey.WORD_CLOUD_SHOW_QUESTION_TEXT]: i18next.t(
    'widgetGenerator.filters.wordCloud.showQuestionText',
  ),
  [SurveysReportGeneratorFilterKey.QUESTIONNAIRE_SURVEY_SINGLE_SELECT]: i18next.t(
    'widgetGenerator.filters.questionnaire.selectedSurvey',
  ),
  [SurveysReportGeneratorFilterKey.QUESTIONNAIRE_ASPECTS_SELECT]: i18next.t(
    'widgetGenerator.filters.questionnaire.selectAspect',
  ),
  [SurveysReportGeneratorFilterKey.QUESTIONNAIRE_TABLE_TO_DISPLAY]: i18next.t(
    'widgetGenerator.filters.questionnaire.selectTableToDisplay',
  ),
  [SurveysReportGeneratorFilterKey.QUESTIONNAIRE_TABLE_SPLIT_POSITION]: i18next.t(
    'widgetGenerator.filters.questionnaire.selectSplitPosition',
  ),
  [SurveysReportGeneratorFilterKey.DEMOGRAPHICS_TABLE_ROWS_PER_TABLE]: i18next.t(
    'widgetGenerator.filters.demographics.rowsPerTable.name',
  ),
  [SurveysReportGeneratorFilterKey.TOPICS_BUBBLE_CHART_SCALE_SELECT]: i18next.t(
    'widgetGenerator.filters.topicsBubbleChart.scaleFilter.selectedScale',
  ),
  [SurveysReportGeneratorFilterKey.TOPICS_BUBBLE_CHART_SENTIMENT_SELECT]: i18next.t(
    'widgetGenerator.filters.topicsBubbleChart.scaleFilter.selectedScale',
  ),
  [SurveysReportGeneratorFilterKey.CROSS_TABS_FILTERS]: i18next.t('widgetGenerator.filters.crossTabs.filters'),
  [SurveysReportGeneratorFilterKey.SERVICE_SENTIMENT_BAR_QUESTION_SELECT]: i18next.t(
    'widgetGenerator.filters.serviceSentimentBar.filters.selectBaseQuestion',
  ),
  [SurveysReportGeneratorFilterKey.CHOICES_QUESTION_CHART_QUESTION_SELECT]: i18next.t(
    'widgetGenerator.filters.choicesQuestionChart.selectedQuestion',
  ),
  [SurveysReportGeneratorFilterKey.CHOICES_QUESTION_CHART_DISPLAY_TYPE]: i18next.t(
    'widgetGenerator.filters.choicesQuestionChart.resultsInPercentage',
  ),
  [SurveysReportGeneratorFilterKey.CHOICES_QUESTION_CHART_ORDER_BY]: i18next.t(
    'widgetGenerator.filters.choicesQuestionChart.orderByPopularity',
  ),
  [SurveysReportGeneratorFilterKey.BENCHMARK_ASPECT_SELECT]: i18next.t(
    'widgetGenerator.filters.questionsBenchmarkTable.selectAspect',
  ),
};

export const REPORT_BUILDER_PATH = 'https://d31bg1n95mz22u.cloudfront.net/summary-report';
