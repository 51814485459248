import { ZCDSelect } from '@zencity/common-ui';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from 'screens/WidgetGenerator/components/FieldTitle/FieldTitle';
import { WidgetsConfigsContext } from 'screens/WidgetGenerator/contexts/WidgetsConfigsContext';
import { fetchAspects } from 'services/aspect';
import { logger } from 'utils/logger';
import { statusIsIdle } from 'utils/misc';

export interface AspectSelectionProps {
  selectedAspects: number[];
  onChange: (selectedAspects: number[]) => void;
}

export const AspectSelection = (props: AspectSelectionProps) => {
  const { selectedAspects, onChange } = props;
  const dispatch = useAppDispatch();
  const { aspectsSortedByLabel, fetchStatus, errorMessage } = useAppSelector((state) => state.aspects);
  const { t: translate } = useTranslation();

  const { selectedSurvey } = useContext(WidgetsConfigsContext);
  const options = Object.values(aspectsSortedByLabel).map((aspect) => ({
    value: aspect.id,
    key: `aspect-${aspect.id}`,
    label: aspect.label,
  }));

  useEffect(() => {
    if (statusIsIdle(fetchStatus) && selectedSurvey) {
      dispatch(fetchAspects({ survey: selectedSurvey }));
    }
    if (errorMessage) {
      logger.error(errorMessage);
    }
  }, [fetchStatus, dispatch, errorMessage, selectedSurvey]);

  const selectedAspectsValue = selectedAspects?.map((aspectId) => options.find((option) => option.value === aspectId));

  const handleChange = (newValue: unknown) => {
    const selected = newValue as { value: number }[];
    onChange(selected.map((item) => item.value));
  };

  return (
    <LoaderMask isLoading={fetchStatus === 'loading'}>
      <FieldTitle title={translate('widgetGenerator.widgets.questionnaire.aspectsSelection')} />
      <ZCDSelect
        isMulti
        placeholder={translate('widgetGenerator.widgets.questionnaire.aspectsSelection')}
        options={options}
        onChange={handleChange}
        value={selectedAspectsValue}
        isDisabled={!selectedSurvey}
      />
    </LoaderMask>
  );
};
