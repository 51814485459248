import React, { ReactElement, ReactNode } from 'react';
import { ZCDSpinner } from '@zencity/common-ui';
import styles from './LoaderMask.module.scss';

interface Props {
  isLoading: boolean;
  children: ReactNode | ReactNode[];
}

/**
 * A wrapper to mask the given children with a spinner while loading.
 */
export const LoaderMask: React.FC<Props> = function LoaderMask({ isLoading, children }: Props): ReactElement {
  return (
    <div className={styles.loaderMaskWrapper}>
      {isLoading && (
        <div className={styles.loaderMask}>
          <ZCDSpinner />
        </div>
      )}
      {children}
    </div>
  );
};
