import { createAsyncThunk } from '@reduxjs/toolkit';
import { TranslationRequestPayload } from 'types/misc';
import { QuestionChoice } from 'types/questionChoice';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/api/v2/question_choices/');
const axiosInstance = createAxiosInstance(apiUrl);

export const fetchQuestionsChoices = createAsyncThunk('questionsChoices/fetchQuestionsChoices', async (_, ThunkAPI) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { nextPage } = ThunkAPI.getState().questionsChoices;
  const response = await axiosInstance.get('', {
    params: {
      page_size: 30,
      page: nextPage,
    },
  });
  return response.data;
});

/**
 * Update a question's choice `label` translation, and have it be fulfilled in the
 * `genericQuestions` slice.
 */
export const updateQuestionChoiceTranslation = createAsyncThunk(
  'genericQuestions/updateQuestionChoiceTranslation',
  async (payload: TranslationRequestPayload): Promise<QuestionChoice> => {
    const { instanceId: questionChoiceId, language, translatedText } = payload;
    const response = await axiosInstance.patch(
      `${questionChoiceId}/`,
      {
        [`label_${language}`]: translatedText,
      },
      {
        params: {
          translations_languages: language,
          bypass_client_filter: true,
        },
      },
    );
    return response.data;
  },
);

/**
 * Create a question choice.
 */
export const createQuestionChoice = async (label: string): Promise<QuestionChoice> => {
  const response = await axiosInstance.post<QuestionChoice>('', { label });
  return response.data;
};
