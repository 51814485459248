/* eslint-disable max-statements */
/* eslint-disable max-params */
/* eslint-disable id-denylist */
import { IDocumentHeadingElement, IDocumentParagraphElement } from '@zencity/reporting-types';
import { DocumentFragment } from '../documentFragment';
import { TABLE_DEFAULT_BORDER_COLOR, reportTemplateTable, reportTemplateTableCell } from './tableTemplate';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import { lineBreakElement } from './lineBreakElement';
import { generateLineBreaks } from './utilsFunctions';

interface SectionCoverPageProps {
  titleChunks: string[];
  descriptionChunks?: string[];
}

const TABLE_CELL_PADDING_IN_ROWS = 3;
const TITLE_LINE_HEIGHT = 5;
const DESCRIPTION_SECTION_HEIGHT = 7;
const TOTAL_ROWS_IN_PAGE = 20;
const MAX_DESCRIPTION_COLUMNS = 3;

export const sectionCoverPage = (props: SectionCoverPageProps): DocumentFragment => {
  const { titleChunks, descriptionChunks = [] } = props;

  const coverPageData: DocumentFragment = [];

  // Calculate the margin from top.
  const descriptionHeight = descriptionChunks.length > 0 ? DESCRIPTION_SECTION_HEIGHT : 0;
  const totalMarginFromTop =
    TOTAL_ROWS_IN_PAGE - TITLE_LINE_HEIGHT * titleChunks.length - TABLE_CELL_PADDING_IN_ROWS - descriptionHeight;

  coverPageData.push(...generateLineBreaks(totalMarginFromTop));

  // Generate section title
  if (titleChunks) {
    const titleCellContent = titleChunks.map<IDocumentHeadingElement>((titleChunk) => ({
      name: 'heading1',
      children: [
        {
          data: titleChunk,
          attributes: {
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading1,
            fontColor: TEMPLATE_TYPOGRAPHY.colors.purple100,
          },
        },
      ],
    }));

    const titleCell = reportTemplateTableCell({
      data: titleCellContent,
      tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
      tableCellWidth: '25%',
      tableCellPadding: '20px',
    });
    coverPageData.push(
      reportTemplateTable({ tableColumns: [titleCell], tableBorderColor: TABLE_DEFAULT_BORDER_COLOR }),
    );
  }
  // Display description in a table.
  if (descriptionChunks.length) {
    const descriptionColumnsContent = descriptionChunks?.map<IDocumentParagraphElement[]>((column) => [
      {
        name: 'paragraph',
        children: [
          {
            data: column,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
    ]);

    const descriptionCells = descriptionColumnsContent.map((descriptionChunk) =>
      reportTemplateTableCell({
        data: descriptionChunk,
        tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
        tableCellWidth: '25%',
        tableCellPadding: '20px',
      }),
    );
    // Add empty cells to fill the table. Otherwise, the cell width will be calculated incorrectly
    // in the report generator.
    for (let column = 0; column < MAX_DESCRIPTION_COLUMNS - descriptionChunks.length; column += 1) {
      descriptionCells.push(
        reportTemplateTableCell({
          data: [lineBreakElement],
          tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
          tableCellWidth: '25%',
          tableCellPadding: '20px',
        }),
      );
    }
    coverPageData.push(
      reportTemplateTable({ tableColumns: descriptionCells, tableBorderColor: TABLE_DEFAULT_BORDER_COLOR }),
    );
  }

  coverPageData.push({
    name: 'pageBreak',
  });

  return coverPageData;
};
