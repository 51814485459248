import { Spacer } from 'components/Spacer/Spacer';
import React, { useContext } from 'react';
import { WidgetGeneratorContext } from '../contexts/WidgetGeneratorContext';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';
import { AspectSelection } from './utilsFunctionsAdditionalFields/AspectSelection';
import { SplitPosition } from './utilsFunctionsAdditionalFields/SplitPosition';
import { SurveysSelection } from './utilsFunctionsAdditionalFields/SurveysSelection';

export const QuestionnaireAdditionalFields = (): React.ReactElement => {
  const {
    selectedAspects,
    selectedSurvey,
    splitPositionQuestionnaire,
    setSplitPositionQuestionnaire,
    setSelectedAspects,
    setSelectedSurvey,
  } = useContext(WidgetsConfigsContext);
  const { selectedSurveyGroup } = useContext(WidgetGeneratorContext);

  const handleSelectSurvey = (newValue: unknown) => {
    const selected = newValue as { value: number };
    setSelectedSurvey(selected.value);
  };

  return (
    <div>
      <SurveysSelection
        selectedSurvey={selectedSurvey}
        handleSelectSurvey={handleSelectSurvey}
        selectedSurveyGroup={selectedSurveyGroup.id}
      />
      <Spacer orientation="vertical" size="xsmall" />
      <AspectSelection selectedAspects={selectedAspects} onChange={setSelectedAspects} />
      <Spacer orientation="vertical" size="xsmall" />
      <SplitPosition onChange={setSplitPositionQuestionnaire} splitPosition={splitPositionQuestionnaire} />
    </div>
  );
};
