import React, { ReactElement } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RouterPath, SurveyChildPath } from 'routerPaths';
import styles from './NewSurveyLink.module.scss';

/**
 * A link component redirecting to the "Start" page of the "Survey Edit" screen.
 */
export const NewSurveyLink = (): ReactElement => {
  const { t: translate } = useTranslation();

  const surveysCreatePath = generatePath(`${RouterPath.SURVEY}/${SurveyChildPath.START_CREATE_NEW}`);

  return (
    <NavLink to={surveysCreatePath} className={styles.newSurveyLink}>
      {translate('mainPage.newSurvey')}
    </NavLink>
  );
};
