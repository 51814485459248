import { SentimentScore } from '@zencity/survey-types';
import React from 'react';
import classNames from 'classnames';
import { DiffIndicator } from 'screens/WidgetGenerator/components/DiffIndicator/DiffIndicator';
import { Flex } from 'components/Flex/Flex';
import { useTranslation } from 'react-i18next';
import styles from './OtherScores.module.scss';

interface SentimentBarProps {
  score: SentimentScore;
  previousCycleScore?: SentimentScore;
}
/**
 * This component shows the neutral and negative scores with the sentiment change.
 */
export function OtherScores(props: SentimentBarProps): React.ReactElement {
  const { score, previousCycleScore } = props;
  const negativeScoreText = `${parseInt(score.negative.toString())}%`;
  const neutralScoreText = `${parseInt(score.neutral.toString())}%`;
  const { t: translate } = useTranslation();

  return (
    <Flex alignItems="center" className={styles.otherScores}>
      <div className={styles.otherScore}>
        <p className={styles.scoreLabel}>{translate('widgetGenerator.otherScores.neutral')}</p>
        <Flex alignItems="center">
          <span className={classNames(styles.neutral, styles.score)}>{neutralScoreText}</span>
          {previousCycleScore && (
            <DiffIndicator currentValue={score.neutral} prevValue={previousCycleScore.neutral} withSeparator />
          )}
        </Flex>
      </div>
      <div className={styles.otherScore}>
        <p className={styles.scoreLabel}>{translate('widgetGenerator.otherScores.dissatisfied')}</p>
        <Flex alignItems="center">
          <span className={classNames(styles.negative, styles.score)}>{negativeScoreText}</span>
          {previousCycleScore && (
            <DiffIndicator currentValue={score.negative} prevValue={previousCycleScore.negative} withSeparator />
          )}
        </Flex>
      </div>
    </Flex>
  );
}
