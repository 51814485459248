import React, { useEffect } from 'react';
import { checkIfUserIsAuthorized } from 'services/user';
import { GlobalConfigsState } from 'slices/globalConfig';
import { User } from 'types/user';
import { initLogger } from 'utils/logger';

export const useValidateAuthorization = (user: User) => {
  const [isUserAuthorized, setIsUserAuthorized] = React.useState(false);
  const [checkingForUserAuthorization, setCheckingForUserAuthorization] = React.useState(true);
  const [globalConfigs, setGlobalConfigs] = React.useState<GlobalConfigsState | null>(null);
  // Initiate the logger with the user's information.
  useEffect(() => {
    initLogger(user);

    checkIfUserIsAuthorized()
      .then((fetchedGlobalConfigs) => {
        setIsUserAuthorized(true);
        setGlobalConfigs(fetchedGlobalConfigs);
      })
      .finally(() => setCheckingForUserAuthorization(false));
  }, [user]);
  return { globalConfigs, isUserAuthorized, checkingForUserAuthorization };
};
