import { Button, Tabs, ZCDDialog, ZCDSpinner } from '@zencity/common-ui';
import classnames from 'classnames';
import { GenericQuestionsListTable } from 'components/GenericQuestionsListTable/GenericQuestionsListTable';
import { NewGenericQuestion } from 'components/NewGenericQuestion/NewGenericQuestion';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './QuestionBankDialog.module.scss';
import { QuestionEditType } from './types';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | ((state: boolean) => void);
  title: string;
  isSelectable?: boolean;
  addQuestionInsideGroup?: boolean;
  isSubmitting?: boolean;
  disableSubmitButton?: boolean;
  submitBtnLabel?: string;
  submitHandler?: () => void;
  closeHandler?: () => void;
  newQuestionPostCreationCallback?: (genericQuestionId?: number) => void;
  newQuestionSubmitBtnLabel?: string;
}

enum DialogTab {
  SINGLE_QUESTION = 'single',
  QUESTIONS_SET = 'set',
  NEW_QUESTION = 'new',
}

/**
 * A dialog to display the Question Bank listing component.
 *
 * @param props
 *  - isOpen: Indicate whether the dialog should be open or not.
 *  - setIsOpen: A handler for toggling the `isOpen` variable.
 *  - title: The title for the dialog.
 *  - isSelectable: Indicate whether the lists of each tab should be selectable.
 *  - addQuestionInsideGroup: Indicate whether the dialog is meant to add/create
 *                            a question inside a group.
 *  - isSubmitting: Indicate whether the dialog is in a submit mode.
 *  - disableSubmitButton: Indicate whether the submit button should be disabled.
 *  - submitBtnLabel: The label to use for the submit button.
 *  - submitHandler: The handler to invoke when user press the submit button.
 *  - closeHandler: A handler to invoke when the dialog gets closed.
 *  - newQuestionPostCreationCallback: A handler to invoke after creating a new
 *                                     Generic Question.
 *  - newQuestionSubmitBtnLabel: The label to use for the submit button of the
 *                               "new question" tab.
 */
// eslint-disable-next-line complexity,max-lines-per-function
export const QuestionBankDialog: React.FC<Props> = function QuestionBankDialog(props: Props): ReactElement {
  const {
    isOpen = false,
    setIsOpen,
    title,
    isSelectable = false,
    addQuestionInsideGroup = false,
    isSubmitting = false,
    disableSubmitButton = false,
    submitBtnLabel,
    submitHandler,
    closeHandler,
    newQuestionPostCreationCallback,
    newQuestionSubmitBtnLabel,
  } = props;

  const { t: translate } = useTranslation();
  const [activeTab, setActiveTab] = useState(DialogTab.SINGLE_QUESTION);
  const [questionChangeType, setQuestionChangeType] = useState<QuestionEditType>(QuestionEditType.CREATE);

  const isAddingNewQuestion = activeTab === DialogTab.NEW_QUESTION;
  // The "New Question" tab should implement its own footer/actions due to the
  // wiring complexity of the actions needs to be triggered on successful
  // creation, thus, avoid displaying the action when that tab is active.
  const displayActions = !isAddingNewQuestion && submitHandler && submitBtnLabel;

  const closeDialog = useCallback(() => {
    if (closeHandler) {
      closeHandler();
    }
    setIsOpen(false);
  }, [closeHandler, setIsOpen]);

  const tabsItemsMap = {
    [DialogTab.SINGLE_QUESTION]: { position: 0, label: translate('questionsBankDialog.tabs.single') },
    [DialogTab.NEW_QUESTION]: { position: 2, label: translate(`questionsBankDialog.tabs.${questionChangeType}`) },
  };

  const onTabSelect = (selectedTab: string) => {
    setActiveTab(selectedTab as DialogTab);
    setQuestionChangeType(QuestionEditType.CREATE);
  };
  const switchToSingleQuestionTab = () => setActiveTab(DialogTab.SINGLE_QUESTION);
  const switchToNewQuestionEditTab = () => {
    setActiveTab(DialogTab.NEW_QUESTION);
    setQuestionChangeType(QuestionEditType.EDIT);
  };

  const Footer = useMemo(
    // eslint-disable-next-line react/no-multi-comp
    () => () => {
      const submitDialog = () => {
        if (!displayActions) {
          return;
        }
        if (submitHandler) {
          submitHandler();
        }
      };
      return (
        <div>
          <Button variant="link" onClick={closeDialog} className={styles.cancelButton}>
            {translate('common.cancel')}
          </Button>
          <Button onClick={submitDialog} disabled={disableSubmitButton}>
            <>
              {isSubmitting && <ZCDSpinner size={16} color={styles.whiteColor} />}
              <p className={classnames({ [styles.marginAddText]: isSubmitting })}>{submitBtnLabel}</p>
            </>
          </Button>
        </div>
      );
    },
    [closeDialog, translate, disableSubmitButton, isSubmitting, submitBtnLabel, displayActions, submitHandler],
  );

  return (
    <ZCDDialog
      isOpen={isOpen}
      onRequestClose={closeDialog}
      shouldCloseOnOverlayClick={false}
      header={<h1>{title}</h1>}
      footer={displayActions ? <Footer /> : undefined}
      width={window.innerWidth * 0.8}
    >
      <>
        <div className={styles.tabsWrapper}>
          <Tabs items={tabsItemsMap} selectedTab={activeTab} onSelect={onTabSelect} />
        </div>
        <div className={styles.selectedTabArea}>
          {activeTab === DialogTab.SINGLE_QUESTION && (
            <GenericQuestionsListTable
              isSelectable={isSelectable}
              addQuestionInsideGroup={addQuestionInsideGroup}
              switchToNewQuestionTab={switchToNewQuestionEditTab}
            />
          )}
          {activeTab === DialogTab.NEW_QUESTION && (
            <NewGenericQuestion
              closeDialog={closeDialog}
              postCreationCallback={newQuestionPostCreationCallback || switchToSingleQuestionTab}
              submitBtnLabel={newQuestionSubmitBtnLabel}
              addQuestionInsideGroup={addQuestionInsideGroup}
              questionChangeType={questionChangeType}
            />
          )}
        </div>
      </>
    </ZCDDialog>
  );
};
