/* eslint-disable id-denylist */
import { format } from 'date-fns';
import { SurveyCycle } from 'screens/WidgetGenerator/utils/misc';
import { DocumentFragment } from '../documentFragment';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import { REPORT_BUILDER_PATH } from '../../constants';
import { generateLineBreaks } from '../utils/utilsFunctions';

interface CoverPageProps {
  mostRecentCycle: SurveyCycle;
}
const ZENCITY_LOGO = `${REPORT_BUILDER_PATH}/logo-zencity.png`;

const getCycleFollowingMonth = (mostRecentCycle: SurveyCycle): string => {
  const endDate = new Date(mostRecentCycle.endDate);
  const nextMonthDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1);
  const formattedNextMonthDate = format(nextMonthDate, 'MMMM');
  const formattedYear = format(nextMonthDate, 'yyyy');
  return `${formattedNextMonthDate}, ${formattedYear}`;
};

export const reportSummaryPage = (props: CoverPageProps): DocumentFragment => {
  const { mostRecentCycle } = props;
  const publicationMonth = getCycleFollowingMonth(mostRecentCycle);

  return [
    ...generateLineBreaks(8),
    {
      name: 'imageBlock',
      attributes: {
        src: ZENCITY_LOGO,
        width: '20%',
      },
    },
    ...generateLineBreaks(8),
    {
      name: 'paragraph',
      attributes: {
        alignment: 'center',
      },
      children: [
        {
          data: `Published on ${publicationMonth} by Zencity`,
          attributes: {
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodySmall,
            fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
          },
        },
      ],
    },
    {
      name: 'paragraph',
      attributes: {
        alignment: 'center',
      },
      children: [
        {
          data: `support@zencity.io`,
          attributes: {
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodySmall,
            fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            linkHref: 'mailto:support@zencity.io',
          },
        },
      ],
    },
  ];
};
