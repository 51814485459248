/* eslint-disable max-lines */
import { generateQuestionnaireWidgetMock } from 'mocks/questionnaireWidget.mock';
import { WidgetType } from './misc';

export const DEFAULT_DATA: Record<WidgetType, string> = {
  [WidgetType.WORD_CLOUD]: `{
		"positive": [
			[
				"town",
				70,
				"positive"
			],
			[
				"nothing",
				60,
				"positive"
			],
			[
				"small",
				58,
				"positive"
			],
			[
				"family",
				48,
				"positive"
			],
			[
				"traffic",
				48,
				"positive"
			],
			[
				"people",
				46,
				"positive"
			],
			[
				"close",
				31,
				"positive"
			],
			[
				"live",
				30,
				"positive"
			],
			[
				"quiet",
				29,
				"positive"
			],
			[
				"like",
				23,
				"positive"
			],
			[
				"barstow",
				23,
				"positive"
			],
			[
				"City",
				22,
				"positive"
			],
			[
				"thing",
				22,
				"positive"
			],
			[
				"place",
				20,
				"positive"
			],
			[
				"rent",
				17,
				"positive"
			],
			[
				"get",
				17,
				"positive"
			]
		],
		"negative": [
			[
				"homeless",
				72,
				"negative"
			],
			[
				"crime",
				66,
				"negative"
			],
			[
				"thing",
				53,
				"negative"
			],
			[
				"people",
				49,
				"negative"
			],
			[
				"store",
				44,
				"negative"
			],
			[
				"need",
				43,
				"negative"
			],
			[
				"business",
				40,
				"negative"
			],
			[
				"get",
				37,
				"negative"
			],
			[
				"barstow",
				35,
				"negative"
			],
			[
				"kid",
				34,
				"negative"
			],
			[
				"job",
				31,
				"negative"
			],
			[
				"activity",
				30,
				"negative"
			],
			[
				"place",
				29,
				"negative"
			],
			[
				"City",
				28,
				"negative"
			],
			[
				"shopping",
				28,
				"negative"
			]
		]
	}`,
  [WidgetType.TOPICS_BUBBLE_CHART]: `[
    {
        "classificationSubTopicId": 602,
        "classificationSubTopicValue": "proximity",
        "classificationTopicId": 25,
        "classificationTopicValue": "proximity",
        "responses": 131
    },
    {
        "classificationSubTopicId": 448,
        "classificationSubTopicValue": "neighborly-relations",
        "classificationTopicId": 8,
        "classificationTopicValue": "neighborly-relations",
        "responses": 112
    },
    {
        "classificationSubTopicId": 487,
        "classificationSubTopicValue": "park-cleaning-maintenance",
        "classificationTopicId": 9,
        "classificationTopicValue": "park-cleaning-maintenance",
        "responses": 52
    },
    {
        "classificationSubTopicId": 791,
        "classificationSubTopicValue": "violence-crime",
        "classificationTopicId": 23,
        "classificationTopicValue": "violence-crime",
        "responses": 133
    },
    {
        "classificationSubTopicId": 526,
        "classificationSubTopicValue": "police",
        "classificationTopicId": 3,
        "classificationTopicValue": "police",
        "responses": 57
    },
    {
        "classificationSubTopicId": 333,
        "classificationSubTopicValue": "housing",
        "classificationTopicId": 7,
        "classificationTopicValue": "housing",
        "responses": 56
    }
]`,
  [WidgetType.DEMOGRAPHICS]: `[
    {
			"demographic_value": "female",
			"demographic_label": "Female",
			"demographic_type": "gender",
			"scores": [
					{
							"scores": {
									"positive": 59,
									"negative": 2,
									"neutral": 39
							},
							"total_submissions": 648,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "male",
			"demographic_label": "Male",
			"demographic_type": "gender",
			"scores": [
					{
							"scores": {
									"positive": 64,
									"negative": 4,
									"neutral": 32
							},
							"total_submissions": 305,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "hispanic_latino",
			"demographic_label": "Hispanic/Latino",
			"demographic_type": "origin",
			"scores": [
					{
							"scores": {
									"positive": 62,
									"negative": 2,
									"neutral": 35
							},
							"total_submissions": 106,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "income_group_1",
			"demographic_label": "$49,999 or less",
			"demographic_type": "income",
			"scores": [
					{
							"scores": {
									"positive": 64,
									"negative": 3,
									"neutral": 33
							},
							"total_submissions": 505,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "income_group_2",
			"demographic_label": "$50,000-$124,999",
			"demographic_type": "income",
			"scores": [
					{
							"scores": {
									"positive": 57,
									"negative": 3,
									"neutral": 40
							},
							"total_submissions": 387,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "income_group_3",
			"demographic_label": "$125,000 or more",
			"demographic_type": "income",
			"scores": [
					{
							"scores": {
									"positive": 67,
									"negative": 1,
									"neutral": 32
							},
							"total_submissions": 85,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "education_group_1",
			"demographic_label": "High school degree or less",
			"demographic_type": "education",
			"scores": [
					{
							"scores": {
									"positive": 60,
									"negative": 5,
									"neutral": 35
							},
							"total_submissions": 341,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "education_group_2",
			"demographic_label": "Some college or college degree",
			"demographic_type": "education",
			"scores": [
					{
							"scores": {
									"positive": 59,
									"negative": 3,
									"neutral": 38
							},
							"total_submissions": 390,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "education_group_3",
			"demographic_label": "Higher education degree",
			"demographic_type": "education",
			"scores": [
					{
							"scores": {
									"positive": 65,
									"negative": 2,
									"neutral": 34
							},
							"total_submissions": 246,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "age_group_18_34",
			"demographic_label": "18-34",
			"demographic_type": "age_group",
			"scores": [
					{
							"scores": {
									"positive": 65,
									"negative": 3,
									"neutral": 32
							},
							"total_submissions": 377,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "age_group_35_54",
			"demographic_label": "35-54",
			"demographic_type": "age_group",
			"scores": [
					{
							"scores": {
									"positive": 59,
									"negative": 2,
									"neutral": 39
							},
							"total_submissions": 258,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "age_group_55_plus",
			"demographic_label": "55+",
			"demographic_type": "age_group",
			"scores": [
					{
							"scores": {
									"positive": 61,
									"negative": 2,
									"neutral": 37
							},
							"total_submissions": 324,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "asian",
			"demographic_label": "Asian",
			"demographic_type": "ethnicity",
			"scores": [
					{
							"scores": {
									"positive": 74,
									"negative": 0,
									"neutral": 26
							},
							"total_submissions": 22,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "black_or_african_american",
			"demographic_label": "Black or African American",
			"demographic_type": "ethnicity",
			"scores": [
					{
							"scores": {
									"positive": 66,
									"negative": 2,
									"neutral": 32
							},
							"total_submissions": 602,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "native_american_or_alaskan_native",
			"demographic_label": "Native American or Alaskan Native",
			"demographic_type": "ethnicity",
			"scores": [
					{
							"scores": {
									"positive": 53,
									"negative": 4,
									"neutral": 44
							},
							"total_submissions": 48,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "native_hawaiian_or_other_pacific_islander",
			"demographic_label": "Native Hawaiian or Other Pacific Islander",
			"demographic_type": "ethnicity",
			"scores": [
					{
							"scores": {
									"positive": 55,
									"negative": 0,
									"neutral": 45
							},
							"total_submissions": 21,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	},
	{
			"demographic_value": "white",
			"demographic_label": "White",
			"demographic_type": "ethnicity",
			"scores": [
					{
							"scores": {
									"positive": 51,
									"negative": 4,
									"neutral": 45
							},
							"total_submissions": 235,
							"start_date": "2022-10-01",
							"end_date": "2022-12-31"
					}
			]
	}
]`,
  [WidgetType.SENTIMENT_BAR]: `[
	{
		"score": {
			"positive": 37,
			"negative": 4,
			"neutral": 59
		},
		"text": "How is the overall quality of life in %client_name%?"
	},
	{
		"score": {
			"positive": 39,
			"negative": 27,
			"neutral": 34
		},
		"text": "How likely are you to recommend %client_name% as a place to live?"
	}
]`,
  [WidgetType.SERVICE_SENTIMENT_BAR]: `[
	{
		"score": {
			"positive": 76,
			"negative": 10,
			"neutral": 14
		},
		"previousCycle": {
			"score": {
					"positive": 40,
					"negative": 28,
					"neutral": 32
			}
		},
		"text": "of residents are satisfied with the availability of arts and cultural events"
	}
]`,
  [WidgetType.OVERALL_SATISFACTION_TEXT]: `[{
	"score":{
		"positive":76,
		"negative":10,
		"neutral":14
	},
	"previousCycle": {
		"score": {
			"positive": 37,
			"neutral": 59,
			"negative": 6
		}
	},
	"totalSubmissions": 100,
	"text": "of 100 surveyed residents are satisfied with life in  Springfield",
	"emphasizedText": "satisfied"
}]`,
  [WidgetType.OVERALL_BAR_CHART]: `{
	"score":{
		"positive":58,
		"neutral":38,
		"negative":4
	},
	"previousCycle": {
		"score": {
			"positive": 37,
			"neutral": 59,
			"negative": 6
		}
	}
}`,
  [WidgetType.SERVICES_BAR_CHART]: `[
	{
		"score":{
			"positive":41,
			"negative":4,
			"neutral":55
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"How is the overall quality of life in %client_name%?",
		"aspectValue":"quality_of_life"
	},
	{
		"score":{
			"positive":41,
			"negative":14,
			"neutral":45
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"How likely are you to recommend %client_name% as a place to live?",
		"aspectValue":"quality_of_life"
	},
	{
		"score":{
			"positive":48,
			"negative":13,
			"neutral":39
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"How likely are you to be living in %client_name% 5 years from now?",
		"aspectValue":"quality_of_life"
	},
	{
		"score":{
			"positive":41,
			"negative":11,
			"neutral":48
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Availability of affordable housing",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":28,
			"negative":18,
			"neutral":54
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Availability of jobs that pay a living wage",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":10,
			"negative":8,
			"neutral":75
		},
		"previousCycle": {
			"score": {
			  "positive": 10,
			  "negative": 4,
			  "neutral": 79
			}
		},
		"text":"Access to quality health care services",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":36,
			"negative":12,
			"neutral":52
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Access to quality education",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":39,
			"negative":12,
			"neutral":49
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Ease of getting around by public transportation",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":32,
			"negative":15,
			"neutral":53
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Maintenance of roads and bridges",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":35,
			"negative":15,
			"neutral":50
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Sense of overall safety",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":56,
			"negative":7,
			"neutral":37
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Acceptance of residents of all backgrounds",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":35,
			"negative":13,
			"neutral":52
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Ability of residents to give input to the %client_name% government",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":48,
			"negative":7,
			"neutral":45
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Quality of parks and recreational amenities",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":52,
			"negative":6,
			"neutral":42
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Quality of the library system",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":95,
			"negative":0,
			"neutral":5
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Quality of waste and recycling services",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":34,
			"negative":10,
			"neutral":56
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Ease of the permit and zoning process",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":35,
			"negative":11,
			"neutral":54
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Access to social services",
		"aspectValue":"characteristic"
	},
	{
		"score":{
			"positive":36,
			"negative":11,
			"neutral":53
		},
		"previousCycle": {
			"score": {
			  "positive": 37,
			  "negative": 4,
			  "neutral": 59
			}
		},
		"text":"Overall quality of services provided by %client_name%",
		"aspectValue":"characteristic"
	}
]`,
  [WidgetType.BENCHMARK_GENERIC_QUESTIONS_TABLE]: `[
		{
			"generic_question_id": 1,
			"generic_question_text": "How would you rate the cleanliness of the facility?",
			"aspect_value": "characteristics",
			"overall_score": 0.825,
			"benchmark_scores": {
				"national": { "positive": 0.564, "negative": 0.158, "neutral": 0.278 },
				"sub_region": { "positive": 0.732, "negative": 0.095, "neutral": 0.173 },
				"cohort": { "positive": 0.694, "negative": 0.095, "neutral": 0.211 },
				"size_group": { "positive": 0.815, "negative": 0.055, "neutral": 0.130 }
			}
		},
		{
			"generic_question_id": 2,
			"generic_question_text": "How would you rate the quality of the food?",
			"aspect_value": "quality_of_life",
			"overall_score": 0.764,
			"benchmark_scores": {
				"national": { "positive": 0.456, "negative": 0.256, "neutral": 0.288 },
				"sub_region": { "positive": 0.623, "negative": 0.123, "neutral": 0.254 },
				"cohort": { "positive": 0.695, "negative": 0.132, "neutral": 0.173 },
				"size_group": { "positive": 0.835, "negative": 0.095, "neutral": 0.092 }
			}
		},
		{
			"generic_question_id": 3,
			"generic_question_text": "How helpful were the staff members?",
			"aspect_value": "characteristics",
			"overall_score": 0.921,
			"benchmark_scores": {
				"national": { "positive": 0.732, "negative": 0.095, "neutral": 0.173 },
				"sub_region": { "positive": 0.853, "negative": 0.032, "neutral": 0.115 },
				"cohort": { "positive": 0.921, "negative": 0.032, "neutral": 0.047 },
				"size_group": { "positive": 0.912, "negative": 0.042, "neutral": 0.046 }
			}
		},
		{
			"generic_question_id": 4,
			"generic_question_text": "How comfortable was your stay?",
			"aspect_value": "quality_of_life",
			"overall_score": 0.698,
			"benchmark_scores": {
				"national": { "positive": 0.547, "negative": 0.232, "neutral": 0.219 },
				"sub_region": { "positive": 0.654, "negative": 0.132, "neutral": 0.213 },
				"cohort": { "positive": 0.698, "negative": 0.132, "neutral": 0.159 },
				"size_group": { "positive": 0.721, "negative": 0.079, "neutral": 0.200 }
			}
		}
]`,
  [WidgetType.SENTIMENT_BAR_GROUPS]: `[
	{
		"groupTitle":"Age",
		"groupItems":[
			{
				"score":{
					"positive":92,
					"negative":4,
					"neutral":4
				},
				"text":"18–34"
			},
			{
				"score":{
					"positive":80,
					"negative":6,
					"neutral":14
				},
				"text":"35–54"
			},
			{
				"score":{
					"positive":76,
					"negative":10,
					"neutral":14
				},
				"text":"55+"
			}
		]
	},
	{
		"groupTitle":"Children under 18",
		"groupItems":[
			{
				"score":{
					"positive":92,
					"negative":4,
					"neutral":4
				},
				"text":"Yes"
			},
			{
				"score":{
					"positive":80,
					"negative":6,
					"neutral":14
				},
				"text":"No"
			}
		]
	}
]`,
  [WidgetType.SENTIMENT_BAR_OVERALL_TABLE]: `{
    "qualityOfLifeLastCycleScores": [
        {
            "score": {
                "positive": 44,
                "negative": 14,
                "neutral": 42
            },
            "previousCycle": {
                "score": {
                    "positive": 47,
                    "negative": 18,
                    "neutral": 35
                }
            },
            "genericQuestionId": 15,
            "text": "How is the overall quality of life in Clayton County?",
            "aspectValue": "quality_of_life"
        },
        {
            "score": {
                "positive": 36,
                "negative": 28,
                "neutral": 36
            },
            "previousCycle": {
                "score": {
                    "positive": 41,
                    "negative": 24,
                    "neutral": 35
                }
            },
            "genericQuestionId": 16,
            "text": "How likely are you to recommend Clayton County as a place to live?",
            "aspectValue": "quality_of_life"
        },
        {
            "score": {
                "positive": 47,
                "negative": 32,
                "neutral": 21
            },
            "previousCycle": {
                "score": {
                    "positive": 49,
                    "negative": 28,
                    "neutral": 23
                }
            },
            "genericQuestionId": 19,
            "text": "How likely are you to be living in Clayton County 5 years from now?",
            "aspectValue": "quality_of_life"
        }
    ],
    "characteristicsLastCycleScores": [
        {
            "score": {
                "positive": 54,
                "negative": 3,
                "neutral": 43
            },
            "previousCycle": {
                "score": {
                    "positive": 58,
                    "negative": 5,
                    "neutral": 37
                }
            },
            "text": "Average rating from the Community Characteristics questions"
        }
    ]
}`,
  [WidgetType.GENERAL_TABLE]: `[
	{
		"your_cohort": "City of Example, XY",
		"population_size": "100.000",
		"another_feature": "99.000",
		"another_feature2": "98.000"
	},
	{
		"your_cohort": "City of Example, XY",
		"population_size": "100.000",
		"another_feature": "99.000",
		"another_feature2": "98.000"
	},
	{
		"your_cohort": "City of Example, XY",
		"population_size": "100.000",
		"another_feature": "99.000",
		"another_feature2": "98.000"
	}
	]`,
  [WidgetType.SENTIMENT_BAR_AREAS_TABLE]: `[
    {
        "id": "5fa1110018d36e3a5cffe771",
        "name": "District 4",
		"totalSubmissions": 100,
        "score": {
            "positive": 60,
            "negative": 4,
            "neutral": 36
        },
        "previousCycle": {
            "score": {
                "positive": 60,
                "negative": 4,
                "neutral": 36
            }
        }
    },
    {
        "id": "5fa1110018d36e7b51ffe76f",
        "name": "District 2",
		"totalSubmissions": 100,
        "score": {
            "positive": 62,
            "negative": 10,
            "neutral": 28
        },
        "previousCycle": {
            "score": {
                "positive": 62,
                "negative": 10,
                "neutral": 28
            }
        }
    },
    {
        "id": "5fa1110018d36ea86fffe76e",
        "name": "District 1",
		"totalSubmissions": 100,
        "score": {
            "positive": 59,
            "negative": 3,
            "neutral": 38
        },
        "previousCycle": {
            "score": {
                "positive": 59,
                "negative": 3,
                "neutral": 38
            }
        }
    },
    {
        "id": "5fa1110018d36e2f8dffe770",
        "name": "District 3",
		"totalSubmissions": 100,
        "score": {
            "positive": 65,
            "negative": 3,
            "neutral": 32
        },
        "previousCycle": {
            "score": {
                "positive": 65,
                "negative": 3,
                "neutral": 32
            }
        }
    }
]`,
  [WidgetType.BENCHMARK_OVERALL_SATISFACTION_TEXT]: `{
    "benchmarkOverallScores": [
        {
            "start_date": "2023-01-01",
            "end_date": "2023-03-29",
            "scores": {
                "positive": 68,
                "negative": 3,
                "neutral": 29
            },
            "total_submissions": 6129,
            "benchmark_type": "national"
        },
        {
            "start_date": "2023-01-01",
            "end_date": "2023-03-29",
            "scores": {
                "positive": 74,
                "negative": 2,
                "neutral": 24
            },
            "total_submissions": 506,
            "benchmark_type": "cohort"
        },
        {
            "start_date": "2023-01-01",
            "end_date": "2023-03-29",
            "scores": {
                "positive": 74,
                "negative": 2,
                "neutral": 24
            },
            "total_submissions": 395,
            "benchmark_type": "sub_region"
        },
        {
            "start_date": "2023-01-01",
            "end_date": "2023-03-29",
            "scores": {
                "positive": 65,
                "negative": 5,
                "neutral": 30
            },
            "total_submissions": 1379,
            "benchmark_type": "size_group"
        }
    ],
    "communitySurveyOverallScores": [
        {
            "scores": {
                "positive": 71,
                "negative": 1,
                "neutral": 28
            },
            "total_submissions": 1370,
            "start_date": "2023-02-22",
            "end_date": "2023-03-31"
        }
    ],
    "overallScoreText": {
        "prefix": "The general resident satisfaction in New Castle County is",
        "satisfactionCohort": "lower",
        "suffixCohort": "than among its cohort and",
        "satisfactionNational": "higher",
        "suffixNational": "than the national average."
    }
}`,
  [WidgetType.CHOICES_QUESTION_CHART]: `{  
    "total_responses": 1077,
    "total_valid_responses": 987,
    "choices": [
        {
            "responseOptions": [
                {
                    "count": 316,
                    "validCount": 293,
                    "weightsSum": 303.79,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "Text message"
        },
        {
            "responseOptions": [
                {
                    "count": 456,
                    "validCount": 427,
                    "weightsSum": 429.64,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "Email"
        },
        {
            "responseOptions": [
                {
                    "count": 266,
                    "validCount": 246,
                    "weightsSum": 230.51,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "Newsletter"
        },
        {
            "responseOptions": [
                {
                    "count": 313,
                    "validCount": 288,
                    "weightsSum": 263.13,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "Social media"
        },
        {
            "responseOptions": [
                {
                    "count": 207,
                    "validCount": 186,
                    "weightsSum": 174.73,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "Mail"
        },
        {
            "responseOptions": [
                {
                    "count": 95,
                    "validCount": 84,
                    "weightsSum": 85.55,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "Face-to-face meetings"
        },
        {
            "responseOptions": [
                {
                    "count": 83,
                    "validCount": 76,
                    "weightsSum": 79.30,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "Online video meetings (e.g. Zoom)"
        },
        {
            "responseOptions": [
                {
                    "count": 254,
                    "validCount": 236,
                    "weightsSum": 229.26,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "County website"
        },
        {
            "responseOptions": [
                {
                    "count": 19,
                    "validCount": 17,
                    "weightsSum": 15.06,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "Podcast "
        },
        {
            "responseOptions": [
                {
                    "count": 66,
                    "validCount": 58,
                    "weightsSum": 59.15,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "CCTV 23 (Government Access Channel)"
        },
        {
            "responseOptions": [
                {
                    "count": 109,
                    "validCount": 98,
                    "weightsSum": 104.51,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "Radio"
        },
        {
            "responseOptions": [
                {
                    "count": 129,
                    "validCount": 120,
                    "weightsSum": 117.00,
                    "segmentLabel": ""
                }
            ],
            "responseContent": "Local Commercial Television Stations"
        }
    ]
}`,
  [WidgetType.MAINTAIN_AND_FOCUS_SERVICES]: `{
	"maintain": [
	  {
		"score": {
		  "positive": 41,
		  "negative": 4,
		  "neutral": 55
		},
		"previousCycle": {
		  "score": {
			"positive": 37,
			"negative": 4,
			"neutral": 59
		  }
		},
		"text": "How is the overall quality of life in %client_name%?",
		"aspectValue": "characteristic"
	  },
	  {
		"score": {
		  "positive": 41,
		  "negative": 14,
		  "neutral": 45
		},
		"previousCycle": {
		  "score": {
			"positive": 37,
			"negative": 4,
			"neutral": 59
		  }
		},
		"text": "How likely are you to recommend %client_name% as a place to live?",
		"aspectValue": "characteristic"
	},
	{
		"score": {
			"positive": 48,
			"negative": 13,
			"neutral": 39
		},
		"previousCycle": {
			"score": {
				"positive": 37,
				"negative": 4,
				"neutral": 59
			}
		},
		"text": "How likely are you to be living in %client_name% 5 years from now?",
		"aspectValue": "characteristic"
	  }
	],
	"focus": [
	  {
		"score": {
		  "positive": 48,
		  "negative": 7,
		  "neutral": 45
		},
		"previousCycle": {
		  "score": {
			"positive": 37,
			"negative": 4,
			"neutral": 59
		  }
		},
		"text": "Quality of parks and recreational amenities",
		"aspectValue": "characteristic"
	  },
	  {
		"score": {
		  "positive": 52,
		  "negative": 6,
		  "neutral": 42
		},
		"previousCycle": {
		  "score": {
			"positive": 37,
			"negative": 4,
			"neutral": 59
		  }
		},
		"text": "Quality of the library system",
		"aspectValue": "characteristic"
	  },
	  {
		"score": {
		  "positive": 95,
		  "negative": 0,
		  "neutral": 5
		},
		"previousCycle": {
		  "score": {
			"positive": 37,
			"negative": 4,
			"neutral": 59
		  }
		},
		"text": "Quality of waste and recycling services",
		"aspectValue": "characteristic"
	  }
	]
  }`,
  [WidgetType.QUESTIONNAIRE]: JSON.stringify(generateQuestionnaireWidgetMock(10)),
  [WidgetType.CROSS_TABS]: `{
	"crossQuestionsData": [
        {
            "Which of the following do you identify as?": [
                {
                    "Female": {
                        "score": {
                            "positive": 39.682,
                            "negative": 22.067999999999998,
                            "neutral": 38.25
                        },
                        "totalSubmissions": 300
                    }
                },
                {
                    "Male": {
                        "score": {
                            "positive": 36.515,
                            "negative": 28.28,
                            "neutral": 35.204
                        },
                        "totalSubmissions": 300
                    }
                },
                {
                    "Prefer to self-describe": {
                        "score": {
                            "positive": 18.006,
                            "negative": 39.468,
                            "neutral": 42.525
                        },
                        "totalSubmissions": 22
                    }
                }
            ]
        }
    ],
    "crossDemographicGeographicData": [
        {
            "age": [
								{
									"18-34": {
											"score": {
													"positive": 45.53,
													"negative": 22.141000000000002,
													"neutral": 32.329
											},
											"totalSubmissions": 200
									}
								},
                {
                    "35-54": {
                        "score": {
                            "positive": 30.686999999999998,
                            "negative": 31.308999999999997,
                            "neutral": 38.004
                        },
                        "totalSubmissions": 300
                    }
                },
                {
                    "55+": {
                        "score": {
                            "positive": 37.444,
                            "negative": 22.121000000000002,
                            "neutral": 40.435
                        },
                        "totalSubmissions": 45
                    }
                }
            ]
        }
    ],
    "scoreTextWithSentimentBar": [
        {
            "score": {
                "positive": 44,
                "negative": 24,
                "neutral": 32
            },
            "text": "of residents are satisfied with the availability of arts and cultural events"
        }
    ]
}`,
  [WidgetType.DEFAULT]: '',
};

export const DEFAULT_WIDGET_SIZE: Record<WidgetType, number> = {
  [WidgetType.WORD_CLOUD]: 800,
  [WidgetType.TOPICS_BUBBLE_CHART]: 1400,
  [WidgetType.SENTIMENT_BAR]: 600,
  [WidgetType.DEMOGRAPHICS]: 1700,
  [WidgetType.SERVICE_SENTIMENT_BAR]: 600,
  [WidgetType.OVERALL_SATISFACTION_TEXT]: 600,
  [WidgetType.OVERALL_BAR_CHART]: 1000,
  [WidgetType.SERVICES_BAR_CHART]: 3300,
  [WidgetType.SENTIMENT_BAR_GROUPS]: 600,
  [WidgetType.SENTIMENT_BAR_OVERALL_TABLE]: 600,
  [WidgetType.SENTIMENT_BAR_AREAS_TABLE]: 600,
  [WidgetType.BENCHMARK_GENERIC_QUESTIONS_TABLE]: 1300,
  [WidgetType.BENCHMARK_OVERALL_SATISFACTION_TEXT]: 474,
  [WidgetType.GENERAL_TABLE]: 1000,
  [WidgetType.CHOICES_QUESTION_CHART]: 1000,
  [WidgetType.MAINTAIN_AND_FOCUS_SERVICES]: 1500,
  [WidgetType.QUESTIONNAIRE]: 1000,
  [WidgetType.CROSS_TABS]: 1200,
  [WidgetType.DEFAULT]: 0,
};
