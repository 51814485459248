import { VerticalListWithSeparators } from '@zencity/common-ui';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { TypeformLanguageSelect } from 'components/TypeformLanguageSelect/TypeformLanguageSelect';
import { TypeformSurveyLanguageItem } from 'components/TypeformSurveyLanguageItem/TypeformSurveyLanguageItem';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { fetchEndScreensBySurveyId } from 'services/endScreen';
import { fetchGenericQuestionsByIds } from 'services/genericQuestion';
import { fetchQuestionsBySurveyId } from 'services/questions';
import { fetchSurveyById } from 'services/survey';
import { updateGenericQuestions } from 'slices/genericQuestion';
import { initialState as globalConfigInitialState } from 'slices/globalConfig';
import { setSurveyInStore } from 'slices/survey';
import { setCurrentLanguage, setSelectedTypeformSurvey } from 'slices/surveyManage';
import { RootState } from 'store';
import { Question } from 'types/questions';
import { logger } from 'utils/logger';
import { updateTranslationSections } from 'utils/surveyManage';
import styles from './SurveyManageList.module.scss';

interface Props {
  surveyId: number;
}

/**
 * The language menu for the Survey Manage screen. A user can select different languages
 * to add for a given survey to add translations and publish to Typeform.
 */
export const SurveyManageList = (props: Props): ReactElement => {
  const { surveyId } = props;
  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();
  const { typeformSurveysById } = useAppSelector((state: RootState) => state.surveyManage);
  const { defaultLanguage } = useAppSelector((state: RootState) => state.globalConfigs);
  const [translationsLoading, setTranslationsLoading] = useState(false);
  const currentSurveyTypeformSurveys = useMemo(
    () =>
      Object.values(typeformSurveysById).filter(
        (typeformSurvey) => typeformSurvey.survey === surveyId && !typeformSurvey.panel,
      ),
    [typeformSurveysById, surveyId],
  );

  /**
   * Fetches translations for a survey, it's questions,
   * and the relevant question's generic questions. Once they are fetched,
   * the Translation Sections are updated to reflect the fetched translations.
   */
  const fetchTranslations = (languages: string) => {
    try {
      // Fetch Questions for its `overridden_text`/`configs`.
      // The questions need to be fetched first, since they are required for fetching
      // the generic questions.
      dispatch(fetchQuestionsBySurveyId({ survey: surveyId, translations_languages: languages })).then(
        async (action: { payload: { results: Question[] } }) => {
          await dispatch(fetchEndScreensBySurveyId({ survey: surveyId, translations_languages: languages }));
          const translatedQuestions = action.payload.results as Question[];
          // Fetch the Survey, because it returns the SurveyGroup with its
          // `title_for_display`.
          const survey = await fetchSurveyById(surveyId, { translations_languages: languages });
          dispatch(setSurveyInStore(survey));

          // Fetch GenericQuestions for its `text`/`choices`/`configs`.
          const genericQuestionIds = translatedQuestions.map((instance) => instance.generic_question);
          const response = await fetchGenericQuestionsByIds(genericQuestionIds, languages);
          dispatch(updateGenericQuestions(response.results));
          dispatch(updateTranslationSections(surveyId));
          setTranslationsLoading(false);
        },
      );
    } catch (error) {
      toastError();
      logger.error(error);
    }
  };

  useEffect(
    () => () => {
      dispatch(setSelectedTypeformSurvey(undefined));
      dispatch(setCurrentLanguage(defaultLanguage));
    },
    [defaultLanguage, dispatch],
  );

  /**
   * Fetch the translations whenever the list of TypeformSurvey instances gets
   * updated (as each instance in charge of a different language).
   */
  useEffect(() => {
    const languages = currentSurveyTypeformSurveys.map((instance) => instance.language).join(',');
    if (!languages || languages === globalConfigInitialState.defaultLanguage) {
      // No need to fetch any translation.
      return;
    }
    setTranslationsLoading(true);
    fetchTranslations(languages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSurveyTypeformSurveys]);

  return (
    <div className={styles.surveyManageList}>
      <LoaderMask isLoading={translationsLoading}>
        <VerticalListWithSeparators
          className={styles.typeformSurveysList}
          removeLastChildBottomSeparator={false}
          removeFirstChildTopSeparator={false}
        >
          {currentSurveyTypeformSurveys.map((typeformSurvey) => (
            <TypeformSurveyLanguageItem key={`typeform-survey-${typeformSurvey.id}`} typeformSurvey={typeformSurvey} />
          ))}
        </VerticalListWithSeparators>
      </LoaderMask>
      <TypeformLanguageSelect typeformSurveys={currentSurveyTypeformSurveys} />
    </div>
  );
};
