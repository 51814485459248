/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TranslationSection } from 'components/SurveyTranslationsList/SurveyTranslationsList.helpers';
import { fetchTypeformSurveysBySurveyId } from 'services/typeformSurvey';
import { initialState as globalConfigInitialState } from 'slices/globalConfig';
import { PaginatedResults, RequestStatus } from 'types/misc';
import { QuestionItem, ResolvedIndexes } from 'types/questions';
import { TypeformSurvey } from 'types/typeformSurvey';
import { resolvedQuestionsIndexes } from 'utils/question';

export enum SurveyManageDisplay {
  TRANSLATION = 'translation',
  PREVIEW = 'preview',
}

export interface SurveyManageState {
  typeformSurveysById: { [typeformSurveyId: number]: TypeformSurvey };
  selectedTypeformSurvey?: TypeformSurvey;
  currentSurveyId?: number;
  fetchStatus: string;
  selectedLanguage: string;
  currentDisplay: SurveyManageDisplay;
  errorMessage?: string;
  resolvedIndexes: ResolvedIndexes;
  translationSections: TranslationSection[];
}

const initialState: SurveyManageState = {
  typeformSurveysById: {},
  selectedLanguage: globalConfigInitialState.defaultLanguage,
  fetchStatus: RequestStatus.IDLE,
  currentDisplay: SurveyManageDisplay.PREVIEW,
  resolvedIndexes: {},
  translationSections: [],
};

const slice = createSlice({
  name: 'surveyManage',
  initialState,
  reducers: {
    setCurrentSurveyId: (state: SurveyManageState, action: PayloadAction<number>) => {
      const surveyId = action.payload;
      state.currentSurveyId = surveyId;
    },
    setTypeformSurvey: (state: SurveyManageState, action: PayloadAction<TypeformSurvey>) => {
      const updatedTypeformSurvey = action.payload;
      state.typeformSurveysById[updatedTypeformSurvey.id] = updatedTypeformSurvey;
    },
    setTypeformSurveys: (state: SurveyManageState, action: PayloadAction<TypeformSurvey[]>) => {
      const updatedTypeformSurveys = action.payload;
      updatedTypeformSurveys.forEach((typeformSurvey) => {
        state.typeformSurveysById[typeformSurvey.id] = typeformSurvey;
      });
    },
    setCurrentLanguage: (state: SurveyManageState, action: PayloadAction<string>) => {
      const language = action.payload;
      state.selectedLanguage = language;
    },
    setCurrentDisplay: (state: SurveyManageState, action: PayloadAction<SurveyManageDisplay>) => {
      const surveyManageScreen = action.payload;
      state.currentDisplay = surveyManageScreen;
    },
    updateResolvedIndexes: (state: SurveyManageState, action: PayloadAction<QuestionItem[]>) => {
      state.resolvedIndexes = resolvedQuestionsIndexes(action.payload);
    },
    setTranslationSections: (state: SurveyManageState, action: PayloadAction<TranslationSection[]>) => {
      const translationSections = action.payload;
      state.translationSections = translationSections;
    },
    setSelectedTypeformSurvey: (state: SurveyManageState, action: PayloadAction<TypeformSurvey | undefined>) => {
      const selectedTypeformSurvey = action.payload;
      state.selectedTypeformSurvey = selectedTypeformSurvey;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTypeformSurveysBySurveyId.pending, (state: SurveyManageState) => {
        state.fetchStatus = RequestStatus.LOADING;
      })
      .addCase(
        fetchTypeformSurveysBySurveyId.fulfilled,
        (state: SurveyManageState, action: PayloadAction<PaginatedResults<TypeformSurvey>>) => {
          state.fetchStatus = RequestStatus.SUCCESS;
          const { results } = action.payload;
          results.forEach((typeformSurvey) => {
            state.typeformSurveysById[typeformSurvey.id] = typeformSurvey;
          });
        },
      )
      .addCase(fetchTypeformSurveysBySurveyId.rejected, (state: SurveyManageState, action) => {
        state.fetchStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      });
  },
});

export const {
  setCurrentSurveyId,
  setTypeformSurvey,
  setCurrentLanguage,
  setCurrentDisplay,
  updateResolvedIndexes,
  setTranslationSections,
  setTypeformSurveys,
  setSelectedTypeformSurvey,
} = slice.actions;
export default slice;
