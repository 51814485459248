import React, { ReactElement } from 'react';
import styles from './CheckboxAsSwitch.module.scss';

interface Props {
  inputId: string;
  labelText: string;
  isChecked: boolean;
  onCheckHandler: (checked: boolean) => void;
}

/**
 * A "checkbox" component with a look of a switch.
 */
export const CheckboxAsSwitch = (props: Props): ReactElement => {
  const { inputId, isChecked, labelText, onCheckHandler } = props;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckHandler(event.target.checked);
  };

  return (
    <label htmlFor={inputId} className={styles.checkboxAsSwitchWrapper}>
      {labelText}
      <input id={inputId} type="checkbox" checked={isChecked} onChange={onChangeHandler} />
      <span />
    </label>
  );
};
