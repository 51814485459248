import { NewGenericQuestionPayload } from 'types/genericQuestion';
import { QuestionConfigType, QuestionConfigForNewQuestion } from 'types/questionConfig';

/**
 * A helper to set/remove a config on the given payload.
 */
export const configSetter = (
  questionPayload: NewGenericQuestionPayload,
  configName: QuestionConfigType,
  configValue: string,
): QuestionConfigForNewQuestion[] => {
  const configs = [...questionPayload.configs];
  const configIndex = configs.findIndex(({ config_name }) => config_name === configName);

  if (!configValue) {
    if (configIndex > -1) {
      configs.splice(configIndex, 1);
    }
  } else {
    const configToSet = {
      config_name: configName,
      config_value: configValue,
    };
    if (configIndex > -1) {
      configs.splice(configIndex, 1, configToSet);
    } else {
      configs.push(configToSet);
    }
  }

  return configs;
};

/**
 * A helper to be able to find a config on the payload of the new question.
 *
 * @param configName The name of the config to find.
 * @param newQuestionPayload The payload to search in.
 */
export const getNewQuestionConfig = (
  configName: QuestionConfigType,
  newQuestionPayload: NewGenericQuestionPayload,
): QuestionConfigForNewQuestion | undefined =>
  newQuestionPayload.configs.find(({ config_name }) => config_name === configName);
