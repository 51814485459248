/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchQuestionsChoices } from 'services/questionChoice';
import { PaginatedResults, RequestStatus } from 'types/misc';
import { QuestionChoice } from 'types/questionChoice';
import { statusIsSuccessful } from 'utils/misc';
import { sortQuestionChoices } from 'utils/question';

export interface QuestionsChoicesState {
  questionsChoicesById: { [questionChoiceId: number]: QuestionChoice };
  questionsChoicesSortedByLabel: QuestionChoice[];
  fetchStatus: string;
  errorMessage?: string;
  nextPage?: number;
}

const initialState: QuestionsChoicesState = {
  questionsChoicesById: {},
  questionsChoicesSortedByLabel: [],
  nextPage: 1,
  fetchStatus: RequestStatus.IDLE,
};

/**
 * Helper function that sorts question choices by the label.
 */
const sortQuestionChoicesByLabel = (questionChoicesById: { [questionChoiceId: number]: QuestionChoice }) =>
  sortQuestionChoices(Object.values(questionChoicesById));

const slice = createSlice({
  name: 'questionsChoices',
  initialState,
  reducers: {
    addQuestionChoice: (state: QuestionsChoicesState, action: PayloadAction<QuestionChoice>) => {
      const questionChoice = action.payload;
      state.questionsChoicesById[questionChoice.id] = questionChoice;
      state.questionsChoicesSortedByLabel = sortQuestionChoicesByLabel(state.questionsChoicesById);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchQuestionsChoices.pending, (state: QuestionsChoicesState) => {
        state.fetchStatus = RequestStatus.LOADING;
      })
      .addCase(
        fetchQuestionsChoices.fulfilled,
        (state: QuestionsChoicesState, action: PayloadAction<PaginatedResults<QuestionChoice>>) => {
          const { results, next: nextUrl } = action.payload;
          state.nextPage = nextUrl && state.nextPage ? state.nextPage + 1 : undefined;

          // As we want to fetch the entire QuestionChoice instances, we'll
          // mark it as a success only when there is no next page to fetch,
          // otherwise we'll set it back as idled so the component will know to
          // send an additional request.
          state.fetchStatus = state.nextPage ? RequestStatus.IDLE : RequestStatus.SUCCESS;

          results.forEach((questionChoice) => {
            state.questionsChoicesById[questionChoice.id] = questionChoice;
          });

          // When the fetch process is complete, sort the choices once for
          // different usages within the app.
          if (statusIsSuccessful(state.fetchStatus)) {
            state.questionsChoicesSortedByLabel = sortQuestionChoicesByLabel(state.questionsChoicesById);
          }
        },
      )
      .addCase(fetchQuestionsChoices.rejected, (state: QuestionsChoicesState, action) => {
        state.fetchStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      });
  },
});

export const { addQuestionChoice } = slice.actions;
export default slice;
