import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import styles from './Header.module.scss';

interface BackLink {
  text: string;
  path: string;
}

interface Props {
  titleText: string;
  actions?: ReactElement[];
  backLink?: BackLink;
}

/**
 * Generic header component.
 * @param titleText - The text of the header.
 */
export const Header = (props: Props): ReactElement => {
  const { titleText, actions, backLink } = props;

  return (
    <div className={classnames(styles.header, { [styles.backLinkHeader]: !!backLink })}>
      {backLink && (
        <NavLink to={backLink.path} className={styles.backLink}>
          {backLink.text}
        </NavLink>
      )}
      <div className={styles.headerText}>
        <h1>{titleText}</h1>
        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
    </div>
  );
};
