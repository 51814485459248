import { ZCDInput } from '@zencity/common-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from '../FieldTitle/FieldTitle';

interface WidgetSelectionProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number;
}

export const WidgetWidthInput = ({ onChange, value }: WidgetSelectionProps): React.ReactElement => {
  const { t: translate } = useTranslation();
  return (
    <>
      <FieldTitle title={translate('widgetGenerator.widgetWidth')} />
      <ZCDInput
        placeholder={translate('widgetGenerator.widgetWidth')}
        type="number"
        onChange={onChange}
        value={value}
      />
    </>
  );
};
