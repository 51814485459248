import React from 'react';
import { SentimentBar } from 'screens/WidgetGenerator/components/SentimentBar/SentimentBar';
import { v4 } from 'uuid';
import { ScoreTextWithSentimentBarWidgetData } from '../../utils/misc';
import styles from './ScoreTextWithSentimentBar.module.scss';
import { OtherScores } from './OtherScores';
import { OvertimeScore } from './OvertimeScore';

interface ScoreTextWithSentimentBarProps {
  widgetData: ScoreTextWithSentimentBarWidgetData[];
  isBenchmarked?: boolean;
  showAllSatisfactionScores?: boolean;
  showSentimentBar?: boolean;
}

const satisfactionTextToDisplay = (satisfactionText: string, emphasizedText?: string): React.ReactElement => {
  const [textBeforeEmphasizedText, textAfterEmphasizedText] = emphasizedText
    ? satisfactionText.split(emphasizedText)
    : [];
  if (textBeforeEmphasizedText && textAfterEmphasizedText) {
    return (
      <p>
        {textBeforeEmphasizedText}
        <span>{emphasizedText} </span>
        {textAfterEmphasizedText}
      </p>
    );
  }
  return <p>{satisfactionText}</p>;
};

/**
 * This component is used by other widgets to display a more extensive sentiment data.
 * It may display the score with sentiment bar and/or with the sentiment change compared to the previous cycle.
 */
export function ScoreTextWithSentimentBar(props: ScoreTextWithSentimentBarProps): React.ReactElement {
  const { widgetData, isBenchmarked, showAllSatisfactionScores, showSentimentBar } = props;
  return (
    <div className={styles.scoreTextWithSentimentBar}>
      {widgetData.map((element) => {
        const { score, previousCycle } = element;
        const previousScore = isBenchmarked && previousCycle ? previousCycle.score : undefined;

        const scoreText = element.text ? satisfactionTextToDisplay(element.text, element.emphasizedText) : '';

        return (
          <div key={v4()}>
            <p className={styles.positiveScore}>{score ? score.positive : 'XX'}%</p>
            <div className={styles.scoreText}>{scoreText}</div>
            <div className={styles.sentimentBarOvertime}>
              {previousScore && <OvertimeScore score={score} previousCycleScore={previousScore} />}
              {showSentimentBar && (
                <SentimentBar
                  positive={score?.positive}
                  neutral={score?.neutral}
                  negative={score?.negative}
                  positiveText={false}
                />
              )}
            </div>
            {showAllSatisfactionScores && (
              <OtherScores score={widgetData[0].score} previousCycleScore={previousScore} />
            )}
          </div>
        );
      })}
    </div>
  );
}
