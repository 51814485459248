import React from 'react';

type WithoutCustomSize = {
  size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  customSize?: never;
  orientation: 'vertical' | 'horizontal';
};

type WithCustomSize = {
  customSize: number;
  size?: never;
  orientation: 'vertical' | 'horizontal';
};

type Props = WithoutCustomSize | WithCustomSize;

const sizeDictionary = {
  xsmall: 4,
  small: 8,
  medium: 16,
  large: 32,
  xlarge: 64,
};
/* 
    This component display space according to the size and orientation.
 */
export const Spacer = ({ orientation, size = 'xsmall', customSize }: Props): React.ReactElement | null => (
  <div
    style={{
      padding:
        orientation === 'horizontal'
          ? `0px ${customSize ?? sizeDictionary[size]}px`
          : `${customSize ?? sizeDictionary[size]}px 0px`,
    }}
  />
);
