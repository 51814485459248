/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Translations } from 'types/misc';
import { SurveyType } from 'types/survey';
import { PanelVendor } from 'types/typeformSurvey';

export interface GlobalConfigsState {
  languages: Translations;
  defaultScaleQuestionSteps: number;
  defaultLanguage: string;
  recurringSurveyTypes: SurveyType[];
  panelVendors: PanelVendor[];
  volumeTrackerUrl: string;
  demographicTypes: Record<string, string>;
  allSurveyTypes: SurveyType[];
}

export const initialState: GlobalConfigsState = {
  languages: {
    en: 'English',
  },
  defaultScaleQuestionSteps: 5,
  defaultLanguage: 'en',
  recurringSurveyTypes: [],
  panelVendors: [],
  volumeTrackerUrl: '',
  demographicTypes: {},
  allSurveyTypes: [],
};

/**
 * This slice will contain global configurations and data from Vault.
 */
const slice = createSlice({
  name: 'globalConfigs',
  initialState,
  reducers: {
    setGlobalConfigs: (state: GlobalConfigsState, action: PayloadAction<GlobalConfigsState>) => {
      const {
        languages,
        defaultScaleQuestionSteps,
        defaultLanguage,
        recurringSurveyTypes,
        panelVendors,
        volumeTrackerUrl,
        demographicTypes,
        allSurveyTypes,
      } = action.payload;

      // Redux requires setting each state value explicitly.
      state.languages = languages;
      state.defaultLanguage = defaultLanguage;
      state.defaultScaleQuestionSteps = defaultScaleQuestionSteps;
      state.recurringSurveyTypes = recurringSurveyTypes;
      state.panelVendors = panelVendors;
      state.volumeTrackerUrl = volumeTrackerUrl;
      state.demographicTypes = demographicTypes;
      state.allSurveyTypes = allSurveyTypes;
    },
  },
});

export const { setGlobalConfigs } = slice.actions;
export default slice;
