import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { END_SCREEN_SELECT_OPTION } from 'components/LogicActionDialog/components/LogicActionForm/LogicActionForm';
import { EndScreen } from 'types/endScreen';
import {
  ActionType,
  DestinationType,
  LogicActionItem,
  LogicActionPayloadItem,
  OperationType,
  VarType,
} from 'types/logicAction';
import { Question, QuestionItem } from 'types/questions';
import { isStringType } from 'utils/misc';

interface ConvertLogicActionParams {
  questionItem: QuestionItem;
  questions: Question[];
  endScreens: EndScreen[];
  questionChoiceOptions: SelectOption[];
}

export const convertLogicActionsToLogicActionItems = (params: ConvertLogicActionParams): LogicActionItem[] => {
  const { questionItem, questions, endScreens, questionChoiceOptions } = params;
  return questionItem.item.logic_actions.map((logicAction) => {
    let destinationOption: SelectOption;
    if (logicAction.destination_question) {
      destinationOption = {
        label: questions.find((instance) => instance.id === logicAction.destination_question)?.text ?? '',
        value: `${DestinationType.QUESTION}-${logicAction.destination_question}`,
      };
    } else if (logicAction.destination_end_screen) {
      destinationOption = {
        label: endScreens.find((instance) => instance.id === logicAction.destination_end_screen)?.title ?? '',
        value: `${DestinationType.ENDING_SCREEN}-${logicAction.destination_end_screen}`,
      };
    } else {
      destinationOption = END_SCREEN_SELECT_OPTION;
    }

    const questionChoiceOption = questionChoiceOptions.find(
      (option) => option.value === logicAction.condition.vars[1].value,
    );

    if (!questionChoiceOption) {
      throw new Error('Error converting the question logic actions to logic action items.');
    }

    return {
      // There is no unique property combination for the key, so UUID is used.
      id: logicAction.id,
      originQuestionId: questionItem.item.id,
      questionChoiceOption,
      destinationOption,
      deleted: false,
    };
  });
};

interface LogicActionHelperParams {
  questionItem: QuestionItem;
  questions: Question[];
  endScreens: EndScreen[];
  questionChoiceOptions: SelectOption[];
  logicActionItems: LogicActionItem[];
}

/**
 * Filter out logic actions from the original fetched logic actions, so that only
 * created/deleted/updated logic items from the originals are returned.
 */
const filterUnchangedLogicActionItems = (params: LogicActionHelperParams) => {
  const { questionItem, questions, endScreens, questionChoiceOptions, logicActionItems } = params;
  const convertedOriginalLogicActions = convertLogicActionsToLogicActionItems({
    questionItem,
    questions,
    endScreens,
    questionChoiceOptions,
  });

  // Only send updated/deleted/created logic actions to the request.
  const filteredLogicActionItems = logicActionItems.filter((logicActionItem) => {
    const unchangedLogicAction = !convertedOriginalLogicActions.some(
      (convertedLogicAction) =>
        convertedLogicAction.questionChoiceOption === logicActionItem.questionChoiceOption &&
        convertedLogicAction.destinationOption === logicActionItem.destinationOption &&
        convertedLogicAction.deleted === logicActionItem.deleted,
    );
    const isDeletedLocalLogicAction = isStringType(logicActionItem.id) && logicActionItem.deleted;
    return unchangedLogicAction || isDeletedLocalLogicAction;
  });

  return filteredLogicActionItems;
};

/**
 * Compares the logic action items to the initial logic actions, and filters any logic action items
 * that are unedited. Then, converts the logic actions from the LogicActionItem interface format to the expected payload
 * LogicAction format.
 */
export const buildLogicActionPayload = (params: LogicActionHelperParams): LogicActionPayloadItem[] => {
  const { questionItem, questions, endScreens, questionChoiceOptions, logicActionItems } = params;
  const filteredLogicActionItems = filterUnchangedLogicActionItems({
    questionItem,
    questions,
    endScreens,
    questionChoiceOptions,
    logicActionItems,
  });

  return filteredLogicActionItems.reduce<LogicActionPayloadItem[]>((logicActionPayloadItems, logicActionItem) => {
    // logic action destination is an end screen when the defult end screen option is selected
    // or when the destination is an end screen (by value).
    const isEndScreen =
      logicActionItem.destinationOption === END_SCREEN_SELECT_OPTION ||
      (<string>logicActionItem.destinationOption?.value).includes(DestinationType.ENDING_SCREEN);
    const originQuestionId = questionItem.item.id;
    const updatedQuestionLogicAction = questionItem.item.logic_actions.find(
      (originalLogicAction) => originalLogicAction.id === logicActionItem.id,
    );

    // The lgoic action item was created and deleted before the request to create
    // it was ever sent.
    if (!updatedQuestionLogicAction && logicActionItem.deleted) {
      return logicActionPayloadItems;
    }

    logicActionPayloadItems.push({
      ...(updatedQuestionLogicAction && { id: updatedQuestionLogicAction.id }),
      origin_question: originQuestionId,
      destination_question: isEndScreen
        ? null
        : parseInt((<string>logicActionItem.destinationOption?.value).replace(`${DestinationType.QUESTION}-`, '')),
      destination_end_screen: isEndScreen
        ? parseInt(
            (<string>logicActionItem.destinationOption?.value).replace(`${DestinationType.ENDING_SCREEN}-`, ''),
          ) || null
        : null,
      action_type: ActionType.JUMP,
      destination_type: isEndScreen ? DestinationType.ENDING_SCREEN : DestinationType.QUESTION,
      condition: {
        op: OperationType.IS,
        vars: [
          {
            type: VarType.FIELD,
            value: `${originQuestionId}`,
          },
          {
            type: VarType.CHOICE,
            value: logicActionItem.questionChoiceOption?.value as string,
          },
        ],
      },
      deleted: logicActionItem.deleted,
    });

    return logicActionPayloadItems;
  }, []);
};

interface LogicActionPayloadItemsByQuestionId {
  [questionId: number]: LogicActionPayloadItem[];
}

/**
 * Get all Question Logic Actions that have this question as a destination,
 * and delete them.
 */
export const getDeletedDestinationLogicActions = (
  deletedItemId: number,
  questions: Question[],
  destinationType: DestinationType,
): LogicActionPayloadItemsByQuestionId =>
  questions.reduce<LogicActionPayloadItemsByQuestionId>((deletedLogicActionsByQuestionId, question) => {
    const { logic_actions: logicActions, id: questionId } = question;
    const logicActionsToDelete = logicActions.reduce<LogicActionPayloadItem[]>(
      (logicActionPayloadItems, logicAction) => {
        const shouldDeleteLogicAction =
          (destinationType === DestinationType.ENDING_SCREEN && logicAction.destination_end_screen === deletedItemId) ||
          (destinationType === DestinationType.QUESTION && logicAction.destination_question === deletedItemId);

        if (shouldDeleteLogicAction) {
          const logicActionPayloadItem: LogicActionPayloadItem = {
            ...logicAction,
            origin_question: questionId,
            // Add the `deleted` key to indicate that this logic action should be deleted.
            deleted: true,
          };
          logicActionPayloadItems.push(logicActionPayloadItem);
        }
        return logicActionPayloadItems;
      },
      [],
    );

    const updatedDeletedLogicActionsByQuestionId = {
      ...deletedLogicActionsByQuestionId,
    };
    if (logicActionsToDelete.length) {
      updatedDeletedLogicActionsByQuestionId[questionId] = logicActionsToDelete;
    }
    return updatedDeletedLogicActionsByQuestionId;
  }, {});
