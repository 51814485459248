/* eslint-disable max-lines */
import { SurveyGroup } from '@zencity/survey-types';
import React from 'react';
import { LeanClient } from 'types/client';

import { SelectedDateRange, SurveyCycle } from '../utils/misc';

export type WidgetGeneratorContextProps = BaseInterface;

interface BaseInterface {
  client: LeanClient;
  selectedDateRange: SelectedDateRange;
  selectedSurveyGroup: SurveyGroup;
  cycles: SurveyCycle[];
}

export const WidgetGeneratorContext = React.createContext<WidgetGeneratorContextProps>(
  {} as WidgetGeneratorContextProps,
);
