/* eslint-disable max-lines-per-function */
/* eslint-disable id-denylist */
import { IDocumentReactItemElement, ILandscapeHeaderElement } from '@zencity/reporting-types';
import { SurveyGroup } from '@zencity/survey-types';
import { ParsedRakeWeighterExecutions } from 'utils/rakeWeighterExecutions';
import {
  REPORT_BUILDER_PATH,
  SurveysReportGeneratorDataItemKey,
  SurveysReportGeneratorFilterKey,
} from '../../constants';
import { DocumentFragment } from '../documentFragment';
import { config } from '../../../../../../config/config';
import { lineBreakElement } from '../utils/lineBreakElement';
import { sectionCoverPage } from '../utils/sectionCoverPage';
import { TABLE_DEFAULT_BORDER_COLOR, reportTemplateTable, reportTemplateTableCell } from '../utils/tableTemplate';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';

interface WordCloudResponsesParams {
  clientName: string;
  surveyGroup: SurveyGroup;
  parsedMostRecentCycle?: ParsedRakeWeighterExecutions;
  reportDateRange: string;
  headerElement: ILandscapeHeaderElement;
}
interface QuestionCellParams {
  questionText: string;
  heading: string;
  reactItemElement: IDocumentReactItemElement;
  imageUrl: string;
}
const POSITIVE_ICON = `${REPORT_BUILDER_PATH}/positive-wordcloud.png`;
const NEGATIVE_ICON = `${REPORT_BUILDER_PATH}/negative-wordcloud.png`;
const questionCell = (params: QuestionCellParams) => {
  const { questionText, heading, reactItemElement, imageUrl } = params;
  return reportTemplateTableCell({
    data: [
      lineBreakElement,
      {
        name: 'paragraph',
        children: [
          {
            name: 'imageInline',
            attributes: {
              src: imageUrl,
            },
          },
        ],
      },
      {
        name: 'heading3',
        children: [
          {
            data: heading,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading5,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
      {
        name: 'paragraph',
        children: [
          {
            data: questionText,
            attributes: {
              fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
              fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
            },
          },
        ],
      },
      reactItemElement,
    ],
    tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
    tableCellWidth: '50%',
  });
};
export const wordCloudResponses = (params: WordCloudResponsesParams): DocumentFragment => {
  const wordCloudCoverPage = sectionCoverPage({
    titleChunks: ['Free-Text', 'Responses'],
  });

  // TODO: Add async logic to fetch the generic questions for the free text responses.
  // Will be covered in https://zencity.atlassian.net/browse/SRV-1075
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { surveyGroup, reportDateRange, parsedMostRecentCycle, clientName, headerElement } = params;
  const positiveQuestion = questionCell({
    heading: 'What residents love',
    questionText: `Question: What is your favorite thing about living in ${clientName}?`,
    reactItemElement: {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.WORD_CLOUD,
        params: {
          filters: {
            [SurveysReportGeneratorFilterKey.WORD_CLOUD_SHOW_QUESTION_TEXT]: true,
            [SurveysReportGeneratorFilterKey.WORD_CLOUD_GENERIC_QUESTION_SELECT]: {
              value: config.communitySurvey.PositiveSentimentGenericQuestionId,
            },
          },
        },
      },
    },
    imageUrl: POSITIVE_ICON,
  });
  const negativeQuestion = questionCell({
    heading: 'What residents want changed',
    questionText: `Question: What is the one thing you would change in ${clientName}?`,
    reactItemElement: {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.WORD_CLOUD,
        params: {
          filters: {
            [SurveysReportGeneratorFilterKey.WORD_CLOUD_SHOW_QUESTION_TEXT]: true,
            [SurveysReportGeneratorFilterKey.WORD_CLOUD_GENERIC_QUESTION_SELECT]: {
              value: config.communitySurvey.NegativeSentimentGenericQuestionId,
            },
          },
        },
      },
    },
    imageUrl: NEGATIVE_ICON,
  });
  const wordCloudTable = reportTemplateTable({
    tableColumns: [positiveQuestion, negativeQuestion],
    tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  });
  return [
    headerElement,
    ...wordCloudCoverPage,
    headerElement,
    wordCloudTable,
    {
      name: 'pageBreak',
    },
  ];
};
