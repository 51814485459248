import React from 'react';
import { DiffIndicator } from 'screens/WidgetGenerator/components/DiffIndicator/DiffIndicator';

import { SentimentScore } from '@zencity/survey-types';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/Flex/Flex';
import styles from './ScoreTextWithSentimentBar.module.scss';

interface ScoreTextWithSentimentBarProps {
  score: SentimentScore;
  previousCycleScore?: SentimentScore;
}

/**
 * This component shows the sentiment change compared to the previous cycle.
 */
export function OvertimeScore(props: ScoreTextWithSentimentBarProps): React.ReactElement {
  const { score, previousCycleScore } = props;
  const { t: translate } = useTranslation();

  return (
    <div className={styles.sentimentBarOvertime}>
      {previousCycleScore && (
        <Flex alignItems="center" className={styles.overtimeScore}>
          <DiffIndicator currentValue={score.positive} prevValue={previousCycleScore.positive} />
          <span className={styles.overtimeText}>
            {translate('widgetGenerator.sentimentBarOvertime.comparisonText')}
          </span>
        </Flex>
      )}
    </div>
  );
}
