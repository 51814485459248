import React, { ReactElement } from 'react';
import { GenericQuestion, QuestionType } from 'types/genericQuestion';
import { TeaserAdditionalInfo } from 'components/GenericQuestionTeaserView/TeaserAdditionalInfo/TeaserAdditionalInfo';
import styles from './GenericQuestionTeaserView.module.scss';
import { Choices } from './Choices';
import { Scale } from './Scale';
import { YesNo } from './YesNo';

/**
 * A teaser view of a generic question instance.
 */
export const GenericQuestionTeaserView = ({ instance }: { instance: GenericQuestion }): ReactElement => {
  const isYesNo = instance.question_type === QuestionType.YES_NO;
  const isChoices = instance.question_type === QuestionType.CHOICES;
  const isScale = instance.question_type === QuestionType.SCALE;

  return (
    <div className={styles.genericQuestionTeaser}>
      {instance && <div className={styles.text}>{instance.text}</div>}
      {isYesNo && <YesNo instance={instance} />}
      {isChoices && <Choices instance={instance} />}
      {isScale && <Scale instance={instance} />}
      {!isYesNo && !isChoices && !isScale && (
        <TeaserAdditionalInfo items={[]} keysPrefix={`generic-question-${instance.id}`} genericQuestion={instance} />
      )}
    </div>
  );
};
