interface FilterParamsProps {
  searchInputValue?: string;
  surveyTypeFilterValue?: string;
  clientFilterValue?: number;
  filteredResultsNextPage?: number;
}

/**
 * A helper function to build the params for fetching filtered results.
 */
export const getParamsForFilters = (props: FilterParamsProps): Record<string, string> => {
  const { searchInputValue, surveyTypeFilterValue, clientFilterValue, filteredResultsNextPage } = props;

  let params = {};
  if (searchInputValue) {
    params = {
      ...params,
      title: searchInputValue,
    };
  }
  if (clientFilterValue) {
    params = {
      ...params,
      client: clientFilterValue,
    };
  }
  if (surveyTypeFilterValue) {
    params = {
      ...params,
      survey_type: surveyTypeFilterValue,
    };
  }
  if (filteredResultsNextPage) {
    params = {
      ...params,
      page: filteredResultsNextPage,
    };
  }

  return params;
};
