/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import { User } from 'types/user';
import { sentryClient } from './sentry';

class Logger {
  scope: Sentry.Scope;

  constructor(user: User) {
    const scope = new Sentry.Scope();
    scope.setTag('product', 'survey-manager-app');
    scope.setUser({
      id: user.id,
      username: `${user.firstName} ${user.lastName}`,
      roles: user.roles,
    });
    this.scope = scope;
  }

  isUptimeUser(): boolean {
    return this.scope.getUser()?.username === 'Surveys - Uptime Survey Manager';
  }

  setDynamicTags(): void {
    const loc = window.location; // eslint-disable-line id-length
    this.scope.setTag('host', loc.origin);
    this.scope.setTag('path', loc.pathname + loc.search);
  }

  log(message: string | any, ...extraArgs: any): void { // eslint-disable-line id-length,prettier/prettier
    console.log(message, ...extraArgs);
  }

  info(message: string | any, ...extraArgs: any): void {
    console.info(message, ...extraArgs);
  }

  debug(message: string | any): void {
    console.debug(message);
    if (this.isUptimeUser()) {
      // Avoid spamming sentry as part of an Uptime check.
      return;
    }
    this.setDynamicTags();
    sentryClient?.captureMessage(message, Sentry.Severity.Debug, undefined, this.scope);
  }

  warn(message: string | any): void {
    console.warn(message);
    if (this.isUptimeUser()) {
      // Avoid spamming sentry as part of an Uptime check.
      return;
    }
    this.setDynamicTags();
    sentryClient?.captureMessage(message, Sentry.Severity.Warning, undefined, this.scope);
  }

  error(errorObj: Error | any): void {
    console.error(errorObj);
    if (this.isUptimeUser()) {
      // Avoid spamming sentry as part of an Uptime check.
      return;
    }
    this.setDynamicTags();
    sentryClient?.captureException(errorObj, undefined, this.scope);
  }
}

// Instantiate a logger with no user, until the entry component will invoke
// `initLogger()` and instantiate a new logger with the user's information.
let logger = new Logger({ id: '0', firstName: 'logger not', lastName: 'initiated' } as User); // eslint-disable-line import/no-mutable-exports

export const initLogger = function initLogger(user: User): void {
  logger = new Logger(user);
};

export { logger };
