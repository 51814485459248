import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericQuestion } from 'types/genericQuestion';
import { TeaserAdditionalInfo } from './TeaserAdditionalInfo/TeaserAdditionalInfo';

interface Props {
  instance: GenericQuestion;
}

/**
 * A helper component to display the additional information of a
 * `yes_no` question.
 */
export const YesNo = ({ instance }: Props): ReactElement => {
  const { t: translate } = useTranslation();

  const items = [
    {
      id: 'yes',
      label: translate('common.yes'),
    },
    {
      id: 'no',
      label: translate('common.no'),
    },
  ];

  return <TeaserAdditionalInfo items={items} keysPrefix="yes-no" genericQuestion={instance} />;
};
