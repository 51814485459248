import { ZCDIconButton, zcdNotify } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericQuestion } from 'types/genericQuestion';

interface Props {
  genericQuestion: GenericQuestion;
}

export const CopyTextToClipboard = ({ genericQuestion }: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const handleOnCopyToClipboard = () => {
    navigator.clipboard.writeText(genericQuestion.text);
    zcdNotify.success(translate('toastMessages.copiedToClipboard'));
  };
  return <ZCDIconButton onClick={handleOnCopyToClipboard} icon="copy-transparent" variant="menu" type="button" />;
};
