import queryString from 'qs';
import { LeanClient } from 'types/client';
import { QuestionType } from 'types/genericQuestion';
import { PaginatedResults } from 'types/misc';
import { Question } from 'types/questions';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';
import { logger } from 'utils/logger';
import { QuestionnaireWidgetData } from '../utils/misc';
import { filterQuestionnaireData, formatQuestionnaireData } from '../widgets/Questionnaire/helpers';

const apiUrl = generateVaultApiUrl('/api/v2/questions/');
const axiosInstance = createAxiosInstance(apiUrl);
const MAX_PAGE_SIZE = 60;

const FALLBACK_RESPONSE: Question[] = [
  {
    id: 1,
    aspect: {
      id: 1,
      value: 'quality_of_life',
      label: 'Quality of Life',
    },
    text: '*FALL BACK QUESTION* How satisfied are you with your life?',
    question_type: QuestionType.OPEN_ENDED,
    choices: [],
    configs: [],
    generic_question: 1,
    group_question: null,
    index: 1,
    logic_actions: [],
    overridden_text: null,
    overridden_text_to_speech: null,
    survey: 1,
    updated_at: '2020-10-01T12:00:00Z',
    translations: {
      overridden_text: {},
      overridden_text_to_speech: {},
    },
  },
];

interface QuestionnaireParams {
  aspectIds?: number[];
  surveyId?: number;
  client: LeanClient;
}
export const fetchQuestionnaireData = async ({
  client,
  surveyId,
  aspectIds,
}: QuestionnaireParams): Promise<QuestionnaireWidgetData[]> => {
  try {
    const result = await axiosInstance.get<PaginatedResults<Question>>('', {
      params: {
        aspect_ids: aspectIds,
        survey: surveyId,
        include_generic_configs: true,
        page_size: MAX_PAGE_SIZE,
        hide_is_ignored: true,
      },
      headers: { 'X-Zc-Client-Id': client.zc_client_id },
      paramsSerializer: (params) => queryString.stringify(params, { encode: false, arrayFormat: 'repeat' }),
    });
    const filteredQuestions = filterQuestionnaireData(result.data.results);
    return formatQuestionnaireData(filteredQuestions);
  } catch (error) {
    logger.error(error);
    return formatQuestionnaireData(FALLBACK_RESPONSE);
  }
};
