interface FilterParamsProps {
  searchInputValue?: string;
  questionTypeFilterValue?: string;
  filteredResultsNextPage?: number;
}

/**
 * A helper function to build the params for fetching filtered results.
 */
export const getParamsForFilters = (props: FilterParamsProps): Record<string, string> => {
  const { searchInputValue, questionTypeFilterValue, filteredResultsNextPage } = props;

  let params = {};
  if (searchInputValue) {
    params = {
      ...params,
      text: searchInputValue,
    };
  }
  if (questionTypeFilterValue) {
    params = {
      ...params,
      question_type: questionTypeFilterValue,
    };
  }
  if (filteredResultsNextPage) {
    params = {
      ...params,
      page: filteredResultsNextPage,
    };
  }

  return params;
};
