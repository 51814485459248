/* eslint-disable max-lines */
import { GenericQuestion, QuestionType } from 'types/genericQuestion';
import { Question, ResolvedIndexes } from 'types/questions';
import { Translations } from 'types/misc';
import { Survey } from 'types/survey';
import { TranslatableField } from 'types/translation';
import { QuestionConfigType } from 'types/questionConfig';
import { getQuestionConfig, getGenericQuestionConfig } from 'utils/genericAndQuestionConfigs';
import { EndScreen } from 'types/endScreen';

export interface TranslationSection {
  key: string;
  translationItems: TranslationItem[];
  resolvedIndex?: string;
}

export interface TranslationItem {
  // The ID of the instance from which the translation is derived.
  instanceId: number;
  fieldName: TranslatableField;
  textToTranslate: string;
  translatedTexts: Translations;
  originalTextToTranslate?: string;
  originalTranslatedTexts?: Translations;
  resolvedIndex?: string;
}

/**
 * Gets a question config value's translations and turns it into a TranslationItem.
 * If the value belongs to the question, then it is prioritized over the
 * generic question.
 *
 * NOTE: Currently, the only config translations are scale labels and description,
 * and the field names are determined from such.
 */
const getQuestionConfigTranslationItem = (
  question: Question,
  genericQuestion: GenericQuestion,
  configType: QuestionConfigType,
): TranslationItem | undefined => {
  const questionConfig = getQuestionConfig(question, configType);
  const genericQuestionConfig = getGenericQuestionConfig(genericQuestion, configType);
  if (!questionConfig) {
    if (!genericQuestionConfig) {
      // There are no configs for the config type.
      return undefined;
    }
    // Use the generic question's config type translations.
    return {
      instanceId: genericQuestionConfig.id,
      fieldName:
        configType === QuestionConfigType.DESCRIPTION
          ? TranslatableField.GENERIC_QUESTION_DESCRIPTION
          : TranslatableField.GENERIC_QUESTION_SCALE_LABEL,
      textToTranslate: genericQuestionConfig.config_value,
      translatedTexts: genericQuestionConfig.translations?.config_value ?? {},
    };
  }
  // Use the question's config type translations.
  return {
    instanceId: questionConfig.id,
    fieldName:
      configType === QuestionConfigType.DESCRIPTION
        ? TranslatableField.QUESTION_DESCRIPTION
        : TranslatableField.QUESTION_SCALE_LABEL,
    textToTranslate: questionConfig.config_value,
    translatedTexts: questionConfig.translations?.config_value ?? {},
    originalTextToTranslate: genericQuestionConfig?.config_value,
    originalTranslatedTexts: genericQuestionConfig?.translations?.config_value,
  };
};

/**
 * Get a multiple choice question's choices options translations
 * and turn them into translation items.
 */
const getQuestionChoicesTranslationOptions = (genericQuestion: GenericQuestion): TranslationItem[] => {
  const { choices: questionChoices } = genericQuestion;
  return questionChoices.map((questionChoice) => ({
    instanceId: questionChoice.id,
    textToTranslate: questionChoice.label,
    fieldName: TranslatableField.QUESTION_CHOICE_LABEL,
    translatedTexts: questionChoice.translations?.label ?? {},
  }));
};

/**
 * Checks for scale label configs and returns all of the relevant
 * translation items.
 */
// eslint-disable-next-line max-statements
const getScaleLabelTranslationItems = (question: Question, genericQuestion: GenericQuestion): TranslationItem[] => {
  const translationItems: TranslationItem[] = [];

  // Get the first scale label.
  const scaleLabelFirstTranslationItem = getQuestionConfigTranslationItem(
    question,
    genericQuestion,
    QuestionConfigType.SCALE_LABEL_FIRST,
  );
  if (scaleLabelFirstTranslationItem) {
    translationItems.push(scaleLabelFirstTranslationItem);
  }

  // Get the middle scale label.
  const scaleLabelMiddleTranslationItem = getQuestionConfigTranslationItem(
    question,
    genericQuestion,
    QuestionConfigType.SCALE_LABEL_MIDDLE,
  );
  if (scaleLabelMiddleTranslationItem) {
    translationItems.push(scaleLabelMiddleTranslationItem);
  }

  // Get the last scale label.
  const scaleLabelLastTranlsationItem = getQuestionConfigTranslationItem(
    question,
    genericQuestion,
    QuestionConfigType.SCALE_LABEL_LAST,
  );
  if (scaleLabelLastTranlsationItem) {
    translationItems.push(scaleLabelLastTranlsationItem);
  }

  return translationItems;
};

/**
 * Reduces the translations from questions, including whether they are overridden
 * or not.
 */
const reduceQuestionsToTranslationSections = (
  questions: Question[],
  genericQuestionsById: { [genericQuestionId: number]: GenericQuestion },
  resolvedIndexes: ResolvedIndexes,
): TranslationSection[] =>
  // eslint-disable-next-line max-statements,complexity
  questions.reduce<TranslationSection[]>((translationSections, currentQuestion) => {
    const translationItems: TranslationItem[] = [];
    let translationSectionKey = '';
    const resolvedIndex = resolvedIndexes[currentQuestion.id];
    const hasOverriddenText = !!currentQuestion.overridden_text && currentQuestion.overridden_text.length > 0;
    const genericQuestion = genericQuestionsById[currentQuestion.generic_question];
    if (hasOverriddenText) {
      translationSectionKey = `question-${currentQuestion.id}-overridden-text`;
      translationItems.push({
        instanceId: currentQuestion.id,
        fieldName: TranslatableField.QUESTION_OVERRIDDEN_TEXT,
        textToTranslate: currentQuestion.text,
        translatedTexts: currentQuestion.translations?.overridden_text ?? {},
        originalTextToTranslate: genericQuestion?.text,
        originalTranslatedTexts: genericQuestion?.translations?.text,
        resolvedIndex,
      });
    } else {
      translationSectionKey = `generic-question-${genericQuestion.id}-text`;
      translationItems.push({
        instanceId: genericQuestion.id,
        fieldName: TranslatableField.GENERIC_QUESTION_TEXT,
        textToTranslate: genericQuestion?.text,
        translatedTexts: genericQuestion?.translations?.text ?? {},
        resolvedIndex,
      });
    }

    // Add the description translation item.
    const descriptionTranslationItem = getQuestionConfigTranslationItem(
      currentQuestion,
      genericQuestion,
      QuestionConfigType.DESCRIPTION,
    );
    if (descriptionTranslationItem) {
      translationItems.push(descriptionTranslationItem);
    }

    // Add the scale label translation items (if they exist).
    if (currentQuestion.question_type === QuestionType.SCALE) {
      const scaleLabelTranslationItems = getScaleLabelTranslationItems(currentQuestion, genericQuestion);
      if (scaleLabelTranslationItems.length) {
        translationItems.push(...scaleLabelTranslationItems);
      }
    }
    // Add the multiple choice questions options (if they exist).
    else if (currentQuestion.question_type === QuestionType.CHOICES) {
      const questionChoiceTranslationItems = getQuestionChoicesTranslationOptions(genericQuestion);
      if (questionChoiceTranslationItems.length) {
        translationItems.push(...questionChoiceTranslationItems);
      }
    }
    // Add the `QuestionChoice` translations (if they exist).
    else if (currentQuestion.question_type === QuestionType.DROPDOWN) {
      const questionChoiceTranslationItems = getQuestionChoicesTranslationOptions(genericQuestion);
      if (questionChoiceTranslationItems.length) {
        translationItems.push(...questionChoiceTranslationItems);
      }
    }

    const newTranlsationSection = {
      key: translationSectionKey,
      translationItems,
      resolvedIndex,
    };
    translationSections.push(newTranlsationSection);
    return translationSections;
  }, []);

/**
 * Gets the end screen title for display and creates a translation
 * section for it.
 */
const reduceEndScreensToTranslationSection = (endScreens: EndScreen[]): TranslationSection[] =>
  endScreens.reduce<TranslationSection[]>((translationSections, screen) => {
    const translationItems: TranslationItem[] = [];
    const translationSectionKey = `end-screen-${screen.id}-text`;
    // Add the title translation item.
    translationItems.push({
      instanceId: screen.id,
      fieldName: TranslatableField.END_SCREEN_TITLE,
      textToTranslate: screen.title,
      translatedTexts: screen?.translations?.title ?? {},
    });

    if (screen.description) {
      // Add the description translation item.
      translationItems.push({
        instanceId: screen.id,
        fieldName: TranslatableField.END_SCREEN_DESCRIPTION,
        textToTranslate: screen.description,
        translatedTexts: screen?.translations?.description ?? {},
      });
    }

    const newTranlsationSection = {
      key: translationSectionKey,
      translationItems,
    };
    translationSections.push(newTranlsationSection);
    return translationSections;
  }, []);

/**
 * Gets the survey title for display and creates a translation
 * section for it.
 */
const reduceSurveyGroupToTranslationSection = (currentSurvey: Survey): TranslationSection => {
  const originalSurveyTitleForDisplay = currentSurvey?.survey_group?.title_for_display;
  const surveyTitleTranslationItem: TranslationItem = {
    instanceId: currentSurvey.id,
    fieldName: TranslatableField.SURVEY_GROUP_TITLE_FOR_DISPLAY,
    textToTranslate: originalSurveyTitleForDisplay,
    translatedTexts: currentSurvey?.survey_group?.translations?.title_for_display ?? {},
  };
  return {
    key: `survey-group-${currentSurvey?.survey_group?.id}-title-for-display`,
    translationItems: [surveyTitleTranslationItem],
  };
};

/**
 * Reduces all necessary components into Translation Sections,
 * to be used for editing and viewing translations.
 */
export const reduceTranslationSections = (
  questions: Question[],
  currentSurvey: Survey,
  genericQuestionsById: { [genericQuestionId: number]: GenericQuestion },
  resolvedIndexes: ResolvedIndexes,
  endScreens: EndScreen[],
  // eslint-disable-next-line max-params
): TranslationSection[] => {
  const translationSections: TranslationSection[] = [];

  // Add survey group title.
  const surveyGroupTitleTranslationSection = reduceSurveyGroupToTranslationSection(currentSurvey);
  translationSections.push(surveyGroupTitleTranslationSection);

  // Add the questions.
  const reducedTranslationSectionsFromQuestions = reduceQuestionsToTranslationSections(
    questions,
    genericQuestionsById,
    resolvedIndexes,
  );
  translationSections.push(...reducedTranslationSectionsFromQuestions);

  translationSections.push(...reduceEndScreensToTranslationSection(endScreens));

  return translationSections;
};
