export default {
  environment: 'staging',
  apiBaseUrl: 'https://api-staging.zencity.io',
  vaultBaseUrl: 'https://vault-staging.zencity.io',
  insights: {
    domain: 'http://app-staging.zencity.io',
  },
  communitySurvey: {
    PositiveSentimentGenericQuestionId: 22,
    NegativeSentimentGenericQuestionId: 23,
    CharacteristicsAspectId: 4,
  },
  surveyManager: {
    baseRouterUrl: '/admin/surveys/survey-manager',
  },
};
