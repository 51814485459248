import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MissingTranslation.module.scss';

interface Props {
  text: string;
  requiredIndication?: React.ReactNode;
}

/**
 * A component to emphasize a content which is missing a translation.
 */
export const MissingTranslation = (props: Props): ReactElement => {
  const { text, requiredIndication } = props;
  const { t: translate } = useTranslation();

  return (
    <div className={styles.missingTranslationWrapper}>
      {text}
      {requiredIndication}
      <div className={styles.missingTranslationText}>{translate('common.missingTranslation')}</div>
    </div>
  );
};
