/* eslint-disable max-lines-per-function,max-statements */
import { Dialog, HorizontalSeparator, ZCDButton } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { LogicActionForm } from 'components/LogicActionDialog/components/LogicActionForm/LogicActionForm';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateQuestionLogicActions } from 'services/questions';
import { RootState } from 'store';
import { GenericQuestion } from 'types/genericQuestion';
import { LogicActionItem } from 'types/logicAction';
import { QuestionItem } from 'types/questions';
import { logger } from 'utils/logger';
import { statusIsError, statusIsLoading } from 'utils/misc';
import { buildLogicActionPayload, convertLogicActionsToLogicActionItems } from 'utils/questionLogicAction';
import { v4 as uuidv4 } from 'uuid';
import styles from './LogicActionDialog.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  questionItem: QuestionItem;
  genericQuestion: GenericQuestion;
}

/**
 * When this dialog is open, it will allow a user to create logic actions for a question.
 */
export const LogicActionDialog = (props: Props): ReactElement => {
  const { isOpen, setIsOpen, questionItem, genericQuestion } = props;
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { toastError } = useContext(SurveyManagerToastContext);

  const { resolvedIndexes } = useAppSelector((state: RootState) => state.surveyManage);
  const { questions, updateStatus, errorMessage } = useAppSelector((state: RootState) => state.questions);
  const { endScreens } = useAppSelector((state: RootState) => state.endScreens);
  const resolvedIndex = useMemo(() => resolvedIndexes[questionItem.id], [questionItem.id, resolvedIndexes]);

  const [logicActionItems, setLogicActionItems] = useState<LogicActionItem[]>([]);
  const filteredLogicActionItems = useMemo(
    () => logicActionItems.filter((logicActionItem) => !logicActionItem.deleted),
    [logicActionItems],
  );

  const allQuestionChoiceOptions: SelectOption[] =
    genericQuestion?.choices.map((questionChoice) => ({
      value: questionChoice.id.toString(),
      label: questionChoice.label,
    })) || [];

  // Filter out question choice options that have already been chosen.
  const filteredQuestionChoiceOptions = allQuestionChoiceOptions.filter((choiceOption) => {
    const questionChoiceAlreadyChosen = filteredLogicActionItems.some(
      (logicActionItem) => logicActionItem.questionChoiceOption?.value === choiceOption.value,
    );
    return !questionChoiceAlreadyChosen;
  });

  const closeDialog = () => {
    setIsOpen(false);
  };

  const handleOnSubmit = () => {
    if (logicActionItems) {
      const uniqueQuestionChoiceOptions = Array.from(
        new Set(logicActionItems.map((item) => item.questionChoiceOption)),
      );
      if (uniqueQuestionChoiceOptions.length === logicActionItems.length) {
        const formattedLogicActions = buildLogicActionPayload({
          questionItem,
          questions,
          endScreens,
          logicActionItems,
          questionChoiceOptions: allQuestionChoiceOptions,
        });
        dispatch(
          updateQuestionLogicActions({
            questionId: questionItem.item.id,
            logicActions: formattedLogicActions,
          }),
        );
      } else {
        toastError('Multiple logic actions with the same question choice were chosen.');
      }
    }
    closeDialog();
  };

  const handleOnAddClick = () => {
    setLogicActionItems((prevLogicActionItems) => [
      ...prevLogicActionItems,
      {
        // There is no unique property combination for the ID, so UUID is used.
        id: uuidv4(),
        originQuestionId: questionItem.item.id,
        deleted: false,
      },
    ]);
  };

  /**
   * Instead of deleting the logic action item, a soft-deletion will occur,
   * marking the item as deleted without removing it from the state.
   */
  const handleOnDeleteClick = (logicActionId: string | number) => {
    const updatedLogicActionItems = [...logicActionItems];
    const logicActionItem = updatedLogicActionItems.find((item) => item.id === logicActionId);
    if (!logicActionItem) {
      return;
    }
    const logicActionItemIndex = updatedLogicActionItems.indexOf(logicActionItem);
    updatedLogicActionItems[logicActionItemIndex].deleted = true;
    setLogicActionItems(updatedLogicActionItems);
  };

  /**
   * Check that the fetched question already has logic actions, to fill the forms
   * accordingly.
   */
  useEffect(() => {
    const convertedLogicActionItems = convertLogicActionsToLogicActionItems({
      questionItem,
      questions,
      endScreens,
      questionChoiceOptions: allQuestionChoiceOptions,
    });
    setLogicActionItems(convertedLogicActionItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionItem, questions, endScreens]);

  useEffect(() => {
    if (statusIsError(updateStatus)) {
      toastError(errorMessage);
      logger.error(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, errorMessage]);

  const logicActionItemsFilled = filteredLogicActionItems.every(
    (logicActionItem) => !!logicActionItem.questionChoiceOption && !!logicActionItem.destinationOption,
  );

  return (
    <LoaderMask isLoading={statusIsLoading(updateStatus)}>
      <Dialog
        key={`question-${questionItem.item.id}-logic-jump-dialog`}
        isOpen={isOpen}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={false}
        header={<h1>{translate('logicActionDialog.header', { resolvedIndex })}</h1>}
        footer={
          <div className={styles.footer}>
            <ZCDButton text={translate('common.cancel')} variant="link" onClick={closeDialog} />
            <ZCDButton text={translate('common.save')} disabled={!logicActionItemsFilled} onClick={handleOnSubmit} />
          </div>
        }
      >
        {filteredLogicActionItems.map((logicActionItem) => (
          <>
            <LogicActionForm
              key={logicActionItem.id}
              id={logicActionItem.id}
              questionItem={questionItem}
              questionChoiceOptions={
                logicActionItem.questionChoiceOption ? allQuestionChoiceOptions : filteredQuestionChoiceOptions
              }
              defaultDestinationOption={logicActionItem.destinationOption}
              defaultQuestionChoiceOption={logicActionItem.questionChoiceOption}
              setLogicActionItems={setLogicActionItems}
            />
            <div className={styles.deleteButton}>
              <ZCDButton
                variant="link"
                text={translate('logicActionDialog.deleteRule')}
                onClick={() => handleOnDeleteClick(logicActionItem.id)}
              />
            </div>

            <HorizontalSeparator />
          </>
        ))}
        {filteredLogicActionItems.length < allQuestionChoiceOptions.length && (
          <div className={styles.addButton}>
            <ZCDButton
              // Only add a rule when the current rule is finished.
              disabled={!logicActionItemsFilled}
              variant="link"
              text={translate('logicActionDialog.addRule')}
              onClick={handleOnAddClick}
            />
          </div>
        )}
      </Dialog>
    </LoaderMask>
  );
};
