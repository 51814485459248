import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppDispatch } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { writeQuestionConfig } from 'services/questions';
import { QuestionConfigType } from 'types/questionConfig';
import { QuestionItem } from 'types/questions';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  questionItem: QuestionItem;
}

/**
 * Dialog to remove an image_url QuestionConfig from a question.
 */
export const RemoveImageFromQuestionDialog = (props: Props): ReactElement => {
  const { isOpen, setIsOpen, questionItem } = props;
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOnRemoveImage = () => {
    const payload = {
      questionId: questionItem.item.id,
      config_name: QuestionConfigType.IMAGE_URL,
      config_value: null,
    };
    dispatch(writeQuestionConfig(payload));
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={translate('questionActionsMenu.removeImage')}
      submitBtnLabel={translate('common.delete')}
      confirmationHandler={handleOnRemoveImage}
    >
      <p>{translate('removeImageDialog.content')}</p>
    </ConfirmationDialog>
  );
};
