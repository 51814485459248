import { getCyclesStartingFromSelectedCycle } from 'screens/WidgetGenerator/utils/cycles';
import { LeanClient } from 'types/client';
import { logger } from 'utils/logger';
import { CommunitySurveyDemographicsScore } from 'types/communitySurveyScores';
import { fetchCommunitySurveyDemographicsScore } from 'services/communitySurveyScores';
import { SelectedDateRange, SentimentBarGroupsWidgetData, SurveyCycle } from '../utils/misc';
import { getDemographicLabel } from '../widgets/DemographicsTable/utils';

export const getDemographicsScoreData = async (
  client: LeanClient,
  cycles: SurveyCycle[],
  selectedDateRange: SelectedDateRange,
): Promise<CommunitySurveyDemographicsScore[]> => {
  const filteredCycles = getCyclesStartingFromSelectedCycle(selectedDateRange, cycles);
  const lastCycleIndex = 0;
  try {
    const demographicsScores = await fetchCommunitySurveyDemographicsScore(client.id, {
      start_date: filteredCycles[lastCycleIndex].startDate,
      end_date: filteredCycles[lastCycleIndex].endDate,
    });
    return demographicsScores;
  } catch (error) {
    logger.error(error);
    return [];
  }
};
export const getDemographicScoreByGroups = async (
  client: LeanClient,
  cycles: SurveyCycle[],
  selectedDateRange: SelectedDateRange,
): Promise<SentimentBarGroupsWidgetData[]> => {
  try {
    const demographicsScoresLastCycle = await getDemographicsScoreData(client, cycles, selectedDateRange);
    const demographicsTypesSet = new Set(
      demographicsScoresLastCycle.map((demographicScore) => demographicScore.demographic_type),
    );
    return Array.from(demographicsTypesSet).map((demographicType) => {
      const demographicsScores = demographicsScoresLastCycle.filter(
        (demographicScore) => demographicScore.demographic_type === demographicType,
      );
      const groupItems = demographicsScores.map((demographicScore) => ({
        score: demographicScore.scores[0]?.scores,
        text: demographicScore.demographic_label,
      }));
      return {
        groupTitle: getDemographicLabel(demographicType),
        groupItems,
      };
    });
  } catch (error) {
    logger.error(error);
    return [];
  }
};
