import { ZCDCheckbox, ZCDCheckboxVariant, ZCDFormGroup } from '@zencity/common-ui';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { configSetter, getNewQuestionConfig } from 'components/NewGenericQuestion/NewGenericQuestion.helpers';
import styles from 'components/NewGenericQuestion/subComponents/NewQuestionRightSection.module.scss';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { setNewQuestionConfigs } from 'slices/questionsBank';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';

/**
 * Displays the specific form section of the `scale` question type.
 */
export const ScaleRightSection = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const { newQuestionPayload } = useAppSelector((state: RootState) => state.questionsBank);

  const questionSteps = getNewQuestionConfig(QuestionConfigType.STEPS, newQuestionPayload);
  const startAtOne = getNewQuestionConfig(QuestionConfigType.START_AT_ONE, newQuestionPayload);

  const onQuestionScaleChange = (value: string) =>
    dispatch(setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.STEPS, value)));

  const handleOnStartAtOneConfigChange = (isChecked: boolean) => {
    dispatch(
      setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.START_AT_ONE, isChecked ? '1' : '')),
    );
  };

  // Currently, the allowed steps are between 5-11, thus, we use these magic
  // numbers to produce the `scaleOptions`.
  const scaleOptions = Array.from(Array(7)).map((unused, index) => {
    const step = index + 5;
    return {
      value: step,
      key: `scale-step-${step}`,
      label: step.toString(),
    };
  });

  return (
    <>
      <div className={styles.stepsDropdownWrapper}>
        <span>{translate('common.steps')}</span>
        <Dropdown
          selectedValue={questionSteps?.config_value}
          onChangeCallback={onQuestionScaleChange}
          options={scaleOptions}
          dropdownClassName={styles.stepsDropdown}
        />
      </div>
      <ZCDFormGroup>
        <ZCDCheckbox
          checked={!!startAtOne}
          label={translate('questionConfig.startAtOne')}
          variant={ZCDCheckboxVariant.TOGGLE}
          onChange={handleOnStartAtOneConfigChange}
        />
      </ZCDFormGroup>
    </>
  );
};
