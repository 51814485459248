/* eslint-disable max-lines */
import { createAsyncThunk } from '@reduxjs/toolkit';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { DebounceDelay } from 'constants/misc';
import { reduceQuestionsIntoGroups, mapQuestionIdToIndexAndGroup } from 'components/BuildForm/BuildForm.helpers';
import { Nullable, PaginatedResults, TranslationRequestPayload } from 'types/misc';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';
import { updateSurveyQuestionIndexesAndGroups } from 'services/survey';
import { Question } from 'types/questions';
import { QuestionConfig } from 'types/questionConfig';
import { LogicActionPayloadItem } from 'types/logicAction';
import { QuestionType } from 'types/genericQuestion';

const apiUrl = generateVaultApiUrl('/api/v2/questions/');
const axiosInstance = createAxiosInstance(apiUrl);
const MAX_PAGE_SIZE = 100;
const questionConfigApiUrl = generateVaultApiUrl('/api/v2/question_configs/');
const questionConfigAxiosInstance = createAxiosInstance(questionConfigApiUrl);
const groupedQuestionsApiUrl = generateVaultApiUrl('/api/v2/grouped_questions/');
const groupedQuestionsAxiosInstance = createAxiosInstance(groupedQuestionsApiUrl);

export const deleteQuestion = createAsyncThunk(
  'questions/delete',
  async (payload: { questionId: number; childrenIds: number[] }) => {
    const { questionId, childrenIds } = payload;
    await axiosInstance.delete(`${questionId}/?bypass_client_filter=true`);
    return { deletedQuestionsIds: [...childrenIds, questionId] };
  },
);

interface FetchQuestionsBySurveyIdParams {
  survey: number;
  translations_languages?: string;
}

export const fetchQuestionsBySurveyId = createAsyncThunk(
  'questions/fetchQuestions',
  async (params: FetchQuestionsBySurveyIdParams) => {
    const response = await axiosInstance.get<PaginatedResults<Question>>('', {
      params: {
        ...params,
        bypass_client_filter: true,
        page_size: MAX_PAGE_SIZE,
      },
    });
    return response.data;
  },
);

export async function fetchQuestionsBySurveyGroupId(params: {
  survey_group: number;
  question_type?: QuestionType;
  start_date?: string;
  end_date?: string;
}): Promise<Question[]> {
  const response = await axiosInstance.get<PaginatedResults<Question>>('', {
    params: {
      ...params,
      bypass_client_filter: true,
      page_size: MAX_PAGE_SIZE,
    },
  });

  return response.data.results;
}

export interface QuestionFilterParams {
  page_size?: number;
  survey?: number;
  survey_group?: number;
  survey_type?: string;
  question_type?: string;
  hide_from_live_feed: boolean;
  start_date?: string;
  end_date?: string;
}

export async function fetchGroupedQuestions(params: QuestionFilterParams): Promise<Question[]> {
  const result = await groupedQuestionsAxiosInstance.get<PaginatedResults<Question>>('', {
    params: {
      ...params,
      bypass_client_filter: true,
      page_size: MAX_PAGE_SIZE,
    },
  });

  return result.data.results;
}

const updateSurveyQuestionsDebounced = AwesomeDebouncePromise(
  updateSurveyQuestionIndexesAndGroups,
  DebounceDelay.DRAG_AND_DROP,
);

/**
 * After an update in the state's questions, each question's index and group question needs to be recalculated.
 * The questions are reduced into groups, then the index are recalculated.
 * Finally, a request to update the indexes in the DB is sent.
 */
export const updateIndexes = createAsyncThunk<
  void,
  undefined,
  { state: { questions: { questions: Question[]; currentSurveyId: number } } }
>('questions/updateIndexes', async (_, ThunkAPI) => {
  const { questions, currentSurveyId } = ThunkAPI.getState().questions;
  if (questions.length > 0) {
    const questionItems = reduceQuestionsIntoGroups(questions);
    const questionIndexMap = mapQuestionIdToIndexAndGroup(questionItems);
    await updateSurveyQuestionsDebounced(currentSurveyId, questionIndexMap);
  }
});

interface QuestionUpdatePayload {
  questionId: number;
  overridden_text: Nullable<string>;
}

/**
 * PATCH the given question with the given payload.
 */
export const updateQuestion = createAsyncThunk(
  'questions/updateQuestion',
  async (questionData: QuestionUpdatePayload) => {
    const { questionId, ...payload } = questionData;
    const response = await axiosInstance.patch(`${questionId}/?bypass_client_filter=true`, payload);

    return response.data;
  },
);

/**
 * Write the given config for the given Question.
 */
export const writeQuestionConfig = createAsyncThunk(
  'questions/writeQuestionConfig',
  async (questionData: { questionId: number; config_name: string; config_value: string | null }) => {
    const { questionId, ...payload } = questionData;
    const response = await axiosInstance.post(`${questionId}/config/?bypass_client_filter=true`, payload);

    return response.data;
  },
);

/**
 * Reset all "overridden fields" + soft-delete all QuestionConfig instances of
 * the given Question.
 */
export const resetAllOverrides = createAsyncThunk('questions/resetAllOverrides', async (questionId: number) => {
  const response = await axiosInstance.post(`${questionId}/reset_all_overrides/?bypass_client_filter=true`);

  return response.data;
});

interface AddQuestionPayload {
  genericQuestionId: number;
  surveyId: number;
  newIndex: number;
  groupQuestionId?: number;
}

/**
 * Add a new question to the survey and update the Questions state.
 */
export const addQuestion = createAsyncThunk('questions/addQuestion', async (payload: AddQuestionPayload) => {
  const { genericQuestionId, surveyId, newIndex, groupQuestionId } = payload;
  const response = await axiosInstance.post('', {
    generic_question: genericQuestionId,
    survey: surveyId,
    index: newIndex,
    ...(groupQuestionId && { group_question: groupQuestionId }),
  });
  return { newQuestion: response.data, newIndex };
});

/**
 * Update a question's overriden text translation.
 */
export const updateQuestionOverriddenTextTranslation = createAsyncThunk(
  'questions/update',
  async (payload: TranslationRequestPayload): Promise<Question> => {
    const { instanceId: questionId, language, translatedText } = payload;
    const response = await axiosInstance.patch(
      `${questionId}/`,
      {
        [`overridden_text_${language}`]: translatedText,
      },
      {
        params: {
          translations_languages: language,
          bypass_client_filter: true,
        },
      },
    );
    return response.data;
  },
);

/**
 * Update a generic question config's `config_value` translation.
 */
export const updateQuestionConfigTranslation = createAsyncThunk(
  'questions/updateQuestionConfigTranslation',
  async (payload: TranslationRequestPayload): Promise<QuestionConfig> => {
    const { instanceId: questionConfigId, language, translatedText } = payload;
    const response = await questionConfigAxiosInstance.patch(
      `${questionConfigId}/`,
      {
        [`config_value_${language}`]: translatedText,
      },
      {
        params: {
          translations_languages: language,
          bypass_client_filter: true,
        },
      },
    );
    return response.data;
  },
);

interface LogicActionsPayload {
  questionId: number;
  logicActions: LogicActionPayloadItem[];
}

/**
 * Create logic actions for a question.
 */
export const updateQuestionLogicActions = createAsyncThunk(
  'questions/updateQuestionLogicActions',
  async (payload: LogicActionsPayload): Promise<Question> => {
    const { questionId, logicActions } = payload;
    const response = await axiosInstance.post(`${questionId}/logic_action/`, logicActions, {
      params: {
        bypass_client_filter: true,
      },
    });
    return response.data;
  },
);

export const uploadQuestionImage = createAsyncThunk(
  'questions/uploadQuestionImage',
  async (payload: { questionId: number; image: FormData }): Promise<Question> => {
    const { questionId, image } = payload;
    const response = await axiosInstance.post<Question>(`${questionId}/upload_image/`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        bypass_client_filter: true,
      },
    });
    return response.data;
  },
);
