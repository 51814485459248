import classNames from 'classnames';
import React from 'react';
import styles from './ChoicesQuestionChart.module.scss';

interface Props {
  width: number;
  height: number;
  colorClassName?: string;
}

export const QuestionItemBar = (props: Props): React.ReactElement => {
  const { width, colorClassName, height } = props;
  return <div className={classNames(colorClassName, styles.questionItemBar)} style={{ width, height }} />;
};
