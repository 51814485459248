import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@zencity/common-ui';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | ((state: boolean) => void);
  title: string;
  content?: string;
  confirmationHandler: () => void;
  submitBtnLabel: string;
  children?: React.ReactNode;
}

/**
 * A simple confirmation dialog for the user.
 */
export const ConfirmationDialog: React.FC<Props> = function ConfirmationDialog({
  isOpen = false,
  setIsOpen,
  title,
  content,
  submitBtnLabel,
  confirmationHandler,
  children,
}: Props): ReactElement {
  const { t: translate } = useTranslation();

  const closeDialog = () => setIsOpen(false);
  const submitDialog = () => {
    setIsOpen(false);
    confirmationHandler();
  };

  const footer = (
    <div>
      <Button variant="link" onClick={closeDialog}>
        {translate('common.cancel')}
      </Button>
      <Button onClick={submitDialog} id="dialog-submit-button">
        {submitBtnLabel}
      </Button>
    </div>
  );

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={closeDialog}
      shouldCloseOnOverlayClick={false}
      header={<h1>{title}</h1>}
      footer={footer}
    >
      {content}
      {children}
    </Dialog>
  );
};
