import styles from './TemplateTypography.module.scss';

export const TEMPLATE_TYPOGRAPHY = {
  fontSizes: {
    bodySmall: styles.bodySmallFont,
    bodyMedium: styles.bodyMediumFont,
    bodyLarge: styles.bodyLargeFont,
    heading5: styles.heading5Font,
    heading4: styles.heading4Font,
    heading3: styles.heading3Font,
    heading2: styles.heading2Font,
    heading1: styles.heading1Font,
  },
  colors: {
    gray100: styles.gray100Color,
    red90: styles.red90Color,
    gray80: styles.gray80Color,
    gray90: styles.gray90Color,
    purple100: styles.purple100Color,
    blue50: styles.blue50Color,
    negative: styles.negativeColor,
    neutral: styles.neutralColor,
    positive: styles.positiveColor,
  },
};
