/* eslint-disable max-lines-per-function */
import React, { useContext } from 'react';
import { WidgetType } from 'screens/WidgetGenerator/utils/misc';
import { WidgetsConfigsContext } from 'screens/WidgetGenerator/contexts/WidgetsConfigsContext';
import { OverallBarChart } from 'screens/WidgetGenerator/widgets/BarChart/OverallBarChart/OverallBarChart';
import { SatisfactionBarChart } from 'screens/WidgetGenerator/widgets/BarChart/SatisfactionBarChart/SatisfactionBarChart';
import { BenchmarkGenericQuestionsTable } from 'screens/WidgetGenerator/widgets/BenchmarkWidgets/BenchmarkGenericQuestionsTable/BenchmarkGenericQuestionsTable';
import { Bubble } from 'screens/WidgetGenerator/widgets/Bubble/Bubble';
import { CrossTabs } from 'screens/WidgetGenerator/widgets/CrossTabs/CrossTabs';
import { DemographicsTable } from 'screens/WidgetGenerator/widgets/DemographicsTable/DemographicsTable';
import { MaintainAndFocusServices } from 'screens/WidgetGenerator/widgets/MaintainAndFocusServices/MaintainAndFocusServices';
import { Questionnaire } from 'screens/WidgetGenerator/widgets/Questionnaire/Questionnaire';
import { SentimentBarGroups } from 'screens/WidgetGenerator/widgets/SentimentBarRows/SentimentBarGroups';
import { SentimentBarRows } from 'screens/WidgetGenerator/widgets/SentimentBarRows/SentimentBarRows';
import { ScoreTextWithSentimentBar } from 'screens/WidgetGenerator/widgets/ScoreTextWithSentimentBar/ScoreTextWithSentimentBar';
import { AreasSentimentBarTable } from 'screens/WidgetGenerator/widgets/SentimentBarTable/AreasSentimentBarTable';
import { OverallSentimentBarTable } from 'screens/WidgetGenerator/widgets/SentimentBarTable/OverallSentimentBarTable';
import { TableWrapper } from 'screens/WidgetGenerator/widgets/TableWrapper/TableWrapper';
import { WordCloudChart } from 'screens/WidgetGenerator/widgets/WordCloud/WordCloud';
import { ChoicesQuestionChart } from 'screens/WidgetGenerator/widgets/ChoicesQuestionChart/ChoicesQuestionChart';
import { BenchmarkOverallSatisfactionText } from 'screens/WidgetGenerator/widgets/BenchmarkWidgets/BenchmarkOverallSatisfactionText/BenchmarkOverallSatisfactionText';
import { WidgetGeneratorContext } from 'screens/WidgetGenerator/contexts/WidgetGeneratorContext';

export const SelectedWidget = (): React.ReactElement | null => {
  const {
    widgetType,
    rawData,
    isValidData,
    widgetWidth,
    circleScale,
    wordCloudQuestionSentiments,
    demographicsTableNumRowsPerTable,
    isBenchmarkedOverallBar,
    servicesBarChartNumBarsPerRow,
    isLabeledBar,
    selectedBenchmarkClient,
    splitPositionBarChart,
    isBenchmarked,
    isBenchmarkedServices,
    isBenchmarkedAreas,
    isShortBarChart,
    selectedBenchmarkAspect,
    showAllSatisfactionScores,
    splitPositionQuestionnaire,
  } = useContext(WidgetsConfigsContext);

  const { selectedDateRange } = useContext(WidgetGeneratorContext);

  switch (widgetType) {
    case WidgetType.WORD_CLOUD:
      return (
        <WordCloudChart
          isValidData={isValidData}
          widgetData={rawData}
          sentimentDataTypes={wordCloudQuestionSentiments}
          width={widgetWidth}
          height={widgetWidth}
        />
      );
    case WidgetType.TOPICS_BUBBLE_CHART:
      return <Bubble widgetData={rawData} scale={circleScale} />;
    case WidgetType.SENTIMENT_BAR:
      return <SentimentBarRows widgetData={rawData} />;
    case WidgetType.DEMOGRAPHICS:
      return <DemographicsTable widgetData={rawData} numRowsPerTable={demographicsTableNumRowsPerTable} />;
    case WidgetType.SERVICE_SENTIMENT_BAR:
      return (
        <ScoreTextWithSentimentBar
          widgetData={rawData}
          isBenchmarked={isBenchmarked}
          showAllSatisfactionScores={showAllSatisfactionScores}
          showSentimentBar
        />
      );
    case WidgetType.SENTIMENT_BAR_GROUPS:
      return <SentimentBarGroups widgetData={rawData} />;
    case WidgetType.OVERALL_SATISFACTION_TEXT:
      return (
        <ScoreTextWithSentimentBar
          widgetData={rawData}
          isBenchmarked={isBenchmarkedOverallBar}
          showAllSatisfactionScores={showAllSatisfactionScores}
        />
      );
    case WidgetType.OVERALL_BAR_CHART:
      return <OverallBarChart widgetData={rawData} isLabeled={isLabeledBar} isBenchmarked={isBenchmarkedOverallBar} />;
    case WidgetType.SERVICES_BAR_CHART:
      return (
        <SatisfactionBarChart
          widgetData={rawData}
          splitPositionIndex={splitPositionBarChart}
          isBenchmarked={isBenchmarkedServices}
          isShortBarChart={isShortBarChart}
          numBarsPerRow={servicesBarChartNumBarsPerRow}
        />
      );
    case WidgetType.GENERAL_TABLE:
      return <TableWrapper widgetData={rawData} />;
    case WidgetType.SENTIMENT_BAR_AREAS_TABLE:
      return <AreasSentimentBarTable widgetData={rawData} isBenchmarked={isBenchmarkedAreas} />;
    case WidgetType.SENTIMENT_BAR_OVERALL_TABLE:
      return <OverallSentimentBarTable widgetData={rawData} isBenchmarked={isBenchmarked} />;
    case WidgetType.BENCHMARK_GENERIC_QUESTIONS_TABLE:
      return <BenchmarkGenericQuestionsTable widgetData={rawData} selectedAspect={selectedBenchmarkAspect} />;
    case WidgetType.BENCHMARK_OVERALL_SATISFACTION_TEXT:
      return (
        <BenchmarkOverallSatisfactionText
          widgetData={rawData}
          selectedClient={selectedBenchmarkClient}
          selectedDateRange={selectedDateRange}
        />
      );
    case WidgetType.CHOICES_QUESTION_CHART:
      return <ChoicesQuestionChart widgetData={rawData} />;
    case WidgetType.MAINTAIN_AND_FOCUS_SERVICES:
      return (
        <MaintainAndFocusServices
          widgetData={rawData}
          splitPositionBarChart={splitPositionBarChart}
          isBenchmarkedServices={isBenchmarkedServices}
          isShortBarChart={isShortBarChart}
        />
      );
    case WidgetType.QUESTIONNAIRE:
      return <Questionnaire widgetData={rawData} splitPositionQuestionnaire={splitPositionQuestionnaire} />;
    case WidgetType.CROSS_TABS:
      return <CrossTabs widgetData={rawData} />;
    default:
      return null;
  }
};
