import { Spacer } from 'components/Spacer/Spacer';
import React, { useContext } from 'react';
import { WidgetsConfigsContext } from 'screens/WidgetGenerator/contexts/WidgetsConfigsContext';
import {
  ChoicesQuestionChartDisplayOrder,
  ChoicesQuestionChartDisplayType,
  ChoicesQuestionChartData,
  Segment,
} from 'screens/WidgetGenerator/utils/misc';
import { Flex } from 'components/Flex/Flex';
import styles from './ChoicesQuestionChart.module.scss';
import { QuestionItem } from './QuestionItem';
import { Segments } from './Segments';
import { useChoicesQuestionChartDisplayOrder } from './useChoicesQuestionChartDisplayOrder';
import { getOptionCount } from './utilFunctions';

interface Props {
  widgetData: ChoicesQuestionChartData;
  dataDisplayType?: ChoicesQuestionChartDisplayType;
  choicesOrder?: ChoicesQuestionChartDisplayOrder;
}

const COLOR_MAPPING: Record<number, string> = {
  1: styles.purple,
  2: styles.green,
  3: styles.red,
};

export const FALLBACK_COLOR = styles.blue;

export const ChoicesQuestionChart = (props: Props): React.ReactElement => {
  const { widgetData, dataDisplayType, choicesOrder } = props;
  const { choicesQuestionChartDisplayType, choicesQuestionChartDisplayOrder } = useContext(WidgetsConfigsContext);
  const selectedDisplayType = dataDisplayType ?? choicesQuestionChartDisplayType;
  const selectedOrder = choicesOrder ?? choicesQuestionChartDisplayOrder;
  const isPercentage = selectedDisplayType === ChoicesQuestionChartDisplayType.PERCENTAGE;

  const segments = widgetData.choices?.reduce<Segment[]>((current, option) => {
    option.responseOptions?.forEach((response, index) => {
      if (!response.segmentLabel) return;
      // eslint-disable-next-line max-nested-callbacks
      if (!current.find((element) => element.segmentLabel === response.segmentLabel)) {
        current.push({
          label: response.segmentLabel,
          colorClassName: COLOR_MAPPING[index] ?? FALLBACK_COLOR,
          segmentLabel: response.segmentLabel,
        });
      }
    });

    return current;
  }, []);

  const { sortedChoices } = useChoicesQuestionChartDisplayOrder({
    widgetData,
    selectedDisplayType,
    selectedOrder,
  });
  const totalWeights = widgetData.total_weights;
  const maxTotalSubmissions = sortedChoices?.reduce<number>((current, option) => {
    const totalSubmissions = option.responseOptions?.reduce<number>((currentMax, response) => {
      const count = getOptionCount({
        count: response.count,
        weightsSum: response.weightsSum,
        validCount: response.validCount,
        totalResponses: widgetData.total_responses,
        totalWeights,
        isPercentage,
      });
      return currentMax + count;
    }, 0);
    return Math.max(current, totalSubmissions);
  }, 0);
  return (
    <div className={styles.choicesQuestionChart}>
      <Flex flexDirection="column" justifyContent="space-between" className={styles.choicesItemsWrapper}>
        {sortedChoices?.map((option) => (
          <QuestionItem
            responseOption={option}
            segments={segments}
            fallBackColor={FALLBACK_COLOR}
            totalRespondents={widgetData.total_responses}
            totalWeights={totalWeights}
            maxTotalSubmissions={maxTotalSubmissions}
            isPercentage={isPercentage}
            previousCycleTotalWeights={widgetData.previousCycle?.total_weights}
            previousCycleTotalRespondents={widgetData.previousCycle?.total_responses}
            key={option.id}
          />
        ))}
      </Flex>
      <Spacer orientation="vertical" size="medium" />
      <Segments segments={segments} />
    </div>
  );
};
