import { ZCDSpinner } from '@zencity/common-ui';
import { SurveyManagerToastContextProvider } from 'contexts/SurveyManagerToastContext';
import { useValidateAuthorization } from 'customHooks/useValidateAuthorization';
import React from 'react';
import { Provider } from 'react-redux';
import { Analysis } from 'screens/Analysis/Analysis';
import { UnauthorizedUser403Screen } from 'screens/UnauthorizedUser403Screen/UnauthorizedUser403Screen';
import store from 'store';
import { User } from 'types/user';
import './i18n';
import './index.scss';

interface Props {
  user: User;
}

const AnalysisAppEntry: React.FC<Props> = (props: Props) => {
  const { user } = props;

  const { isUserAuthorized, checkingForUserAuthorization } = useValidateAuthorization(user);

  if (checkingForUserAuthorization) {
    return <ZCDSpinner />;
  }

  if (isUserAuthorized) {
    return (
      <Provider store={store}>
        <SurveyManagerToastContextProvider>
          <Analysis />
        </SurveyManagerToastContextProvider>
      </Provider>
    );
  }

  return <UnauthorizedUser403Screen />;
};

export default AnalysisAppEntry;
