import { ZCDSelect } from '@zencity/common-ui';
import i18next from 'i18next';

import '../../../../../i18n';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from 'screens/WidgetGenerator/components/FieldTitle/FieldTitle';
import { ReportBuilderFilterProps } from '../../constants';

export enum BubbleChartDataType {
  ALL = 'all',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

const FILTER_OPTIONS = [
  {
    label: i18next.t('widgetGenerator.filters.topicsBubbleChart.sentimentFilter.filterOptions.all'),
    value: BubbleChartDataType.ALL,
  },
  {
    label: i18next.t('widgetGenerator.filters.topicsBubbleChart.sentimentFilter.filterOptions.positive'),
    value: BubbleChartDataType.POSITIVE,
  },
  {
    label: i18next.t('widgetGenerator.filters.topicsBubbleChart.sentimentFilter.filterOptions.negative'),
    value: BubbleChartDataType.NEGATIVE,
  },
];

export const SentimentFilter: React.FC<ReportBuilderFilterProps> = (props: ReportBuilderFilterProps) => {
  const { value: filterValue, onChange } = props;
  const { t: translate } = useTranslation();
  const [selectedSentimentOption, setSelectedSentimentOption] = useState<BubbleChartDataType>(BubbleChartDataType.ALL);

  const getSelectedValue = (selectedValue: BubbleChartDataType) =>
    FILTER_OPTIONS.find((option) => option.value === selectedValue);

  const selectedFilterOption = getSelectedValue((filterValue as BubbleChartDataType) ?? selectedSentimentOption);

  const handleSentimentSelection = (newValue: unknown) => {
    const selected = newValue as { value: BubbleChartDataType };
    setSelectedSentimentOption(selected.value);
    onChange(selected.value);
  };

  return (
    <>
      <FieldTitle title={translate('widgetGenerator.filters.topicsBubbleChart.sentimentFilter.title')} />
      <ZCDSelect options={FILTER_OPTIONS} onChange={handleSentimentSelection} value={selectedFilterOption} />
    </>
  );
};
