/* eslint-disable camelcase */
import { ClassificationSubTopicResponses, SubTopic, subTopicToExclude } from 'screens/WidgetGenerator/utils/misc';
import { LeanClient } from 'types/client';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';
import { config } from '../../../config/config';
import { exportDataToFile, FileTypes } from './util';

const apiUrl = generateVaultApiUrl('/api/v2/answers_leading_sub_topics/');
const axiosInstance = createAxiosInstance(apiUrl);

interface LeadingSubTopicsParams {
  generic_question: number;
  survey_type: string;
  start_date: string;
  end_date: string;
}

/**
 * Fetch leading sub topic for a positive/negative sentiment generic questions (open ended).
 */
export async function exportAnswersLeadingSubTopics(
  params: LeadingSubTopicsParams,
  client: LeanClient,
): Promise<number> {
  const result = await axiosInstance.get('', {
    params,
    headers: { 'X-Zc-Client-Id': client.zc_client_id },
  });

  // Filter out miscellaneous subtopic and take first 3 topics.
  const stringfyedResponse = JSON.stringify(
    result.data.results.filter((subTopic: SubTopic) => !subTopicToExclude.includes(subTopic.subtopic)).splice(0, 3),
  );
  const questionSentiment =
    params.generic_question === config.communitySurvey.PositiveSentimentGenericQuestionId ? 'Positive' : 'Negative';
  exportDataToFile(
    FileTypes.JSON,
    stringfyedResponse,
    `${client.name} ${questionSentiment} leading sub topics ${params.start_date} to ${params.end_date}`,
  );

  return result.status;
}

interface AnswersLeadingSubTopicsResponse {
  classification_sub_topic_id: number;
  classification_sub_topic_value: string;
  classification_topic_id: number;
  classification_topic_value: string;
  responses: number;
}

/**
 * Fetch leading sub topic for a positive/negative sentiment generic questions (open ended).
 */
export async function fetchAnswersLeadingSubTopics(
  params: LeadingSubTopicsParams,
  client: LeanClient,
): Promise<ClassificationSubTopicResponses[]> {
  const results = await axiosInstance.get<{
    results: AnswersLeadingSubTopicsResponse[];
  }>('', {
    params,
    headers: { 'X-Zc-Client-Id': client.zc_client_id },
  });

  // Convert camel case fields to snake case.
  const classificationSubTopics = results.data.results.map((subTopic: AnswersLeadingSubTopicsResponse) => ({
    classificationSubTopicId: subTopic.classification_sub_topic_id,
    classificationSubTopicValue: subTopic.classification_sub_topic_value,
    classificationTopicId: subTopic.classification_topic_id,
    classificationTopicValue: subTopic.classification_topic_value,
    responses: subTopic.responses,
  }));
  return classificationSubTopics;
}
