import React, { ReactElement } from 'react';
import styles from './TableRowSkeleton.module.scss';

interface Props {
  colSpan: number;
}

/**
 * A component to use as a "loader" when fetching data for a table.
 */
export const TableRowSkeleton = (props: Props): ReactElement => {
  const { colSpan } = props;

  const cells = Array.from(Array(colSpan).keys());
  return (
    <tr role="row">
      {cells.map((index) => (
        <td key={`row-skeleton-${index}`} role="cell">
          <div className={styles.skeleton} />
        </td>
      ))}
    </tr>
  );
};
