import { Select } from '@zencity/common-ui';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createNewTypeformSurvey } from 'services/typeformSurvey';
import { setTypeformSurvey } from 'slices/surveyManage';
import { RootState } from 'store';
import { TypeformSurvey } from 'types/typeformSurvey';
import { logger } from 'utils/logger';
import styles from './TypeformLanguageSelect.module.scss';

export interface LanguageSelectOption {
  label: string;
  value: string;
}

interface Props {
  typeformSurveys: TypeformSurvey[];
}

/**
 * A selector for adding a language to a survey.
 */
export const TypeformLanguageSelect = (props: Props): ReactElement => {
  const { toastError } = useContext(SurveyManagerToastContext);
  const { typeformSurveys } = props;
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageSelectOption | null>();
  const [loadingTypeformSurveyAddition, setLoadingTypeformSurveyAddition] = useState(false);
  const { languages } = useAppSelector((state: RootState) => state.globalConfigs);
  const { currentSurveyId } = useAppSelector((state: RootState) => state.surveyManage);
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const handleOnAddLanguage = async () => {
    if (!selectedLanguage) {
      return;
    }

    setLoadingTypeformSurveyAddition(true);
    try {
      const newTypeformSurvey = await createNewTypeformSurvey(selectedLanguage.value, currentSurveyId as number);
      dispatch(setTypeformSurvey(newTypeformSurvey));
      setSelectedLanguage(null);
    } catch (error) {
      toastError();
      logger.error(`Failed to POST Typeform Survey for Survey #${currentSurveyId} (${selectedLanguage.value}).`);
    }
    setLoadingTypeformSurveyAddition(false);
  };

  const handleOnSelectChange = (selectedOption: unknown) => {
    const option = selectedOption as LanguageSelectOption;
    setSelectedLanguage(option);
  };

  const languageSelectOptions = useMemo(() => {
    const currentSurveyLanguages = typeformSurveys.map((typeformSurvey) => typeformSurvey.language);
    const possibleLanguages = Object.entries(languages).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([languageCode, languageValue]) => !currentSurveyLanguages.includes(languageCode),
    );
    const selectOptions = possibleLanguages.map(([languageCode, languageLabel]) => ({
      label: languageLabel,
      value: languageCode,
    }));
    return selectOptions;
  }, [typeformSurveys, languages]);

  return (
    <LoaderMask isLoading={loadingTypeformSurveyAddition}>
      <div className={styles.languageSelect}>
        <Select
          options={languageSelectOptions}
          className={styles.selectBox}
          value={selectedLanguage}
          onChange={handleOnSelectChange}
        />
        <button type="button" className={styles.addButton} onClick={handleOnAddLanguage}>
          {translate('common.add')}
        </button>
      </div>
    </LoaderMask>
  );
};
