export enum TranslatableField {
  GENERIC_QUESTION_TEXT = 'genericQuestionText',
  GENERIC_QUESTION_DESCRIPTION = 'genericQuestionDescription',
  GENERIC_QUESTION_SCALE_LABEL = 'genericQuestionScaleLabel',
  QUESTION_OVERRIDDEN_TEXT = 'questionOverriddenText',
  QUESTION_CHOICE_LABEL = 'questionChoiceLabel',
  QUESTION_DESCRIPTION = 'questionDescription',
  QUESTION_SCALE_LABEL = 'questionScaleLabel',
  SURVEY_GROUP_TITLE_FOR_DISPLAY = 'surveyGroupTitleForDisplay',
  END_SCREEN_TITLE = 'endScreenTitle',
  END_SCREEN_DESCRIPTION = 'endScreenDescription',
}
