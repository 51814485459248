import { ZCDSpinner } from '@zencity/common-ui';
import { useValidateAuthorization } from 'customHooks/useValidateAuthorization';
import React, { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { UnauthorizedUser403Screen } from 'screens/UnauthorizedUser403Screen/UnauthorizedUser403Screen';
import store from 'store';
import { User } from 'types/user';
import { config } from '../config/config';
import { SurveyManagerToastContextProvider } from './contexts/SurveyManagerToastContext';
import './i18n';
import './index.scss';
import { SurveyManagerRoutes } from './router';

interface Props {
  user: User;
}

/**
 * The "Survey Manager App" main component (entry point).
 *
 * @param user: The user object from Core-Platform.
 * @param baseUrl: The parent will tell in which URL does it live.
 */
const SurveyManagerAppEntry: React.FC<Props> = function SurveyManagerAppEntry({ user }: Props): ReactElement {
  const { globalConfigs, isUserAuthorized, checkingForUserAuthorization } = useValidateAuthorization(user);

  // eslint-disable-next-line no-nested-ternary
  return checkingForUserAuthorization ? (
    <ZCDSpinner />
  ) : isUserAuthorized && globalConfigs ? (
    <Provider store={store}>
      <SurveyManagerToastContextProvider>
        <BrowserRouter basename={config.surveyManager.baseRouterUrl}>
          <SurveyManagerRoutes globalConfigs={globalConfigs} />
        </BrowserRouter>
      </SurveyManagerToastContextProvider>
    </Provider>
  ) : (
    <UnauthorizedUser403Screen />
  );
};

export default SurveyManagerAppEntry;
