import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from 'screens/WidgetGenerator/components/FieldTitle/FieldTitle';
import { fetchSurveysByParams } from 'services/survey';
import { DateFormat, formatISODate } from 'utils/dates';
import { logger } from 'utils/logger';

interface SurveysSelectionProps {
  handleSelectSurvey: (newValue: unknown) => void;
  // If true, the first survey in the list will be selected by default.
  shouldSelectDefaultSurvey?: boolean;
  selectedSurvey?: number;
  selectedSurveyGroup: number;
}

export const getSurveysOptions = async (selectedSurveyGroup: number): Promise<SelectOption[]> => {
  const { results } = await fetchSurveysByParams({ survey_group: selectedSurveyGroup });
  const mappedSurveyOptions: SelectOption[] = results
    .map((survey) => ({
      value: survey.id,
      label: `${survey.survey_group.title_for_display}: ${formatISODate(
        survey.created_at,
        DateFormat.SLASHES_WITHOUT_TIME,
      )}`,
    }))
    // The surveys are returned in chronological order by creation date,
    // so they are reversed to show the most recent survey first.
    .reverse();
  return mappedSurveyOptions;
};

export const SurveysSelection = (props: SurveysSelectionProps): React.ReactElement => {
  const { handleSelectSurvey, selectedSurvey, shouldSelectDefaultSurvey = false, selectedSurveyGroup } = props;
  const [loading, setLoading] = useState(false);
  const [surveyOptions, setSurveyOptions] = useState<SelectOption[]>([]);
  const selectedValue = surveyOptions.find(({ value }) => value === selectedSurvey);
  const { t: translate } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const mappedSurveyOptions = await getSurveysOptions(selectedSurveyGroup);

      setSurveyOptions(mappedSurveyOptions);

      if (shouldSelectDefaultSurvey && mappedSurveyOptions.length > 0) {
        handleSelectSurvey(mappedSurveyOptions[0]);
      }
    };

    fetchData()
      .catch(logger.error)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSurveyGroup, shouldSelectDefaultSurvey]);

  return (
    <>
      <FieldTitle title={translate('widgetGenerator.widgets.questionnaire.selectSurvey')} />
      <ZCDSelect
        placeholder={translate('widgetGenerator.widgets.questionnaire.selectSurvey')}
        options={surveyOptions}
        onChange={handleSelectSurvey}
        value={selectedValue}
        isLoading={loading}
      />
    </>
  );
};
