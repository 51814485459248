import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import React from 'react';
import { QuestionnaireWidgetData } from 'screens/WidgetGenerator/utils/misc';
import { QuestionConfigType } from 'types/questionConfig';
import { getQuestionConfig } from 'utils/genericAndQuestionConfigs';
import styles from './QuestionnaireQuestion.module.scss';

interface Props {
  question: QuestionnaireWidgetData['questions'][0];
}

export const QuestionnaireQuestion = (props: Props) => {
  const { question } = props;
  const { text } = question;
  const isRequired = getQuestionConfig(question, QuestionConfigType.REQUIRED);

  return (
    <div className={styles.questionTextWrapper}>
      <Flex alignItems="center">
        <div className={styles.questionText}>
          {text}
          {isRequired && (
            <>
              <div className={styles.required}>*</div>
            </>
          )}
        </div>
        <Spacer orientation="horizontal" size="xsmall" />
      </Flex>
    </div>
  );
};
