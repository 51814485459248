import { QuestionnaireWidgetData } from 'screens/WidgetGenerator/utils/misc';
import { formatQuestionnaireData } from 'screens/WidgetGenerator/widgets/Questionnaire/helpers';
import { Aspect } from 'types/aspect';
import { QuestionType } from 'types/genericQuestion';
import { QuestionConfig, QuestionConfigType } from 'types/questionConfig';
import { Choice } from 'types/questions';

const ASPECTS: Aspect[] = [
  {
    id: 5,
    value: 'general',
    label: 'General',
  },
  {
    id: 1,
    value: 'quality_of_life',
    label: 'Quality of Life',
  },
  {
    id: 2,
    value: 'characteristic',
    label: 'Characteristic',
  },
];

const CHOICES: Choice[] = [
  {
    label: 'Choice #1',
    id: 1,
  },
  {
    label: 'Choice #2',
    id: 2,
  },
  {
    label: 'Choice #3',
    id: 3,
  },
  {
    label: 'Choice #4',
    id: 4,
  },
  {
    label:
      'Choice #5 with very long text that will be truncated in the widget and will be shown in the full screen view.',
    id: 5,
  },
  {
    label: 'Choice #6',
    id: 6,
  },
];

const SCALE_CONFIG: QuestionConfig[] = [
  {
    config_name: QuestionConfigType.SCALE_LABEL_FIRST,
    config_value: 'Unlikely',
    id: 1,
    question: 1,
    translations: {
      config_value: {
        en: 'Unlikely',
        es: 'Poco probable',
      },
    },
  },
  {
    config_name: QuestionConfigType.SCALE_LABEL_LAST,
    config_value: 'Very likely',
    id: 2,
    question: 1,
    translations: {
      config_value: {
        en: 'Very likely',
        es: 'Muy probable',
      },
    },
  },
  {
    config_name: QuestionConfigType.STEPS,
    config_value: '1',
    id: 3,
    question: 1,
  },
];

const REQUIRED_CONFIG: QuestionConfig = {
  config_name: QuestionConfigType.REQUIRED,
  config_value: '1',
  id: 1,
  question: 1,
};

const getRandomQuestionType = () => {
  const questionTypes = Object.values(QuestionType);
  const randomIndex = Math.floor(Math.random() * questionTypes.length);
  return questionTypes[randomIndex];
};

const getRandomAspect = () => {
  const randomIndex = Math.floor(Math.random() * ASPECTS.length);
  return ASPECTS[randomIndex];
};

export const generateQuestionnaireWidgetMock = (amount: number): QuestionnaireWidgetData[] => {
  const questions = [];

  for (let index = 0; index < amount; index += 1) {
    const questionType = getRandomQuestionType();
    const choices = questionType === QuestionType.CHOICES ? CHOICES : [];
    const configs = questionType === QuestionType.SCALE ? SCALE_CONFIG : [];
    // Randomly add a required config.
    if (Math.random() > 0.5) {
      configs.push(REQUIRED_CONFIG);
    }
    questions.push({
      id: index,
      question_type: questionType,
      aspect: getRandomAspect(),
      choices,
      configs,
      generic_question: index,
      group_question: null,
      index,
      logic_actions: [],
      overridden_text: null,
      overridden_text_to_speech: null,
      survey: 1,
      text: `Question #${index}?`,
      updated_at: new Date().toISOString(),
    });
  }

  return formatQuestionnaireData(questions);
};
