/* eslint-disable complexity */
/* eslint-disable react/jsx-props-no-spreading */
import { QuestionItem } from 'types/questions';
import { TextBasedQuestion } from 'components/TextBasedQuestion/TextBasedQuestion';
import { MultipleChoiceQuestion } from 'components/MultipleChoiceQuestion/MultipleChoiceQuestion';
import { QuestionWrapper } from 'components/QuestionWrapper/QuestionWrapper';
import { ScaleQuestion } from 'components/ScaleQuestion/ScaleQuestion';
import { YesNoQuestion } from 'components/YesNoQuestion/YesNoQuestion';
import React, { ReactElement } from 'react';
import { QuestionType } from 'types/genericQuestion';
import { logger } from 'utils/logger';
import { DropdownQuestion } from 'components/DropdownQuestion/DropdownQuestion';

export const determineQuestionTypeComponent = (
  questionItem: QuestionItem,
  index: number,
  allowEditMode: boolean,
  parentIndex?: number,
): ReactElement => {
  const commonProps = {
    key: `${questionItem.item.question_type}-question-${questionItem.id}`,
    questionItem,
    index,
    parentIndex,
    allowEditMode,
  };
  switch (questionItem.item.question_type) {
    case QuestionType.STATEMENT:
      return <QuestionWrapper {...commonProps} withLargeText />;
    case QuestionType.YES_NO:
      return <YesNoQuestion {...commonProps} />;
    case QuestionType.CHOICES:
      return <MultipleChoiceQuestion {...commonProps} />;
    case QuestionType.SCALE:
      return <ScaleQuestion {...commonProps} />;
    case QuestionType.OPEN_ENDED:
    case QuestionType.EMAIL:
    case QuestionType.NUMBER:
    case QuestionType.ZIP_CODE:
      return <TextBasedQuestion {...commonProps} />;
    case QuestionType.DROPDOWN:
      return <DropdownQuestion {...commonProps} />;
    default:
      logger.error(
        `Question wrapper component type not accounted for of question type ${questionItem.item.question_type}. Rendered from Question #${questionItem.id}`,
      );
      return (
        <QuestionWrapper {...commonProps}>
          This question should belong to a specific question type. Please contact customer support.
        </QuestionWrapper>
      );
  }
};
