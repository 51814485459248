import { QuestionType, GenericQuestion, GenericQuestionConfig } from 'types/genericQuestion';
import { QuestionConfigType } from 'types/questionConfig';

const FILTERED_CONFIGS_PER_QUESTION_TYPE: Record<string, QuestionConfigType[]> = {
  [QuestionType.SCALE]: [
    QuestionConfigType.SCALE_LABEL_FIRST,
    QuestionConfigType.SCALE_LABEL_LAST,
    QuestionConfigType.SCALE_LABEL_MIDDLE,
    QuestionConfigType.STEPS,
  ],
};

/**
 * Return the config name with capitilzation and from underscore_separated to space separated.
 */
export const formatConfigName = (configName: QuestionConfigType): string =>
  configName
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const resolveConfigValue = (configName: QuestionConfigType, configValue: string): string | undefined => {
  if (configValue === '1') {
    // Return the config name with capitilzation and from underscore_separated to space separated.
    return formatConfigName(configName);
  }
  if (configValue === '0') {
    return undefined;
  }
  return `${formatConfigName(configName)}: "${configValue}"`;
};

/**
 * Filter out the configs that are not relevant for the `GenericQuestion`s question type.
 * As well as this, the description should always be filtered out.
 */
export const filterConfigsByQuestionType = (genericQuestion: GenericQuestion): GenericQuestionConfig[] => {
  const { question_type: questionType, configs } = genericQuestion;
  // Always filter out the description config.
  const initialFilteredConfigs = configs.filter((config) => config.config_name !== QuestionConfigType.DESCRIPTION);
  const filteredConfigs = FILTERED_CONFIGS_PER_QUESTION_TYPE[questionType];
  if (!filteredConfigs) {
    return initialFilteredConfigs;
  }
  return initialFilteredConfigs.filter((config) => !filteredConfigs.includes(config.config_name));
};
