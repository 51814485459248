import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isNumeric, statusIsIdle, statusIsLoading, statusIsSuccessful } from 'utils/misc';
import { ZCDSpinner } from '@zencity/common-ui';
import { CurrentSurvey, isFormInEditMode } from 'screens/SurveyEditStartScreen/SurveyEditStartScreen.helpers';
import { fetchClients } from 'services/client';
import styles from 'screens/SurveyEditStartScreen/SurveyEditStartScreen.module.scss';
import { useAppDispatch, useAppSelector } from '../../../customHooks/hooks';

interface Props {
  currentSurvey: CurrentSurvey;
  setCurrentSurvey: React.Dispatch<React.SetStateAction<CurrentSurvey>>;
  inCloneMode: boolean;
  existsSurveyRequest: boolean;
}

export const SurveyEditClientsSection = (props: Props): ReactElement => {
  const { currentSurvey, setCurrentSurvey, inCloneMode, existsSurveyRequest } = props;
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const { clientsSortedByName, fetchStatus, errorMessage } = useAppSelector((state) => state.clients);

  const inEditMode = isFormInEditMode(currentSurvey, inCloneMode);

  /**
   * Fetch the clients from the server if still idled.
   */
  useEffect(() => {
    if (statusIsIdle(fetchStatus)) {
      dispatch(fetchClients());
    }
    if (errorMessage) {
      throw new Error(errorMessage);
    }
  }, [fetchStatus, dispatch, errorMessage]);

  const onClientChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const clientId = isNumeric(event.target.value) ? Number(event.target.value) : undefined;
    setCurrentSurvey({
      ...currentSurvey,
      clientId,
    });
  };

  const isClientSelectDisabled = inEditMode || existsSurveyRequest;

  return (
    <div className={styles.sectionGrid}>
      <div className={styles.sectionTitle}>{translate('surveyEdit.pickAClient')}</div>
      <div>
        {statusIsLoading(fetchStatus) && <ZCDSpinner />}
        {statusIsSuccessful(fetchStatus) && (
          <select
            className={styles.dropdown}
            value={currentSurvey?.clientId}
            onChange={onClientChange}
            disabled={isClientSelectDisabled}
          >
            <option>- {translate('common.none')} -</option>
            {clientsSortedByName.map((client) => (
              <option key={`client-${client.id}`} value={client.id}>
                {client.state ? `${client.name}, ${client.state}` : client.name}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};
