export enum FileTypes {
  JSON = 'json',
  XLSX = 'xlsx',
  CSV = 'csv',
}

const ContentTypes = {
  [FileTypes.JSON]: 'application/json',
  [FileTypes.XLSX]: 'application/xlsx; charset=utf-8',
  [FileTypes.CSV]: 'text/csv',
};

export const exportDataToFile = (type: FileTypes, payload: BlobPart, filename: string): void => {
  const linkToFile = document.createElement('a');
  linkToFile.href = window.URL.createObjectURL(new Blob([payload], { type: ContentTypes[type] }));
  linkToFile.download = `${filename}.${type}`;
  linkToFile.style.display = 'none';
  document.body.appendChild(linkToFile);
  linkToFile.click();
  linkToFile.remove();
};
