import { ZCDIcon, ZCDIconColor } from '@zencity/common-ui';
import React from 'react';
import styles from './DiffIcon.module.scss';

interface DiffIconProps {
  diff: number;
  arrowColor?: ZCDIconColor;
}

export const DiffIcon = (props: DiffIconProps): JSX.Element => {
  const { diff, arrowColor } = props;
  const color = arrowColor ?? ZCDIconColor.INHERIT;

  if (diff === 0) {
    return (
      <p className={styles.noChange} style={{ color }}>
        --&nbsp;
      </p>
    );
  }
  if (diff < 0) {
    return <ZCDIcon name="arrow-down" color={color} />;
  }
  return <ZCDIcon name="arrow-up" color={color} />;
};
