import React, { ReactElement, useState } from 'react';
import classnames from 'classnames';
import { GenericQuestion, QuestionType } from 'types/genericQuestion';
import { QuestionConfigType } from 'types/questionConfig';
import { ConfigsInfo } from 'components/GenericQuestionTeaserView/TeaserAdditionalInfo/ConfigsInfo/ConfigsInfo';
import styles from '../GenericQuestionTeaserView.module.scss';
import { InfoRecord } from '../types';

interface AdditionalInfoProps {
  items: InfoRecord[];
  keysPrefix: string;
  genericQuestion: GenericQuestion;
  allowToggle?: boolean;
}

const formatItemLabel = (item: InfoRecord) =>
  `${item.label}${item.numericValue !== undefined ? ` [${item.numericValue}]` : ''}`;

const QUESTION_TYPES_NOT_TO_SHOW_CONFIGS_INLINE = [QuestionType.SCALE, QuestionType.CHOICES];
// eslint-disable-next-line id-length
const QUESTION_TYPES_NOT_TO_SHOW_DESCRIPTION_INLINE = [QuestionType.SCALE, QuestionType.CHOICES, QuestionType.YES_NO];

/**
 * A helper component to display the additional info of a teaser.
 */
export const TeaserAdditionalInfo = (props: AdditionalInfoProps): ReactElement => {
  const { items, keysPrefix, allowToggle = false, genericQuestion } = props;
  const [inlineDisplay, setInlineDisplay] = useState(true);

  const { configs } = genericQuestion;
  const description = configs.find((config) => config.config_name === QuestionConfigType.DESCRIPTION);

  const toggleInlineDisplay = () => {
    if (!allowToggle) {
      return;
    }
    setInlineDisplay(!inlineDisplay);
  };

  const showConfigsInline =
    !QUESTION_TYPES_NOT_TO_SHOW_CONFIGS_INLINE.includes(genericQuestion.question_type) && inlineDisplay;

  const showConfigs = showConfigsInline || !inlineDisplay;

  const showDescription = () => {
    if (
      inlineDisplay &&
      !QUESTION_TYPES_NOT_TO_SHOW_DESCRIPTION_INLINE.includes(genericQuestion.question_type) &&
      description
    ) {
      return true;
    }
    if (!inlineDisplay && description) {
      return true;
    }
    return false;
  };

  return (
    <div className={classnames({ [styles.additionalInfoToggle]: allowToggle })}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
      <div onClick={toggleInlineDisplay} role="button">
        <div
          className={classnames(styles.additionalInfo, {
            [styles.inlineDisplay]: inlineDisplay,
          })}
        >
          {showDescription() && <p className={styles.description}>{description?.config_value}</p>}
          {items.map((item) => (
            <li key={`${keysPrefix}-${item.id}`} className={styles.teaserItem}>
              {formatItemLabel(item)}
            </li>
          ))}
        </div>
      </div>
      {showConfigs && <ConfigsInfo genericQuestion={genericQuestion} />}
    </div>
  );
};
