export enum RequestStatus {
  SUCCESS = 'success',
  LOADING = 'loading',
  ERROR = 'error',
  IDLE = 'idle',
}

export interface PaginatedResults<T> {
  count: number;
  next: Nullable<string>;
  previous: Nullable<string>;
  results: T[];
}

export type Nullable<T> = T | null;

export type LocationState = {
  previousPath?: string;
};

export type Translations = Record<string, string>;

export interface TranslationRequestPayload {
  instanceId: number;
  language: string;
  translatedText: string;
}

export class ValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ValidationError';
  }
}
