import classNames from 'classnames';
import React from 'react';
import styles from './ScoreIndicator.module.scss';

interface ScoreIndicatorProps {
  value: number;
  type: string;
  hideScoreColor?: boolean;
}

export function ScoreIndicator(props: ScoreIndicatorProps): React.ReactElement {
  const { value, type, hideScoreColor } = props;
  return (
    <div className={styles.scoreIndicator}>
      {!hideScoreColor && <i className={classNames(styles.scoreColor, styles[type])} />}
      <p>{value}%</p>
    </div>
  );
}
