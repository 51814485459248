import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { DocumentFragment } from '../documentFragment';
import { sectionCoverPage } from '../utils/sectionCoverPage';

export const benchmarkCoverPage = (headerElement: ILandscapeHeaderElement): DocumentFragment => [
  headerElement,
  ...sectionCoverPage({
    titleChunks: ['Community', 'Benchmark'],
  }),
];
