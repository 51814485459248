/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus, PaginatedResults } from 'types/misc';
import { GenericQuestion, GenericQuestionConfig } from 'types/genericQuestion';
import {
  loadGenericQuestions,
  updateGenericQuestionConfigTranslation,
  updateGenericQuestionTextTranslation,
} from 'services/genericQuestion';
import { updateQuestionChoiceTranslation } from 'services/questionChoice';
import { QuestionChoice } from 'types/questionChoice';

interface GenericQuestionsState {
  genericQuestionsById: { [genericQuestionId: number]: GenericQuestion };
  paginatedResultsIds: number[];
  fetchStatus: string;
  updateStatus: string;
  nextPage?: number;
  errorMessage?: string;
}

const initialState: GenericQuestionsState = {
  genericQuestionsById: {},
  paginatedResultsIds: [],
  nextPage: 1,
  fetchStatus: RequestStatus.IDLE,
  updateStatus: RequestStatus.IDLE,
};

const slice = createSlice({
  name: 'genericQuestions',
  initialState,
  reducers: {
    updateGenericQuestions: (state: GenericQuestionsState, action: PayloadAction<GenericQuestion[]>) => {
      const genericQuestions = action.payload;
      genericQuestions.forEach((genericQuestion) => {
        state.genericQuestionsById[genericQuestion.id] = genericQuestion;
      });
    },
    deleteGenericQuestion: (state: GenericQuestionsState, action: PayloadAction<number>) => {
      const genericQuestionId = action.payload;
      delete state.genericQuestionsById[genericQuestionId];
      state.paginatedResultsIds = state.paginatedResultsIds.filter((id) => id !== genericQuestionId);
    },
    appendNewGenericQuestion: (state: GenericQuestionsState, action: PayloadAction<GenericQuestion>) => {
      const genericQuestion = action.payload;

      // Add the new instance to be the first element (as it suppose to be
      // descending sorted).
      state.paginatedResultsIds.unshift(genericQuestion.id);
      state.genericQuestionsById[genericQuestion.id] = genericQuestion;
    },
    updateGenericQuestionInStore: (state: GenericQuestionsState, action: PayloadAction<GenericQuestion>) => {
      const genericQuestion = action.payload;
      state.genericQuestionsById[genericQuestion.id] = genericQuestion;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadGenericQuestions.pending, (state: GenericQuestionsState) => {
        state.fetchStatus = RequestStatus.LOADING;
      })
      .addCase(
        loadGenericQuestions.fulfilled,
        (state: GenericQuestionsState, action: PayloadAction<PaginatedResults<GenericQuestion>>) => {
          state.fetchStatus = RequestStatus.SUCCESS;
          const { results, next: nextUrl } = action.payload;

          // Using a set to avoid duplications.
          const fetchedIds = results.map((instance) => instance.id);
          const idsSet = new Set(state.paginatedResultsIds);
          fetchedIds.forEach((id) => {
            idsSet.add(id);
          });
          state.paginatedResultsIds = Array.from(idsSet);
          state.nextPage = nextUrl && state.nextPage ? state.nextPage + 1 : undefined;

          results.forEach((genericQuestion) => {
            state.genericQuestionsById[genericQuestion.id] = genericQuestion;
          });
        },
      )
      .addCase(loadGenericQuestions.rejected, (state: GenericQuestionsState, action) => {
        state.fetchStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(updateGenericQuestionTextTranslation.rejected, (state: GenericQuestionsState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(
        updateGenericQuestionTextTranslation.fulfilled,
        (state: GenericQuestionsState, action: PayloadAction<GenericQuestion>) => {
          const genericQuestion = action.payload;
          state.genericQuestionsById[genericQuestion.id] = genericQuestion;
          state.updateStatus = RequestStatus.SUCCESS;
        },
      )
      .addCase(updateGenericQuestionConfigTranslation.rejected, (state: GenericQuestionsState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(
        updateGenericQuestionConfigTranslation.fulfilled,
        (state: GenericQuestionsState, action: PayloadAction<GenericQuestionConfig>) => {
          const updatedGenericQuestionConfig = action.payload;
          const genericQuestionsByIdCopy = { ...state.genericQuestionsById };
          // Find the generic questions with this config and update them.
          Object.values(genericQuestionsByIdCopy).forEach((genericQuestion) => {
            // eslint-disable-next-line max-nested-callbacks
            const genericQuestionConfigIds = genericQuestion.configs.map((config) => config.id);
            const genericQuestionConfigIndex = genericQuestionConfigIds.indexOf(updatedGenericQuestionConfig.id);
            if (genericQuestionConfigIndex > -1) {
              genericQuestion.configs.splice(genericQuestionConfigIndex, 1, updatedGenericQuestionConfig);
            }
          });
          state.genericQuestionsById = genericQuestionsByIdCopy;
          state.updateStatus = RequestStatus.SUCCESS;
        },
      )
      .addCase(updateQuestionChoiceTranslation.rejected, (state: GenericQuestionsState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(
        updateQuestionChoiceTranslation.fulfilled,
        (state: GenericQuestionsState, action: PayloadAction<QuestionChoice>) => {
          const updatedQuestionChoice = action.payload;
          // Find the generic questions with this choice and update them.
          const genericQuestionsByIdCopy = { ...state.genericQuestionsById };
          Object.values(genericQuestionsByIdCopy).forEach((genericQuestion) => {
            // eslint-disable-next-line max-nested-callbacks
            const questionChoiceIds = genericQuestion.choices.map((questionChoice) => questionChoice.id);
            const questionChoiceIndex = questionChoiceIds.indexOf(updatedQuestionChoice.id);
            if (questionChoiceIndex > -1) {
              genericQuestion.choices.splice(questionChoiceIndex, 1, updatedQuestionChoice);
            }
          });
          state.genericQuestionsById = genericQuestionsByIdCopy;
          state.updateStatus = RequestStatus.SUCCESS;
        },
      );
  },
});

export const { updateGenericQuestions, appendNewGenericQuestion, updateGenericQuestionInStore, deleteGenericQuestion } =
  slice.actions;
export default slice;
