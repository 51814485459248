import { SurveyRequest } from 'types/surveyRequest';
import { generateVaultApiUrl, createAxiosInstance } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/api/v2/survey_request/');
const axiosInstance = createAxiosInstance(apiUrl);

/**
 * Fetch a single SurveyRequest by the given ID.
 */
export const fetchSurveyRequestById = async (surveyRequestId: number): Promise<SurveyRequest> => {
  const response = await axiosInstance.get<SurveyRequest>(`${surveyRequestId}/`, {
    params: {
      bypass_client_filter: true,
    },
  });
  return response.data;
};
