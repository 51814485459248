import { SentimentScore } from '@zencity/survey-types';
import React from 'react';
import { Flex } from 'components/Flex/Flex';
import { ZCDIconColor } from '@zencity/common-ui';
import { ScoreIndicator } from 'screens/WidgetGenerator/components/ScoreIndicator/ScoreIndicator';
import { DiffIndicator } from 'screens/WidgetGenerator/components/DiffIndicator/DiffIndicator';
import styles from './BarChart.module.scss';

interface AdditionalScoresProps {
  score: SentimentScore;
  isBenchmarked?: boolean;
  barBenchmarkScore: SentimentScore;
  arrowColor?: ZCDIconColor;
}
export const AdditionalScores = ({
  score,
  isBenchmarked,
  barBenchmarkScore,
  arrowColor,
}: AdditionalScoresProps): React.ReactElement => (
  <div className={styles.additionalScores}>
    <Flex alignItems="center" justifyContent="space-between" className={styles.scoreText}>
      <ScoreIndicator value={score.negative} type="negative" />
      {isBenchmarked && (
        <DiffIndicator currentValue={score.negative} prevValue={barBenchmarkScore.negative} arrowColor={arrowColor} />
      )}
    </Flex>
    <Flex className={styles.scoreText} alignItems="center" justifyContent="space-between">
      <ScoreIndicator value={score.neutral} type="neutral" />
      {isBenchmarked && (
        <DiffIndicator currentValue={score.neutral} prevValue={barBenchmarkScore.neutral} arrowColor={arrowColor} />
      )}
    </Flex>
  </div>
);
