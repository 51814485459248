/* eslint-disable id-denylist */
import { format } from 'date-fns';
import { DocumentFragment, StructuredReport } from './templateParts/documentFragment';
import { SurveyCycle } from '../utils/misc';
import { rotatingSurveySection } from './templateParts/summaryReport/rotatingSection';
import { header } from './templateParts/utils/header';
import { questionnaireSectionTemplate } from './templateParts/summaryReport/questionnaire';
import { reportCoverPage } from './templateParts/summaryReport/reportCoverPage';
import { reportIntroduction } from './templateParts/summaryReport/reportIntroduction';
import { wordCloudResponses } from './templateParts/summaryReport/wordCloudSection';
import { servicesSatisfaction } from './templateParts/summaryReport/servicesSatisfaction';
import { crossTabsSection } from './templateParts/summaryReport/crossTabsSection';
import { demographicsBreakdown } from './templateParts/summaryReport/demographicsBreakdown';
import { reportSummaryPage } from './templateParts/summaryReport/reportSummaryPage';
import { overallSatisfaction } from './templateParts/summaryReport/overallSatisfaction';
import { geographicsBreakdown } from './templateParts/summaryReport/geographicsBreakdown';
import { generateBenchmarkReportTemplate } from './benchmarkReportTemplate';

const formatCycleDateRange = (mostRecentCycle: SurveyCycle): string => {
  const startData = new Date(mostRecentCycle.startDate);
  const endDate = new Date(mostRecentCycle.endDate);
  const cycleStartMonth = format(startData, 'MMMM');
  const cycleEndMonth = format(endDate, 'MMMM');
  const cycleStartYear = format(startData, 'yyyy');
  const cycleEndYear = format(endDate, 'yyyy');
  const year = cycleStartYear === cycleEndYear ? cycleStartYear : `${cycleStartYear} - ${cycleEndYear}`;
  return `${cycleStartMonth} - ${cycleEndMonth} ${year}`;
};

const summaryReportTemplate = async (structuredSummaryReport: StructuredReport): Promise<DocumentFragment> => {
  const { client, surveyGroup, parsedMostRecentCycle, mostRecentCycle } = structuredSummaryReport;
  const reportDateRange = formatCycleDateRange(mostRecentCycle);

  const clientName = client.state ? `${client.name}, ${client.state}` : client.name;
  const headerElement = header({ clientName, reportDateRange, headerTitle: 'Zencity Community Survey' });
  const questionnaireTemplate = await questionnaireSectionTemplate({
    selectedSurveyGroupId: surveyGroup.id,
    headerElement,
  });

  const benchmarkTemplate = generateBenchmarkReportTemplate(structuredSummaryReport, false);

  return [
    ...reportCoverPage({ clientName, reportDateRange }),
    ...reportIntroduction({
      clientName,
      reportDateRange,
      totalSubmissions: parsedMostRecentCycle?.totalSubmissions,
    }),
    ...overallSatisfaction({ headerElement }),
    ...geographicsBreakdown({ headerElement }),
    ...demographicsBreakdown({ headerElement }),
    ...servicesSatisfaction({ clientName: client.name, headerElement }),
    ...crossTabsSection({ headerElement }),
    ...benchmarkTemplate,
    ...wordCloudResponses({
      clientName: client.name,
      surveyGroup,
      reportDateRange,
      parsedMostRecentCycle,
      headerElement,
    }),
    ...rotatingSurveySection({ headerElement }),
    ...questionnaireTemplate,
    ...reportSummaryPage({ mostRecentCycle }),
  ];
};
export const generateSummaryReportTemplate = async (
  structuredSummaryReport: StructuredReport,
): Promise<DocumentFragment> => {
  const { client, surveyGroup, mostRecentCycle, parsedMostRecentCycle } = structuredSummaryReport;
  const summaryReportContent = await summaryReportTemplate({
    client,
    surveyGroup,
    parsedMostRecentCycle,
    mostRecentCycle,
  });
  return summaryReportContent;
};
