import { HorizontalSeparator, IconName, ZCDIcon, ZCDIconColor } from '@zencity/common-ui';
import classnames from 'classnames';
import { ActionButtonAsIcon } from 'components/ActionButtonAsIcon/ActionButtonAsIcon';
import { useOutsideClickListener } from 'customHooks/hooks';
import React, { ReactElement, useRef, useState } from 'react';
import styles from './ThreeDotsActionsMenu.module.scss';

export interface ActionMenuItem {
  icon: IconName;
  text: string;
  onClickHandler: () => void;
  isActive?: boolean;
  isSeparator?: boolean;
  isDisabled?: boolean;
}

interface Props {
  menuItems: ActionMenuItem[];
}

/**
 * A generic implementation of an "Actions menu" with a three dots toggle button.
 */
export const ThreeDotsActionsMenu = (props: Props): ReactElement => {
  const { menuItems } = props;

  const wrapperRef = useRef(null);
  const [displayActionsMenu, setDisplayActionsMenu] = useState(false);
  const toggleActionMenu = () => setDisplayActionsMenu(!displayActionsMenu);
  const closeActionMenu = () => setDisplayActionsMenu(false);
  useOutsideClickListener(wrapperRef, closeActionMenu);

  const invokeAndClose = (callbackToInvoke: () => void) => () => {
    callbackToInvoke();
    closeActionMenu();
  };

  return (
    <div className={styles.actionsMenuWrapper} ref={wrapperRef}>
      <ActionButtonAsIcon iconName="3-dot-menu-vertical" handleOnButtonClick={toggleActionMenu} />
      {displayActionsMenu && (
        <div className={styles.actionsMenu}>
          {menuItems.map((menuItem, index) =>
            menuItem.isSeparator ? (
              // eslint-disable-next-line react/no-array-index-key
              <HorizontalSeparator key={`separator-${index}`} />
            ) : (
              <button
                key={menuItem.icon}
                type="button"
                className={classnames(styles.actionMenuItem, { [styles.active]: menuItem.isActive })}
                onClick={invokeAndClose(menuItem.onClickHandler)}
                disabled={menuItem.isDisabled}
              >
                <ZCDIcon name={menuItem.icon} color={menuItem.isActive ? ZCDIconColor.BLUE : ZCDIconColor.ORIGINAL} />
                <span>{menuItem.text}</span>
              </button>
            ),
          )}
        </div>
      )}
    </div>
  );
};
