/* eslint-disable react/no-multi-comp */
import React, { ReactElement, useMemo } from 'react';
import { BenchmarkGenericQuestionScore } from 'screens/WidgetGenerator/utils/misc';
import { BenchmarkComparisonScale } from 'screens/WidgetGenerator/widgets/BenchmarkWidgets/BenchmarkGenericQuestionsTable/BenchmarkComparisonScale/BenchmarkComparisonScale';
import { GenericReactTable } from 'screens/WidgetGenerator/components/GenericReactTable/GenericReactTable';
import classnames from 'classnames';
import { DISPLAYED_BENCHMARK_TYPES } from 'screens/WidgetGenerator/widgets/BenchmarkWidgets/constants';
import { DEFAULT_MAX_ITEMS } from 'screens/WidgetGenerator/components/GenericReactTable/constants';
import styles from './BenchmarkGenericQuestionsTable.module.scss';

interface BenchmarkGenericQuestionsTableProps {
  widgetData: BenchmarkGenericQuestionScore[];
  selectedAspect?: string;
}

const COMPARISON_BAR_TITLE_COLUMN = 'Satisfaction Comparison';

const TABLE_PADDING_ROW_THRESHOLD = 4;

export const BenchmarkGenericQuestionsTable = (props: BenchmarkGenericQuestionsTableProps): ReactElement => {
  const { widgetData: benchmarkScores, selectedAspect } = props;

  const getColumns = () => {
    const benchmarkTypeHeaders = DISPLAYED_BENCHMARK_TYPES.map((benchmarkType) => ({
      Header: benchmarkType.replace('_', ' '),
      accessor: `benchmark_scores.${benchmarkType}.positive`,
      Cell: ({ value }: { value: number }) => `${Math.round(value * 100)}%`,
      width: 'auto',
    }));
    benchmarkTypeHeaders.push({
      Header: 'Your Score',
      accessor: 'overall_score',
      Cell: ({ value }: { value: number }) => `${Math.round(value * 100)}%`,
      width: 'auto',
    });

    return [
      {
        Header: 'Question',
        accessor: 'generic_question_text' as keyof BenchmarkGenericQuestionScore,
        width: 200,
      },
      ...benchmarkTypeHeaders,
      {
        Header: COMPARISON_BAR_TITLE_COLUMN,
        accessor: '',
        // eslint-disable-next-line id-length
        Cell: ({ row }: { row: { original: BenchmarkGenericQuestionScore } }) => (
          <BenchmarkComparisonScale benchmarkScore={row.original} />
        ),
        width: 180,
        // Only add a footer after the comparison bar column.
        Footer: () => (
          <div className={styles.scalePercentages}>
            <div className={styles.scalePercentage}>0%</div>
            <div className={styles.scalePercentage}>25%</div>
            <div className={styles.scalePercentage}>50%</div>
            <div className={styles.scalePercentage}>75%</div>
            <div className={styles.scalePercentage}>100%</div>
          </div>
        ),
      },
    ];
  };

  const benchmarkScoresFilteredByAspect = useMemo(
    () =>
      benchmarkScores.filter((benchmarkScore) =>
        selectedAspect ? benchmarkScore.aspect_value === selectedAspect : true,
      ),
    [benchmarkScores, selectedAspect],
  );

  return (
    <div
      className={classnames(styles.tableOverrides, {
        [styles.biggerPadding]: benchmarkScoresFilteredByAspect.length <= TABLE_PADDING_ROW_THRESHOLD,
      })}
    >
      <GenericReactTable
        columns={getColumns()}
        tableData={benchmarkScoresFilteredByAspect}
        hasFooter
        maxItemsLimit={DEFAULT_MAX_ITEMS}
        hideBorder
      />
    </div>
  );
};
