import { ZCDSelect } from '@zencity/common-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from 'screens/WidgetGenerator/components/FieldTitle/FieldTitle';
import { AspectValue } from 'screens/WidgetGenerator/utils/misc';
import { WidgetsConfigsContext } from 'screens/WidgetGenerator/contexts/WidgetsConfigsContext';
import { QuestionType } from 'types/genericQuestion';
import { logger } from 'utils/logger';
import { WidgetGeneratorContext } from 'screens/WidgetGenerator/contexts/WidgetGeneratorContext';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { SurveyGroup } from '@zencity/survey-types';
import { getGenericQuestionsData } from 'screens/WidgetGenerator/widgetAdditionalFields/utilsFunctionsAdditionalFields/GenericQuestionSelection/utils';

interface GenericQuestionSelectionProps {
  selectedSurveyGroup?: SurveyGroup;
  questionType?: QuestionType;
  aspect?: AspectValue;
}

export const GenericQuestionSelection = (props: GenericQuestionSelectionProps): React.ReactElement => {
  const { selectedSurveyGroup, questionType, aspect } = props;
  const [genericQuestionOptions, setGenericQuestionOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedGenericQuestionId, setSelectedGenericQuestionId } = useContext(WidgetsConfigsContext);
  const { selectedDateRange } = useContext(WidgetGeneratorContext);
  const shouldDisableSelection = !selectedSurveyGroup || !genericQuestionOptions.length;
  const { t: translate } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const fetchedGenericQuestionOptions = await getGenericQuestionsData({
          aspect,
          selectedDateRange,
          selectedSurveyGroup,
          questionTypesToInclude: questionType && [questionType],
        });
        setGenericQuestionOptions(fetchedGenericQuestionOptions);
      } catch (error) {
        logger.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [questionType, aspect, selectedSurveyGroup, selectedDateRange]);

  const handleSelectQuestion = (newValue: unknown) => {
    const selected = newValue as SelectOption;
    setSelectedGenericQuestionId(selected.value as number);
  };

  const selectedGenericQuestionOption = genericQuestionOptions.find(({ value }) => value === selectedGenericQuestionId);
  return (
    <>
      <FieldTitle title={translate('widgetGenerator.filters.crossTabs.selectBaseQuestion')} />
      <ZCDSelect
        placeholder={translate('widgetGenerator.filters.crossTabs.selectBaseQuestion')}
        options={genericQuestionOptions}
        onChange={handleSelectQuestion}
        value={selectedGenericQuestionOption}
        isDisabled={shouldDisableSelection}
        isLoading={isLoading}
      />
    </>
  );
};
