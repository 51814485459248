import { Flex } from 'components/Flex/Flex';
import React from 'react';
import styles from './MaintainAndFocusServices.module.scss';

interface Props {
  title: string;
  subtitle: string;
}

export const Title = (props: Props) => {
  const { title, subtitle } = props;

  return (
    <Flex flexDirection="column">
      <span className={styles.title}>{title}</span>
      <span className={styles.subtitle}>{subtitle}</span>
    </Flex>
  );
};
