/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAspects } from 'services/aspect';
import { Aspect } from 'types/aspect';
import { PaginatedResults, RequestStatus } from 'types/misc';

export interface AspectsState {
  aspectsSortedByLabel: Aspect[];
  fetchStatus: string;
  errorMessage?: string;
}

const initialState: AspectsState = {
  aspectsSortedByLabel: [],
  fetchStatus: RequestStatus.IDLE,
};

const aspectsSlice = createSlice({
  name: 'aspects',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAspects.pending, (state: AspectsState) => {
        state.fetchStatus = RequestStatus.LOADING;
      })
      .addCase(fetchAspects.fulfilled, (state: AspectsState, action: PayloadAction<PaginatedResults<Aspect>>) => {
        state.fetchStatus = RequestStatus.SUCCESS;
        state.aspectsSortedByLabel = action.payload.results;
      })
      .addCase(fetchAspects.rejected, (state: AspectsState, action) => {
        state.fetchStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      });
  },
});

export default aspectsSlice;
