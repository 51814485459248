import {
  ChoiceOption,
  NON_SUBSTANTIABLE_QUESTION_CHOICE_OPTION_TOKEN,
} from 'components/NewGenericQuestion/subComponents/NewQuestionMainSectionComponents/ChoicesMainSection/QuestionChoiceSelector';
import { NewGenericQuestionChoice } from 'types/genericQuestion';
import { ValidationError } from 'types/misc';

export const convertChoiceOptionToQuestionChoice = (questionChoice: ChoiceOption): NewGenericQuestionChoice => {
  const { id, numericValue } = questionChoice;
  if (!numericValue) {
    return {
      id,
    };
  }
  if (typeof numericValue !== 'number' && numericValue !== NON_SUBSTANTIABLE_QUESTION_CHOICE_OPTION_TOKEN) {
    throw new ValidationError(
      `Invalid numeric value: ${numericValue}. Must be a number or '${NON_SUBSTANTIABLE_QUESTION_CHOICE_OPTION_TOKEN}'`,
    );
  }
  if (numericValue === NON_SUBSTANTIABLE_QUESTION_CHOICE_OPTION_TOKEN) {
    return {
      id,
      is_non_substantiative: true,
    };
  }
  return {
    id,
    numeric_value: numericValue,
    is_non_substantiative: false,
  };
};

export const convertQuestionChoicesToChoiceOptions = (questionChoices: NewGenericQuestionChoice[]): ChoiceOption[] =>
  questionChoices.map((questionChoice) => {
    const { id, label, numeric_value, is_non_substantiative } = questionChoice;
    if (is_non_substantiative) {
      return {
        id,
        label,
        numericValue: NON_SUBSTANTIABLE_QUESTION_CHOICE_OPTION_TOKEN,
      };
    }
    if (numeric_value) {
      return {
        id,
        label,
        numericValue: numeric_value,
      };
    }
    return {
      id,
      label,
    };
  });
