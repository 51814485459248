import { Button, Spinner } from '@zencity/common-ui';
import classnames from 'classnames';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch } from 'customHooks/hooks';
import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { publishTypeformSurvey, republishTypeformSurvey } from 'services/typeformSurvey';
import { setTypeformSurvey, setTypeformSurveys } from 'slices/surveyManage';
import { TypeformSurvey } from 'types/typeformSurvey';
import { logger } from 'utils/logger';
import styles from './TypeformPublishButton.module.scss';

export enum PublishAction {
  PUBLISH = 'publish',
  REPUBLISH = 'republish',
}

interface Props {
  typeformSurvey: TypeformSurvey;
  publishAction: PublishAction;
}

/**
 * Handles the publishing of a Typeform survey.
 */
export const TypeformPublishButton = (props: Props): ReactElement => {
  const { toastError } = useContext(SurveyManagerToastContext);
  const { t: translate } = useTranslation();
  const { typeformSurvey, publishAction } = props;
  const dispatch = useAppDispatch();

  const { id: typeformSurveyId, language } = typeformSurvey;

  const [isPublishLoading, setIsPublishLoading] = useState(false);

  const publishSurveyToTypeform = async () => {
    try {
      const updatedTypeformSurvey = await publishTypeformSurvey(typeformSurveyId);
      dispatch(setTypeformSurvey(updatedTypeformSurvey));
    } catch (error) {
      const asError = error as Error;

      // Determine the specific error message for user display.
      if (asError.message.includes('census_data')) {
        toastError(`Before publishing a Typeform Survey, make sure there is a Census Data for the client.`);
      } else {
        toastError(asError.message);
        // There can be many reasons why we get an error from typeform, the user will be shown the error message in the toast.
        // so we only want to log it as a warning in the user's console and not get a Sentry alert.
        logger.warn(`Failed publishing Typeform Survey #${typeformSurvey.id} (${language}): ${error}`);
      }
    } finally {
      setIsPublishLoading(false);
    }
  };

  const republishSurveyToTypeform = async () => {
    try {
      const updatedTyepformSurveys = await republishTypeformSurvey(typeformSurveyId);
      dispatch(setTypeformSurveys(updatedTyepformSurveys));
    } catch (error) {
      toastError();
      logger.error(`Failed republishing Typeform Survey #${typeformSurvey.id} (${language}): ${error}`);
    } finally {
      setIsPublishLoading(false);
    }
  };

  const handleOnPublishButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsPublishLoading(true);
    switch (publishAction) {
      case PublishAction.PUBLISH:
        publishSurveyToTypeform();
        break;
      case PublishAction.REPUBLISH:
        republishSurveyToTypeform();
        break;
      default:
        break;
    }
  };

  return (
    <Button
      className={styles.publishButton}
      type="button"
      size="md"
      variant="primary"
      name="surveyItemPublishButton"
      onClick={handleOnPublishButtonClick}
    >
      <>
        {isPublishLoading && <Spinner size={16} color={styles.whiteColor} />}
        <p className={classnames({ [styles.marginPublishText]: isPublishLoading })}>
          {translate(`surveyManage.typeformSurvey.publishActions.${publishAction}`)}
        </p>
      </>
    </Button>
  );
};
