import { ZCDButton } from '@zencity/common-ui';
import { SurveyGroup } from '@zencity/survey-types';
import format from 'date-fns/format';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { exportSubmissionsBySurveyGroup } from 'services/communitySurveyReport/exportSubmissions';
import { FileTypes } from 'services/communitySurveyReport/util';
import { LeanClient } from 'types/client';
import { logger } from 'utils/logger';
import { SelectedDateRange } from '../misc';

interface Props {
  surveyGroup?: SurveyGroup;
  selectedDateRange?: SelectedDateRange;
  client: LeanClient;
  isLoading: boolean;
  totalSubmissions?: number;
  selectedFileFormat?: FileTypes;
}

const MAX_PAGE_SIZE = 2000;

export const VAULT_COMPATIBLE_DATE_FORMAT = 'yyyy-MM-dd';

export const SubmissionsExporter = (props: Props): ReactElement => {
  const [isExporting, setIsExporting] = React.useState(false);
  const { surveyGroup, selectedDateRange, client, totalSubmissions, selectedFileFormat, isLoading } = props;
  const { t: translate } = useTranslation();
  const propsAreDefined = client && surveyGroup;

  // eslint-disable-next-line max-statements
  const exportCommunitySurveySubmissions = async () => {
    if (!propsAreDefined || !totalSubmissions) {
      return;
    }
    try {
      setIsExporting(true);
      const { startDate, endDate } = selectedDateRange ?? {
        startDate: format(new Date(surveyGroup.created_at), VAULT_COMPATIBLE_DATE_FORMAT),
        endDate: format(new Date(), VAULT_COMPATIBLE_DATE_FORMAT),
      };
      const numberOfRequests = Math.ceil(totalSubmissions / MAX_PAGE_SIZE);
      for (let page = 1; page <= numberOfRequests; page += 1) {
        // eslint-disable-next-line no-await-in-loop
        await exportSubmissionsBySurveyGroup(
          {
            survey_group: surveyGroup.id,
            start_date: startDate,
            end_date: endDate,
            format: selectedFileFormat ?? FileTypes.XLSX,
            page,
            page_size: MAX_PAGE_SIZE,
          },
          client,
        );
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setIsExporting(false);
    }
  };

  const isDisabled = !propsAreDefined || isExporting || !totalSubmissions || isLoading;

  return (
    <ZCDButton
      onClick={exportCommunitySurveySubmissions}
      disabled={isDisabled}
      text={translate(`analysis.widgets.${isExporting ? 'exportingSubmissions' : 'exportSubmissions'}`)}
    />
  );
};
