import { getCyclesForScoring } from 'screens/WidgetGenerator/utils/cycles';
import { LeanClient } from 'types/client';
import { logger } from 'utils/logger';
import { fetchCommunitySurveyAreasScore } from 'services/communitySurveyScores';
import { AreasSentimentBarTableWidgetData, SelectedDateRange, SurveyCycle } from '../utils/misc';

const FALLBACK_RESPONSE = {
  id: '',
  name: '',
  totalSubmissions: 0,
  score: { positive: 0, neutral: 0, negative: 0 },
};

export const getAreasScoreData = async (
  client: LeanClient,
  cycles: SurveyCycle[],
  selectedDateRange: SelectedDateRange,
): Promise<AreasSentimentBarTableWidgetData[]> => {
  try {
    const cyclesForScoring = getCyclesForScoring(selectedDateRange, cycles);

    const communitySurveyAreasScores = await fetchCommunitySurveyAreasScore(client.id);

    const mappedAreasScoreData = Object.keys(communitySurveyAreasScores).map<AreasSentimentBarTableWidgetData>(
      (polygonId) => {
        const currentCycleScore = communitySurveyAreasScores[polygonId]?.scores?.find(
          (score) =>
            score.start_date === cyclesForScoring[0].startDate && score.end_date === cyclesForScoring[0].endDate,
        );
        const previousCycleScore = communitySurveyAreasScores[polygonId]?.scores?.find(
          (score) =>
            score.start_date === cyclesForScoring[1]?.startDate && score.end_date === cyclesForScoring[1]?.endDate,
        );

        const areasSentimentBarData: AreasSentimentBarTableWidgetData = {
          id: communitySurveyAreasScores[polygonId].polygon_id,
          name: communitySurveyAreasScores[polygonId].polygon_name,
          totalSubmissions: currentCycleScore?.total_submissions ?? FALLBACK_RESPONSE.totalSubmissions,
          score: currentCycleScore?.scores ?? FALLBACK_RESPONSE.score,
        };
        if (previousCycleScore) {
          areasSentimentBarData.previousCycle = {
            score: previousCycleScore?.scores,
          };
        }

        return areasSentimentBarData;
      },
    );
    return mappedAreasScoreData;
  } catch (error) {
    logger.error(error);
    return [FALLBACK_RESPONSE];
  }
};
