import React, { ReactElement, useRef, useState } from 'react';
import classnames from 'classnames';
import { ZCDIcon, ZCDIconColor, IconName } from '@zencity/common-ui';
import { ZCDTooltip, TooltipPlacement } from 'components/ZCDTooltip/ZCDTooltip';
import styles from './ActionButtonAsIcon.module.scss';

interface ActionButtonProps {
  iconName: IconName;
  isActive?: boolean;
  handleOnButtonClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tooltipText?: string;
}

/**
 * A helper component for an icon as an action button (tooltip supported).
 */
export const ActionButtonAsIcon = (props: ActionButtonProps): ReactElement => {
  const { iconName, handleOnButtonClick, tooltipText, isActive = false } = props;
  const iconRef = useRef<HTMLDivElement | null>(null);
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const handleOnMouseEnter = () => {
    if (!tooltipText) {
      return;
    }
    setDisplayTooltip(true);
  };

  const handleOnMouseLeave = () => {
    if (!tooltipText) {
      return;
    }
    setDisplayTooltip(false);
  };

  return (
    <>
      <button
        className={classnames(styles.actionButton, { [styles.active]: isActive })}
        type="button"
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleOnButtonClick}
      >
        <div ref={iconRef} className={styles.iconContainer}>
          <ZCDIcon name={iconName} color={isActive ? ZCDIconColor.BLUE : ZCDIconColor.ORIGINAL} />
        </div>
      </button>
      {tooltipText && (
        <ZCDTooltip elementRef={iconRef} tooltipPlacement={TooltipPlacement.RIGHT} displayTooltip={displayTooltip}>
          {tooltipText}
        </ZCDTooltip>
      )}
    </>
  );
};
