/* eslint-disable max-statements */
import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import React from 'react';
import { GenericReactTable } from 'screens/WidgetGenerator/components/GenericReactTable/GenericReactTable';
import { DEFAULT_MAX_ITEMS } from 'screens/WidgetGenerator/components/GenericReactTable/constants';
import i18next from 'i18next';
import { AspectValue, QuestionnaireWidgetData } from 'screens/WidgetGenerator/utils/misc';
import { QuestionnaireChoices } from './QuestionnaireChoices/QuestionnaireChoices';
import { QuestionnaireQuestion } from './QuestionnaireQuestion/QuestionnaireQuestion';
import styles from './Questionnaire.module.scss';

interface Props {
  widgetData: QuestionnaireWidgetData[];
  splitPositionQuestionnaire: number;
  tableIndexToDisplay?: number;
  showAspectTableTitle?: boolean;
}
interface QuestionnaireTableData {
  questions: JSX.Element;
  choices?: JSX.Element;
}
const getTableBodyRows = (questions: QuestionnaireWidgetData['questions']): QuestionnaireTableData[] => {
  const createRow = (question: QuestionnaireWidgetData['questions'][0]) => {
    const isCharacteristicAspect = question.aspect.value === AspectValue.CHARACTERISTIC;
    const tableRow: QuestionnaireTableData = {
      questions: <QuestionnaireQuestion question={question} />,
    };

    if (!isCharacteristicAspect) {
      tableRow.choices = <QuestionnaireChoices question={question} />;
    }
    return tableRow;
  };
  return questions.map(createRow);
};
const choicesText = i18next.t('widgetGenerator.widgets.questionnaire.choices');
const questionsText = i18next.t('widgetGenerator.widgets.questionnaire.questions');

const getColumns = (aspect: AspectValue) => {
  const columns = [
    {
      Header: questionsText,
      accessor: 'questions',
      width: 'auto',
    },
  ];
  if (aspect !== AspectValue.CHARACTERISTIC) {
    columns.push({
      Header: choicesText,
      accessor: 'choices',
      width: 'auto',
    });
  }
  return columns;
};

export const Questionnaire = (props: Props) => {
  const { widgetData, splitPositionQuestionnaire, showAspectTableTitle = true, tableIndexToDisplay } = props;
  const aspectTableToSplitHorizontally = [AspectValue.CHARACTERISTIC];

  return (
    <Flex flexDirection="column" className={styles.questionnaire}>
      {widgetData.map((questionnaireData) => {
        const { aspect, questions } = questionnaireData;
        const aspectValue = aspect.value as AspectValue;
        const isVerticalSplit = !aspectTableToSplitHorizontally.includes(aspectValue);
        const columns = getColumns(aspectValue);

        // In case there is more question than the splitPositionQuestionnaire, we split the table to multiple tables with
        // the same rows amount respectively to the splitPositionQuestionnaire.
        const tables = [];
        const tablesAmount = Math.ceil(questions.length / splitPositionQuestionnaire);
        for (let index = 0; index < tablesAmount; index += 1) {
          const tableQuestions = questions.slice(
            index * splitPositionQuestionnaire,
            (index + 1) * splitPositionQuestionnaire,
          );
          const tableData = getTableBodyRows(tableQuestions);
          tables.push(
            <GenericReactTable
              columns={columns}
              tableData={tableData}
              maxItemsLimit={DEFAULT_MAX_ITEMS}
              key={`table-${index}`}
              condensed
            />,
          );
          if (!isVerticalSplit) {
            tables.push(<Spacer orientation="horizontal" size="medium" key={`h-spacer-${index}`} />);
          }
          // We add a spacer between the tables only when we display multiple tables.
          if (isVerticalSplit && !tableIndexToDisplay) {
            tables.push(<Spacer orientation="vertical" size="medium" key={`v-spacer-${index}`} />);
          }
        }

        const displayAspectTableTitle = showAspectTableTitle && widgetData.length > 1;
        const questionnaireTables = tableIndexToDisplay ? tables[tableIndexToDisplay - 1] : tables;
        return (
          <Flex flexDirection="column" key={`aspect-${aspect.id}`}>
            {displayAspectTableTitle && <p className={styles.tableTitle}>{aspect.label}</p>}
            <Flex alignItems="flex-start" flexDirection={isVerticalSplit ? 'column' : 'row'}>
              {questionnaireTables}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
