import { Dispatch, SetStateAction } from 'react';
import { PaginatedResults } from 'types/misc';
import { logger } from './logger';

interface FetchFilteredResultsParams<T> {
  filteredResults: T;
  filteredResultsNextPage?: number;
  filters: { [key: string]: string | number | undefined };
  setIsFetchingByFilter: Dispatch<SetStateAction<boolean>>;
  setFilteredResults: Dispatch<SetStateAction<T[]>>;
  setFilteredResultsNextPage: Dispatch<SetStateAction<number | undefined>>;
  getParamsForFilters: (filters: { [key: string]: string | number | undefined }) => Record<string, string>;
  fetchFunction: (params: Record<string, string>) => Promise<PaginatedResults<T>>;
  toastError: (content?: string) => void;
}

/**
 * A helper function to invoke the "fetch filtered results" action.
 */
export const fetchFilteredResults = ({
  setIsFetchingByFilter,
  setFilteredResults,
  setFilteredResultsNextPage,
  getParamsForFilters,
  filteredResults,
  filters,
  fetchFunction,
  filteredResultsNextPage,
  toastError,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
FetchFilteredResultsParams<any>): void => {
  setIsFetchingByFilter(true);
  const params = getParamsForFilters({
    ...filters,
    filteredResultsNextPage,
  });

  fetchFunction(params)
    .then((response) => {
      if (!filteredResultsNextPage) {
        setFilteredResults(response.results);
      } else {
        setFilteredResults(filteredResults.concat(response.results));
      }
      if (!response.next) {
        setFilteredResultsNextPage(undefined);
      } else {
        setFilteredResultsNextPage(filteredResultsNextPage ? filteredResultsNextPage + 1 : 2);
      }
    })
    .catch((error) => {
      toastError();
      logger.error(error);
    })
    .finally(() => setIsFetchingByFilter(false));
};
