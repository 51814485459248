/* eslint-disable max-lines,max-lines-per-function,max-statements */
import { ZCDNotificationContainer, zcdNotify } from '@zencity/common-ui';
import { BreadcrumbToolbar } from 'components/BreadcrumbToolbar/BreadcrumbToolbar';
import { Header } from 'components/Header/Header';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RouterPath, SurveyChildPath, useCurrentSurveyPath } from 'routerPaths';
import { SurveyForm } from 'screens/SurveyEditStartScreen/components/SurveyForm';
import {
  CurrentSurvey,
  generateInternalTitlePrefix,
  handleCloneSurvey,
  isValidForSubmit,
  parseNumericUrlParam,
  prepopulateSurveyByClientAndType,
  createOrEditSurvey,
  updateCurrentSurvey,
  generateSurveyPath,
} from 'screens/SurveyEditStartScreen/SurveyEditStartScreen.helpers';
import { fetchSurveyById } from 'services/survey';
import { updateSurveyGroupCadence, updateSurveyGroupTitleForDisplay } from 'services/surveyGroup';
import { fetchSurveyRequestById } from 'services/surveyRequest';
import { setSurveyInStore } from 'slices/survey';
import { RootState } from 'store';
import { Survey, SurveyType } from 'types/survey';
import { zcdDialogConfirm } from 'utils/dialog';
import { logger } from 'utils/logger';
import { getRelevantCadencesBySurveyType } from 'utils/survey';

export const SurveyEditStartScreen = (): ReactElement => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const currentSurveyPath = useCurrentSurveyPath();
  const [currentSurvey, setCurrentSurvey] = useState<CurrentSurvey>({});
  const [isSurveyCreationInProgress, setIsSurveyCreationInProgress] = useState(false);
  const [cloneSurveyInProgress, setCloneSurveyInProgress] = useState(false);
  const [prepopulateRecurringSurveyInProgress, setPrepopulateRecurringSurveyInProgress] = useState(false);
  const [isFetchingSurveyRequest, setIsFetchingSurveyRequest] = useState(false);
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const { clientsById } = useAppSelector((state) => state.clients);
  const { surveys, errorMessage } = useAppSelector((state) => state.surveys);
  const { recurringSurveyTypes, defaultLanguage } = useAppSelector((state: RootState) => state.globalConfigs);

  const cloneSurveyPathPattern = `${RouterPath.SURVEY}/${SurveyChildPath.CLONE}`;
  const inCloneMode = currentSurveyPath === cloneSurveyPathPattern;

  // Fetch the Survey ID and Survey Request ID from the URL (if given).
  const { surveyId = '' } = useParams();
  const surveyIdAsNumber = parseNumericUrlParam(surveyId);
  const surveyRequestIdAsNumber = parseNumericUrlParam(searchParams.get('surveyRequestId') ?? '');

  const relevantCadences = useMemo(() => getRelevantCadencesBySurveyType(currentSurvey?.surveyType), [currentSurvey]);
  const allowedToSelectCadence = !!relevantCadences.length;

  const headerTitle = useMemo(
    () =>
      surveyIdAsNumber
        ? surveys[surveyIdAsNumber]?.survey_group.title_for_display
        : translate('surveyEdit.creatingASurvey'),
    [surveyIdAsNumber, surveys, translate],
  );

  const navigateAfterSurveyCreationOrEdit = (newOrEditedSurveyId: number) => {
    const surveyBuildUrl = generateSurveyPath(SurveyChildPath.BUILD, newOrEditedSurveyId);
    const surveyEditUrl = generateSurveyPath(SurveyChildPath.EDIT, newOrEditedSurveyId);
    navigate(surveyBuildUrl, { state: { previousPath: surveyEditUrl } });
  };

  const updateTitleForDisplay = async (survey: Survey, titleForDisplay: string, language: string) => {
    await dispatch(
      updateSurveyGroupTitleForDisplay({
        survey,
        translatedTitleForDisplay: titleForDisplay,
        language,
        defaultLanguage,
      }),
    );
  };

  const updateSurveyGroupTitle = async (existingSurvey: Survey): Promise<void> => {
    const { survey_group: surveyGroup } = existingSurvey;
    const { title_for_display: titleForDisplay, is_locked: isLocked } = surveyGroup;
    if (titleForDisplay === currentSurvey.titleForDisplay || !currentSurvey.titleForDisplay) {
      return;
    }

    // If the survey group is locked, we need to confirm the change.
    if (isLocked) {
      const updateSurveyGroupTitleConfirmation = await zcdDialogConfirm({
        title: translate('surveyEdit.titleForDisplayConfirmationDialog.title'),
        message: translate('surveyEdit.titleForDisplayConfirmationDialog.content'),
        cancelButtonText: translate('common.cancel'),
        confirmButtonText: translate('common.yes'),
      });
      if (updateSurveyGroupTitleConfirmation) {
        await updateTitleForDisplay(existingSurvey, currentSurvey.titleForDisplay, defaultLanguage);
      }
      // Otherwise, we can just update the title.
    } else {
      await updateTitleForDisplay(existingSurvey, currentSurvey.titleForDisplay, defaultLanguage);
    }
  };

  const updateCadence = async (existingSurvey: Survey): Promise<void> => {
    const { survey_group: surveyGroup } = existingSurvey;
    const { cadence: existingCadence } = surveyGroup;
    const { surveyCadence: updatedCadence } = currentSurvey;
    if (!updatedCadence || existingCadence === updatedCadence) {
      return;
    }
    await dispatch(updateSurveyGroupCadence({ survey: existingSurvey, cadence: updatedCadence }));
  };

  const editSurveySubmitHandler = async () => {
    setIsSurveyCreationInProgress(true);
    const existingSurvey = surveys?.[surveyIdAsNumber];
    try {
      if (existingSurvey) {
        await updateSurveyGroupTitle(existingSurvey);
        await updateCadence(existingSurvey);
      }
      const survey = await createOrEditSurvey({
        allowedToSelectCadence,
        currentSurvey,
        existingSurvey: surveys[surveyIdAsNumber],
        surveyRequestId: surveyRequestIdAsNumber,
      });
      if (survey) {
        dispatch(setSurveyInStore(survey));
        navigateAfterSurveyCreationOrEdit(survey.id);
      }
    } catch (error) {
      if (existingSurvey) {
        zcdNotify.error(`Error updating Survey #${surveyIdAsNumber}`);
      } else {
        zcdNotify.error(`Error creating new Survey.`);
      }
      logger.error(error);
    } finally {
      setIsSurveyCreationInProgress(false);
    }
  };

  const cloneSurveySubmitHandler = () => {
    handleCloneSurvey({
      surveyId: surveyIdAsNumber,
      currentSurvey,
      dispatch,
      navigate,
      setCloneSurveyInProgress,
    });
  };

  /**
   * Determine which action to invoke when the user clicks the "Next" button.
   */
  const clickNext = async (): Promise<void> => {
    if (surveyIdAsNumber) {
      // Check that the page is the "clone" page.
      if (inCloneMode) {
        cloneSurveySubmitHandler();
      } else {
        // When editing, no need a confirmation from the user, because the only
        // part allowed to be modified is the suffix of the "internal title".
        editSurveySubmitHandler();
      }
    } else {
      const confirmationDialogResponse = await zcdDialogConfirm({
        title: translate('surveyEdit.confirmationDialog.title'),
        message: translate('surveyEdit.confirmationDialog.content'),
        confirmButtonText: translate('surveyEdit.confirmationDialog.submitLabel'),
      });
      if (confirmationDialogResponse) {
        editSurveySubmitHandler();
      }
    }
  };

  useEffect(() => {
    if (errorMessage) {
      zcdNotify.error(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    const fetchSurveyRequest = async () => {
      try {
        setIsFetchingSurveyRequest(true);

        const surveyRequest = await fetchSurveyRequestById(surveyRequestIdAsNumber);
        const internalTitlePattern = generateInternalTitlePrefix(
          surveyRequest.client.id,
          SurveyType.COMMUNITY_ASKS,
          clientsById,
          translate,
        );
        setCurrentSurvey((prevCurrentSurvey) => ({
          ...prevCurrentSurvey,
          clientId: surveyRequest.client.id,
          surveyType: SurveyType.COMMUNITY_ASKS,
          internalTitlePattern,
        }));
      } catch (error) {
        zcdNotify.error(`Error fetching Survey Request #${surveyRequestIdAsNumber}`);
        logger.error(error);
      } finally {
        setIsFetchingSurveyRequest(false);
      }
    };
    if (surveyRequestIdAsNumber) {
      // Survey Request sent in the URL Params.
      // Fetch the survey request and prepopulate the form.
      fetchSurveyRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyRequestIdAsNumber, clientsById]);

  useEffect(() => {
    if (currentSurvey?.clientId && currentSurvey?.surveyType) {
      // Populate fields based off of the chosen client and survey type.
      prepopulateSurveyByClientAndType({
        clientId: currentSurvey.clientId,
        surveyType: currentSurvey.surveyType,
        setCurrentSurvey,
        clientsById,
        translate,
        setPrepopulateRecurringSurveyInProgress,
        recurringSurveyTypes,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSurvey?.clientId, currentSurvey?.surveyType]);

  /**
   * Load the data of the given Survey and adjust the state accordingly.
   */
  useEffect(() => {
    if (!surveyIdAsNumber) {
      return;
    }
    if (!surveys[surveyIdAsNumber]) {
      fetchSurveyById(surveyIdAsNumber)
        .then((survey) => {
          // Cache the fetched Survey in the store. This will trigger another
          // render which will then invoke `updateCurrentSurvey()` because the
          // Survey is already in the store.
          dispatch(setSurveyInStore(survey));
        })
        .catch((error) => {
          zcdNotify.error(`Error fetching Survey #${surveyIdAsNumber}`);
          logger.error(error);
        });
    } else {
      updateCurrentSurvey(surveys[surveyIdAsNumber], clientsById, translate, setCurrentSurvey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, clientsById, surveys, surveyId]);

  const isLoading =
    isSurveyCreationInProgress ||
    cloneSurveyInProgress ||
    prepopulateRecurringSurveyInProgress ||
    isFetchingSurveyRequest;

  const isNextButtonDisabled = !isValidForSubmit(currentSurvey) || isSurveyCreationInProgress;

  return (
    <>
      <LoaderMask isLoading={isLoading}>
        <Header titleText={headerTitle} />
        <BreadcrumbToolbar
          previousText={`<- ${translate('surveyEdit.backToMainPage')}`}
          previousLink={RouterPath.MAIN}
          nextText={translate('common.next')}
          nextButtonOnClick={clickNext}
          nextButtonDisabled={isNextButtonDisabled}
          surveyId={surveyId}
        />
        <SurveyForm
          currentSurvey={currentSurvey}
          setCurrentSurvey={setCurrentSurvey}
          inCloneMode={inCloneMode}
          relevantCadences={relevantCadences}
          existsSurveyRequest={!!surveyRequestIdAsNumber}
        />
      </LoaderMask>
      <ZCDNotificationContainer />
    </>
  );
};
