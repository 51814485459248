import React from 'react';
import { OverallSentimentBarTableWidgetData } from 'screens/WidgetGenerator/utils/misc';
import { SentimentBarTable } from './SentimentBarTable';

interface OverallSentimentBarTableProps {
  widgetData: OverallSentimentBarTableWidgetData;
  isBenchmarked: boolean;
}

export function OverallSentimentBarTable(props: OverallSentimentBarTableProps): React.ReactElement {
  const { widgetData, isBenchmarked } = props;
  const mergedData = [...widgetData.qualityOfLifeLastCycleScores, ...widgetData.characteristicsLastCycleScores];

  return (
    <div className="overallSentimentBarTable">
      <SentimentBarTable
        widgetData={mergedData}
        firstColumHeader="Question"
        secondColumHeader="Resident satisfaction"
        isBenchmarked={isBenchmarked}
        type="overall"
      />
    </div>
  );
}
