import html2canvas from 'html2canvas';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { WidgetType } from '../../../utils/misc';
import { WidgetsConfigsContext } from '../../../contexts/WidgetsConfigsContext';
import styles from './CanvasWrapper.module.scss';

interface CanvasWrapperProps {
  children: React.ReactElement;
}

export function CanvasWrapper(props: CanvasWrapperProps): React.ReactElement | null {
  const { children } = props;
  const { widgetWidth, isValidData, widgetType } = useContext(WidgetsConfigsContext);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: widgetWidth,
    height: widgetWidth,
  });
  const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D | null>();
  const canvasRef = useRef<HTMLCanvasElement>(null as unknown as HTMLCanvasElement);
  const htmlRef = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);
  const scale = 2;

  useEffect(() => {
    if (htmlRef.current) {
      setCanvasDimensions({
        width: htmlRef.current.offsetWidth * scale,
        height: htmlRef.current.offsetHeight * scale,
      });
      if (canvasRef.current) {
        setCanvasContext(canvasRef.current.getContext('2d'));
        if (canvasContext) {
          canvasContext.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);
        }
      }
      html2canvas(htmlRef.current, {
        canvas: canvasRef.current,
        width: canvasDimensions.width,
        height: canvasDimensions.height,
        backgroundColor: 'transparent',
        scale,
        removeContainer: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidData, widgetWidth]);

  // in case widgetType is is word cloud, we need to return him without the canvas wrapper.
  return (
    <div className={styles.canvasWrapper} data-is_valid={isValidData}>
      {widgetType === WidgetType.WORD_CLOUD ? (
        children
      ) : (
        <>
          <canvas
            className={styles.canvaselm}
            ref={canvasRef}
            width={canvasDimensions.width}
            height={canvasDimensions.height}
          />
          <div className={styles.elmCapture} ref={htmlRef} style={{ width: widgetWidth }}>
            <div>{children}</div>
          </div>
        </>
      )}
    </div>
  );
}
