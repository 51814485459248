import React, { ReactElement } from 'react';
import { Icon } from '@zencity/common-ui';
import classnames from 'classnames';
import styles from './Dropdown.module.scss';

export interface Option {
  label: string;
  value: string | number;
  key: string | number;
}
interface Props {
  selectedValue?: string;
  onChangeCallback: (v: string) => void;
  options: Option[];
  emptyOptionLabel?: string;
  dropdownClassName?: string;
}

/**
 * A generic, but styled, "Dropdown" component.
 *
 * @param props
 *  - `selectedValue` - The value of the selected option.
 *  - `onChaneCallback` - A callback function to invoke when selection is changed.
 *  - `options` - The list of options to apply to the dropdown.
 *  - `emptyOptionLabel` - The label for the empty option (if not passed, the empty option will be hidden).
 *  - `dropdownClassName` - A classname to add to the "select" element (for custom styling).
 */
export const Dropdown = (props: Props): ReactElement => {
  const { selectedValue, onChangeCallback, options, emptyOptionLabel, dropdownClassName } = props;

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChangeCallback) {
      onChangeCallback(event.currentTarget.value);
    }
  };

  return (
    <div className={styles.dropdownWrapper}>
      <select
        className={classnames(styles.dropdown, dropdownClassName)}
        value={selectedValue}
        onChange={handleOnChange}
      >
        {emptyOptionLabel && <option value="">- {`${emptyOptionLabel}`} -</option>}
        {options.map((option) => (
          <option key={option.key} value={option.value}>
            {`${option.label}`}
          </option>
        ))}
      </select>
      <Icon name="arrow/wide-angle-down.svg" height={7} width={12} className={styles.wideArrowDownIcon} />
    </div>
  );
};
