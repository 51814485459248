import { Box, HorizontalSeparator, Tabs } from '@zencity/common-ui';
import { TranslationSection } from 'components/SurveyTranslationsList/SurveyTranslationsList.helpers';
import { TranslationPageItem } from 'components/TranslationPageItem/TranslationPageItem';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import styles from './SurveyTranslationsList.module.scss';

enum TranslateFormTab {
  MISSING = 'missing',
  ALL = 'all',
}

/**
 * The form to add and view missing translations for a survey language.
 */
export const SurveyTranslationsList = (): ReactElement => {
  const { t: translate } = useTranslation();
  const { translationSections, selectedLanguage } = useAppSelector((state: RootState) => state.surveyManage);
  const [activeTab, setActiveTab] = useState(TranslateFormTab.MISSING);

  const sectionsWithMissingTranslations = useMemo(
    () =>
      // Filter the sections to only contain translation items that are missing.
      translationSections.reduce<TranslationSection[]>((missingTranslationSections, currentTranslationSection) => {
        const missingTranslationItems = currentTranslationSection.translationItems.filter(
          // eslint-disable-next-line max-nested-callbacks
          (translationItem) => !translationItem.translatedTexts[selectedLanguage],
        );
        if (missingTranslationItems.length > 0) {
          missingTranslationSections.push({
            ...currentTranslationSection,
            translationItems: missingTranslationItems,
          });
        }
        return missingTranslationSections;
      }, []),
    [translationSections, selectedLanguage],
  );

  const numMissingTranslations = useMemo(() => {
    const allTranslationItems = translationSections
      .map((translationSection) => translationSection.translationItems)
      .flat();
    const filteredTranslationItemsByLanguage = allTranslationItems.filter(
      (translationItem) => !translationItem.translatedTexts[selectedLanguage],
    );
    return filteredTranslationItemsByLanguage.length;
  }, [translationSections, selectedLanguage]);

  const baseTranslationTabPath = 'surveyTranslationFormLayout.translationTabs';
  const tabsItemsMap = {
    [TranslateFormTab.MISSING]: {
      position: 0,
      label: `${translate(`${baseTranslationTabPath}.missing`)} (${numMissingTranslations})`,
    },
    [TranslateFormTab.ALL]: { position: 1, label: translate(`${baseTranslationTabPath}.all`) },
  };
  const onTabSelect = (selectedTab: number | string) => setActiveTab(selectedTab as TranslateFormTab);

  return (
    <Box.Wrapper className={styles.surveyTranslationsList}>
      <Box.Header className={styles.boxHeader}>
        <Tabs items={tabsItemsMap} selectedTab={activeTab} onSelect={onTabSelect} />
      </Box.Header>
      <Box.Content>
        {activeTab === TranslateFormTab.MISSING &&
          sectionsWithMissingTranslations.map((translationSection, index) => (
            <>
              <TranslationPageItem key={translationSection.key} translationSection={translationSection} />
              {index < sectionsWithMissingTranslations.length - 1 && <HorizontalSeparator />}
            </>
          ))}
        {activeTab === TranslateFormTab.ALL &&
          translationSections.map((translationSection, index) => (
            <>
              <TranslationPageItem key={translationSection.key} translationSection={translationSection} />
              {index < translationSections.length - 1 && <HorizontalSeparator />}
            </>
          ))}
      </Box.Content>
    </Box.Wrapper>
  );
};
