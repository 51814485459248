/* eslint-disable id-denylist */
import { DocumentFragment } from '../documentFragment';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import { lineBreakElement } from '../utils/lineBreakElement';
import { REPORT_BUILDER_PATH } from '../../constants';
import { generateLineBreaks } from '../utils/utilsFunctions';

interface CoverPageProps {
  clientName: string;
  reportDateRange: string;
}
const ZENCITY_LOGO = `${REPORT_BUILDER_PATH}/logo-zencity.png`;

export const reportCoverPage = (props: CoverPageProps): DocumentFragment => [
  {
    name: 'paragraph',
    children: [
      {
        data: '[logo]',
        attributes: {
          fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
          fontColor: TEMPLATE_TYPOGRAPHY.colors.red90,
        },
      },
    ],
  },
  lineBreakElement,
  lineBreakElement,
  {
    name: 'heading1',
    children: [
      {
        data: props.clientName,
        attributes: {
          fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading1,
          fontColor: TEMPLATE_TYPOGRAPHY.colors.purple100,
        },
      },
    ],
  },
  {
    name: 'heading1',
    children: [
      {
        data: 'Community Survey',
        attributes: {
          fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading1,
          fontColor: TEMPLATE_TYPOGRAPHY.colors.blue50,
        },
      },
    ],
  },
  {
    name: 'heading2',
    children: [
      {
        data: props.reportDateRange,
        attributes: {
          fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading2,
          fontColor: TEMPLATE_TYPOGRAPHY.colors.purple100,
        },
      },
    ],
  },
  ...generateLineBreaks(4),
  {
    name: 'paragraph',
    children: [
      {
        data: `Powered by `,
        attributes: {
          fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.bodyMedium,
          fontColor: TEMPLATE_TYPOGRAPHY.colors.gray100,
        },
      },
      {
        name: 'imageInline',
        attributes: {
          src: ZENCITY_LOGO,
          width: '5%',
        },
      },
    ],
  },
  {
    name: 'pageBreak',
  },
];
