import { ZCDFilter, ZCDSpinner } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterOptionWithKey } from 'types/filters';
import styles from '../Analysis.module.scss';
import { SelectedDateRange } from '../misc';

interface Props {
  isLoading: boolean;
  options: FilterOptionWithKey[];
  selectedDateRange?: SelectedDateRange;
  onChangeCallback: (option: FilterOptionWithKey & { totalSubmissions: number }) => void;
}

export const DateRangeFilter = ({ isLoading, onChangeCallback, options, selectedDateRange }: Props): ReactElement => {
  const dateFilterValue = options.find(
    (rakeWeighterExecution) => rakeWeighterExecution.value === selectedDateRange?.value,
  );
  const { t: translate } = useTranslation();
  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <ZCDSpinner />
      </div>
    );
  }

  return (
    <ZCDFilter<FilterOptionWithKey>
      value={dateFilterValue}
      onChange={(option) => onChangeCallback(option as FilterOptionWithKey & { totalSubmissions: number })}
      label={translate('analysis.dateRange')}
      options={options}
      isDisabled={!options.length}
    />
  );
};
