import { ZCDButton } from '@zencity/common-ui';
import { Flex } from 'components/Flex/Flex';
import { configSetter } from 'components/NewGenericQuestion/NewGenericQuestion.helpers';
import { Spacer } from 'components/Spacer/Spacer';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { setNewQuestionConfigs } from 'slices/questionsBank';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';

interface Props {
  addNewOption: () => void;
  isDisplayingOther: boolean;
  allowOtherOption: boolean;
}

export const AddOptionButtons = (props: Props): ReactElement => {
  const { addNewOption, isDisplayingOther, allowOtherOption } = props;
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { newQuestionPayload } = useAppSelector((state: RootState) => state.questionsBank);

  const toggleIsDisplayingOther = (value: string) =>
    dispatch(setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.DISPLAY_OTHER, value)));

  const displayAddOtherButton = !isDisplayingOther && allowOtherOption;

  return (
    <Flex alignItems="center">
      <ZCDButton
        variant="link"
        text={translate('questionsBankDialog.newQuestion.addOption')}
        preIcon="more"
        onClick={addNewOption}
      />
      {displayAddOtherButton && (
        <>
          <Spacer size="small" orientation="horizontal" />
          <p>{translate('common.or')}</p>
          <Spacer size="small" orientation="horizontal" />
          <ZCDButton
            variant="link"
            text={translate('questionsBankDialog.newQuestion.addOther')}
            preIcon="more"
            onClick={() => toggleIsDisplayingOther('1')}
          />
        </>
      )}
    </Flex>
  );
};
