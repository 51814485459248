/* eslint-disable max-lines-per-function */
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { configSetter } from 'components/NewGenericQuestion/NewGenericQuestion.helpers';
import { ActionMenuItem, ThreeDotsActionsMenu } from 'components/ThreeDotsActionsMenu/ThreeDotsActionsMenu';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  newQuestionPayloadInitialState,
  setNewQuestionConfigs,
  setNewQuestionPayload,
  setShowNumericValues,
} from 'slices/questionsBank';
import { RootState } from 'store';
import { QuestionType } from 'types/genericQuestion';
import { QuestionConfigType } from 'types/questionConfig';
import {
  resolveAlphabeticalOrderConfig,
  resolveHideFromLiveFeedConfig,
  resolveRandomizedConfig,
} from 'utils/genericAndQuestionConfigs';

const TRUTHY_VALUE = '1';

/**
 * Displays the Generic Question "actions menu".
 *
 * This menu allows the user to set some configurations of a Generic Question.
 */
// eslint-disable-next-line max-statements
export const NewQuestionActionsMenu = (): ReactElement => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const [resetFormIsOpen, setResetFormIsOpen] = useState(false);
  const { newQuestionPayload, showNumericValues } = useAppSelector((state: RootState) => state.questionsBank);
  const questionIsRandomized = resolveRandomizedConfig(
    newQuestionPayload.configs.find(({ config_name }) => config_name === QuestionConfigType.RANDOMIZE)?.config_value,
  );

  const questionIsAlphabeticalOrder = resolveAlphabeticalOrderConfig(
    newQuestionPayload.configs.find(({ config_name }) => config_name === QuestionConfigType.ALPHABETICAL_ORDER)
      ?.config_value,
  );

  const toggleQuestionIsRandomized = () =>
    dispatch(
      setNewQuestionConfigs(
        configSetter(newQuestionPayload, QuestionConfigType.RANDOMIZE, !questionIsRandomized ? TRUTHY_VALUE : ''),
      ),
    );

  const toggleQuestionAlphabeticalOrder = () =>
    dispatch(
      setNewQuestionConfigs(
        configSetter(
          newQuestionPayload,
          QuestionConfigType.ALPHABETICAL_ORDER,
          !questionIsAlphabeticalOrder ? TRUTHY_VALUE : '',
        ),
      ),
    );
  const questionIsHiddenFromLiveFeed = resolveHideFromLiveFeedConfig(
    newQuestionPayload.configs.find(({ config_name }) => config_name === QuestionConfigType.HIDE_FROM_LIVE_FEED)
      ?.config_value,
  );

  const toggleHideFromLiveFeed = () => {
    dispatch(
      setNewQuestionConfigs(
        configSetter(
          newQuestionPayload,
          QuestionConfigType.HIDE_FROM_LIVE_FEED,
          !questionIsHiddenFromLiveFeed ? TRUTHY_VALUE : '',
        ),
      ),
    );
  };

  const resetFormHandler = () => {
    dispatch(setNewQuestionPayload(newQuestionPayloadInitialState));
  };

  let menuItems = [] as ActionMenuItem[];

  const randomizeMenuItem: ActionMenuItem = {
    icon: 'sort',
    text: translate('questionActionsMenu.randomizeChoices'),
    isActive: questionIsRandomized,
    onClickHandler: toggleQuestionIsRandomized,
    isDisabled: questionIsAlphabeticalOrder,
  };
  const addNumericValuesMenuItem: ActionMenuItem = {
    icon: 'hashtag',
    text: translate('questionActionsMenu.addNumericValues'),
    isActive: showNumericValues,
    onClickHandler: () => dispatch(setShowNumericValues(!showNumericValues)),
  };

  if (newQuestionPayload.question_type === QuestionType.CHOICES) {
    menuItems = menuItems.concat([
      addNumericValuesMenuItem,
      randomizeMenuItem,
      {
        isSeparator: true,
      },
    ] as ActionMenuItem[]);
  }

  if (newQuestionPayload.question_type === QuestionType.DROPDOWN) {
    menuItems = menuItems.concat([
      randomizeMenuItem,
      {
        icon: 'sort',
        text: translate('questionActionsMenu.alphabeticalOrder'),
        isActive: questionIsAlphabeticalOrder,
        onClickHandler: toggleQuestionAlphabeticalOrder,
        isDisabled: questionIsRandomized,
      },
      {
        isSeparator: true,
      },
    ] as ActionMenuItem[]);
  }
  if (newQuestionPayload.question_type === QuestionType.OPEN_ENDED) {
    menuItems = menuItems.concat([
      {
        icon: 'views',
        text: translate('questionActionsMenu.hideFromLiveFeed'),
        isActive: questionIsHiddenFromLiveFeed,
        onClickHandler: toggleHideFromLiveFeed,
      },
      {
        isSeparator: true,
      },
    ] as ActionMenuItem[]);
  }
  // Always display the "reset form" action.
  menuItems.push({
    icon: 'delete',
    text: translate('questionActionsMenu.resetForm'),
    onClickHandler: () => setResetFormIsOpen(true),
  });

  return (
    <>
      <ThreeDotsActionsMenu menuItems={menuItems} />
      <ConfirmationDialog
        isOpen={resetFormIsOpen}
        setIsOpen={setResetFormIsOpen}
        title={translate('questionActionsMenu.resetForm')}
        submitBtnLabel={translate('questionActionsMenu.resetSubmitLabel')}
        confirmationHandler={resetFormHandler}
      >
        <div>{translate('questionActionsMenu.resetFormConfirmation')}</div>
      </ConfirmationDialog>
    </>
  );
};
