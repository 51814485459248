import React, { ReactElement } from 'react';
import { QuestionWrapper } from 'components/QuestionWrapper/QuestionWrapper';
import { QuestionTypeProps } from 'components/QuestionWrapper/QuestionWrapper.declarations';
import { QuestionType } from 'types/genericQuestion';
import styles from './TextBasedQuestion.module.scss';

/**
 * This component is for any question type that is text-based,
 * meaning not selectable/clickable.
 */
export const TextBasedQuestion = (props: QuestionTypeProps): ReactElement => {
  const { questionItem } = props;
  const { question_type: questionType } = questionItem.item;
  const isShortAnswer =
    questionType === QuestionType.EMAIL ||
    questionType === QuestionType.NUMBER ||
    questionType === QuestionType.ZIP_CODE;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <QuestionWrapper {...props}>
      <input type="text" className={isShortAnswer ? styles.shortAnswerInput : styles.longAnswerInput} disabled />
    </QuestionWrapper>
  );
};
