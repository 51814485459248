import classnames from 'classnames';
import styles from 'components/NewGenericQuestion/NewGenericQuestion.module.scss';
import { ChoicesMainSection } from 'components/NewGenericQuestion/subComponents/NewQuestionMainSectionComponents/ChoicesMainSection/ChoicesMainSection';
import { OptionWrapper } from 'components/NewGenericQuestion/subComponents/OptionWrapper';
import { ScaleMainSection } from 'components/NewGenericQuestion/subComponents/NewQuestionMainSectionComponents/ScaleMainSection';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { QuestionType } from 'types/genericQuestion';

/**
 * Displays the relevant form section depending on the question type.
 */
export const NewQuestionMainSection = (): ReactElement => {
  const { t: translate } = useTranslation();
  const { newQuestionPayload } = useAppSelector((state: RootState) => state.questionsBank);

  const sectionPerQuestionType = useMemo(() => {
    switch (newQuestionPayload.question_type) {
      case QuestionType.DROPDOWN:
        return <ChoicesMainSection allowOtherOption={false} />;

      case QuestionType.CHOICES:
        return <ChoicesMainSection allowOtherOption />;

      case QuestionType.SCALE:
        return <ScaleMainSection />;

      case QuestionType.YES_NO:
        return (
          <div className={styles.sectionLayout}>
            <OptionWrapper isDraggable={false}>{translate('common.yes')}</OptionWrapper>
            <OptionWrapper isDraggable={false}>{translate('common.no')}</OptionWrapper>
          </div>
        );

      default:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newQuestionPayload.question_type]);

  return (
    <div className={classnames(styles.newQuestionGrid, styles.topMargin)}>
      <div className={styles.leftSide}>{sectionPerQuestionType}</div>
    </div>
  );
};
