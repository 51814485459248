import { Button } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TypeformSurveyTranslateButton.module.scss';

interface Props {
  onClickHandler: (event: React.MouseEvent<HTMLDivElement | HTMLInputElement | HTMLButtonElement, MouseEvent>) => void;
}

/**
 * This button should display when there are
 * missing translations for a typeform survey language.
 */
export const TypeformSurveyTranslateButton = (props: Props): ReactElement => {
  const { onClickHandler } = props;
  const { t: translate } = useTranslation();

  return (
    <Button className={styles.translateButton} type="button" size="md" variant="secondary" onClick={onClickHandler}>
      {translate('common.translate')}
    </Button>
  );
};
