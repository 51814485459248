import { ZCDFilter } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FileTypes } from 'services/communitySurveyReport/util';
import { FilterOptionWithKey } from 'types/filters';

interface Props {
  selectedFileFormat?: FileTypes;
  onChangeCallback: (option: FileExtensionOption) => void;
}

export interface FileExtensionOption {
  value: FileTypes;
  label: FileTypes;
}

export const FileExtensionFilter = ({ onChangeCallback, selectedFileFormat }: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const fileFormatOptions = Object.values(FileTypes)
    .filter((fileType) => fileType !== FileTypes.JSON)
    .map((fileType) => ({
      value: fileType,
      label: fileType,
    }));
  const fileFormatValue = fileFormatOptions.find((fileFormatOption) => fileFormatOption.value === selectedFileFormat);
  return (
    <ZCDFilter<FilterOptionWithKey>
      value={fileFormatValue}
      onChange={(option) => onChangeCallback(option as FileExtensionOption)}
      label={translate('analysis.fileFormat')}
      options={fileFormatOptions}
    />
  );
};
