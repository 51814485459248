import { Cadence } from '@zencity/survey-types';
import { cadencesPerSurveyTypeMapping, SurveyType } from 'types/survey';
import { statusIsIdle } from 'utils/misc';

/**
 * Get the relevant cadences by the given survey type.
 *
 * @param surveyType - The Survey type to get its relevant cadences for.
 */
export const getRelevantCadencesBySurveyType = (surveyType?: SurveyType): Cadence[] => {
  if (!surveyType || !cadencesPerSurveyTypeMapping[surveyType]) {
    return [];
  }
  return cadencesPerSurveyTypeMapping[surveyType];
};

export const currentSurveyQuestionsAreNotFetched = (
  currentQuestionsSurveyId: number | undefined,
  paramSurveyId: number,
  questionsFetchStatus: string,
): boolean => currentQuestionsSurveyId !== paramSurveyId || statusIsIdle(questionsFetchStatus);

export const currentSurveyEndScreensAreNotFetched = (
  currentEndScreensSurveyId: number | undefined,
  paramSurveyId: number,
  screensFetchStatus: string,
): boolean => currentEndScreensSurveyId !== paramSurveyId || statusIsIdle(screensFetchStatus);
