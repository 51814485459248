import { SentimentScore } from '@zencity/survey-types';
import classnames from 'classnames';
import React from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { Flex } from 'components/Flex/Flex';
import { DiffIndicator } from '../DiffIndicator/DiffIndicator';
import styles from './SentimentBar.module.scss';

interface SentimentBarProps {
  positive: SentimentScore['positive'];
  negative: SentimentScore['negative'];
  neutral: SentimentScore['neutral'];
  positiveText: boolean;
  prevPositiveValue?: number;
  isBenchmarked?: boolean;
}
function toPercentString(value: number) {
  return `${Math.round(value)}%`;
}

export function SentimentBar(props: SentimentBarProps): React.ReactElement {
  const { positive = 0, negative = 0, neutral = 0, positiveText, prevPositiveValue, isBenchmarked } = props;

  const positivePercent = toPercentString(positive);
  const negativePercent = toPercentString(negative);
  const neutralPercent = toPercentString(neutral);

  const shouldShowBenchamrk = prevPositiveValue && isBenchmarked;
  return (
    <Flex
      alignItems="center"
      className={classnames(styles.sentimentBarWrapper, { [styles.withScoreText]: positiveText })}
    >
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        className={classnames({ [styles.isBenchmarked]: isBenchmarked })}
      >
        {positiveText && <p className={styles.positiveScore}>{positivePercent}</p>}
        {shouldShowBenchamrk && <Spacer size="xsmall" orientation="horizontal" />}

        {shouldShowBenchamrk && <DiffIndicator currentValue={positive} prevValue={prevPositiveValue} withSeparator />}
      </Flex>
      <div className={styles.sentimentBar}>
        {positive !== 0 && <span className={styles.positive} style={{ width: positivePercent }} />}
        {neutral !== 0 && <span className={styles.neutral} style={{ width: neutralPercent }} />}
        {negative !== 0 && <span className={styles.negative} style={{ width: negativePercent }} />}
      </div>
    </Flex>
  );
}
