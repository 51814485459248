export type RakeWeighterExecution = {
  id: number;
  display_on_dashboard: boolean;
  survey_group: number;
  start_date: string;
  end_date: string;
  total_submissions: number;
};
export type ParsedRakeWeighterExecutions = {
  value: string;
  key: string;
  label: string;
  totalSubmissions: number;
};

export const parseRakeWeighterExecutions = (
  rakeWeighterExecutions: RakeWeighterExecution[],
): ParsedRakeWeighterExecutions[] =>
  rakeWeighterExecutions.map((rakeWeighterExecution) => {
    const { display_on_dashboard, start_date, end_date } = rakeWeighterExecution;
    const rangeDates = [start_date, end_date];
    const labelAddition = !display_on_dashboard ? '( * )' : '';
    return {
      value: rangeDates.join(' '),
      key: `rakeWeighterExecution-${rangeDates.join('_')}`,
      label: `${rangeDates.join(' to ')} ${labelAddition}`,
      totalSubmissions: rakeWeighterExecution.total_submissions,
    };
  });
