import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericQuestion } from 'types/genericQuestion';
import { QuestionConfigType } from 'types/questionConfig';
import { normalizeGenericQuestionConfigs } from 'utils/genericAndQuestionConfigs';
import { TeaserAdditionalInfo } from './TeaserAdditionalInfo/TeaserAdditionalInfo';

/**
 * A helper component to display the additional information of a
 * `scale` question.
 */
export const Scale = ({ instance }: { instance: GenericQuestion }): ReactElement => {
  const { t: translate } = useTranslation();

  // TODO: Should we really apply `useMemo` in here?
  const items = useMemo(() => {
    const configs = normalizeGenericQuestionConfigs(instance);
    const questionStep = configs[QuestionConfigType.STEPS] ?? 'unknown';
    const startAtOneConfigValue = configs[QuestionConfigType.START_AT_ONE] ? '1' : '0';

    const itemsList = [];
    itemsList.push({
      id: 'steps',
      label: `${translate('common.steps')}: ${startAtOneConfigValue} - ${questionStep}`,
    });

    const potentialLabels = {
      first: QuestionConfigType.SCALE_LABEL_FIRST,
      middle: QuestionConfigType.SCALE_LABEL_MIDDLE,
      last: QuestionConfigType.SCALE_LABEL_LAST,
    } as Record<string, QuestionConfigType>;
    Object.keys(potentialLabels).forEach((key) => {
      const label = configs[potentialLabels[key]];
      if (!label) {
        return;
      }
      itemsList.push({ id: key, label });
    });

    return itemsList;
  }, [instance, translate]);

  return <TeaserAdditionalInfo items={items} keysPrefix="scale-label" allowToggle genericQuestion={instance} />;
};
