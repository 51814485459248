import { ZCDIcon, ZCDIconColor, IconName } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import styles from './ManageDropdownButton.module.scss';

export interface ActionItemProps {
  iconName: IconName;
  text: string;
  onClick: () => void;
  color?: ZCDIconColor;
}
export const ActionItem = (props: ActionItemProps): ReactElement => {
  const { iconName, text, onClick, color = ZCDIconColor.ORIGINAL } = props;
  return (
    <button type="button" className={styles.actionItem} onClick={onClick}>
      <ZCDIcon name={iconName} color={color} />
      <p className={styles[color]}>{text}</p>
    </button>
  );
};
