import { Dialog, ZCDButton } from '@zencity/common-ui';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { Spacer } from 'components/Spacer/Spacer';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import i18next from 'i18next';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createNewPanelTypeformSurvey } from 'services/typeformSurvey';
import { setTypeformSurvey } from 'slices/surveyManage';
import { KnownPanelVendor, PanelVendor, TypeformSurvey } from 'types/typeformSurvey';
import { logger } from 'utils/logger';
import { Header } from './Header';
import { SharingLink } from './SharingLink';

interface DialogLink {
  name: string;
  source: string;
}

export const PUBLIC_LINKS: DialogLink[] = [
  {
    name: i18next.t('shareLinksDialog.selfDistribution'),
    source: 'sd',
  },
  {
    name: i18next.t('shareLinksDialog.facebook'),
    source: 'fb',
  },
  {
    name: i18next.t('shareLinksDialog.instagram'),
    source: 'ig',
  },
  {
    name: i18next.t('shareLinksDialog.email'),
    source: 'email',
  },
  {
    name: i18next.t('shareLinksDialog.sms'),
    source: 'sms',
  },
  {
    name: i18next.t('shareLinksDialog.nextdoor'),
    source: 'nd',
  },
];

export const TYPEFORM_LINKS: DialogLink[] = [
  {
    name: i18next.t('shareLinksDialog.typeformDirectLink'),
    source: '',
  },
  {
    name: i18next.t('shareLinksDialog.hardCopy'),
    source: 'prt',
  },
];

export const PANEL_TYPEFORM_LINKS: { [panelName: string]: DialogLink } = {
  [KnownPanelVendor.TAP]: {
    name: i18next.t('shareLinksDialog.tap'),
    source: 'tap',
  },
  [KnownPanelVendor.PURESPECTRUM]: {
    name: i18next.t('shareLinksDialog.pureSpectrum'),
    source: 'ps',
  },
};

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | ((state: boolean) => void);
  typeformSurvey: TypeformSurvey;
  panelVendorTypeformSurveys: TypeformSurvey[];
}

/**
 * A dialog to display the links to be shared.
 */
export const ShareLinksDialog: React.FC<Props> = function ShareLinksDialog({
  isOpen = false,
  setIsOpen,
  typeformSurvey,
  panelVendorTypeformSurveys,
}: Props): ReactElement {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { surveys } = useAppSelector((state) => state.surveys);
  const { currentSurveyId } = useAppSelector((state) => state.surveyManage);
  const { panelVendors } = useAppSelector((state) => state.globalConfigs);
  const [isLoading, setIsLoading] = useState(false);
  const currentSurvey = surveys[currentSurveyId as number];
  const publicLink = currentSurvey?.survey_group?.survey_public_link;

  const { typeform_id: typeformId } = typeformSurvey;

  const creatablePanelVendors = useMemo(() => {
    const existingPanelTypeformSurveyNames = panelVendorTypeformSurveys.map(
      (panelVendorTypeformSurvey) => panelVendorTypeformSurvey.panel?.name,
    );
    return panelVendors.filter((panelVendor) => !existingPanelTypeformSurveyNames.includes(panelVendor.name));
  }, [panelVendorTypeformSurveys, panelVendors]);

  const closeDialog = () => setIsOpen(false);

  const handleOnAddPanelVendor = async (panelVendor: PanelVendor) => {
    setIsLoading(true);
    try {
      const newTypeformSurvey = await createNewPanelTypeformSurvey(typeformSurvey.id, panelVendor.id);
      dispatch(setTypeformSurvey(newTypeformSurvey));
    } catch (error) {
      logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={closeDialog}
      shouldCloseOnOverlayClick={false}
      header={<Header />}
      footer={<ZCDButton text={translate('common.done')} onClick={closeDialog} />}
    >
      <LoaderMask isLoading={isLoading}>
        {publicLink &&
          PUBLIC_LINKS.map((link) => {
            const linkUrl = `${publicLink}?s=${link.source}`;
            return <SharingLink key={link.source} name={link.name} linkUrl={linkUrl} />;
          })}
        {typeformId &&
          TYPEFORM_LINKS.map((link) => {
            const linkUrl = `https://elucd.typeform.com/to/${typeformId}${
              link?.source ? `?source=${link.source}` : ''
            }`;
            return <SharingLink key={link.source} name={link.name} linkUrl={linkUrl} />;
          })}
        {panelVendorTypeformSurveys.map((panelVendor) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const panelLink = PANEL_TYPEFORM_LINKS[panelVendor.panel!.name];
          const linkUrl = `https://elucd.typeform.com/to/${panelVendor.typeform_id}${
            panelLink?.source ? `?source=${panelLink?.source}` : ''
          }`;
          return <SharingLink key={panelLink.source} name={panelLink.name} linkUrl={linkUrl} />;
        })}
        {creatablePanelVendors.map((panelVendor) => (
          <div key={panelVendor.id}>
            <ZCDButton
              text={`${translate('common.add')} ${panelVendor.name}`}
              onClick={() => handleOnAddPanelVendor(panelVendor)}
              disabled={isLoading}
            />
            <Spacer orientation="vertical" size="small" />
          </div>
        ))}
      </LoaderMask>
    </Dialog>
  );
};
