import { QuestionType } from 'types/genericQuestion';
import { Nullable } from 'types/misc';
import { Question, QuestionItem } from 'types/questions';

export enum DraggableType {
  GROUP_QUESTION = 'GROUP_QUESTION',
  NORMAL_QUESTION = 'NORMAL_QUESTION',
}

export interface QuestionItemMap {
  index: number;
  group_question_id: Nullable<number>;
}

/**
 * Creates a list of questions reduced into groups, with their child (sub) questions.
 */
export const reduceQuestionsIntoGroups = (questions: Question[]): QuestionItem[] =>
  questions.reduce((questionItems, currentQuestion) => {
    if (currentQuestion.question_type === QuestionType.GROUP) {
      const childQuestions = questions
        .filter((question) => question.group_question === currentQuestion.id)
        .map((question) => ({
          id: question.id,
          item: question,
          children: [],
        }));
      const questionByGroup = {
        id: currentQuestion.id,
        item: currentQuestion,
        children: childQuestions,
      };
      questionItems.push(questionByGroup);
    } else if (currentQuestion.question_type !== QuestionType.GROUP && !currentQuestion.group_question) {
      questionItems.push({
        id: currentQuestion.id,
        item: currentQuestion,
        children: [],
      });
    }
    return questionItems;
  }, [] as QuestionItem[]);

/**
 * This function takes a list of QuestionItems and creates a map of question ID's to its index and group question ID.
 * This mapping is needed in order to update the question's index and group question after being dragged and dropped,
 * and is used before sending a request to update those fields.
 *
 * For an example of this, check the BuildForm.helpers.spec.ts file in this folder.
 */
export const mapQuestionIdToIndexAndGroup = (questionItems: QuestionItem[]): Record<number, QuestionItemMap> => {
  const initialValue = { questions: {}, questionIndex: 0 } as {
    questions: Record<number, QuestionItemMap>;
    questionIndex: number;
  };

  const questionIndexMap = questionItems.reduce((questionsMap, currentQuestionItem) => {
    const rootQuestionId = currentQuestionItem.item.id;
    const { questions, questionIndex } = questionsMap;
    let currentIndex = questionIndex;
    questions[rootQuestionId] = {
      index: currentIndex,
      group_question_id: null,
    };
    currentIndex += 1;
    currentQuestionItem.children.forEach((childQuestion) => {
      questions[childQuestion.id] = {
        index: currentIndex,
        group_question_id: currentQuestionItem.item.id,
      };
      currentIndex += 1;
    });

    return {
      questions,
      questionIndex: currentIndex,
    };
  }, initialValue);

  return questionIndexMap.questions;
};

/**
 * This function converts the QuestionItem[] list back into a Question[] list.
 * By taking the questions map with its new indexes and group questions, the new
 * question's index and group questions are set and flattened. The list is then sorted.
 */
export const convertQuestionItemsToQuestions = (
  questionMap: Record<number, QuestionItemMap>,
  questions: Question[],
): Question[] => {
  const newOrderedQuestions = questions.reduce((questionsList, currentQuestion) => {
    const currentQuestionCopy = { ...currentQuestion };
    const newQuestionMap = { ...questionMap[currentQuestionCopy.id] };
    if (currentQuestionCopy.index !== newQuestionMap.index) {
      currentQuestionCopy.index = newQuestionMap.index;
    }
    if (currentQuestionCopy.group_question !== newQuestionMap.group_question_id) {
      currentQuestionCopy.group_question = newQuestionMap.group_question_id;
    }
    questionsList.push(currentQuestionCopy);
    return questionsList;
  }, [] as Question[]);

  return newOrderedQuestions.sort((question1, question2) => question1.index - question2.index);
};
