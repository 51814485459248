import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { configSetter } from 'components/NewGenericQuestion/NewGenericQuestion.helpers';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { setNewQuestionConfigs } from 'slices/questionsBank';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';

export const DemographicTypesDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const { newQuestionPayload } = useAppSelector((state: RootState) => state.questionsBank);
  const { demographicTypes } = useAppSelector((state: RootState) => state.globalConfigs);

  const demographicType = newQuestionPayload.configs.find(
    ({ config_name }) => config_name === QuestionConfigType.DEMOGRAPHIC,
  );

  const handleOnChange = (option: SelectOption) => {
    dispatch(
      setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.DEMOGRAPHIC, option.value as string)),
    );
  };

  const options = Object.entries(demographicTypes).map(([value, label]) => ({
    value,
    label,
  }));
  return (
    <ZCDSelect<SelectOption>
      options={options}
      value={
        demographicType
          ? { value: demographicType.config_value, label: demographicTypes[demographicType.config_value] }
          : undefined
      }
      onChange={(newValue) => handleOnChange(newValue as unknown as SelectOption)}
      placeholder={translate('questionsBankDialog.newQuestion.demographicTypePlaceholder')}
    />
  );
};
