/* eslint-disable complexity,max-statements,prefer-destructuring */
import { authentication } from '@zencity/microfrontend-utils';
import axios, { AxiosInstance } from 'axios';
import path from 'utils/path';
import { config } from '../../config/config';

export const generateVaultApiUrl = (apiUrl: string): string => path.joinURL(config.vaultBaseUrl, apiUrl);

export const createAxiosInstance = (baseURL: string): AxiosInstance => {
  const extraHeaders = {} as Record<string, string>;

  // Postman (for example) does not work with credentials
  // (such as cookies, authorization headers or TLS client certificates).
  let withCredentials: boolean = baseURL.includes('.zencity.io');

  /* delete_for_production:start */
  // Webpack "string-replace-loader" loader will completely remove this code
  // block from the source code.
  if (config.environment === 'development') {
    if (process.env.X_ZC_USER_ID) {
      extraHeaders['X-Zc-User-Id'] = process.env.X_ZC_USER_ID;
    }
    if (process.env.VAULT_BYPASS_HAS_API_ACCESS_SECRET) {
      extraHeaders['X-Api-Access-Token'] = process.env.VAULT_BYPASS_HAS_API_ACCESS_SECRET;
    }
    if (process.env.VAULT_USERNAME && process.env.VAULT_PASSWORD) {
      const userPassword = `${process.env.VAULT_USERNAME}:${process.env.VAULT_PASSWORD}`;
      extraHeaders['Authorization'] = `Basic ${btoa(userPassword)}`; // eslint-disable-line @typescript-eslint/dot-notation
    }

    if (!withCredentials) {
      withCredentials = baseURL.search(/(localhost)|(127\.0\.0\.1)/gi) > -1;
    }
  }
  /* delete_for_production:end */

  const instance = axios.create({
    baseURL,
    withCredentials,
  });

  // Add all custom headers to the request.
  instance.interceptors.request.use((request) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [header, value] of Object.entries(extraHeaders)) {
      request.headers[header] = value;
    }

    return request;
  });

  instance.interceptors.request.use(authentication.authTokenHeaderAxiosInterceptor);

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Flatten axios' error to a normal Error.
      const message = error.message;
      try {
        let resData = error.response?.data;
        if (typeof resData === 'object' && resData !== null) {
          resData = JSON.stringify(resData);
        } else {
          resData = resData.toString();
        }
        return Promise.reject(new Error(`${message}; ${resData}`));
      } catch (secondError) {
        return Promise.reject(new Error(message));
      }
    },
  );

  return instance;
};
