/* eslint-disable no-param-reassign */
import classnames from 'classnames';
import { calculateRightTooltipPlacement, calculateTopTooltipPlacement } from 'components/ZCDTooltip/ZCDTooltip.helpers';
import React, { ReactElement, useLayoutEffect, useRef } from 'react';
import styles from './ZCDTooltip.module.scss';

export enum TooltipPlacement {
  TOP = 'top',
  RIGHT = 'right',
}

interface Props {
  elementRef: React.MutableRefObject<HTMLDivElement | null>;
  tooltipPlacement: TooltipPlacement;
  displayTooltip: boolean;
  children: React.ReactNode;
}

/**
 * Tooltip that displays information passed as children.
 */
export const ZCDTooltip = (props: Props): ReactElement => {
  const { elementRef, tooltipPlacement, children, displayTooltip } = props;
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  // Set the location of the tooltip centered above the reference element.
  useLayoutEffect(() => {
    if (!tooltipRef.current || !elementRef.current) {
      return;
    }

    if (tooltipPlacement === TooltipPlacement.TOP) {
      const { bottomPixel, leftPixel } = calculateTopTooltipPlacement(elementRef.current, tooltipRef.current);
      tooltipRef.current.style.bottom = bottomPixel;
      tooltipRef.current.style.left = leftPixel;
    } else if (tooltipPlacement === TooltipPlacement.RIGHT) {
      const { bottomPixel, leftPixel } = calculateRightTooltipPlacement(elementRef.current);
      tooltipRef.current.style.bottom = bottomPixel;
      tooltipRef.current.style.left = leftPixel;
    }
  }, [elementRef, tooltipPlacement, displayTooltip]);

  return displayTooltip ? (
    <div className={classnames(styles.tooltip, styles[tooltipPlacement])} ref={tooltipRef}>
      {children}
    </div>
  ) : (
    <></>
  );
};
