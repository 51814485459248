/* eslint-disable max-lines, react/jsx-props-no-spreading */
import { Button } from '@zencity/common-ui';
import classnames from 'classnames';
import { GenericQuestionRadioSelect } from 'components/GenericQuestionRadioSelect/GenericQuestionRadioSelect';
import { GenericQuestionTeaserView } from 'components/GenericQuestionTeaserView/GenericQuestionTeaserView';
import { QuestionTypeDropdown } from 'components/QuestionTypeDropdown/QuestionTypeDropdown';
import { SearchBoxInput } from 'components/SearchBoxInput/SearchBoxInput';
import { TableRowSkeleton } from 'components/TableRowSkeleton/TableRowSkeleton';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTable } from 'react-table';
import {
  debouncedFetchGenericQuestionsByParams,
  fetchGenericQuestionsByParams,
  loadGenericQuestions,
} from 'services/genericQuestion';
import { RootState } from 'store';
import { GenericQuestion } from 'types/genericQuestion';
import { DateFormat, formatISODate } from 'utils/dates';
import { fetchFilteredResults } from 'utils/ListTableHelpers';
import { statusIsError, statusIsIdle, statusIsLoading } from 'utils/misc';
import { abbreviateLastName } from 'utils/user';
import { GenericQuestionActions } from './components/GenericQuestionActions';
import { getParamsForFilters } from './GenericQuestionsListTable.helpers';
import styles from './GenericQuestionsListTable.module.scss';

interface Props {
  isSelectable?: boolean;
  addQuestionInsideGroup?: boolean;
  switchToNewQuestionTab?: () => void;
}

// eslint-disable-next-line max-lines-per-function, max-statements
export const GenericQuestionsListTable = (props: Props): JSX.Element => {
  const { isSelectable = false, addQuestionInsideGroup = false, switchToNewQuestionTab } = props;
  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();
  const { nextPage, errorMessage, fetchStatus, genericQuestionsById, paginatedResultsIds } = useAppSelector(
    (state: RootState) => state.genericQuestions,
  );

  const { t: translate } = useTranslation();

  const [searchInputValue, setSearchInputValue] = useState('');
  const [questionTypeFilterValue, setQuestionTypeFilterValue] = useState('');
  const [filteredResults, setFilteredResults] = useState<GenericQuestion[]>([]);
  const [filteredResultsNextPage, setFilteredResultsNextPage] = useState<number>();
  const [isFetchingByFilter, setIsFetchingByFilter] = useState(false);

  const currentlyLoading = isFetchingByFilter || statusIsLoading(fetchStatus);
  const isFiltered = !!searchInputValue || !!questionTypeFilterValue;

  useEffect(() => {
    if (statusIsIdle(fetchStatus)) {
      dispatch(loadGenericQuestions({}));
    }
  }, [fetchStatus, dispatch]);

  const onSearchInputChange = (value: string) => {
    setFilteredResultsNextPage(undefined);
    setFilteredResults([]);
    setSearchInputValue(value);
  };

  const onQuestionTypeFilterChange = (value: string) => {
    setFilteredResultsNextPage(undefined);
    setFilteredResults([]);
    setQuestionTypeFilterValue(value);
  };

  /**
   * Handle the fetch when the user uses filters.
   *
   * Since the "questions bank" component will be in use constantly, and to
   * avoid re-fetching the same data over and over, the redux store will keep
   * the non-filtered paginated results, and when the user decides to use
   * filters, only then the component will try to re-fetch data from the server
   * depending on the filters.
   */
  useEffect(() => {
    if (statusIsIdle(fetchStatus) || statusIsLoading(fetchStatus)) {
      // Initial fetch hasn't dispatched yet.
      return;
    }

    if (searchInputValue || questionTypeFilterValue) {
      fetchFilteredResults({
        filteredResults,
        filteredResultsNextPage,
        filters: { searchInputValue, questionTypeFilterValue },
        setFilteredResults,
        setFilteredResultsNextPage,
        setIsFetchingByFilter,
        getParamsForFilters,
        fetchFunction: searchInputValue ? debouncedFetchGenericQuestionsByParams : fetchGenericQuestionsByParams,
        toastError,
      });
    } else {
      // When filters are empty, simply reset its structure so the non-filtered
      // paginated results from Redux's store will be displayed again.
      setFilteredResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValue, questionTypeFilterValue]);

  const loadMore = () => {
    if (searchInputValue || questionTypeFilterValue) {
      if (filteredResultsNextPage) {
        fetchFilteredResults({
          filteredResults,
          filteredResultsNextPage,
          filters: { searchInputValue, questionTypeFilterValue },
          setFilteredResults,
          setFilteredResultsNextPage,
          setIsFetchingByFilter,
          getParamsForFilters,
          fetchFunction: searchInputValue ? debouncedFetchGenericQuestionsByParams : fetchGenericQuestionsByParams,
          toastError,
        });
      }
    } else {
      dispatch(loadGenericQuestions({ page: nextPage }));
    }
  };

  const getGenericQuestionById = (questionId: number) => genericQuestionsById[questionId];

  const genericQuestionsData = useMemo(
    () => paginatedResultsIds.map(getGenericQuestionById),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginatedResultsIds],
  );

  const radioSelectColumn = useMemo(
    () => ({
      id: 'selectable-column',
      Header: '',
      accessor: (originalRow: GenericQuestion) => originalRow,
      // eslint-disable-next-line react/no-multi-comp
      Cell: ({ value: genericQuestion }: { value: GenericQuestion }) => (
        <GenericQuestionRadioSelect genericQuestion={genericQuestion} addQuestionInsideGroup={addQuestionInsideGroup} />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, addQuestionInsideGroup],
  );

  const columns = useMemo(
    () => [
      ...(isSelectable ? [radioSelectColumn] : []),
      {
        Header: translate('common.question'),
        accessor: (originalRow: GenericQuestion) => originalRow,
        // eslint-disable-next-line react/no-multi-comp
        Cell: ({ value: instance }: { value: GenericQuestion }) => <GenericQuestionTeaserView instance={instance} />,
      },
      {
        Header: translate('common.aspect'),
        accessor: 'aspect.label' as keyof GenericQuestion,
      },
      {
        Header: translate('common.type'),
        accessor: 'question_type' as keyof GenericQuestion,
        Cell: ({ value: questionType }: { value: string }) => translate(`questionType.${questionType}`),
      },
      {
        Header: translate('common.createdBy'),
        accessor: 'created_by.full_name' as keyof GenericQuestion,
        Cell: ({ value }: { value: string }) => abbreviateLastName(value),
      },
      {
        Header: translate('common.createdOn'),
        accessor: 'created_at' as keyof GenericQuestion,
        Cell: ({ value }: { value: string }) => formatISODate(value, DateFormat.SLASHES),
      },
      {
        Header: translate('common.actions'),
        accessor: (originalRow: GenericQuestion) => originalRow,
        // eslint-disable-next-line react/no-multi-comp
        Cell: ({ value }: { value: GenericQuestion }) => (
          <GenericQuestionActions switchToNewQuestionTab={switchToNewQuestionTab} genericQuestion={value} />
        ),
      },
    ],
    [isSelectable, radioSelectColumn, translate, switchToNewQuestionTab],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    // eslint-disable-next-line id-denylist
  } = useTable<GenericQuestion>({ columns, data: isFiltered ? filteredResults : genericQuestionsData });

  const displayLoadMore = (isFiltered && filteredResultsNextPage) || (!isFiltered && nextPage);

  return (
    <div className={styles.listTable}>
      <div className={styles.filtersWrapper}>
        <QuestionTypeDropdown stateValue={questionTypeFilterValue} onChangeCallback={onQuestionTypeFilterChange} />
        <SearchBoxInput stateValue={searchInputValue} onChangeCallback={onSearchInputChange} />
      </div>
      <table {...getTableProps({ className: styles.table })}>
        <thead className={styles.headerRow}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps({ className: classnames({ [styles.selectColumn]: isSelectable }) })}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps({ className: styles.tableBody })}>
          {!statusIsError(fetchStatus) &&
            rows.map((rowData) => {
              prepareRow(rowData);
              return (
                <tr {...rowData.getRowProps({ className: styles.row })}>
                  {rowData.cells.map((cell) => (
                    <td {...cell.getCellProps({ className: classnames({ [styles.selectColumn]: isSelectable }) })}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          {currentlyLoading && <TableRowSkeleton colSpan={columns.length} />}
        </tbody>
        {displayLoadMore && (
          <tfoot>
            <tr>
              <td colSpan={columns.length}>
                <div className={styles.spinnerWrapper}>
                  <Button type="button" variant="primary" onClick={loadMore} disabled={currentlyLoading}>
                    {translate('common.loadMore')}
                  </Button>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      {!currentlyLoading && !rows.length && (
        <div className={styles.spinnerWrapper}>{translate('common.noResults')}</div>
      )}
      {statusIsError(fetchStatus) && errorMessage && <div className={styles.spinnerWrapper}>{errorMessage}</div>}
    </div>
  );
};
