/* eslint-disable max-lines */
import { HorizontalSeparator, Icon, ZCDIcon, ZCDImage } from '@zencity/common-ui';
import classnames from 'classnames';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { NewEndScreenDialog } from 'components/NewEndScreenDialog/NewEndScreenDialog';
import { OverrideQuestion } from 'components/OverrideQuestion/OverrideQuestion';
import { QuestionAddDialog } from 'components/QuestionAddDialog/QuestionAddDialog';
import { QuestionContextualToolbar } from 'components/QuestionContextualToolbar/QuestionContextualToolbar';
import { QuestionDeleteDialog } from 'components/QuestionDeleteDialog/QuestionDeleteDialog';
import { ViewQuestionHeader } from 'components/ViewQuestionHeader/ViewQuestionHeader';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { QuestionType } from 'types/genericQuestion';
import { QuestionConfigType } from 'types/questionConfig';
import { QuestionItem } from 'types/questions';
import { getQuestionConfig } from 'utils/genericAndQuestionConfigs';
import { convertNumberToLetter } from 'utils/misc';
import styles from './QuestionWrapper.module.scss';

/**
 * The draggable area for a question. When grabbing this area, it will move the whole QuestionWrapper component.
 */
const QuestionDraggableArea = (): ReactElement => (
  <div className={classnames(styles.draggableArea, 'draggableArea')}>
    <Icon width={10} height={6} name="six-small-circles.svg" />
  </div>
);

interface Props {
  questionItem: QuestionItem;
  index: number;
  children?: React.ReactNode;
  withLargeText?: boolean;
  parentIndex?: number;
  allowEditMode?: boolean;
}

/**
 * This is a container for a question that will be displayed on the "Build" screen.
 * The idea of this wrapper is to have all common components/styles that would be for all questions.
 *
 * @props questionItem - The question in the interface form of QuestionItem.
 * @props index - The questions index in its specific list.
 * @props children - The content of the question.
 * @props withLargeText - Whether to use large text for the question header.
 * @props parentIndex - If the question item is a child, then it will contain the parent's index in its root level list.
 * @props allowEditMode - Whether the question can be edited, depending on the screen it is rendered in.
 */
// eslint-disable-next-line max-lines-per-function, max-statements, react/no-multi-comp
export const QuestionWrapper = (props: Props): ReactElement => {
  const { children, withLargeText = false, questionItem, index, parentIndex, allowEditMode = true } = props;
  const { t: translate } = useTranslation();
  const [isToolbarVisible, setIsToolbarVisible] = useState(false);
  const { genericQuestionsById } = useAppSelector((state: RootState) => state.genericQuestions);
  const { resolvedIndexes } = useAppSelector((state: RootState) => state.surveyManage);
  const [inEditMode, setInEditMode] = useState(false);
  const [editConfirmationIsOpen, setEditConfirmationIsOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [addEndScreenDialogIsOpen, setAddEndScreenDialogIsOpen] = useState(false);
  const [addQuestionDialogIsOpen, setAddQuestionDialogIsOpen] = useState(false);
  const [addQuestionInsideGroup, setAddQuestionInsideGroup] = useState(false);

  const genericQuestion = genericQuestionsById[questionItem.item.generic_question];
  const isRootLevelQuestion = parentIndex === undefined;
  const isGroupQuestion = questionItem.item.question_type === QuestionType.GROUP;
  const inEditModeAndDisplayChildren = [QuestionType.GROUP].includes(genericQuestion?.question_type);

  const resolvedIndex = useMemo(() => resolvedIndexes[questionItem.id], [questionItem.id, resolvedIndexes]);

  const resolvedPotentialNextIndex = useMemo(
    () => (parentIndex || parentIndex === 0 ? `${parentIndex + 2}` : `${index + 2}`),
    [parentIndex, index],
  );

  const resolvedPotentialNextChildIndex = useMemo(() => {
    if (isGroupQuestion) {
      return `${index + 1}${convertNumberToLetter(0, false)}`;
    }
    return parentIndex || parentIndex === 0
      ? `${parentIndex + 1}${convertNumberToLetter(index + 1, false)}`
      : `${index + 2}`;
  }, [parentIndex, index, isGroupQuestion]);

  const existingQuestionImage = getQuestionConfig(questionItem.item, QuestionConfigType.IMAGE_URL);

  /**
   * If the question is a root level question, then a large border should be rendered.
   * Otherwise, render a thin horizontal separator border.
   */
  // eslint-disable-next-line react/no-multi-comp
  const TopBorder = () => {
    if (isRootLevelQuestion) {
      return <div className={styles.rootLevelQuestionBorder} />;
    }
    return <HorizontalSeparator />;
  };

  const handleOnMouseEnter = () => {
    setIsToolbarVisible(true);
  };

  const handleOnMouseLeave = () => {
    if (editConfirmationIsOpen) {
      // Avoid hiding the toolbar when the confirmation dialog is open.
      return;
    }

    setIsToolbarVisible(false);
  };

  const toggleInEditMode = () => setInEditMode(!inEditMode);
  const handleOnEditClick = () => {
    if (!allowEditMode) {
      return;
    }

    if (inEditMode) {
      toggleInEditMode();
    } else {
      setEditConfirmationIsOpen(true);
    }
  };
  const handleOnDeleteClick = () => setIsDeleteDialogOpen(true);

  const handleOnAddEndScreenClick = () => setAddEndScreenDialogIsOpen(true);

  const handleOnAddClick = (shouldAddQuestionInsideGroup: boolean) => {
    setAddQuestionDialogIsOpen(true);
    setAddQuestionInsideGroup(shouldAddQuestionInsideGroup);
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      data-question-type={questionItem.item.question_type}
      data-question-id={questionItem.id}
    >
      <div
        className={classnames(styles.questionWrapper, {
          [styles.childQuestionWrapper]: !isRootLevelQuestion,
        })}
      >
        <TopBorder />
        {allowEditMode && <QuestionDraggableArea />}
        <div
          className={classnames(styles.content, {
            [styles.childQuestion]: !isRootLevelQuestion,
            [styles.groupQuestion]: isGroupQuestion,
            [styles.viewOnly]: !allowEditMode,
          })}
        >
          {!inEditMode && (
            <ViewQuestionHeader
              questionItem={questionItem}
              withLargeText={withLargeText}
              resolvedIndex={resolvedIndex}
            />
          )}
          {allowEditMode && inEditMode && genericQuestion && (
            <OverrideQuestion
              genericQuestion={genericQuestion}
              questionItem={questionItem}
              resolvedIndex={resolvedIndex}
            >
              {existingQuestionImage && (
                <div>
                  {/* The image is wrapped in a div so the image does not render outside of the main component. */}

                  <ZCDImage
                    className={styles.questionImage}
                    fallback={<ZCDIcon name="image-icon" />}
                    width="100%"
                    height="100%"
                    src={existingQuestionImage.config_value}
                  />
                </div>
              )}
              {children}
            </OverrideQuestion>
          )}

          {(!inEditMode || inEditModeAndDisplayChildren) && (
            <section
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(isGroupQuestion
                ? { onMouseEnter: handleOnMouseLeave, onMouseLeave: handleOnMouseEnter }
                : { className: styles.nonGroupQuestion })}
            >
              {existingQuestionImage && (
                <div>
                  {/* The image is wrapped in a div so the image does not render outside of the main component. */}
                  <ZCDImage
                    className={styles.questionImage}
                    fallback={<ZCDIcon name="image-icon" />}
                    width="100%"
                    height="100%"
                    src={existingQuestionImage.config_value}
                  />
                </div>
              )}
              {children}
            </section>
          )}
        </div>
        {isToolbarVisible && allowEditMode && (
          <QuestionContextualToolbar
            questionItem={questionItem}
            isRootLevelQuestion={isRootLevelQuestion}
            handleOnEditClick={handleOnEditClick}
            handleOnDeleteClick={handleOnDeleteClick}
            handleOnAddClick={handleOnAddClick}
            handleOnAddEndScreenClick={handleOnAddEndScreenClick}
            inEditMode={inEditMode}
            resolvedPotentialNextIndex={resolvedPotentialNextIndex}
            resolvedPotentialNextChildIndex={resolvedPotentialNextChildIndex}
          />
        )}
      </div>
      <QuestionDeleteDialog isOpen={isDeleteDialogOpen} setIsOpen={setIsDeleteDialogOpen} questionItem={questionItem} />
      <QuestionAddDialog
        isOpen={addQuestionDialogIsOpen}
        setIsOpen={setAddQuestionDialogIsOpen}
        questionItem={questionItem}
        addQuestionInsideGroup={addQuestionInsideGroup}
      />
      <NewEndScreenDialog isOpen={addEndScreenDialogIsOpen} setIsOpen={setAddEndScreenDialogIsOpen} />
      <ConfirmationDialog
        isOpen={editConfirmationIsOpen}
        setIsOpen={setEditConfirmationIsOpen}
        title={translate('overrideQuestion.editConfirmationDialog.title')}
        submitBtnLabel={translate('overrideQuestion.editConfirmationDialog.submitLabel')}
        confirmationHandler={toggleInEditMode}
      >
        <div>{translate('overrideQuestion.editConfirmationDialog.contentA')}</div>
        <br />
        <div>{translate('overrideQuestion.editConfirmationDialog.contentB')}</div>
      </ConfirmationDialog>
    </div>
  );
};
