/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { AddImageToQuestionDialog } from 'components/AddImageToQuestionDialog/AddImageToQuestionDialog';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { LogicActionDialog } from 'components/LogicActionDialog/LogicActionDialog';
import { RemoveImageFromQuestionDialog } from 'components/RemoveImageFromQuestionDialog/RemoveImageFromQuestionDialog';
import { ActionMenuItem, ThreeDotsActionsMenu } from 'components/ThreeDotsActionsMenu/ThreeDotsActionsMenu';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resetAllOverrides, writeQuestionConfig } from 'services/questions';
import { RootState } from 'store';
import { QuestionType } from 'types/genericQuestion';
import { QuestionConfigType } from 'types/questionConfig';
import { QuestionItem } from 'types/questions';
import {
  findQuestionConfig,
  getGenericQuestionConfigValue,
  getQuestionConfig,
  resolveAlphabeticalOrderConfig,
  resolveHideFromLiveFeedConfig,
  resolveRandomizedConfig,
  resolveStartAtOneConfig,
} from 'utils/genericAndQuestionConfigs';

interface Props {
  questionItem: QuestionItem;
}

/**
 * Displays the Question's "actions menu".
 *
 * This menu allows the user to set some configurations over a Question.
 */
// eslint-disable-next-line max-statements
export const QuestionEditActionsMenu = (props: Props): ReactElement => {
  const { questionItem } = props;
  const questionType = questionItem.item.question_type;

  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const [resetAllOverridesIsOpen, setResetAllOverridesIsOpen] = useState(false);
  const [logicActionDialogIsOpen, setLogicActionDialogIsOpen] = useState(false);
  const [addImageDialogIsOpen, setAddImageDialogIsOpen] = useState(false);
  const [removeImageDialogIsOpen, setRemoveImageDialogIsOpen] = useState(false);
  const { genericQuestionsById } = useAppSelector((state: RootState) => state.genericQuestions);

  const genericQuestion = genericQuestionsById[questionItem.item.generic_question];

  const existingQuestionImage = getQuestionConfig(questionItem.item, QuestionConfigType.IMAGE_URL);

  const originallyStartAtOne = genericQuestion
    ? resolveStartAtOneConfig(getGenericQuestionConfigValue(genericQuestion, QuestionConfigType.START_AT_ONE))
    : false;
  const scaleQuestionStartAtOne = genericQuestion
    ? resolveStartAtOneConfig(
        findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.START_AT_ONE)?.configValue,
      )
    : false;

  const originallyRandomized = genericQuestion
    ? resolveRandomizedConfig(getGenericQuestionConfigValue(genericQuestion, QuestionConfigType.RANDOMIZE))
    : false;
  const questionIsRandomized = genericQuestion
    ? resolveRandomizedConfig(
        findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.RANDOMIZE)?.configValue,
      )
    : false;

  const originallyAlphabetical = genericQuestion
    ? resolveAlphabeticalOrderConfig(
        getGenericQuestionConfigValue(genericQuestion, QuestionConfigType.ALPHABETICAL_ORDER),
      )
    : false;
  const questionIsAlphabetical = genericQuestion
    ? resolveAlphabeticalOrderConfig(
        findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.ALPHABETICAL_ORDER)?.configValue,
      )
    : false;
  const originallyIsHiddenFromLiveFeed = genericQuestion
    ? resolveHideFromLiveFeedConfig(
        getGenericQuestionConfigValue(genericQuestion, QuestionConfigType.HIDE_FROM_LIVE_FEED),
      )
    : false;
  const questionIsHiddenFromLiveFeed = genericQuestion
    ? resolveHideFromLiveFeedConfig(
        findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.HIDE_FROM_LIVE_FEED)?.configValue,
      )
    : false;

  const toggleBooleanConfig = (originalValue: boolean, currentValue: boolean, configType: QuestionConfigType): void => {
    const disableValue = originalValue ? '0' : null;
    const enableValue = originalValue ? null : '1';

    const payload = {
      questionId: questionItem.id,
      config_name: configType,
      config_value: !currentValue ? enableValue : disableValue,
    };
    dispatch(writeQuestionConfig(payload));
  };
  const toggleHideFromLiveFeed = () => {
    // Depending on the Generic Question value, we should override with
    // a truthy/falsy value, otherwise, send a `null` to soft-delete the config.
    const disableHideFromLiveFeedValue = originallyIsHiddenFromLiveFeed ? '0' : null;
    const enableHideFromLiveFeedValue = originallyIsHiddenFromLiveFeed ? null : '1';
    const payload = {
      questionId: questionItem.id,
      config_name: QuestionConfigType.HIDE_FROM_LIVE_FEED,
      config_value: !questionIsHiddenFromLiveFeed ? enableHideFromLiveFeedValue : disableHideFromLiveFeedValue,
    };
    dispatch(writeQuestionConfig(payload));
  };

  const containsLogicActions = !!questionItem.item.logic_actions.length;
  const handleOnLogicActionClick = () => setLogicActionDialogIsOpen(true);

  const resetAllOverridesHandler = () => {
    dispatch(resetAllOverrides(questionItem.id));
  };

  let menuItems = [] as ActionMenuItem[];

  const randomizeMenuItem = {
    icon: 'sort',
    text: translate('questionActionsMenu.randomizeChoices'),
    isActive: questionIsRandomized,
    onClickHandler: () => toggleBooleanConfig(originallyRandomized, questionIsRandomized, QuestionConfigType.RANDOMIZE),
    isDisabled: questionIsAlphabetical,
  };

  if (questionType === QuestionType.CHOICES) {
    const isSingleSelect = !findQuestionConfig(
      questionItem.item,
      genericQuestion,
      QuestionConfigType.ALLOW_MULTI_CHOICE,
    );
    const logicActionMenuItem = isSingleSelect
      ? ([
          {
            icon: 'jump-in-logic',
            text: translate('questionActionsMenu.logicActions'),
            isActive: containsLogicActions,
            onClickHandler: handleOnLogicActionClick,
          },
        ] as ActionMenuItem[])
      : [];

    const randomizeChoicesMenuItem = [
      randomizeMenuItem,
      {
        isSeparator: true,
      },
    ] as ActionMenuItem[];
    menuItems = menuItems.concat(logicActionMenuItem, randomizeChoicesMenuItem);
  }

  if (questionItem.item.question_type === QuestionType.OPEN_ENDED) {
    menuItems = menuItems.concat([
      {
        icon: 'views',
        text: translate('questionActionsMenu.hideFromLiveFeed'),
        isActive: questionIsHiddenFromLiveFeed,
        onClickHandler: toggleHideFromLiveFeed,
      },
      {
        isSeparator: true,
      },
    ] as ActionMenuItem[]);
  }
  if (questionType === QuestionType.DROPDOWN) {
    menuItems = menuItems.concat([
      randomizeMenuItem,
      {
        icon: 'sort',
        text: translate('questionActionsMenu.alphabeticalOrder'),
        isActive: questionIsAlphabetical,
        onClickHandler: () =>
          toggleBooleanConfig(originallyAlphabetical, questionIsAlphabetical, QuestionConfigType.ALPHABETICAL_ORDER),
        isDisabled: questionIsRandomized,
      },
      {
        isSeparator: true,
      },
    ] as ActionMenuItem[]);
  }

  if (questionType === QuestionType.SCALE) {
    menuItems.push({
      icon: 'less', // TODO: There is no Figma design for this, so this is a placeholder icon.
      text: translate('questionActionsMenu.startAtOne'),
      isActive: scaleQuestionStartAtOne,
      onClickHandler: () =>
        toggleBooleanConfig(originallyStartAtOne, scaleQuestionStartAtOne, QuestionConfigType.START_AT_ONE),
    });
  }

  // Every question type can have an image uploaded to it.
  if (existingQuestionImage) {
    menuItems.push({
      icon: 'delete',
      text: translate('questionActionsMenu.removeImage'),
      onClickHandler: () => setRemoveImageDialogIsOpen(true),
    });
  } else {
    menuItems.push({
      icon: 'upload',
      text: translate('questionActionsMenu.addImage'),
      onClickHandler: () => {
        setAddImageDialogIsOpen(true);
      },
    });
  }

  // Always display the "Reset all overrides" action.
  menuItems.push({
    icon: 'delete',
    text: translate('questionActionsMenu.resetAllOverrides'),
    onClickHandler: () => setResetAllOverridesIsOpen(true),
  });

  return (
    <>
      <ThreeDotsActionsMenu menuItems={menuItems} />
      <ConfirmationDialog
        isOpen={resetAllOverridesIsOpen}
        // TODO: Don't send `setState` as a prop.
        setIsOpen={setResetAllOverridesIsOpen}
        title={translate('questionActionsMenu.resetAllOverrides')}
        submitBtnLabel={translate('questionActionsMenu.resetSubmitLabel')}
        confirmationHandler={resetAllOverridesHandler}
      >
        <div>{translate('questionActionsMenu.resetAllOverridesConfirmation')}</div>
      </ConfirmationDialog>
      <LogicActionDialog
        isOpen={logicActionDialogIsOpen}
        // TODO: Don't send `setState` as a prop.
        setIsOpen={setLogicActionDialogIsOpen}
        questionItem={questionItem}
        genericQuestion={genericQuestion}
      />
      <AddImageToQuestionDialog
        isOpen={addImageDialogIsOpen}
        closeDialog={() => setAddImageDialogIsOpen(false)}
        questionItem={questionItem}
      />
      <RemoveImageFromQuestionDialog
        isOpen={removeImageDialogIsOpen}
        // TODO: Don't send `setState` as a prop.
        setIsOpen={setRemoveImageDialogIsOpen}
        questionItem={questionItem}
      />
    </>
  );
};
