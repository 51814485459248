import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { useAsyncEffect } from 'customHooks/hooks';
import React, { useContext } from 'react';
import { FieldTitle } from 'screens/WidgetGenerator/components/FieldTitle/FieldTitle';
import { WidgetsConfigsContext } from 'screens/WidgetGenerator/contexts/WidgetsConfigsContext';
import { fetchAllClients } from 'services/client';
import { LeanClient } from 'types/client';
import styles from './WidgetAdditionalFields.module.scss';

export const BenchmarkOverallScoresAdditionalFields: React.FC = () => {
  const { handleSelectBenchmarkClient } = useContext(WidgetsConfigsContext);
  const { fetchedData: clients, isLoading, error } = useAsyncEffect(fetchAllClients, []);
  if ((!clients || error) && !isLoading) {
    throw new Error(`Error fetching clients: ${error?.message}`);
  }

  const clientOptions = clients?.map<SelectOption>((client) => ({
    value: client.id,
    label: client.state ? `${client.name}, ${client.state}` : client.name,
  }));

  const clientsById = clients?.reduce<{
    [key: number]: LeanClient;
  }>(
    (clientsByIdObject, client) => ({
      ...clientsByIdObject,
      [client.id]: client,
    }),
    {},
  );

  const handleOnClientSelect = (selectedValue: unknown) => {
    const selectedClientOption = selectedValue as { value: number };
    const selectedClient = clientsById?.[selectedClientOption.value];
    if (!selectedClient) return;
    handleSelectBenchmarkClient(selectedClient);
  };

  return (
    <div className={styles.fieldContainer}>
      <FieldTitle title="Choose the Client to get their Benchmark Question Scores:" />
      <ZCDSelect isLoading={isLoading} options={clientOptions} onChange={handleOnClientSelect} />
    </div>
  );
};
