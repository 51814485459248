import { ZCDCheckbox, ZCDInput } from '@zencity/common-ui';
import { Spacer } from 'components/Spacer/Spacer';
import React, { useContext } from 'react';
import { FieldTitle } from '../components/FieldTitle/FieldTitle';
import { WidgetType } from '../utils/misc';
import { WidgetsConfigsContext } from '../contexts/WidgetsConfigsContext';
import { getBarChartFilteredData } from '../widgets/BarChart/barChartUtils';
import styles from './WidgetAdditionalFields.module.scss';

export const SatisfactionBarChartAdditionalFields = (): React.ReactElement => {
  const {
    isBenchmarkedServices,
    rawData,
    widgetType,
    servicesBarChartNumBarsPerRow,
    handleServicesBarChartNumBarsPerRow,
    handleBenchmarkCycleServices,
    isShortBarChart,
    handleIsShortBarChartButton,
  } = useContext(WidgetsConfigsContext);
  const columnsLength =
    Array.isArray(rawData) && rawData.length && widgetType === WidgetType.SERVICES_BAR_CHART
      ? getBarChartFilteredData(rawData).length
      : 0;

  return (
    <>
      <div className={styles.fieldContainer}>
        <FieldTitle title={`Number of columns per row (total columns: ${columnsLength}):`} />
        <ZCDInput type="number" onChange={handleServicesBarChartNumBarsPerRow} value={servicesBarChartNumBarsPerRow} />
      </div>
      <ZCDCheckbox
        label="Short version for bar height"
        checked={isShortBarChart}
        onChange={handleIsShortBarChartButton}
      />
      <Spacer orientation="vertical" size="xsmall" />
      <ZCDCheckbox
        label="Show benchmark to previous cycle"
        checked={isBenchmarkedServices}
        onChange={handleBenchmarkCycleServices}
      />
    </>
  );
};
