/* eslint-disable id-denylist */
import { PaginatedResults } from 'types/misc';
import { BlockwiseClientDetailsPromise, CensusData } from 'types/surveyClientConfiguration';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';

const apiBlockwiseClientDetailsUrl = generateVaultApiUrl('/api/v2/blockwise_client_details/');
const axiosBlockwiseClientDetailsInstance = createAxiosInstance(apiBlockwiseClientDetailsUrl);

export const createBlockwiseClientDetails = async (
  blockwiseClientFormData: FormData,
  clientId: string | undefined,
): Promise<BlockwiseClientDetailsPromise> => {
  const response = await axiosBlockwiseClientDetailsInstance.post('', blockwiseClientFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Zc-Client-Id': clientId,
    },
  });
  return response.data;
};

export const retrieveBlockwiseClientDetails = async (
  clientId: string | undefined,
): Promise<BlockwiseClientDetailsPromise> => {
  const response = await axiosBlockwiseClientDetailsInstance.get('retrieve_current_client/', {
    headers: { 'X-Zc-Client-Id': clientId },
  });
  return response.data;
};

export const partialUpdateBlockwiseClientDetails = async (
  blockwiseClientDetailsPk: number | undefined,
  blockwiseClientFormData: FormData,
): Promise<BlockwiseClientDetailsPromise> => {
  const response = await axiosBlockwiseClientDetailsInstance.patch(
    `${blockwiseClientDetailsPk}/`,
    blockwiseClientFormData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
  return response.data;
};

const apiCensusDataUrl = generateVaultApiUrl('/api/v2/census_data/');
const axiosCensusDataInstance = createAxiosInstance(apiCensusDataUrl);

export const retrieveCensusData = async (clientId?: string): Promise<PaginatedResults<CensusData>> => {
  const response = await axiosCensusDataInstance.get('', {
    params: { zc_client_id: clientId },
  });

  return response.data;
};

export const createCensusData = async (censusData: CensusData, clientId?: string): Promise<CensusData> => {
  const response = await axiosCensusDataInstance.post(
    '',
    {
      survey_year: censusData?.survey_year,
      data: censusData?.data,
      population_size: censusData?.population_size,
    },
    {
      headers: { 'Content-Type': 'application/json', 'X-Zc-Client-Id': clientId },
    },
  );
  return response.data;
};

export const partialUpdateCensusData = async (censusData: CensusData): Promise<CensusData> => {
  const response = await axiosCensusDataInstance.patch(
    `${censusData.id}/`,
    {
      survey_year: censusData?.survey_year,
      data: censusData?.data,
      population_size: censusData?.population_size,
    },
    {
      headers: { 'Content-Type': 'application/json' },
    },
  );
  return response.data;
};
