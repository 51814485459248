/* eslint-disable react/jsx-props-no-spreading,max-statements,max-lines-per-function,complexity,max-lines */
import { Button } from '@zencity/common-ui';
import { ClientDropdown } from 'components/ClientDropdown/ClientDropdown';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { SearchBoxInput } from 'components/SearchBoxInput/SearchBoxInput';
import { SurveyTypeDropdown } from 'components/SurveyTypeDropdown/SurveyTypeDropdown';
import { TableRowSkeleton } from 'components/TableRowSkeleton/TableRowSkeleton';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch } from 'customHooks/hooks';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useTable } from 'react-table';
import { RouterPath, SurveyChildPath } from 'routerPaths';
import { fetchEndScreensBySurveyId } from 'services/endScreen';
import { fetchQuestionsBySurveyId } from 'services/questions';
import { debouncedFetchSurveysByParams, fetchSurveys, fetchSurveysByParams } from 'services/survey';
import { RootState } from 'store';
import { FilterOptionWithKey } from 'types/filters';
import { Survey } from 'types/survey';
import { DateFormat, formatISODate } from 'utils/dates';
import { fetchFilteredResults } from 'utils/ListTableHelpers';
import { logger } from 'utils/logger';
import { statusIsError, statusIsIdle, statusIsLoading } from 'utils/misc';
import { abbreviateLastName } from 'utils/user';
import { getParamsForFilters } from './SurveysListTable.helpers';
import styles from './SurveysListTable.module.scss';

export const SurveysListTable = (): JSX.Element => {
  const { surveys, fetchStatus, nextPage, errorMessage } = useSelector((state: RootState) => state.surveys);
  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [isLoadingSurvey, setIsLoadingSurvey] = useState(false);
  const navigate = useNavigate();

  const [searchInputValue, setSearchInputValue] = useState('');
  const [surveyTypeFilterValue, setSurveyTypeFilterValue] = useState('');
  const [clientFilterValue, setClientFilterValue] = useState<number>(0);
  const [filteredResults, setFilteredResults] = useState<Survey[]>([]);
  const [filteredResultsNextPage, setFilteredResultsNextPage] = useState<number>();
  const [isFetchingByFilter, setIsFetchingByFilter] = useState(false);

  const currentlyLoading = isFetchingByFilter || statusIsLoading(fetchStatus);
  const isFiltered = !!searchInputValue || !!clientFilterValue || !!surveyTypeFilterValue;

  const surveysData = useMemo(() => Object.values(surveys) || [], [surveys]);

  const { pathname } = useLocation();

  const columns = useMemo(
    () => [
      {
        Header: translate('surveysListTable.title'),
        accessor: 'internal_title' as keyof Survey,
      },
      {
        Header: translate('surveysListTable.client'),
        accessor: 'survey_group.client.name' as keyof Survey,
      },
      {
        Header: translate('surveysListTable.type'),
        accessor: 'survey_group.type_display' as keyof Survey,
      },
      {
        Header: translate('surveysListTable.createdBy'),
        accessor: 'created_by.full_name' as keyof Survey,
        Cell: ({ value }: { value: string }) => abbreviateLastName(value),
      },
      {
        Header: translate('surveysListTable.createdOn'),
        accessor: 'created_at' as keyof Survey,
        Cell: ({ value }: { value: string }) => formatISODate(value, DateFormat.SLASHES),
      },
    ],
    [translate],
  );

  const handleOnRowClick = (surveyId: number): void => {
    const surveysBuildScreenPath = generatePath(`${RouterPath.SURVEY}/${SurveyChildPath.BUILD}`, {
      surveyId: surveyId.toString(),
    });
    setIsLoadingSurvey(true);
    dispatch(fetchQuestionsBySurveyId({ survey: surveyId }))
      .then(async () => {
        await dispatch(fetchEndScreensBySurveyId({ survey: surveyId }));
        navigate(surveysBuildScreenPath, { state: { previousPath: pathname } });
      })
      .catch((error: unknown) => {
        toastError();
        logger.error(error);
      })
      .finally(() => setIsLoadingSurvey(false));
  };

  const onSearchInputChange = (value: string) => {
    setFilteredResultsNextPage(undefined);
    setFilteredResults([]);
    setSearchInputValue(value);
  };

  const onSurveyTypeFilterChange = (value: FilterOptionWithKey) => {
    if (value.value === 'Any') {
      setSurveyTypeFilterValue('');
      return;
    }
    setFilteredResultsNextPage(undefined);
    setFilteredResults([]);
    setSurveyTypeFilterValue(value.value as string);
  };

  const onClientFilterChange = (value: FilterOptionWithKey) => {
    setFilteredResultsNextPage(undefined);
    setFilteredResults([]);
    if (clientFilterValue === value.value) {
      setClientFilterValue(0);
      return;
    }
    setClientFilterValue(value.value as number);
  };

  /**
   * Handle the fetch when the user uses filters.
   *
   * Since this component will be in use constantly, and to
   * avoid re-fetching the same data over and over, the redux store will keep
   * the non-filtered paginated results, and when the user decides to use
   * filters, only then the component will try to re-fetch data from the server
   * depending on the filters.
   */
  useEffect(() => {
    if (statusIsIdle(fetchStatus) || statusIsLoading(fetchStatus)) {
      // Initial fetch hasn't dispatched yet.
      return;
    }

    if (searchInputValue || surveyTypeFilterValue || clientFilterValue) {
      fetchFilteredResults({
        filteredResults,
        filteredResultsNextPage,
        filters: { searchInputValue, surveyTypeFilterValue, clientFilterValue },
        setFilteredResults,
        setFilteredResultsNextPage,
        setIsFetchingByFilter,
        getParamsForFilters,
        fetchFunction: searchInputValue ? debouncedFetchSurveysByParams : fetchSurveysByParams,
        toastError,
      });
    } else {
      // When filters are empty, simply reset its structure so the non-filtered
      // paginated results from Redux's store will be displayed again.
      setFilteredResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValue, surveyTypeFilterValue, clientFilterValue]);

  const loadMore = () => {
    if (searchInputValue || clientFilterValue || surveyTypeFilterValue) {
      if (filteredResultsNextPage) {
        fetchFilteredResults({
          filteredResults,
          filteredResultsNextPage,
          filters: { searchInputValue, surveyTypeFilterValue, clientFilterValue },
          setFilteredResults,
          setFilteredResultsNextPage,
          setIsFetchingByFilter,
          getParamsForFilters,
          fetchFunction: searchInputValue ? debouncedFetchSurveysByParams : fetchSurveysByParams,
          toastError,
        });
      }
    } else {
      dispatch(fetchSurveys({ page: nextPage }));
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    // eslint-disable-next-line id-denylist
  } = useTable<Survey>({ columns, data: isFiltered ? filteredResults : surveysData });

  const displayLoadMore = (isFiltered && filteredResultsNextPage) || (!isFiltered && nextPage);

  return (
    <LoaderMask isLoading={isLoadingSurvey}>
      <div className={styles.surveysListTable}>
        <div className={styles.filtersWrapper}>
          <ClientDropdown stateValue={clientFilterValue} onChangeCallback={onClientFilterChange} />
          <SurveyTypeDropdown stateValue={surveyTypeFilterValue} onChangeCallback={onSurveyTypeFilterChange} />
          <SearchBoxInput stateValue={searchInputValue} onChangeCallback={onSearchInputChange} />
        </div>
        <table {...getTableProps({ className: styles.table })}>
          <thead className={styles.headerRow}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps({ className: styles.headerCell })}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps({ className: styles.tableBody })}>
            {rows.map((rowData) => {
              prepareRow(rowData);
              return (
                <tr
                  {...rowData.getRowProps({ className: styles.row })}
                  onClick={() => handleOnRowClick(rowData.original.id)}
                >
                  {rowData.cells.map((cell) => (
                    <td {...cell.getCellProps({ className: styles.rowCell })}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
            {currentlyLoading && <TableRowSkeleton colSpan={columns.length} />}
          </tbody>
          {displayLoadMore && (
            <tfoot>
              <tr>
                <td colSpan={columns.length}>
                  <div className={styles.spinnerWrapper}>
                    <Button type="button" variant="primary" onClick={loadMore} disabled={currentlyLoading}>
                      {translate('common.loadMore')}
                    </Button>
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
        {!currentlyLoading && !rows.length && (
          <div className={styles.spinnerWrapper}>{translate('common.noResults')}</div>
        )}
        {statusIsError(fetchStatus) && errorMessage && <div className={styles.spinnerWrapper}>{errorMessage}</div>}
      </div>
    </LoaderMask>
  );
};
